import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'feedback-cta',
  templateUrl: 'feedback-cta.component.html',
  styleUrls: ['feedback-cta.component.scss'],
})
export class FeedbackCtaComponent {
  @Input() usersRating: number = 0;
  @Input() isVerifiedAttendee: boolean = false;
  @Input() emitEvent: boolean = false;

  @Output() ctaClicked = new EventEmitter<void>();

  constructor(private router: Router) {}

  public onCtaClick(): void {
    if (this.emitEvent) {
      this.ctaClicked.emit();
      return;
    }
    
    const currentRoute = window.location.pathname;
    this.router.navigateByUrl(`${currentRoute}/review`);
  }
}
