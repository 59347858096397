<div class="ff-rich-text-editor">
  @if (readMode) {
    <div class="readonly" [innerHTML]="text"></div>
  } @else {
    @if (showEditor) {
      <angular-editor
        [attr.id]="id"
        [id]="id"
        [formControl]="textForm"
        [config]="editorConfig"
        >
        <ng-template #customButtons let-executeCommandFn="executeCommandFn">
          @if (allowHeader) {
            <ae-toolbar-set>
              <button
                class="custom-button"
                mat-icon-button
                (click)="executeCommandForEditor('h1', executeCommandFn)"
                >
                <mat-icon>add</mat-icon>
              </button>
              <button
                class="custom-button"
                mat-icon-button
                (click)="executeCommandForEditor('h5', executeCommandFn)"
                >
                <mat-icon>remove</mat-icon>
              </button>
            </ae-toolbar-set>
          }
        </ng-template>
      </angular-editor>
    }
  }
</div>
