import { FileType } from '../media-api.service';

export enum UploadStatus {
  Started = 0,
  InProgress = 1,
  Done = 2,
  Failed = 3,
  NotStarted = 4,
}

export interface IUpload {
  id: string;
  userId: string;
  fileType: FileType;
  fileUrl?: string;
  captionUrl?: string;
  resourceName: string;
  status: UploadStatus;
  captionStatus: UploadStatus;
  uploadProgressPercentage: number;
  captionsUploadProgressPercentage: number;
  diskFileId?: string;
  thumbnailUrl?: string;
}

export const DEFAULT_UPLOAD: IUpload = {
  id: '',
  userId: '',
  fileType: FileType.Video,
  resourceName: '',
  status: UploadStatus.Done,
  uploadProgressPercentage: 0,
  captionStatus: UploadStatus.Done,
  captionsUploadProgressPercentage: 0,
};
