import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { AdminGuardService } from './core/admin.guard';
import { ErrorsComponent } from './core/errors-page/errors-page.component';
import { LoginRedirectComponent } from './login-redirect/login-redirect.component';
import { PrivacyComponent } from './public/terms-and-conditions/privacy.component';
import { TermsComponent } from './public/terms-and-conditions/terms.component';

const routes: Routes = [
  localStorage.getItem('usrid')
    ? {
        path: '',
        pathMatch: 'full',
        redirectTo: localStorage.getItem('noSubscription')
          ? 'plans'
          : 'dashboard',
      }
    : {
        path: '',
        loadChildren: () =>
          import('./public/public.module').then((m) => m.PublicModule),
      },
  {
    path: 'login',
    component: LoginRedirectComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {
      title: 'Privacy - Future Females',
    },
  },
  {
    path: 'terms',
    component: TermsComponent,
    data: {
      title: 'Terms - Future Females',
    },
  },
  {
    path: 'events',
    loadChildren: () =>
      import('./events/events.module').then((m) => m.EventsModule),
  },
  {
    path: 'courses',
    loadChildren: () =>
      import('./courses/courses.module').then((m) => m.CoursesModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'discussions',
    loadChildren: () =>
      import('./discussions/discussion.module').then((m) => m.DiscussionModule),
  },
  {
    path: 'admin',
    canActivate: mapToCanActivate([AdminGuardService]),
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'plans',
    loadChildren: () =>
      import('./plans/plans.module').then((m) => m.PlansModule),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutModule),
  },
  {
    path: 'errors',
    component: ErrorsComponent,
    data: {
      title: 'Something went wrong',
    },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  { path: '**', pathMatch: 'full', component: ErrorsComponent },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: !BrowserUtils.isInIframe()
        ? 'enabledNonBlocking'
        : 'disabled', // Don't perform initial navigation in iframes
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
