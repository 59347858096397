<div class="members-list">
  <div class="heading">
    <div class="title mat-caption-1">
      <div>Members</div>
      <mat-form-field
        class="w-100 me-2 my-1"
        appearance="outline"
        color="accent"
        >
        <mat-select [formControl]="sortBy">
          @for (option of sortOptions; track option) {
            <mat-option [value]="option">
              {{ option.viewValue }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="content">
    <div class="members">
      @for (member of membersList; track member) {
        <div
          class="member"
          >
          <div class="info" (click)="openMemberDialog(member)">
            <img [src]="member.profilePictureUrl" alt="" />
            <div class="name pointer">{{ member.firstName }} {{ member.lastName }}</div>
          </div>
        </div>
      }

      @if (totalCount > shownCount) {
        <div
          class="d-flex justify-content-end px-3 mt-2 mat-caption"
          >
          <u class="pointer" (click)="loadMore()"
            >See more ({{ totalCount - shownCount }})</u
            >
          </div>
        }
      </div>
    </div>
  </div>
