export const FREE_TRIAL: string = 'FF_PLATFORM_FREETRIAL';
export const PAYING = 'FF_PLATFORM_PAYINGMEMBER';
export const FREE_TRIAL_CANCELLED = 'FF_PLATFORM_FREETRIALCANCELLED';
export const NO_PLAN_SELECTED = 'FF_PLATFORM_SIGNEDUP_NOPLANSELECTED';
export const MEMBERSHIP_CANCELLED = 'FF_PLATFORM_MEMBERSHIPCANCELLED';

export type ConvertKitTag =
  | 'FF_PLATFORM_FREETRIAL'
  | 'FF_PLATFORM_PAYINGMEMBER'
  | 'FF_PLATFORM_FREETRIALCANCELLED'
  | 'FF_PLATFORM_SIGNEDUP_NOPLANSELECTED'
  | 'FF_PLATFORM_MEMBERSHIPCANCELLED';
