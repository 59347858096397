<div>
  <input
    type="text"
    class="my-search-box"
    (keydown.enter)="$event.preventDefault()"
    placeholder="Search Location"
    type="text"
    #search
  />
</div>

<google-map
  #myGoogleMap
  height="500px"
  width="100%"
  [zoom]="zoom"
  [center]="center"
  [options]="options"
  (centerChanged)="onCenterChange()"
></google-map>
