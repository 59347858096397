<div class="ff-date-range-calendar__shared">
  <div
    class="range-calendar-field d-flex align-items-center"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="intervalPick"
    >
    <div class="d-flex align-items-center">
      <mat-icon class="label me-2">calendar_month</mat-icon>
      @if (!selectedInterval.custom) {
        <span>{{ selectedInterval.title }}</span>
      }
      @if (selectedInterval.custom) {
        <span>
          @if (startsAfterUtc) {
            <span>{{ startsAfterUtc | date }}</span>
            @if (startsBeforeUtc) {
              <span> - {{ startsBeforeUtc | date }}</span>
            }
          }
          @if (!startsAfterUtc) {
            {{ selectedInterval.title }}
          }
        </span>
      }
    </div>
  </div>
</div>

<mat-menu
  [xPosition]="'before'"
  #intervalPick="matMenu"
  class="ff-date-range-calendar__shared-menu"
  >
  <div class="d-block d-sm-flex">
    <div class="time-intervals my-2">
      @for (interval of intervals; track interval) {
        @if (!interval.custom) {
          <div
            class="filter-label"
            [class.selected]="interval.id === selectedInterval.id"
            (click)="selectInterval(interval)"
            >
            {{ interval.title }}
          </div>
        }
        @if (interval.custom) {
          <div
            class="filter-label"
            [class.selected]="interval.id === selectedInterval.id"
            (click)="toggleAnyDateCalendar($event, interval)"
            >
            {{ interval.title }}
          </div>
        }
      }
    </div>
    @if (isAnyDateShown) {
      <mat-divider [vertical]="true"></mat-divider>
    }
    @if (isAnyDateShown) {
      <div
        class="range-calendar my-2"
        (click)="$event.stopPropagation()"
        >
        <range-calendar
          [startsAfterUtc]="startsAfterUtc"
          [startsBeforeUtc]="startsBeforeUtc"
          (interval)="selectedChange($event)"
        ></range-calendar>
        <mat-divider></mat-divider>
        <div
          class="d-flex justify-content-between mt-3 px-3 calendar-cta-container"
          >
          <button
            mat-flat-button
            class="ff-base-button"
            (click)="menuTrigger.closeMenu()"
            >
            Cancel
          </button>
          <button
            mat-flat-button
            color="accent"
            (click)="onApplyCustomDates(menuTrigger)"
            >
            Apply
          </button>
        </div>
      </div>
    }
  </div>
</mat-menu>
