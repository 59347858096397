import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FFInsightsService } from '../core/logging.service';
import { API_BASE_URL } from '../shared/app-constants';
import { ApiServiceHelper } from '../shared/helpers';
import { IPage } from '../shared/pagination';
import {
  IUserAPI,
  IUserFilter,
  IUserPatch,
  IUserPost,
  IUserQuery,
  IUserStatsAPI,
  IUserTokenResponse,
  IdentityProvider,
} from './user';

@Injectable()
export class UsersApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
    private ffInsightsService: FFInsightsService,
  ) {}

  public getUsers(filter: IUserFilter): Observable<IPage<IUserAPI>> {
    const url = `${this.apiBaseUrl}/Users`;
    return this.httpClient.get<IPage<IUserAPI>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public getUserAsync(
    id: string,
    filter: IUserQuery = {},
  ): Observable<IUserAPI> {
    const url = `${this.apiBaseUrl}/Users/${id}`;
    return this.httpClient.get<IUserAPI>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public getUserIdentityProviderAsync(email: string): Observable<IdentityProvider> {
    const url = `${this.apiBaseUrl}/Users/identity-provider`;
    return this.httpClient.get<IdentityProvider>(url, {
      params: ApiServiceHelper.getQueryParams({ email }),
    });
  }

  public getUserStatsAsync(
    id: string,
    filter: IUserQuery = {},
  ): Observable<IUserStatsAPI> {
    const url = `${this.apiBaseUrl}/Users/${id}/stats`;
    return this.httpClient.get<IUserStatsAPI>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public createUserAsync(
    user: IUserPost,
    affiliateCode?: string,
    subscriptionCode?: string,
  ): Observable<IUserTokenResponse> {
    const sessionId = sessionStorage.getItem('ff_user_session_id');

    if (!sessionId) {
      throw new Error('No session id found in session storage');
    }

    let body = { ...user, sessionId };

    if (!!affiliateCode) {
      body = { ...body, affiliateCode };
      this.ffInsightsService.logEvent(
        '[Info] User req to create account with affiliateCode',
        { body },
      );
    }
    if (!!subscriptionCode) {
      body = { ...body, subscriptionCode };
      this.ffInsightsService.logEvent(
        '[Info] User req to create account with subscriptionCode',
        { body },
      );
    }
    const url = `${this.apiBaseUrl}/Users`;
    return this.httpClient.post<IUserTokenResponse>(url, body);
  }

  public updateUserAsync(
    userId: string,
    payload: IUserPatch,
  ): Observable<IUserTokenResponse> {
    const url = `${this.apiBaseUrl}/users/${userId}`;
    return this.httpClient.patch<IUserTokenResponse>(url, { ...payload });
  }
}
