import { ILabel, ILabelReadModel } from '../shared/label';
import { IFacilitator } from '../facilitators/facilitator';
import { ICityCountry } from '../shared/models/location';
import { ISponsor } from '../sponsors/sponsor';
import { IResource } from '../shared/resources/resource';
import { FfLanguage } from '../shared/models/language';
import { DifficultyLevel } from '../shared/enums/difficulty-level';
import { INote } from '../shared/models/notes';
import { FacilitatorType } from '../shared/speakers/speaker';
import { IDiscussionSummary } from '../shared/discussions/discussion';
import { IFeedback } from '../shared/feedback/feedback';

export enum EventType {
  Free,
  Special,
  Exclusive,
}

export enum Currency {
  USD = 0,
  EUR = 1,
}

export interface IEventLabelsReadModel {
  categories: ILabelReadModel[];
  topics: ILabelReadModel[];
}

export interface IUserEvent {
  eventId: string;
  userId: string;
  isViewed: boolean;
  isActive: boolean;
  isOnWaitlist: boolean;
  isRegistered: boolean;
  isLiked: boolean;
  isOnFavorite: boolean;
  notificationsEnabled: boolean;
  waitlistPosition: number | null;
  feedback?: IFeedback;
  participationConfirmedAtUtc: string | null;
  completedWatchRecording?: string | null;
  furthestVideoTimeStamp?: string | null;
  lastVideoTimeStamp?: string | null;

  locationUrl?: string;
  notes?: INote[];
}

export interface IPatchUserEvent {
  eventId: string;
  isViewed?: boolean;
  isOnFavorite?: boolean;
  notificationsEnabled?: boolean;
  join?: boolean;
  becomeActive?: boolean;
  notes?: string;

  isRecommended?: boolean;
  confirmParticipation?: boolean;
  recordingTimestamp?: string;
  completeWatchRecording?: boolean;
}

export enum LocationType {
  InPerson,
  Online,
}

export interface IUpdateEventFacilitator {
  type: FacilitatorType;
}

export interface IEventUpdate {
  id: string;
  title?: string;
  description?: string;
  agendaItems?: string[];
  startsAtUtc?: string;
  endsAtUtc?: string;

  registrationStartsAtUtc?: string;
  registrationEndsAtUtc?: string;

  locationType?: LocationType;
  locationUrl?: string;
  latitude?: number;
  longitude?: number;

  address?: string;
  city?: string;
  country?: string;

  language?: string;
  timeZoneOffsetMinutes?: number;
  difficultyLevel?: DifficultyLevel;
  price?: number;
  currency?: Currency;
  maxCapacity?: number;
  eventType?: EventType;
  banner?: string;
  isRecorded?: boolean;
  shareUrl?: string;
  thumbnailUrl?: string;

  videoUrl?: string;
  captionUrl?: string;

  rating?: number;
  facilitators?: IFacilitator[];
  categoryIds?: string[];
  topicIds?: string[];

  confirmParticipationHoursLeftUntilEventStarts?: number;
  notifyConfirmParticipationHoursLeftUntilEventStarts?: number;
}

export interface IEventPost {
  id?: string;
  title: string;
  description: string;
  agendaItems: string[];
  language: FfLanguage;
  thumbnailUrl: string;
  startsAtUtc: string;
  endsAtUtc: string;
  timeZoneOffsetMinutes: number;
  difficultyLevel: DifficultyLevel;
  eventType: EventType;
  isRecorded: boolean;
  registrationStartsAtUtc: string;
  registrationEndsAtUtc: string;
  locationType: LocationType;
  hiddenAtUtc?: string;

  sponsors?: string[];
  thumbnail?: File;
}

export interface IEventSummaryModel {
  id: string;
  title?: string;
  description?: string;
  startsAtUtc: string;
  endsAtUtc: string;
  thumbnailUrl?: string;
}

export interface IEventFacilitator extends IFacilitator {
  type: FacilitatorType;

  speakerIndex?: number;
  speakerTotal?: number;
}

export interface IEvent {
  id: string;
  title?: string;
  description?: string;
  agendaItems?: string[];
  startsAtUtc: string;
  endsAtUtc: string;
  durationSeconds: number;
  registrationStartsAtUtc?: string;
  registrationEndsAtUtc?: string;
  locationType: LocationType;
  locationUrl?: string;
  latitude?: number;
  longitude?: number;
  address?: string;
  cityCountry?: ICityCountry;
  language?: FfLanguage;
  timeZoneOffsetMinutes: number;
  difficultyLevel: DifficultyLevel;
  price: number;
  currency: Currency;
  maxCapacity?: number;
  videoUrl?: string;
  captionUrl?: string;
  audioUrl?: string;
  transcript?: string;
  eventType: EventType;
  rating: number;
  banner?: string;
  isRecorded: boolean;
  shareUrl?: string;
  isInPerson: boolean;
  thumbnailUrl?: string;

  activePeople: number;
  viewedByPeople: number;
  onWaitlistPeople: number;
  registeredPeople: number;
  onFavoritePeople: number;
  likedByPeople: number;

  videoUploadId?: string;

  feedbacksCount: number;

  confirmParticipationHoursLeftUntilEventStarts?: number;
  notifyConfirmParticipationHoursLeftUntilEventStarts?: number;

  eventForCurrentUser?: IUserEvent;

  facilitators: IEventFacilitator[];

  relatedEvents: IEventSummaryModel[];
  resources: IResource[];

  categories: ILabel[];
  topics: ILabel[];
  sponsors: ISponsor[];

  discussion: IDiscussionSummary;
}

export class Event implements IEvent {
  public id!: string;
  public title: string | undefined;
  public description: string | undefined;
  public agendaItems: string[] = [];
  public startsAtUtc!: string;
  public endsAtUtc!: string;
  public durationSeconds!: number;
  public registrationStartsAtUtc?: string | undefined;
  public registrationEndsAtUtc?: string | undefined;
  public locationType!: LocationType;
  public locationUrl?: string | undefined;
  public latitude?: number | undefined;
  public longitude?: number | undefined;
  public address?: string | undefined;
  public cityCountry?: ICityCountry | undefined;
  public language?: FfLanguage;
  public timeZoneOffsetMinutes!: number;
  public difficultyLevel!: DifficultyLevel;
  public price!: number;
  public currency!: Currency;
  public maxCapacity?: number | undefined;
  public videoUrl?: string | undefined;
  public videoUploadId?: string | undefined;
  public captionUrl?: string | undefined;
  public audioUrl?: string | undefined;
  public transcript?: string | undefined;
  public eventType!: EventType;
  public rating!: number;
  public banner?: string | undefined;
  public isRecorded!: boolean;
  public hiddenAtUtc!: boolean;
  public shareUrl?: string | undefined;
  public isInPerson!: boolean;
  public thumbnailUrl?: string | undefined;
  public activePeople!: number;
  public viewedByPeople!: number;
  public onWaitlistPeople!: number;
  public registeredPeople!: number;
  public onFavoritePeople!: number;
  public likedByPeople!: number;
  public eventForCurrentUser?: IUserEvent | undefined;
  public feedbacksCount: number = 0;
  public discussion!: IDiscussionSummary;

  public facilitators!: IEventFacilitator[];
  public relatedEvents!: IEventSummaryModel[];
  public categories!: ILabel[];
  public topics!: ILabel[];

  public resources: IResource[] = [];

  public isInThePast?: boolean;

  public confirmParticipationHoursLeftUntilEventStarts?: number | undefined;
  public notifyConfirmParticipationHoursLeftUntilEventStarts?:
    | number
    | undefined;

  public sponsors: ISponsor[] = [];

  constructor(event: IEvent) {
    for (let key in event) {
      (this as any)[key] = (event as any)[key];
    }
  }

  public isCurrentUserAttending(): boolean {
    return !!this.eventForCurrentUser?.isRegistered;
  }

  public isCurrentEventOnFavorite(): boolean {
    return !!this.eventForCurrentUser?.isOnFavorite;
  }

  public isCurrentUserOnWaitlist(): boolean {
    return !!this.eventForCurrentUser?.isOnWaitlist;
  }

  public getSpeakersNames(): string[] {
    const speakers = this.facilitators.map((f: IFacilitator) => f.name);
    if (this.facilitators.length > 4) {
      return [
        ...speakers.filter((facilitator, index) => index < 4),
        'and more',
      ];
    }
    return speakers;
  }

  public hasLimitedSeating(): boolean {
    return this.maxCapacity !== null;
  }

  public hasAvailableSeats(): boolean {
    if (!this.hasLimitedSeating()) {
      return true;
    }

    return this.maxCapacity! > this.registeredPeople;
  }

  public getActivePeopleCount(): number {
    return this.activePeople;
  }

  public getviewedByPeopleCount(): number {
    return this.viewedByPeople;
  }

  public getTopics(): ILabel[] {
    return this.topics;
  }

  public getCategories(): ILabel[] {
    return this.categories;
  }

  public hasWaitlist(): boolean {
    return this.onWaitlistPeople > 0;
  }
}
