import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'login-redirect',
  templateUrl: 'login-redirect.component.html',
  styleUrls: ['login-redirect.component.scss'],
})
export class LoginRedirectComponent {
  constructor(
    private authService: MsalService,
    private activatedRoute: ActivatedRoute,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {
    const domainHint =
      this.activatedRoute.snapshot.queryParamMap.get('domainHint');
    const path = this.activatedRoute.snapshot.queryParamMap.get('path');

    const redirectStartPage = !!path
      ? `${environment.appBaseUrl}/${path}`
      : `${environment.appBaseUrl}/dashboard`;

    if (!!domainHint) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        redirectStartPage,
        domainHint,
      } as RedirectRequest);
    }
  }
}
