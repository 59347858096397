import { ImgCropperConfig } from '@alyle/ui/image-cropper';
import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageHelper } from '../image-cropper/helper';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';

@Component({
  templateUrl: 'add-image-dialog.component.html',
})
export class AddImageDialogComponent {
  public extension: string | null = null;
  public error: string | null = null;

  public cropperImage: string = '';
  public croppedImage: string = '';
  public cropperConfig: ImgCropperConfig = {
    width: 720,
    height: 370,
    type: 'image/jpeg',
    output: ImageHelper.CropOutputSize.landscape,
  };
  @ViewChild('cropper')
  public cropper?: ImageCropperComponent;

  constructor(public dialogRef: MatDialogRef<AddImageDialogComponent>) {}

  public onThumbnailSelected({
    image,
    extension,
  }: {
    image: string;
    extension: string;
  }): void {
    this.cropperImage = image;
    this.extension = extension;
    this.croppedImage = image;
  }

  public clearThumbnail(): void {
    this.cropperImage = '';
    this.croppedImage = '';
  }

  public onSubmit(): void {
    this.cropper?.cropper?.crop();

    setTimeout(() => {
      if (!this.croppedImage) {
        this.error = 'No image was uploaded';
        return;
      }

      this.dialogRef.close({
        image: this.croppedImage,
        extension: this.extension ?? 'jpeg',
      });
    }, 0);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
