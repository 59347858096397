export class UserAdmin {
  public isAnyTypeOfAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  
  private featureAdminMap: { [key: string]: boolean } = {};

  constructor(roles: string[]) {
    this.isAnyTypeOfAdmin = !!roles.find((r) => r.indexOf('Admin') > -1);

    Object.values(FeatureAdminRoles).forEach((role: string) => {
      this.featureAdminMap[role] = false;
    });

    roles.forEach((role: string) => {
      this.featureAdminMap[role] = true;
    });
    this.isSuperAdmin = this.featureAdminMap[FeatureAdminRoles.Super];
  }

  public isAdminForFeature(feature: FeatureAdminRoles): boolean {
    return this.featureAdminMap[feature];
  }
}

export enum FeatureAdminRoles {
  Super = 'Super Admin',
  Events = 'Event Admin',
  Courses = 'Course Admin',
  Labels = 'Label Admin',
  Faqs = 'FAQ Admin',
  Plans = 'Subscription Plan Admin',
  Member = 'Member Admin'
}
