import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as countrycitystatejson from 'country-state-city';
import { IDiscussionMember } from '../../shared/discussions/discussion';
import { UsersApiService } from '../../user/users-api.service';
import { IUserAPI, IUserStatsAPI } from '../../user/user';
import {
  SocialMediaAPIService,
  SocialMediaType,
} from '../../shared/social-media-api.service';
import { zip } from 'rxjs';
import { SocialMediaTypes } from '../../share/share';

@Component({
  templateUrl: './discussions-member-dialog.component.html',
  styleUrls: ['./discussions-member-dialog.component.scss'],
})
export class DiscussionsMemberDialog implements OnInit {
  public member!: IDiscussionMember;
  public user?: IUserStatsAPI;
  public location: string = '';
  public learningPreferences?: string;
  public socialMediaIcons = SocialMediaAPIService.iconsClass;
  public socialMediaTypes?: SocialMediaType[] = [];
  public userSocialMediaTypes?: SocialMediaType[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      member: IDiscussionMember;
    },
    public dialogRef: MatDialogRef<DiscussionsMemberDialog>,

    private usersApiService: UsersApiService,
    private socialMediaAPIService: SocialMediaAPIService,
  ) {
    this.member = data.member;
  }

  public ngOnInit(): void {
    zip(
      this.usersApiService.getUserStatsAsync(this.member.id),
      this.socialMediaAPIService.getSocialMediaTypes(),
    ).subscribe(([user, socialMediaTypes]) => {
      this.userSocialMediaTypes = this.computeSocialTypes(
        user,
        socialMediaTypes,
      );
      this.computeUserDetails(user);
    });
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  private computeUserDetails(user?: IUserStatsAPI) {
    if (!user) {
      return;
    }

    this.user = user;
    this.location = this.getUserLocation(user);
    this.learningPreferences = user.userLearningPreferences
      .map((preference) => preference.name)
      .join(', ');
  }

  private getUserLocation(user: IUserStatsAPI): string {
    let country = '';

    const isoCode = countrycitystatejson.Country.getAllCountries().find(
      (country: any) => {
        return country.name === user.location?.country;
      },
    )?.isoCode;

    country = isoCode || user.location?.country;

    let location = country;
    if (user.location?.city) {
      location = `${user.location?.city}, ${user.location.regionName}, ${country}`;
    }

    return location;
  }

  private computeSocialTypes(
    user: IUserStatsAPI,
    socialMediaTypes: SocialMediaType[],
  ): SocialMediaType[] {
    this.socialMediaTypes = socialMediaTypes.filter(
      (media) => media.socialMediaType !== SocialMediaTypes.WhatsApp,
    );

    return this.socialMediaTypes.map((socialMediaType) => {
      const socialMediaLink = user.socialMediaLinks.find((link) => {
        return link.type === socialMediaType.socialMediaType;
      });

      return {
        name: socialMediaType.name,
        socialMediaType: socialMediaType.socialMediaType,
        url: socialMediaLink?.url,
      };
    }) as SocialMediaType[];
  }
}
