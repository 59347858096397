import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { concatMap, filter, Observable, of } from 'rxjs';
import { IStringResponse } from 'src/app/shared/api/string-response';
import { API_BASE_URL } from 'src/app/shared/app-constants';
import { UserAdmin } from 'src/app/user/admin';
import { UsersService } from 'src/app/user/users.service';
import { IUpload } from './upload';
import { Feature } from 'src/app/shared/enums/features';
import { ApiServiceHelper } from 'src/app/shared/helpers';

@Injectable()
export class UploadApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
    public usersService: UsersService,
  ) {}

  public getEventUploadStatus(
    uploadId: string,
  ): Observable<IUpload | undefined> {
    if (!this.usersService.isAnyTypeOfAdmin()) {
      return of(undefined);
    }
    const url = `${this.apiBaseUrl}/uploads/${uploadId}`;
    return this.httpClient.get<IUpload | undefined>(url);
  }

  public getLatestUploadForFeature(
    feature: Feature,
    hasVideo?: boolean,
  ): Observable<IUpload | undefined> {
    const url = `${this.apiBaseUrl}/uploads/feature`;
    return this.httpClient.get<IUpload | undefined>(url, {
      params: ApiServiceHelper.getQueryParams({ feature, hasVideo }),
    });
  }

  public createUpload(body: any): Observable<IStringResponse | undefined> {
    if (!this.usersService.isAnyTypeOfAdmin()) {
      return of(undefined);
    }
    const url = `${this.apiBaseUrl}/uploads`;
    return this.httpClient.post<IStringResponse>(url, body);
  }

  public changeThumbnail(
    id: string,
    timeStamp: string,
  ): Observable<IStringResponse | undefined> {
    if (!this.usersService.isAnyTypeOfAdmin()) {
      return of(undefined);
    }
    const url = `${this.apiBaseUrl}/uploads/${id}/thumbnail`;
    return this.httpClient.patch<IStringResponse>(url, { timeStamp });
  }
}
