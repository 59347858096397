<div>
  <div class="d-flex align-items-center">
    <star-rating [readonly]="true" [smallDisplay]="true" [initialRating]="5"></star-rating>
    <span class="ms-2 percentage-text-margin-top">{{ displayedFeedbacks.fiveStar }}%</span>
  </div>
  <div class="d-flex align-items-center">
    <star-rating [readonly]="true" [smallDisplay]="true" [initialRating]="4"></star-rating>
    <span class="ms-2 percentage-text-margin-top">{{ displayedFeedbacks.fourStar }}%</span>
  </div>
  <div class="d-flex align-items-center">
    <star-rating [readonly]="true" [smallDisplay]="true" [initialRating]="3"></star-rating>
    <span class="ms-2 percentage-text-margin-top">{{ displayedFeedbacks.threeStar }}%</span>
  </div>
  <div class="d-flex align-items-center">
    <star-rating [readonly]="true" [smallDisplay]="true" [initialRating]="2"></star-rating>
    <span class="ms-2 percentage-text-margin-top">{{ displayedFeedbacks.twoStar }}%</span>
  </div>
  <div class="d-flex align-items-center">
    <star-rating [readonly]="true" [smallDisplay]="true" [initialRating]="1"></star-rating>
    <span class="ms-2 percentage-text-margin-top">{{ displayedFeedbacks.oneStar }}%</span>
  </div>
</div>
