import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { flatten } from 'lodash';
import { forkJoin, map, Observable, of } from 'rxjs';
import { IStringResponse } from './api/string-response';
import { API_BASE_URL } from './app-constants';
import { ApiServiceHelper } from './helpers';
import { ILabel, ILabelFilter, ILabelPost, LabelType } from './label';
import { IPage } from './pagination';

@Injectable()
export class LabelApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getLabelCollection(filter: ILabelFilter): Observable<IPage<ILabel>> {
    const url = `${this.apiBaseUrl}/labels`;
    return this.httpClient.get<IPage<ILabel>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public getLabelsBasedOnTypeCollection(
    filter: ILabelFilter,
    labelTypes: LabelType[],
  ): Observable<ILabel[]> {
    const url = `${this.apiBaseUrl}/labels`;
    if (!labelTypes) {
      return of([]);
    }
    const requests = labelTypes.map((labelType) =>
      this.getLabelCollection({ ...filter, labelType }),
    );

    return forkJoin(requests).pipe(
      map((pages: IPage<ILabel>[]) => {
        const itemsArray = pages.map((p) => p.items);
        return flatten(itemsArray).sort((a: ILabel, b: ILabel) =>
          a.title > b.title ? 1 : a.title < b.title ? -1 : 0,
        );
      }),
    );
  }

  public postLabel(labelPost: ILabelPost): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/labels`;
    return this.httpClient.post<IStringResponse>(url, labelPost);
  }

  public deleteLabel(id: string): Observable<void> {
    const url = `${this.apiBaseUrl}/labels/${id}`;
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.httpClient.delete<void>(url, { headers, body: { id } });
  }
}
