import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { AngularMaterialModule } from './angular-material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CodeInputModule } from 'angular-code-input';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareModule } from 'ngx-sharebuttons';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';

import { DateTimePickerComponent } from './date/date-time.component';
import { GmapsComponent } from './gmaps/gmaps.component';
import { AddImageDialogComponent } from './add-image-dialog/add-image-dialog.component';
import { ChipAutocompleteComponent } from './chip-autocmoplete/chip-autocomplete.component';
import { FacebookButtonComponent } from '../share/components/buttons/facebook-button/facebook-button.component';
import { LinkedinButtonComponent } from '../share/components/buttons/linkedin-button/linkedin-button.component';
import { TwitterButtonComponent } from '../share/components/buttons/twitter-button/twitter-button.component';
import { WhatsappButtonComponent } from '../share/components/buttons/whatsapp-button/whatsapp-button.component';
import { FfCarouselComponent } from './ff-carousel/ff-carousel.component';
import { FieldFiltersComponent } from './filters/field-filters/field-filters.component';
import { LabelListComponent } from './filters/topics-and-categories/label-list/label-list.component';
import { TopicsAndCategoriesComponent } from './filters/topics-and-categories/topics-and-categories.component';
import { SidebarComponent } from '../core/sidebar/sidebar.component';
import { ImageGallerySelectComponent } from './image-gallery-select/image-gallery-select.component';
import { RichEditorComponent } from './rich-editor/rich-editor.component';
import { FFResourcesComponent } from './resources/resources.component';
import { DiscussionRootComponent } from './discussions/root/root.component';
import { DiscussionComponent } from './discussions/discussion.component';
import { ReportDiscussionDialog } from './discussions/report-dialog/report-dialog.component';
import { FFFrequentlyAskedQuestionsComponent } from './entity-faq/faq.component';
import { NoteComponent } from './notes/note/note.component';
import { AddSpeakerDialogComponent } from './speakers/add-speaker-dialog/add-speaker-dialog.component';
import { SelectExistingFacilitatorsDialog } from './speakers/select-existing-dialog/select-existing-dialog.component';
import { ConfirmPhoneModalComponent } from './confirm-phone-modal/confirm-phone-modal.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatTabGroupScrollDirective } from './mat-tab-group.directive';
import { SmallLoadingIndicatorComponent } from './small-loading-indicator/small-loading-indicator.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { CourseListCardComponent } from './course-list-card/course-list-card.component';
import { EmojisComponent } from './emojis/emojis.component';
import { EventListCard } from './event-list-card/event-list-card.component';
import { DiscussionCardComponent } from './discussions/discussion-card/discussion-card.component';
import { FilledProgressIndicator } from './filled-progress-indicator/filled-progress-indicator.component';
import { VideoUploadProgress } from './video-upload-progress/video-upload-progress.component';
import { VideoUploadComponent } from './video-upload/video-upload.component';
import { BackNavButtonComponent } from './back-nav-button/back-nav-button.component';
import { DiscussionsMembersListComponent } from './discussions/discussions-members-list/discussions-members-list.component';
import { EntityFAQAccordionComponent } from './entity-faq/entity-faq-accordion/entity-faq-accordion.component';
import {
  CustomHeader,
  RangeCalendarComponent,
} from './date/range-calendar/range-calendar.component';
import { CalendarFilterComponent } from './filters/calendar-range/calendar-filter.component';
import { CalendarFilterMobileComponent } from './filters/calendar-range/mobile/mobile.component';
import { LocationFilterComponent } from './filters/field-filters/location-filter/location-filter.component';
import { LocationDropdownComponent } from './filters/field-filters/location-filter/location-dropdown/location-dropdown.component';
import { FeedbackPageComponent } from './feedback/feedback.component';
import { StarRatingComponent } from './feedback/rating/rating.component';
import { FeedbackCtaComponent } from './feedback/feedback-cta/feedback-cta.component';
import { FeedbackSummaryComponent } from './feedback/feedback-summary/feedback-summary.component';
import { VideoPlayerMK } from './video-player-mk/video-player-mk.component';

@NgModule({
  declarations: [
    DateTimePickerComponent,
    ImageCropperComponent,
    GmapsComponent,
    AddImageDialogComponent,
    ChipAutocompleteComponent,
    TwitterButtonComponent,
    FacebookButtonComponent,
    LinkedinButtonComponent,
    WhatsappButtonComponent,
    FfCarouselComponent,
    FieldFiltersComponent,
    LabelListComponent,
    TopicsAndCategoriesComponent,
    SidebarComponent,
    ImageGallerySelectComponent,
    RichEditorComponent,
    FFResourcesComponent,
    FFFrequentlyAskedQuestionsComponent,
    DiscussionRootComponent,
    DiscussionComponent,
    ReportDiscussionDialog,
    NoteComponent,
    AddSpeakerDialogComponent,
    SelectExistingFacilitatorsDialog,
    ConfirmPhoneModalComponent,
    SpinnerComponent,
    MatTabGroupScrollDirective,
    SmallLoadingIndicatorComponent,
    ImageCropperComponent,
    CourseListCardComponent,
    EmojisComponent,
    EventListCard,
    DiscussionCardComponent,
    FilledProgressIndicator,
    VideoUploadComponent,
    VideoUploadProgress,
    BackNavButtonComponent,
    DiscussionsMembersListComponent,
    EntityFAQAccordionComponent,
    RangeCalendarComponent,
    CustomHeader,
    CalendarFilterComponent,
    CalendarFilterMobileComponent,
    LocationFilterComponent,
    LocationDropdownComponent,
    FeedbackPageComponent,
    StarRatingComponent,
    FeedbackCtaComponent,
    FeedbackSummaryComponent,
    VideoPlayerMK,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    ShareModule,
    DragDropModule,
    AngularEditorModule,
    LyImageCropperModule,
    LySliderModule,
    NgcCookieConsentModule,
    CodeInputModule.forRoot({
      codeLength: 6,
      isCharsCode: true,
      code: '',
    }),
  ],
  exports: [
    AngularMaterialModule,
    DateTimePickerComponent,
    GmapsComponent,
    ImageCropperComponent,
    AddImageDialogComponent,
    ChipAutocompleteComponent,
    TwitterButtonComponent,
    FacebookButtonComponent,
    LinkedinButtonComponent,
    WhatsappButtonComponent,
    FfCarouselComponent,
    FieldFiltersComponent,
    TopicsAndCategoriesComponent,
    SidebarComponent,
    ImageGallerySelectComponent,
    RichEditorComponent,
    FFFrequentlyAskedQuestionsComponent,
    FFResourcesComponent,
    DiscussionRootComponent,
    LabelListComponent,
    NoteComponent,
    AddSpeakerDialogComponent,
    SelectExistingFacilitatorsDialog,
    ConfirmPhoneModalComponent,
    SpinnerComponent,
    MatTabGroupScrollDirective,
    SmallLoadingIndicatorComponent,
    CourseListCardComponent,
    EmojisComponent,
    EventListCard,
    DiscussionCardComponent,
    FilledProgressIndicator,
    VideoUploadComponent,
    VideoUploadProgress,
    BackNavButtonComponent,
    DiscussionsMembersListComponent,
    EntityFAQAccordionComponent,
    RangeCalendarComponent,
    CalendarFilterComponent,
    CalendarFilterMobileComponent,
    LocationFilterComponent,
    LocationDropdownComponent,
    FeedbackPageComponent,
    StarRatingComponent,
    FeedbackCtaComponent,
    FeedbackSummaryComponent,
    VideoPlayerMK,
  ],
})
export class SharedModule {}
