<div class="row mb-4 mx-0" [class.discussion-container]="canHaveChildLevel">
  <div class="col-auto">
    @if (profilePicUrl) {
      <img
        class="profile-picture pointer"
        [src]="profilePicUrl"
        alt="profile-picture"
        (click)="onProfilePictureClick()"
        />
    }
  </div>
  <div class="col p-0">
    <div style="width: fit-content" class="p-1">
      <div>
        @if (name) {
          <span class="mat-body-1">{{ name }}</span>
        }
        <span class="mx-2 mat-caption text-gray">{{ createdAt }}</span>
        @if (canHaveChildLevel) {
          <span class="ms-2 mat-caption text-gray"
            >{{ totalAnswers }}
            @if (totalAnswers === 1) {
              <span>answer</span>
            } @else {
              <span>answers</span>
            }
          </span>
        }
        @if (isHidden) {
          <span class="ms-2 mat-caption hidden-message"
            >HIDDEN</span
            >
          }
          @if (isAdminShown) {
            <span
              class="ms-2 mat-caption reported-count-message"
              [class.solved]="!reportedCount || isHidden"
              >REPORTED BY {{ reportedCount }}</span
              >
            }
          </div>
          <div class="my-2 text-container">
            <span class="mat-body-2" [innerText]="discussion.text"></span>
          </div>
        </div>
        <div>
          <div class="d-flex align-items-center text-gray">
            <div class="d-flex align-items-center pointer">
              @if (!hasUserUpvoted) {
                <mat-icon (click)="vote(true)"
                  >favorite_outlined</mat-icon
                  >
                }
                @if (hasUserUpvoted) {
                  <mat-icon (click)="vote(false)" color="accent"
                    >favorite</mat-icon
                    >
                  }

                  <div class="ms-2 mat-caption">
                    <span [ngPlural]="upvotes">
                      <ng-template ngPluralCase="=1">{{ upvotes }} upvote</ng-template>
                      <ng-template ngPluralCase="other"
                        >{{ upvotes }} upvotes</ng-template
                        >
                      </span>
                    </div>
                  </div>

                  <div class="ms-4 d-flex align-items-center pointer">
                    @if (isAdminShown || !isReportedByCurrentUser) {
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="discussionItemOptions"
                        >
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    }
                    @if (isReportedByCurrentUser) {
                      <mat-icon matTooltip="Reported by you"
                        >warning</mat-icon
                        >
                      }
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="mt-2">
                    <div class="left-reply-border mt-4 ps-4">
                      @for (answer of displayedReplies; track answer) {
                        <discussion
                          [isAdminShown]="isAdminShown"
                          [reportGroups]="reportGroups"
                          [entityId]="entityId"
                          [discussion]="answer"
                          [canHaveChildLevel]="false"
                          [reports]="reports"
                          >
                        </discussion>
                      }
                      @if (areMoreRepliesToBeShown) {
                        <button
                          mat-button
                          class="pointer mb-3 see-more-replies"
                          (click)="setMoreVisibleReplies()"
                          >
                          See more replies ({{
                          discussion.discussionItemChildren.totalCount -
                          displayedReplies.length
                          }})
                        </button>
                      }
                    </div>
                  </div>
                  @if (canHaveChildLevel) {
                    <div class="reply-form d-flex mt-2">
                      <div class="avatar-column mt-1">
                        @if (user?.profilePictureUrl) {
                          <img
                            matPrefix
                            class="profile-picture"
                            [src]="user?.profilePictureUrl"
                            alt="profile-picture"
                            />
                        }
                      </div>
                      <div class="col">
                        <mat-form-field class="w-100 add-answer textarea-field" appearance="outline">
                          <textarea
                            matInput
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="100"
                            [placeholder]="hasAddedAnswers ? '' : 'Add a reply'"
                            (keydown)="onKeyDown($event)"
                            (keyup)="onKeyUp($event)"
                            (keydown.shift)="isShiftHeld = true"
                            (keyup.shift)="isShiftHeld = false"
                            (keydown.control)="isShiftHeld = true"
                            (keyup.control)="isShiftHeld = false"
                            [formControl]="answerForm"
                          ></textarea>
                          <button
                            mat-flat-button
                            matSuffix
                            [color]="answerForm.dirty ? 'accent' : 'primary'"
                            [disabled]="isPostReplyInProgress"
                            (click)="submitAnswer()"
                            >
                            Post
                          </button>
                        </mat-form-field>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>

            <mat-menu #discussionItemOptions="matMenu">
              <button mat-menu-item (click)="reportItem()">Report</button>
              @if (isAdminShown && isHidden) {
                <button
                  mat-menu-item
                  (click)="hideItem(false)"
                  >
                  Unhide
                </button>
              }
              @if (isAdminShown && !isHidden) {
                <button
                  mat-menu-item
                  (click)="hideItem(true)"
                  >
                  Hide
                </button>
              }
            </mat-menu>
