export enum FacilitatorType {
  Speaker,
  Host,
}

export interface IFacilitatorCreate {
  name: string;
  description: string;
  bio: string;
  type: FacilitatorType;
  title: string;
  company: string;

  profilePhotoFile?: File;
  profilePhotoUrl?: string;
}
