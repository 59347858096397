import { Event, LocationType } from './event';
import * as countrycitystatejson from 'country-state-city';
import { DateHelpers } from '../shared/date/date-helpers';

export class EventHelper {
  public static getSpeakersNamesForView(event: Event): string {
    if (!event.facilitators?.length) {
      return '';
    }

    const speakers = event.facilitators
      .filter((facilitator) => facilitator.type === 0)
      .map((f) => f.name);

    if (speakers.length <= 2) {
      return speakers.slice(0, 2).join(' & ');
    }

    if (speakers.length > 2) {
      const firstTwo = speakers.slice(0, 2).join(', ');
      return `${firstTwo} and more`;
    }

    return '';
  }

  public static getEventLocation(
    event: Event,
    forShare: 'ical' | 'googlecal' | undefined,
    shorten?: boolean,
  ): string {
    if (event.locationType === LocationType.Online) {
      return forShare === 'googlecal'
        ? `${encodeURIComponent(window.location.href)}`
        : forShare === 'ical'
        ? `${window.location.href}`
        : `Zoom`;
    }

    if (!event.cityCountry) {
      return `To be set`;
    }

    let country = '';

    const isoCode = countrycitystatejson.Country.getAllCountries().find(
      (country: any) => {
        return country.name === event.cityCountry?.country;
      },
    )?.isoCode;

    country = isoCode || event.cityCountry?.country;

    let location = country;
    if (event.address && !shorten) {
      location = event.address.replace(event.cityCountry.country, country);
    } else {
      if (event.cityCountry?.city && shorten) {
        location = `${event.cityCountry?.city}, ${country}`;
      }
    }

    return location;
  }

  public static CreateIcalFromEvent(event: Event): string {
    const timeStamp = EventHelper.FormatDateForCalendar(
      DateHelpers.getParsedIsoFromServer(new Date().toISOString()),
    );
    const uuid = `${timeStamp}Z-uid@futurefemales.co`;

    const elem = document.createElement('div');
    elem.innerHTML = event.description
      ? event.description
          .replace(/<br>/g, '!!!br!!!')
          .replace(/(<([^>]+)>)/gi, '')
      : '';
    const description = elem.textContent
      ? `${elem.textContent.replace(/!!!br!!!/g, '\\n')}\\n${
          window.location.href
        }`
      : `${window.location.href}`;

    const lines = [
      'BEGIN:VCALENDAR',
      'PRODID:-//Events Calendar//HSHSOFT 1.0//DE',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `DTSTAMP:${timeStamp}Z`,
      `DTSTART:${EventHelper.FormatDateForCalendar(
        DateHelpers.getParsedIsoFromServer(event.startsAtUtc),
      )}`,
      `DTEND:${EventHelper.FormatDateForCalendar(
        DateHelpers.getParsedIsoFromServer(event.endsAtUtc),
      )}`,
      `SUMMARY:${event.title ?? ''}`,
      `DESCRIPTION:${description}`,
      `LOCATION:${EventHelper.getEventLocation(event, 'ical')}`,
      `URL:${window.location.href}`,
      `UID:${uuid}`,
      'END:VEVENT',
      'END:VCALENDAR',
    ];

    // Join lines with CLRF format
    return lines.join('\r\n');
  }

  /**
   * Transforms event into a filename:
   * EX: { title: 'the new beginning!!!', id: 'E-a34b3b1559' }
   * => the_new_beginning-Ea34b3b1559.ics
   */
  public static GenerateIcalFilename(event: Event): string {
    // Replace all the spaces with underscore and remove everything else that is not a _- or a number/letter
    const title =
      event?.title?.replace(/ /m, '_')?.replace(/[^a-zA-Z0-9._-]/g, '') ?? '';

    return `${title}${title ? '-' : ''}${event.id.replace('-', '')}.ics`;
  }

  public static DownloadIcal(ical: string, filename: string) {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(ical),
    );
    element.setAttribute('download', filename);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    element.click();
  }

  public static GenerateGoogleCalendarLink(event: Event) {
    const title = encodeURIComponent(event.title ?? '');
    const description =
      event.locationType === LocationType.Online
        ? encodeURIComponent(
            `Join on <a href=${window.location.href}>Future Females</a><br>${
              event.description ?? ''
            }`,
          )
        : encodeURIComponent(event.description ?? '');
    const start = EventHelper.FormatDateForCalendar(
      DateHelpers.getParsedIsoFromServer(event.startsAtUtc),
    );
    const end = EventHelper.FormatDateForCalendar(
      DateHelpers.getParsedIsoFromServer(event.endsAtUtc),
    );
    const location = EventHelper.getEventLocation(event, 'googlecal');

    return `https://www.google.com/calendar/event?action=TEMPLATE&text=${title}&dates=${start}/${end}&details=${description}&location=${location}`;
  }

  /**
   * Returns a YYYYMMDDTHHmmss considering user's timezone
   */
  public static FormatDateForCalendar(_date: string): string {
    const date = new Date(_date);
    if (!date) {
      return '';
    }

    const [year, month, day, hour, minutes, seconds] = [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ].map((value) => value.toString().padStart(2, '0'));

    return `${year}${month}${day}T${hour}${minutes}${seconds}`;
  }
}
