<mat-accordion [multi]="multi">
  @for (
    faqItem of faqs; track
    faqItem; let faqIndex = $index; let isLast = $last; let faqAmount = $count) {
    @if (faqIndex < limit) {
      <mat-expansion-panel
        (opened)="openPanel(faqIndex)"
        (closed)="closePanel(faqIndex)"
        class="faq-extension-panel"
        hideToggle
        >
        <mat-expansion-panel-header class="faq-extension-panel-header">
          <span class="mat-content">
            <mat-panel-title class="faq-question-title">
              @if (isAdmin) {
                <mat-icon
                  class="me-3 pointer"
                  color="accent"
                  (click)="onEditClicked.next(faqItem.id)"
                  >edit</mat-icon
                  >
                }
                @if (isAdmin) {
                  <mat-icon
                    class="me-2 pointer"
                    color="accent"
                    (click)="onDeleteClicked.next(faqItem.id)"
                    >delete</mat-icon
                    >
                  }
                  {{ faqItem.question }}</mat-panel-title
                  >
                  <mat-panel-description class="faq-question-description">
                    <mat-icon color="none">{{
                      accordionState[faqIndex] ? 'remove' : 'add'
                    }}</mat-icon>
                  </mat-panel-description>
                </span>
              </mat-expansion-panel-header>
              <p class="text-break">{{ faqItem.answer }}</p>
            </mat-expansion-panel>
          }
          @if (isLast && faqAmount > limit) {
            <button
              mat-flat-button
              color="primary"
              class="show-more bg-transparent"
              (click)="showMoreFAQs()"
              >
              View more ( {{ faqAmount - limit }}
              {{ faqAmount - limit > 1 ? 'questions' : 'question' }}
              )
            </button>
          }
        }
      </mat-accordion>
