import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { APP_BASE_URL } from '../app-constants';

@Component({
  selector: 'back-nav-button',
  templateUrl: 'back-nav-button.component.html',
  styleUrls: ['back-nav-button.component.scss'],
})
export class BackNavButtonComponent {
  @Input() public backToResourceName!: string;
  @Input() public urlPath!: string;
  @Input() public reloadPage = false;

  constructor(private router: Router) {}

  public goBack(): void {
    const url = `/${this.urlPath}`;
    if (this.reloadPage) {
      window.open(url, '_self');
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
