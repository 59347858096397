<mat-radio-group
  aria-labelledby="location-radio-group"
  class="radio-group"
  (change)="onChange($event)"
  [(ngModel)]="selectedLocation"
  >
  @for (location of locationOptions; track location) {
    <mat-radio-button
      class="radio-button"
      [value]="location"
      >
      {{ location.title }}
      @if (location.value.usedCount) {
        <span
          >({{ location.value.usedCount }})</span
          >
        }
        @if (!location.value.usedCount) {
          <span
            >(0)</span
            >
          }
        </mat-radio-button>
      }
    </mat-radio-group>
