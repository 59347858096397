import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatSelectionList,
  MatSelectionListChange,
} from '@angular/material/list';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { IFacilitator } from 'src/app/facilitators/facilitator';
import { FacilitatorApiService } from 'src/app/facilitators/facilitator-api.service';

@Component({
  selector: 'select-existing-facilitators',
  templateUrl: 'select-existing-dialog.component.html',
  styleUrls: ['select-existing-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectExistingFacilitatorsDialog implements OnInit, OnDestroy {
  public speakers: IFacilitator[] = [];
  public allSpeakers: IFacilitator[] = [];

  public name = new UntypedFormControl('');
  public isHost = new UntypedFormControl(false);

  public error: string = '';

  private selectedId: string = '';
  private destroyNotifier = new Subject<void>();

  @ViewChild('facilitatorList') public facilitatorList!: MatSelectionList;

  constructor(
    public dialogRef: MatDialogRef<SelectExistingFacilitatorsDialog>,
    private facilitatorApiService: FacilitatorApiService,
    @Inject(MAT_DIALOG_DATA)
    public data: { existing: string[]; addAsHost: string },
  ) {}

  public ngOnInit(): void {
    this.facilitatorApiService
      .getAll()
      .subscribe((speakers: IFacilitator[]) => {
        if (
          !!this.data &&
          !!this.data.existing &&
          this.data.existing.length > 0
        ) {
          this.speakers = speakers.filter(
            (s) => this.data.existing.indexOf(s.id!) < 0,
          );
        } else {
          this.speakers = speakers;
        }
        this.allSpeakers = [...this.speakers];
      });

    this.name.valueChanges
      .pipe(takeUntil(this.destroyNotifier), debounceTime(500))
      .subscribe((value: string) => {
        if (!value || value.trim() === '') {
          this.speakers = [...this.allSpeakers];
          return;
        }
        this.speakers = this.allSpeakers.filter((speaker) =>
          speaker.name.toLowerCase().includes(value.toLowerCase()),
        );
      });
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();
  }

  public onSelection(event: MatSelectionListChange): void {
    this.selectedId = event.options[0].value;
  }

  public onSubmit(): void {
    if (this.selectedId === '') {
      this.error = 'No speaker was selected';
      return;
    }
    const facilitator = this.allSpeakers.find((f) => f.id === this.selectedId);
    this.dialogRef.close({ facilitator, isHost: this.data.addAsHost });
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
