import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IMemberStatsQuery,
  IMembershipReportReadModel,
} from './member-reports';
import { API_BASE_URL } from 'src/app/shared/app-constants';
import { HttpClient } from '@angular/common/http';
import { ApiServiceHelper } from 'src/app/shared/helpers';

@Injectable()
export class MemebrReportsApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getMemberReports(
    query: IMemberStatsQuery,
  ): Observable<IMembershipReportReadModel> {
    const url = `${this.apiBaseUrl}/user-reports`;
    return this.httpClient.get<IMembershipReportReadModel>(url, {
      params: ApiServiceHelper.getQueryParams(query),
    });
  }
}
