import { Component, Input } from '@angular/core';

@Component({
  selector: 'ff-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input()
  public loading: boolean = false;

  @Input()
  public loadingMessage?: string;
}
