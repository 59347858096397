import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IPlan } from '../plans/plans';
import { API_BASE_URL } from './app-constants';
import { IPayment, PaymentIntent } from './billing';
import { ISortFilter } from './filters/filters';
import { ApiServiceHelper } from './helpers';
import { IPage } from './pagination';

@Injectable()
export class BillingService {
  public static returnUrl: () => string = () => window.location.href;

  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getPlans(): Observable<IPlan[]> {
    return this.httpClient.get<IPlan[]>(this.apiBaseUrl + '/plans').pipe(
      map((response) => {
        return response.sort((a, b) => {
          return a.termMonths - b.termMonths;
        });
      }),
    );
  }

  public setupPaymentIntent(
    planId: string | null,
    fundContributionEnabled: boolean,
  ) {
    const sessionId = sessionStorage.getItem('ff_user_session_id');

    if (!sessionId) {
      throw new Error('No session id found in session storage');
    }

    let body = { sessionId } as any;
    body =
      planId === null
        ? body
        : {
            ...body,
            planId,
            fundContributionEnabled,
          };

    return this.httpClient.post<PaymentIntent>(
      this.apiBaseUrl + '/payment-setup-intent',
      body,
    );
  }

  public retryPayment(subscriptionId: string): Observable<void> {
    const sessionId = sessionStorage.getItem('ff_user_session_id');

    if (!sessionId) {
      throw new Error('No session id found in session storage');
    }

    let body = { sessionId } as any;

    const url = `${this.apiBaseUrl}/subscriptions/${subscriptionId}/payments`;
    return this.httpClient.post<void>(url, body);
  }

  public pay(planId: string, fundContributionEnabled: boolean) {
    const sessionId = sessionStorage.getItem('ff_user_session_id');

    if (!sessionId) {
      throw new Error('No session id found in session storage');
    }

    let body = { sessionId } as any;

    return this.httpClient.post<PaymentIntent>(
      this.apiBaseUrl + '/payment-intent',
      {
        ...body,
        planId,
        fundContributionEnabled,
      },
    );
  }

  public setAutoRenew(subscriptionId: string, autoRenew: boolean) {
    const url = `${this.apiBaseUrl}/subscriptions/${subscriptionId}`;
    return this.httpClient.patch<void>(url, { autoRenew });
  }

  public changePlanOnSubscription(
    subscriptionId: string,
    planId: string,
    fundContributionEnabled: boolean,
  ) {
    const url = `${this.apiBaseUrl}/subscriptions/${subscriptionId}/plan`;
    return this.httpClient.post<void>(url, { planId, fundContributionEnabled });
  }

  public createSubscription(planId: string, fundContributionEnabled: boolean) {
    const sessionId = sessionStorage.getItem('ff_user_session_id');

    if (!sessionId) {
      throw new Error('No session id found in session storage');
    }

    let body = { sessionId } as any;

    const url = `${this.apiBaseUrl}/subscriptions`;
    return this.httpClient.post<void>(url, {
      ...body,
      planId,
      fundContributionEnabled,
    });
  }

  public cancelActiveSubscription(subscriptionId: string) {
    const url = `${this.apiBaseUrl}/subscriptions/${subscriptionId}/cancel`;
    return this.httpClient.post<void>(url, {});
  }

  public executePayment(clientSecretIntent: string) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/payments/${clientSecretIntent}/execute`,
      {},
    );
  }

  public getPayments(filter: ISortFilter): Observable<IPage<IPayment>> {
    const url = `${this.apiBaseUrl}/payments`;
    return this.httpClient.get<IPage<IPayment>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }
}
