import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, map, Observable, startWith, concatMap } from 'rxjs';
import { DEFAULT_LABEL_FILTER, ILabel, LabelType } from '../label';
import { LabelApiService } from '../label-api.service';
import { IPage } from '../pagination';

@Component({
  selector: 'chip-autocomplete',
  templateUrl: 'chip-autocomplete.component.html',
  styleUrls: ['chip-autocomplete.component.scss'],
})
export class ChipAutocompleteComponent implements OnInit {
  @Input() public labelType!: LabelType;
  @Input() public chips: ILabel[] = [];
  @Input() public showSuggestions: boolean = false;
  @Input() public label: string = 'Values';
  @Input() public placeholder: string = 'Add new';
  @Input() public chipsSuggestions: ILabel[] = [];

  @Output() public added = new EventEmitter<ILabel>();
  @Output() public removed = new EventEmitter<string>();
  @Output() public suggested = new EventEmitter<ILabel>();

  private baseId: number = 0;

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public chipsForm = new UntypedFormControl();
  public filteredChips!: Observable<ILabel[]>;

  @ViewChild('chipsInput') chipsInput!: ElementRef<HTMLInputElement>;

  constructor(private labelApiService: LabelApiService) {}

  public ngOnInit(): void {
    if (this.showSuggestions) {
      this.filteredChips = this.chipsForm.valueChanges.pipe(
        debounceTime(500),
        startWith(''),
        concatMap((suggestion) =>
          this.labelApiService.getLabelCollection({
            ...DEFAULT_LABEL_FILTER,
            searchText: suggestion,
            labelType: this.labelType,
          }),
        ),
        map(
          (chipPage: IPage<ILabel>) => (this.chipsSuggestions = chipPage.items),
        ),
      );
    }

    this.labelApiService
      .getLabelCollection({
        ...DEFAULT_LABEL_FILTER,
        labelType: this.labelType,
      })
      .subscribe((page: IPage<ILabel>) => {
        this.chipsSuggestions = page.items;
      });
  }

  public remove(chip: ILabel): void {
    const index = this.chips.indexOf(chip);

    if (index >= 0) {
      this.chips.splice(index, 1);
    }

    this.removed.emit(chip.id);
  }

  public onNewChip(event: any): void {
    this.chipsInput.nativeElement.value = '';
    this.chipsForm.setValue('');
    const exists = this.chips.findIndex((c) => c === event.value) > -1;
    if (exists) {
      return;
    }

    const chip = {
      id: `${this.baseId}`,
      title: event.value,
      labelType: this.labelType,
    };
    this.chips.push(chip);
    this.baseId++;

    this.added.emit(chip);
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    const exists =
      this.chips.findIndex(
        (c) =>
          c.id === event.option.value.id ||
          c.title === event.option.value.title,
      ) > -1;
    this.chipsInput.nativeElement.value = '';
    this.chipsForm.setValue('');

    if (exists) {
      return;
    }

    const chip = event.option.value;
    this.suggested.emit(chip);
  }
}
