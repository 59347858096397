import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IStringResponse } from '../shared/api/string-response';
import { API_BASE_URL } from '../shared/app-constants';

@Injectable()
export class AffiliatesApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public postAffiliateCLick(
    affiliateId: string,
    sessionId: string,
  ): Observable<IStringResponse> {
    const endpointUrl = `${this.apiBaseUrl}/affiliates/${affiliateId}/link-clicks`;

    const currentAppUrl = window.location.href;
    return this.httpClient.post<IStringResponse>(endpointUrl, {
      url: currentAppUrl,
      sessionId,
    });
  }
}
