<div class="shared-discussion-root">
  <div class="members-list">
    <discussions-members-list [entityId]="entityId"></discussions-members-list>
  </div>
  <div class="discussions-container">
    <div class="mat-body-2 d-flex shared-discussions">
      <div class="avatar-column mt-1 me-3">
        @if (profilePicUrl) {
          <img
            matPrefix
            class="profile-picture"
            [src]="profilePicUrl"
            alt="profile"
            />
        }
      </div>
      <div class="col">
        <mat-form-field
          class="w-100 add-question-field textarea-field mt-1"
          appearance="outline"
          >
          <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="100"
            placeholder="Add a question or comment"
            (keydown)="onKeyDown($event)"
            (keyup)="onKeyUp($event)"
            (keydown.shift)="isShiftHeld = true"
            (keyup.shift)="isShiftHeld = false"
            (keydown.control)="isShiftHeld = true"
            (keyup.control)="isShiftHeld = false"
            [formControl]="questionForm"
          ></textarea>
          <button
            mat-flat-button
            matSuffix
            [color]="questionForm.dirty ? 'accent' : 'primary'"
            [disabled]="isQuestionPostInProgress"
            (click)="submitQuestion()"
            >
            Post
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="mt-3">
      @for (discussion of discussionItems; track discussion) {
        @if (isAdminShown) {
          <discussion
            [isAdminShown]="true"
            [reports]="reportedIds"
            [discussion]="discussion"
            [entityId]="entityId"
            [canHaveChildLevel]="true"
            [reportGroups]="reportGroups"
            (answerAdded)="onAnswerAdded()"
          ></discussion>
        }
        @if (!isAdminShown) {
          <discussion
            [isAdminShown]="false"
            [reports]="reportedIds"
            [discussion]="discussion"
            [entityId]="entityId"
            [canHaveChildLevel]="true"
            (answerAdded)="onAnswerAdded()"
          ></discussion>
        }
      }
      @if (areMoreQuestions) {
        <div class="text-center">
          <button
            mat-button
            (click)="loadMoreQuestions()"
            class="px-4 py-3 pointer show-more-button"
            >
            See more ({{ totalItemsShown }})
          </button>
        </div>
      }
    </div>
  </div>
</div>
