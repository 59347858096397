import { EnumHelpers } from "../enums/helpers";

export enum FfLanguage {
    English_en_US = 0,
    English_en_UK = 1,
    Spanish_es_MX = 2,
    Spanish_es_ES = 3,
    Italian_it_IT = 4,
    French_fr_FR = 5,
    German_de_DE = 6
}

export const mapLanguagesToLangAndCulture = (): [string, string][] => {
    return EnumHelpers.getEnumAsStringArray(FfLanguage).map((langCulture) => {
        const tokens = langCulture.viewValue.split('_');
        return [tokens[0], `${tokens[1]}-${tokens[2]}`];
    });
}

export const mapLanguageToLangAndCulture = (language: FfLanguage): [string, string] => {
    const tokens = FfLanguage[language].toString().split('_');
    return [tokens[0], `${tokens[1]}-${tokens[2]}`];
}