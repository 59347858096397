import { ISortFilter } from './filters/filters';

export interface IIndustry {
  id: string;
  name: string;
}

export const DEFAULT_INDUSTRY_FILTER: ISortFilter = {
  pageIndex: 0,
  pageSize: 999,
  sortBy: 'name',
  sortDescending: false,
};
