import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IFAQ } from '../../types/faq.type';

@Component({
  selector: 'ff-entity-faq-accordion',
  templateUrl: 'entity-faq-accordion.component.html',
  styleUrls: ['entity-faq-accordion.component.scss'],
})
export class EntityFAQAccordionComponent implements OnInit {
  @Input()
  public multi: boolean = true;

  @Input()
  public faqs: IFAQ[] = [];

  @Input()
  public limit!: number;

  @Input()
  public isAdmin: boolean = false;

  @Output()
  public onEditClicked = new EventEmitter();

  @Output()
  public onDeleteClicked = new EventEmitter();

  @Input()
  public paginationStep: number = 5;

  public accordionState: Record<string, boolean> = {};

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.limit) {
      this.limit = this.paginationStep;
    }
  }

  public openPanel(faqIndex: number) {
    this.accordionState[faqIndex] = true;
  }

  public closePanel(faqIndex: number) {
    this.accordionState[faqIndex] = false;
  }

  public showMoreFAQs() {
    this.limit += this.paginationStep;

    this.changeDetector.detectChanges();
  }
}
