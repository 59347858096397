import { Inject, Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { APP_INSIGHTS } from '../shared/app-constants';

@Injectable()
export class FFInsightsService {
  private appInsights: ApplicationInsights | undefined;

  constructor(@Inject(APP_INSIGHTS) private instrumentationKey: string) {
    if (this.instrumentationKey !== '') {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.instrumentationKey,
          enableAutoRouteTracking: true, // option to log all route changes
        },
      });
      this.appInsights.loadAppInsights();
    }
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    if (this.instrumentationKey !== '') {
      this.appInsights!.trackPageView({
        name: name,
        uri: url,
      });
    } else {
      console.log('View: ', name, url);
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.instrumentationKey !== '') {
      this.appInsights!.trackEvent({ name: name }, properties);
    } else {
      console.log('Event: ', name, properties);
    }
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any },
  ) {
    if (this.instrumentationKey !== '') {
      this.appInsights!.trackMetric(
        { name: name, average: average },
        properties,
      );
    } else {
      console.log('Metric: ', name, average, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {
    const userId = localStorage.getItem('usrid');
    if (!!userId) {
      exception = {
        ...exception,
        message: `${exception.message} - UserId: ${userId}`
      };
    }

    if (this.instrumentationKey !== '') {
      this.appInsights!.trackException({
        exception: exception,
        severityLevel: severityLevel,
      });
    } else {
      console.error(exception, severityLevel);
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.instrumentationKey !== '') {
      this.appInsights!.trackTrace({ message: message }, properties);
    } else {
      console.log('Trace: ', message, properties);
    }
  }
}
