<div class="app-xxl-container">
  <div class="not-found-container">
    <div class="info-container">
      <div class="error-info">
        <div class="error">{{ code }} error</div>
        @if (heading) {
          <div class="heading">{{ heading }}</div>
        }
        @if (description) {
          <div class="description">
            {{ description }}
          </div>
        }
        @if (!description && code === '404') {
          <div class="description">
            Sorry, the page you are looking for doesn't exist or has been moved.
          </div>
        }

        <button mat-flat-button color="accent" routerLink="/">Go home</button>
      </div>
    </div>
    <div class="random-image">
      <img
        src="../../../assets/images/not-found/dog.jpeg"
        alt="random not found image"
        />
    </div>
  </div>
</div>
