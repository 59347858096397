import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { INote } from 'src/app/shared/models/notes';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent {
  @Input()
  public note?: INote;

  // Used when creating a new note
  @Input()
  public timeStamp?: string;

  @Input()
  public isNewNote: boolean = false;

  @Output()
  public deleteClicked = new EventEmitter();

  @Output()
  public editSubmitted = new EventEmitter<string>();

  @Output()
  public playClicked = new EventEmitter();

  @ViewChild('noteTextArea', { read: ElementRef })
  public noteTextArea?: ElementRef<HTMLTextAreaElement>;

  public editMode: boolean = false;
  public newNoteText?: any;

  constructor() {}

  public ngOnInit(): void {
    if (this.isNewNote) {
      this.editMode = true;
      this.newNoteText = '';
    }
  }

  public onEditClicked() {
    this.editMode = true;
    this.newNoteText = this.note!.text;
  }

  public onDeleteClicked() {
    this.deleteClicked.next(true);
  }

  public onEditSubmit() {
    const newNoteText = this.noteTextArea?.nativeElement?.value?.trim?.();
    if (!newNoteText) {
      return;
    }

    this.editSubmitted.next(newNoteText);
    this.editMode = false;
  }

  public onPlayClicked() {
    this.playClicked.next(true);
  }
}
