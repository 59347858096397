import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IStringResponse } from 'src/app/shared/api/string-response';
import { API_BASE_URL } from 'src/app/shared/app-constants';
import { ApiServiceHelper } from 'src/app/shared/helpers';
import { INote } from 'src/app/shared/models/notes';
import { IUserLessonReadModel, IUserLessonUpdate } from './user';

@Injectable()
export class UserLessonApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getUserLesson(
    courseId: string,
    lessonId: number,
    userId?: string,
  ): Observable<IUserLessonReadModel> {
    const url = `${this.apiBaseUrl}/user-courses/${courseId}/user-lessons/${lessonId}`;
    return this.httpClient.get<IUserLessonReadModel>(url, {
      params: ApiServiceHelper.getQueryParams({ userId }),
    });
  }

  public createOrUpdateUserLesson(courseId: string, lessonId: string, update: IUserLessonUpdate) {
    const url = `${this.apiBaseUrl}/user-courses/${courseId}/user-lessons/${lessonId}`;
    return this.httpClient.patch<IStringResponse>(url, update);
  }

  public deleteUserNote(courseId: string, lessonId: number, noteId: number) {
    const url = `${this.apiBaseUrl}/user-courses/${courseId}/user-lessons/${lessonId}/notes/${noteId}`;
    return this.httpClient.delete<IStringResponse>(url);
  }

  public updateUserNote(
    courseId: string,
    lessonId: number,
    noteId: number,
    newText: string,
  ) {
    const url = `${this.apiBaseUrl}/user-courses/${courseId}/user-lessons/${lessonId}/notes/${noteId}`;
    return this.httpClient.patch<IStringResponse>(url, {
      text: newText,
      // TODO: clarify later
      screenshotUrl: 'test',
    });
  }

  public createUserNote(
    courseId: string,
    lessonId: number,
    note: Partial<INote>,
  ) {
    const url = `${this.apiBaseUrl}/user-courses/${courseId}/user-lessons/${lessonId}/notes`;
    return this.httpClient.post<IStringResponse>(url, {
      text: note.text,
      timeStamp: note.timeStamp,
    });
  }
}
