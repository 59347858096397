import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentStatusTextsService } from '../payment-status-texts.service';
import { Location } from '@angular/common';
import { DataLayerService } from '../data-layer.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ask-to-login',
  templateUrl: 'ask-to-login.component.html',
})
export class AskToLoginComponent implements OnDestroy {
  public freeTrialDays: number | null = null;

  private destroyNotifier = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<AskToLoginComponent>,
    public router: Router,
    private paymentStatusTextsService: PaymentStatusTextsService,
    private urlLocation: Location,
    private dataLayerService: DataLayerService,
    private titleService: Title,
  ) {
    this.paymentStatusTextsService.freeTrialDays
      .pipe(take(1))
      .subscribe(
        (freeTrialDays: number) => (this.freeTrialDays = freeTrialDays),
      );

    const initialUrl = window.location.href;
    const initialPath = `${window.location.href}`.split(
      window.location.origin,
    )[1];

    const path = `${window.location.pathname}/ask-to-join`;
    const url = `${window.location.origin}${path}`;

    this.urlLocation.replaceState(path);

    const pageEvent = {
      page_title: this.titleService.getTitle(),
      page_location: url,
      page_path: `${path}`,
    };

    this.dataLayerService.logPageView(pageEvent);

    this.dialogRef
      .beforeClosed()
      .pipe(takeUntil(this.destroyNotifier))
      .subscribe((_) => {
        const url = `${initialUrl}`;

        this.urlLocation.replaceState(initialPath);

        const pageEvent = {
          page_title: this.titleService.getTitle(),
          page_location: url,
          page_path: `${initialPath}`,
        };

        this.dataLayerService.logPageView(pageEvent);
      });

    this.dialogRef.afterClosed().subscribe();
  }

  public login(route: 'sign-in' | 'sign-up'): void {
    window.open(`${environment.appBaseUrl}/${route}`, '_self');
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();
  }
}
