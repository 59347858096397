import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DateTime } from 'luxon';
import { ILabelReadModel } from '../../../../shared/label';

interface ILabelViewModel {
  id: string;
  title: string;
  isRecentlyCreated: boolean;
  isUsed: boolean;
}

@Component({
  selector: 'label-list',
  templateUrl: 'label-list.component.html',
  styleUrls: ['label-list.component.scss'],
})
export class LabelListComponent implements OnChanges {
  @Input() public labelList!: ILabelReadModel[];
  @Input() public selectedLabelsIds: string[] = [];
  @Input() public allLabel: string = 'all';
  @Input() public resetToAllLabelIfAllItemsSelected = true;

  public selectedLabels: number[] = [];

  public labelViewList: ILabelViewModel[] = [];
  public isLoaded = false;

  private firstEmitted = false;

  @ViewChild('labelListRef') labelListRef!: ElementRef;

  @Output()
  public labelChange = new EventEmitter<string[]>();

  public lastScrollLeft: number = 0;
  public lastScrollDirection: number = 0;

  // used to prevent click if pad just ended
  public panEndAt: number = new Date().getTime();

  public isAllSelected?: boolean = true;
  public haveSomethingUsed?: boolean = false;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['labelList'] && !!changes['labelList'].currentValue) {
      this.labelViewList = this.labelList.map((label: ILabelReadModel) => ({
        id: label.id,
        title: label.title,
        isUsed: label.usedCount > 0,
        isRecentlyCreated:
          DateTime.fromJSDate(new Date()).minus({ days: 1 }).toJSDate() <
          new Date(`${label.createdAtUtc}Z`),
      }));
      this.setSelectedOnChange();
    }

    if (changes['selectedLabelsIds'] && !!this.labelList) {
      this.setSelectedOnChange();
    }
  }

  public ngOnInit(): void {
    this.setSelectedOnChange();
  }

  private setSelectedOnChange(): void {
    this.selectedLabels = [];
    const selectedLabelsIds = this.selectedLabelsIds || [];

    selectedLabelsIds.forEach((value) => {
      const index = this.labelList?.findIndex((label) => label.id === value);
      this.selectedLabels.push(index);
    });

    this.haveSomethingUsed = Boolean(
      this.labelList?.filter(({ usedCount }) => usedCount)?.length,
    );

    const usedLabelsCount = this.labelList?.filter(
      ({ usedCount }) => usedCount,
    )?.length;

    if (
      !this.selectedLabels.length ||
      (this.resetToAllLabelIfAllItemsSelected &&
        usedLabelsCount > 0 &&
        this.selectedLabels.length === usedLabelsCount)
    ) {
      this.selectAll();
    } else {
      this.isAllSelected = false;
    }

    this.isLoaded = true;
  }

  public isSelected(index: number): boolean {
    return this.selectedLabels.indexOf(index) > -1;
  }

  public resetLabels(): void {
    this.selectedLabels = [];
  }

  public toggleLabelState(index: number, disabled: boolean): void {
    // Player just ended pan action, click should not be happening
    if (new Date().getTime() - this.panEndAt < 50) {
      return;
    }

    if (disabled) {
      return;
    }

    const indexOfChipId = this.selectedLabels.indexOf(index);
    if (indexOfChipId > -1) {
      this.selectedLabels.splice(indexOfChipId, 1);
    } else {
      this.selectedLabels.unshift(index);
    }
    this.labelChange.emit(
      this.selectedLabels.map((index) => this.labelList[index].id),
    );
  }

  public onPan(event: any, el: any) {
    // 2 = right, 4 = left
    let direction = event.direction === 2 ? 1 : -1;
    if (event.direction !== this.lastScrollDirection) {
      direction *= -1;
    }
    const distance = event.distance * direction;
    const newScrollLeft = this.lastScrollLeft + distance;
    el.scrollLeft = newScrollLeft < 0 ? 0 : newScrollLeft;
  }

  public onPanEnd(event: any, el: any) {
    event.preventDefault();

    this.lastScrollLeft = el.scrollLeft;
    this.lastScrollDirection = 0;
    this.panEndAt = new Date().getTime();
  }

  public onPanStart(el: any) {
    this.lastScrollDirection = el.direction;
  }

  public selectAll() {
    if (!this.haveSomethingUsed) {
      this.isAllSelected = false;
      return;
    }

    if (this.isAllSelected) {
      return;
    }

    this.isAllSelected = true;

    setTimeout(() => {
      this.selectedLabels = [];
      if (!this.firstEmitted && this.resetToAllLabelIfAllItemsSelected) {
        this.firstEmitted = true;
        return;
      }
      this.labelChange.emit([]);
    });
  }
}
