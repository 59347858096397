import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class AppEventsService {
    private _event = new Subject<string>();
    public event = this._event.asObservable();

    public emitEvent(id: string): void {
        this._event.next(id);
    }
}
