<div
  [class.secondSegmentTransparent]="secondSegmentTransparent"
  class="d-flex flex-column p-relative min-h-100"
  >
  <ff-spinner
    [loading]="isIframe || !renderRouterOutlet || isReloginInProgress"
    loadingMessage="Loading..."
  ></ff-spinner>
  <div class="sticky-top">
    <app-header
      [isUserLoggedIn]="loginDisplay || isReloginInProgress"
      (logoutClicked)="logout()"
      (notificationClicked)="drawer.toggle()"
      >
    </app-header>
    @if (secondSegmentTransparent) {
      <div class="background-segment"></div>
    }
  </div>
  <div class="d-flex flex-grow-1 p-relative">
    <mat-drawer-container class="w-100">
      <mat-drawer-content class="w-100">
        <div class="h-100">
          @if (!isIframe && renderRouterOutlet) {
            <router-outlet
            ></router-outlet>
          }
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
  @if (!isSigninFlow) {
    <app-footer></app-footer>
  }
  <ng-template #sidebar></ng-template>
  <mat-drawer-container
    class="w-100"
    [hasBackdrop]="true"
    id="notification-drawer"
    >
    <mat-drawer
      class="notification-drawer"
      #drawer
      autoFocus="false"
      mode="over"
      position="end"
      >
      @if (isAccessToken && renderRouterOutlet) {
        <app-notifications
        ></app-notifications>
      }
    </mat-drawer>
  </mat-drawer-container>
</div>
