import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_BASE_URL } from './app-constants';
import { DEFAULT_INDUSTRY_FILTER, IIndustry } from './industry';
import { IPage } from './pagination';

@Injectable()
export class IndustriesApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getIndustries(
    filters = DEFAULT_INDUSTRY_FILTER,
  ): Observable<IPage<IIndustry>> {
    const url = `${this.apiBaseUrl}/industries`;
    return this.httpClient.get<IPage<IIndustry>>(url, {
      params: {
        ...filters,
      },
    });
  }
}
