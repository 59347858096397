@if (loading) {
  <div class="backdrop"></div>
  <div class="container">
    <div class="spinner">
      <img src="../../../assets/images/ff-logo-light-2.png" alt="logo" />
      <span class="loading-message">{{ loadingMessage }}</span>
      <div class="cursor">
        <div class="inner"></div>
      </div>
    </div>
  </div>
}
