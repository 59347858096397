import { Feature } from '../enums/features';
import { IPage } from '../pagination';

type Vote = {
  isUpVote: boolean;
  userId: string;
  createdAtUtc: string;
};

export interface IDiscussionMemberReadModel {
  id: string;
  firstName?: string;
  lastName?: string;
  profilePictureUrl?: string;
  userPosts: number;
  userReplies: number;
}

export type IDiscussionSummary = {
  id: string;
  resourceTitle: string;
  feature: Feature;
  createdAtUtc: string;
  numberOfMembers?: number;
  numberOfPosts?: number;
  numberOfReplies?: number;
  lastDiscussionItemId?: string;
  lastDiscussionItemDateUtc?: string;
  lastDiscussionItemUserId?: string;
  members?: Array<{
    id: string;
    firstName: string;
    lastName: string;
    profilePictureUrl: string;
  }>;
  viewedByPeople: number;
};

export type DiscussionItemReport = {
  id: string;
  discussionId: string;
  discussionItemId: string;
  userId: string;
  createdAtUtc: string;
  text: string;
  tags: string[];

  resolvedAt?: string;
  updatedAt?: string;
  resolvedBy?: string;
  updatedBy?: string;
  adminNotes?: string;
};

export interface IPostDiscussionItem {
  text: string;
  parentId?: string;
}

export interface IDiscussionItem {
  id: string;
  parentId?: string;
  user: IDiscussionUserReadModel;
  createdAtUtc: string;
  updatedAtUtc?: string;
  text: string;
  resourceTitle?: string;
  isHidden: boolean;
  votes: Vote[];
  discussionItemChildren: IPage<IDiscussionItem>;
  discussionId: string;
  locallyPushed?: boolean;
}

export interface IDiscussionUserReadModel {
  id: string;
  firstName?: string;
  lastName?: string;
  profilePictureUrl?: string;
}

export interface IDiscussionFilter {
  sortBy?: string;
  sortDescending?: boolean;
  pageIndex: number;
  pageSize: number;
  features?: Feature[];
  childrenSortBy?: string;
  childrenSortDescending?: boolean;
  includeHidden?: boolean;
  resourceTitle?: string;
}

export interface IDiscussionReportFilter extends IDiscussionFilter {
  includeResovled: boolean;
  reportedByUserId?: string;
  discussionId?: string;
}

export interface IDiscussionMembersFilter {
  sortBy?: string;
  sortDescending?: boolean;
  pageIndex: number;
  pageSize: number;
}

export interface IDiscussionMember {
  id: string;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
  createdAtUtc: string;
  updatedAtUtc: string;
  userPosts: number;
  userReplies: number;
}

export interface IUpdateDiscussionItem {
  upvote: boolean;
}

export interface IDiscussionReport {
  id: string;
  discussionId: string;
  discussionItemId: string;
  discussionItemParentId: string;

  userId: string;
  createdAtUtc: string;
  text: string;
  tags: string[];

  resolvedAtUtc?: string;
  updatedAtUtc?: string;
  updatedBy?: string;
  resolvedBy?: string;
  adminNotes?: string;
}

export interface IDiscussionReportGroup {
  id: string;
  discussionId: string;
  reportsCount: number;
}

export interface IDiscussionReportPost {
  discussionId: string;
  discussionItemId: string;

  labelIds: string[];
  text?: string;
}

export const DEFAULT_DISCUSSION_FILTER: IDiscussionFilter = {
  sortBy: 'createdAtUtc',
  sortDescending: true,
  pageIndex: 0,
  pageSize: 10,
  childrenSortBy: 'createdAtUtc',
  childrenSortDescending: false,
  includeHidden: false,
};

export const DEFAULT_DISCUSSION_FILTER_REPORT: IDiscussionReportFilter = {
  sortBy: 'createdAtUtc',
  sortDescending: true,
  pageIndex: 0,
  pageSize: 500,
  includeResovled: false,
  childrenSortBy: 'createdAtUtc',
  childrenSortDescending: false,
};
