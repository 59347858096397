<div class="d-flex align-items-center">
  @if (readonly) {
    <mat-icon
      [svgIcon]="
        rating > 0
          ? rating <= 0.5
            ? 'star_half'
            : 'star_filled'
          : 'star_outline'
      "
      [class.star-distance]="smallDisplay"
      [class.small]="smallDisplay"
      >star</mat-icon
      >
      <mat-icon
      [svgIcon]="
        rating > 1
          ? rating <= 1.5
            ? 'star_half'
            : 'star_filled'
          : 'star_outline'
      "
        [class.star-distance]="smallDisplay"
        [class.small]="smallDisplay"
        >star</mat-icon
        >
        <mat-icon
      [svgIcon]="
        rating > 2
          ? rating <= 2.5
            ? 'star_half'
            : 'star_filled'
          : 'star_outline'
      "
          [class.star-distance]="smallDisplay"
          [class.small]="smallDisplay"
          >star</mat-icon
          >
          <mat-icon
      [svgIcon]="
        rating > 3
          ? rating <= 3.5
            ? 'star_half'
            : 'star_filled'
          : 'star_outline'
      "
            [class.star-distance]="smallDisplay"
            [class.small]="smallDisplay"
            >star</mat-icon
            >
            <mat-icon
      [svgIcon]="
        rating > 4
          ? rating <= 4.5
            ? 'star_half'
            : 'star_filled'
          : 'star_outline'
      "
              [class.star-distance]="smallDisplay"
              [class.small]="smallDisplay"
              >star</mat-icon
              >
            } @else {
              <mat-icon
                [svgIcon]="rating > 0 ? 'star_filled' : 'star_outline'"
                class="pointer"
                [class.star-distance]="smallDisplay"
                [class.small]="smallDisplay"
                (click)="setRating(1)"
                >star</mat-icon
                >
                <mat-icon
                  [svgIcon]="rating > 1 ? 'star_filled' : 'star_outline'"
                  class="pointer"
                  [class.star-distance]="smallDisplay"
                  [class.small]="smallDisplay"
                  (click)="setRating(2)"
                  >star</mat-icon
                  >
                  <mat-icon
                    [svgIcon]="rating > 2 ? 'star_filled' : 'star_outline'"
                    class="pointer"
                    [class.star-distance]="smallDisplay"
                    [class.small]="smallDisplay"
                    (click)="setRating(3)"
                    >star</mat-icon
                    >
                    <mat-icon
                      [svgIcon]="rating > 3 ? 'star_filled' : 'star_outline'"
                      class="pointer"
                      [class.star-distance]="smallDisplay"
                      [class.small]="smallDisplay"
                      (click)="setRating(4)"
                      >star</mat-icon
                      >
                      <mat-icon
                        [svgIcon]="rating > 4 ? 'star_filled' : 'star_outline'"
                        class="pointer"
                        [class.star-distance]="smallDisplay"
                        [class.small]="smallDisplay"
                        (click)="setRating(5)"
                        >star</mat-icon
                        >
                      }
                    </div>
