import { DateTime, Duration } from 'luxon';

export class DateHelpers {
  public static units: Intl.RelativeTimeFormatUnit[] = [
    'year',
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second',
  ];

  public static getParsedIsoFromServer(iso: string): string {
    const date = iso.split('.');
    return `${date[0]}.000Z`;
  }

  public static toIsoString(date: Date) {
    const years = `${date.getFullYear()}`;
    const months =
      `${date.getMonth() + 1}`.length === 1
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    const days =
      `${date.getDate()}`.length === 1
        ? `0${date.getDate()}`
        : `${date.getDate()}`;

    return `${years}-${months}-${days}T00:00:00.000z`;
  }

  public static getDateWithDifference(
    days: number,
    weeks: number,
    months: number,
    years: number,
    startOf?: 'week' | 'month' | 'year',
  ): string {
    let date = DateTime.now().plus({
      days,
      weeks,
      months,
      years,
    });

    if (startOf) {
      date = date.startOf(startOf);
    }
    return date.startOf('day').toJSDate().toISOString();
  }

  public static getDateDifference(
    date1Iso: string,
    date2Iso: string,
  ): Duration {
    let d = date1Iso.split('.');
    date1Iso = `${d[0]}.000Z`;
    d = date2Iso.split('.');
    date2Iso = `${d[0]}.000Z`;

    let lower = null;
    let higher = null;

    if (new Date(date1Iso) > new Date(date2Iso)) {
      lower = DateTime.fromISO(date2Iso);
      higher = DateTime.fromISO(date1Iso);
    } else {
      lower = DateTime.fromISO(date1Iso);
      higher = DateTime.fromISO(date2Iso);
    }

    const diff = higher.diff(lower, ['years', 'months', 'weeks', 'days']);
    return diff;
  }

  public static getTimespawnFrom(isoString: string): string {
    let d = isoString.split('.');
    isoString = `${d[0]}.000Z`;

    const date = new Date(isoString);

    const checkYear = (date: Date): string => {
      const current = new Date();
      if (date.getFullYear() < current.getFullYear()) {
        if (current.getFullYear() - date.getFullYear() === 1) {
          return checkMonthDiffForOneYearGapPast(current, date);
        }
        return `${current.getFullYear() - date.getFullYear()} yrs ago`;
      } else {
        return checkMonth(date);
      }
    };

    const checkMonthDiffForOneYearGapPast = (
      higher: Date,
      lower: Date,
    ): string => {
      const monthDiff = higher.getMonth() - lower.getMonth();
      const yearDiff = higher.getFullYear() - lower.getFullYear();

      var difference = monthDiff + yearDiff * 12;

      return difference > 12
        ? 'Last year'
        : difference === 1 || difference === 0
        ? `Last month`
        : `${difference} months ago`;
    };

    const checkMonth = (date: Date): string => {
      const current = new Date();
      if (date.getMonth() < current.getMonth()) {
        if (current.getMonth() - date.getMonth() === 1) {
          return 'Last month';
        }
        return `${current.getMonth() - date.getMonth()} months ago`;
      } else {
        return checkDate(date);
      }
    };

    const checkDate = (date: Date): string => {
      const current = new Date();
      if (date.getDate() < current.getDate()) {
        if (current.getDate() - date.getDate() === 1) {
          return 'Yesterday';
        }
        return `${current.getDate() - date.getDate()} days ago`;
      } else {
        return checkHours(date);
      }
    };

    const checkHours = (date: Date): string => {
      const current = new Date();
      if (date.getHours() < current.getHours()) {
        if (current.getHours() - date.getHours() === 1) {
          return 'Last hour';
        }
        return `${current.getHours() - date.getHours()} hrs ago`;
      } else {
        return checkMinutes(date);
      }
    };

    const checkMinutes = (date: Date): string => {
      const current = new Date();
      if (date.getMinutes() < current.getMinutes()) {
        if (current.getMinutes() - date.getMinutes() === 1) {
          return '1 min ago';
        }
        return `${current.getMinutes() - date.getMinutes()} mins ago`;
      } else {
        return 'Just now';
      }
    };

    return checkYear(date);
  }

  public static getFriendlyDateInterval(
    isoString1: string,
    isoString2: string,
  ): string {
    if (isoString1 === isoString2) {
      return '-';
    }

    let d = isoString1.split('.');
    isoString1 = `${d[0]}.000Z`;
    d = isoString2.split('.');
    isoString2 = `${d[0]}.000Z`;

    let lower = null;
    let higher = null;

    if (new Date(isoString1) > new Date(isoString2)) {
      lower = new Date(isoString2);
      higher = new Date(isoString1);
    } else {
      lower = new Date(isoString1);
      higher = new Date(isoString2);
    }

    const current = new Date();

    if (lower < current && current < higher) {
      return 'Now';
    }

    const checkyearFuture = (lower: Date, higher: Date): string => {
      if (higher.getFullYear() > lower.getFullYear()) {
        const diff = higher.getFullYear() - lower.getFullYear();
        return diff === 1 ? `Next year` : `In ${diff} years`;
      } else {
        return checkmonthFuture(lower, higher);
      }
    };
    const checkmonthFuture = (lower: Date, higher: Date): string => {
      if (higher.getMonth() > lower.getMonth()) {
        const diff = higher.getMonth() - lower.getMonth();
        return diff === 1 ? `Next month` : `In ${diff} months`;
      } else {
        return checkDateFuture(lower, higher);
      }
    };

    const checkDateFuture = (lower: Date, higher: Date): string => {
      if (higher.getDate() > lower.getDate()) {
        const diff = higher.getDate() - lower.getDate();
        return diff === 1 ? `Tomorrow` : `In ${diff} days`;
      } else {
        return 'Today';
      }
    };

    const checkyearPast = (lower: Date, higher: Date): string => {
      if (higher.getFullYear() > lower.getFullYear()) {
        const diff = higher.getFullYear() - lower.getFullYear();
        return diff === 1 ? `Last year` : `${diff} years ago`;
      } else {
        return checkmonthPast(lower, higher);
      }
    };
    const checkmonthPast = (lower: Date, higher: Date): string => {
      if (higher.getMonth() > lower.getMonth()) {
        const diff = higher.getMonth() - lower.getMonth();
        return diff === 1 ? `Last month` : `${diff} months ago`;
      } else {
        return checkDatePast(lower, higher);
      }
    };

    const checkDatePast = (lower: Date, higher: Date): string => {
      if (higher.getDate() > lower.getDate()) {
        const diff = higher.getDate() - lower.getDate();
        return diff === 1 ? `Yesterday` : `${diff} days ago`;
      } else {
        return 'Today';
      }
    };

    return higher < current
      ? checkyearPast(higher, current)
      : lower < current
      ? checkyearFuture(lower, current)
      : checkyearFuture(current, lower);
  }

  public static getFriendlyDate(
    isoString1: string,
    isoString2: string,
    withTime?: boolean,
  ): string {
    if (isoString1 === isoString2) {
      return 'Now';
    }

    let d = isoString1.split('.');
    isoString1 = `${d[0]}.000Z`;
    d = isoString2.split('.');
    isoString2 = `${d[0]}.000Z`;

    let lower = null;
    let higher = null;

    if (new Date(isoString1) > new Date(isoString2)) {
      lower = new Date(isoString2);
      higher = new Date(isoString1);
    } else {
      lower = new Date(isoString1);
      higher = new Date(isoString2);
    }

    const sameDay = (d1: Date, d2: Date) => {
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    };

    if (sameDay(lower, higher)) {
      return `${lower.toDateString()}, ${this.getFriendlyTimeInterval(
        isoString1,
        isoString2,
      )}`;
    } else if (withTime) {
      const timezone = DateTime.local().toFormat('ZZZZ');
      const lowerHour =
        `${lower.getHours()}`.length === 1
          ? `0${lower.getHours()}`
          : `${lower.getHours()}`;
      const higherHour =
        `${higher.getHours()}`.length === 1
          ? `0${higher.getHours()}`
          : `${higher.getHours()}`;
      const lowerMin =
        `${lower.getMinutes()}`.length === 1
          ? `0${lower.getMinutes()}`
          : `${lower.getMinutes()}`;
      const higherMin =
        `${higher.getMinutes()}`.length === 1
          ? `0${higher.getMinutes()}`
          : `${higher.getMinutes()}`;

      return `${lower.toLocaleDateString()} ${lowerHour}:${lowerMin} to ${higher.toLocaleDateString()} ${higherHour}:${higherMin} (${timezone}) `;
    }

    return `${lower.toLocaleDateString()} to ${higher.toLocaleDateString()}`;
  }

  public static getFriendlyTimeInterval(
    isoString1: string,
    isoString2: string,
  ): string {
    if (isoString1 === isoString2) {
      return 'Now';
    }

    let d = isoString1.split('.');
    isoString1 = `${d[0]}.000Z`;
    d = isoString2.split('.');
    isoString2 = `${d[0]}.000Z`;

    let lower = null;
    let higher = null;

    if (new Date(isoString1) > new Date(isoString2)) {
      lower = new Date(isoString2);
      higher = new Date(isoString1);
    } else {
      lower = new Date(isoString1);
      higher = new Date(isoString2);
    }

    const lowerMonth =
      `${lower.getMonth() + 1}`.length === 1
        ? `0${lower.getMonth() + 1}`
        : `${lower.getMonth() + 1}`;
    const higherMonth =
      `${higher.getMonth() + 1}`.length === 1
        ? `0${higher.getMonth() + 1}`
        : `${higher.getMonth() + 1}`;
    const lowerDays =
      `${lower.getDate()}`.length === 1
        ? `0${lower.getDate()}`
        : `${lower.getDate()}`;
    const higherDays =
      `${higher.getDate()}`.length === 1
        ? `0${higher.getDate()}`
        : `${higher.getDate()}`;
    const lowerHour =
      `${lower.getHours()}`.length === 1
        ? `0${lower.getHours()}`
        : `${lower.getHours()}`;
    const higherHour =
      `${higher.getHours()}`.length === 1
        ? `0${higher.getHours()}`
        : `${higher.getHours()}`;
    const lowerMin =
      `${lower.getMinutes()}`.length === 1
        ? `0${lower.getMinutes()}`
        : `${lower.getMinutes()}`;
    const higherMin =
      `${higher.getMinutes()}`.length === 1
        ? `0${higher.getMinutes()}`
        : `${higher.getMinutes()}`;

    const timezone = DateTime.local().toFormat('ZZZZ');

    const checkYear = (lower: Date, higher: Date): string => {
      if (higher.getFullYear() > lower.getFullYear()) {
        return `from ${lower.toLocaleString()} to ${higher.toLocaleString()}`;
      } else {
        return checkMonth(lower, higher);
      }
    };
    const checkMonth = (lower: Date, higher: Date): string => {
      if (higher.getMonth() > lower.getMonth()) {
        return `from ${lowerMonth}/${lowerDays} ${lowerHour}:${lowerMin} to ${higherMonth}/${higherDays} ${higherHour}:${higherMin} (${timezone})`;
      } else {
        return checkDate(lower, higher);
      }
    };

    const checkDate = (lower: Date, higher: Date): string => {
      if (higher.getDate() > lower.getDate()) {
        return `from ${lowerMonth}/${lowerDays} ${lowerHour}:${lowerMin} to ${higherMonth}/${higherDays} ${higherHour}:${higherMin} (${timezone})`;
      } else {
        return `from ${lowerHour}:${lowerMin} to ${higherHour}:${higherMin} (${timezone})`;
      }
    };

    return checkYear(lower, higher);
  }

  public static getMonths() {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }

  /**
   * @returns Days in month ( use leap year if no year is specified)
   */
  public static getDaysInMonth(month: number, year = 2000) {
    const days = new Date(year, month + 1, 0).getDate();

    return Array.from({ length: days }, (_, index) => {
      return index + 1;
    });
  }

  /**
   * @returns Last 100 years including current year, sorted descending.
   */
  public static getYears() {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 100 }, (_, index) => {
      return currentYear - index;
    });
  }

  public static timeAgo(date: Date) {
    const dateTime = DateTime.fromJSDate(date);
    const diff = dateTime.diffNow().shiftTo(...DateHelpers.units);
    const unit =
      DateHelpers.units.find((unit) => diff.get(unit) !== 0) || 'second';

    const relativeFormatter = new Intl.RelativeTimeFormat('en', {
      numeric: 'auto',
    });

    return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
  }

  public static startOfDay(date: Date | string) {
    return DateTime.fromJSDate(new Date(date))
      .startOf('day')
      .toJSDate()
      .toISOString();
  }

  public static dateString(date: Date | string) {
    return new Date(date).toDateString();
  }
}
