<mat-form-field
  class="w-100"
  style="height: 68px"
  appearance="outline"
  color="accent"
  floatLabel="always"
>
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipList>
    @for (chip of chips; track chip) {
    <mat-chip-row (removed)="remove(chip)">
      {{ chip.title }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    } @if (showSuggestions) {
    <input
      [placeholder]="placeholder"
      #chipsInput
      [formControl]="chipsForm"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
    />
    } @if (!showSuggestions) {
    <input
      [placeholder]="placeholder"
      #chipsInput
      [formControl]="chipsForm"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="onNewChip($event)"
    />
    }
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    @for (chip of filteredChips | async | slice : 0 : 10; track chip) {
    <mat-option [value]="chip">
      {{ chip.title }}
    </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
