@if (uploadProgress && uploadAzureProgress) {
  <div>
    @if (
      uploadProgress.status === UploadStatus.Failed ||
      uploadAzureProgress.status === UploadStatus.Failed ||
      uploadAzureProgress.captionStatus === UploadStatus.Failed
      ) {
      @if (uploadAzureProgress.status === UploadStatus.Failed) {
        <div>
          Video upload was not successful.
        </div>
      }
      @if (uploadAzureProgress.captionStatus === UploadStatus.Failed) {
        <div>
          Caption upload was not successful.
        </div>
      }
    }
    @if (
      uploadProgress.status === UploadStatus.Done &&
      uploadAzureProgress.status === UploadStatus.Done &&
      !!videoUrl
      ) {
      @if (
        uploadAzureProgress.captionStatus === UploadStatus.InProgress ||
        uploadAzureProgress.captionStatus === UploadStatus.NotStarted
        ) {
        Captions processing:
        {{ uploadAzureProgress.captionsUploadProgressPercentage }}%
      }
      @if (videoUrl && captionUrl) {
        <div>Has video with captions</div>
      }
    }
    @if (
      (uploadProgress.status === UploadStatus.Started ||
      uploadProgress.status === UploadStatus.InProgress) &&
      !(
      uploadAzureProgress.status === UploadStatus.Started ||
      uploadAzureProgress.status === UploadStatus.InProgress
      )
      ) {
      <div
        >
        <div class="d-flex align-items-center">
          <div class="me-2">File upload:</div>
          <div class="w-30px">{{ uploadProgress.uploadProgressPercentage }}%</div>
        </div>
      </div>
    }
    @if (
      (uploadAzureProgress.status === UploadStatus.Started ||
      uploadAzureProgress.status === UploadStatus.InProgress) &&
      uploadProgress.status === UploadStatus.Done
      ) {
      <div
        >
        @if (uploadAzureProgress.diskFileId) {
          <div
            class="d-flex align-items-center"
            >
            <div class="me-2">Video processing:</div>
            <div class="w-30px">
              {{ uploadAzureProgress.uploadProgressPercentage }}%
              @if (
                uploadAzureProgress.uploadProgressPercentage > 79 &&
                uploadAzureProgress.uploadProgressPercentage < 90
                ) {
                (Thumbnail generating)
              }
              @if (
                uploadAzureProgress.uploadProgressPercentage > 90 &&
                uploadAzureProgress.uploadProgressPercentage < 99
                ) {
                (Wrapping everything up)
              }
            </div>
          </div>
        }
      </div>
    }
  </div>
}
