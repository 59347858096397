import { Component, Input, OnInit } from '@angular/core';
import { ICourseReadModel } from '../../../../courses/courses';
import { IEvent } from '../../../../events/event';
import { ShareResourceType } from '../../../services/share.service';
import { SocialMediaTypes } from '../../../share';
import { AbstractButtonComponent } from '../abstract-button/abstract-button.component';

/**
 * Uses only text instead of OG:* and description
 */
@Component({
  selector: 'app-whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: ['./whatsapp-button.component.scss'],
})
export class WhatsappButtonComponent
  extends AbstractButtonComponent
  implements OnInit
{
  @Input() override url!: string;
  @Input() override resourceId!: string;
  @Input() override resourceType!: keyof typeof ShareResourceType;
  @Input() override description!: string;
  @Input()
  public override resource!: Partial<IEvent | ICourseReadModel>;

  public get text(): string {
    return `${encodeURIComponent(this.description)} ${encodeURIComponent(this.url)}`;
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.loadUrl(SocialMediaTypes.WhatsApp);
  }
}
