import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'star-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class StarRatingComponent implements OnInit, OnChanges {
  @Input() public readonly = false;
  @Input() public initialRating: number = 0;
  @Input() public smallDisplay: boolean = false;
  @Output() public onRatingChanged = new EventEmitter<number>();

  public rating = 0;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialRating'] && !changes['initialRating'].firstChange) {
      this.rating = changes['initialRating'].currentValue;
    }
  }

  public ngOnInit(): void {
    this.rating = this.initialRating;
  }

  public setRating(rating: number): void {
    if (this.readonly) {
      return;
    }
    this.rating = rating;
    this.onRatingChanged.emit(rating);
  }
}
