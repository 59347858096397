import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment.dev';
import {
  ShareResourceType,
  ShareService,
} from '../../../services/share.service';
import { SocialMediaTypes } from '../../../share';
import { IEvent } from '../../../../events/event';
import { ICourseReadModel } from '../../../../courses/courses';
import { DateHelpers } from '../../../../shared/date/date-helpers';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-abstract-button',
  templateUrl: './abstract-button.component.html',
  styleUrls: ['./abstract-button.component.scss'],
})
export class AbstractButtonComponent implements OnInit {
  public url!: string;
  public resourceId!: string;
  public resourceType!: keyof typeof ShareResourceType;
  public description!: string;
  public resource!: Partial<IEvent | ICourseReadModel>;

  constructor(protected shareService: ShareService) {}

  public ngOnInit(): void {
    if (!this.resource) {
      console.warn('Resource was not provided!');
      return;
    }
    this.description = this.shareService.getDescription(
      this.resourceType,
      this.resource,
    );
  }

  public loadUrl(socialMediaType: SocialMediaTypes): Subscription | void {
    if (this.resourceId) {
      return this.shareService
        .getShareUrl(this.resourceType, this.resourceId, socialMediaType)
        .subscribe((shareUrl) => {
          this.url = shareUrl.toString();
        });
    } else if (!this.url) {
      this.url = `${environment.appBaseUrl}${window.location.pathname}`;
    }
  }
}
