import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_BASE_URL } from '../shared/app-constants';

export interface ICreateSubscriptionCodesCommand {
  emails: string[];
  subscriptionEndsAtUtc?: string;
}
export interface ICreateSubscriptionCodesResponse {
  message: string;
  email: string;
  success: boolean;
  subscriptionCodeId?: string;
}

@Injectable()
export class SubscriptionCodesApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public sendBatchEmailInvite(
    command: ICreateSubscriptionCodesCommand,
  ): Observable<{ responses: ICreateSubscriptionCodesResponse[] }> {
    const url = `${this.apiBaseUrl}/subscription-codes/batch`;
    return this.httpClient.post<{ responses: ICreateSubscriptionCodesResponse[] }>(
      url,
      command,
    );
  }
}
