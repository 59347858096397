import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  DEFAULT_DISCUSSION_FILTER,
  DEFAULT_DISCUSSION_FILTER_REPORT,
  IDiscussionFilter,
  IDiscussionItem,
  IDiscussionReportGroup,
} from './discussion';
import { DiscussionApiService } from './discussion-api.service';
import { DiscussionSignalrService } from './discussion-signalr.service';
import { IStringResponse } from '../../shared/api/string-response';
import { UsersService } from '../../user/users.service';
import { IUserAPI } from '../../user/user';
import { MatDialog } from '@angular/material/dialog';
import { ReportDiscussionDialog } from './report-dialog/report-dialog.component';
import { concatMap, filter, map } from 'rxjs';
import { NotifyService } from '../../core/notify.service';
import { DateHelpers } from 'src/app/shared/date/date-helpers';
import { IPage } from 'src/app/shared/pagination';
import { uniqBy } from 'lodash';
import { DiscussionsMemberDialog } from '../../discussions/discussions-member-dialog/discussions-member-dialog.component';
import { AskToLoginComponent } from '../../core/ask-to-login-dialog/ask-to-login.component';

@Component({
  selector: 'discussion',
  templateUrl: 'discussion.component.html',
  styleUrls: ['discussion.component.scss'],
})
export class DiscussionComponent {
  @Input() public discussion!: IDiscussionItem;
  @Input() public entityId!: string;
  @Input() public isAdminShown!: boolean;
  @Input() public canHaveChildLevel!: boolean;
  @Input() public reports!: string[];
  @Input() public reportGroups: IDiscussionReportGroup[] = [];

  @Output() public answerAdded = new EventEmitter<void>();

  public isReportedByCurrentUser: boolean = false;

  public hasUserUpvoted: boolean = false;

  public totalAnswers: number = 0;
  public upvotes: number = 0;

  public profilePicUrl!: string | null;
  public name!: string | null;

  public answerForm = new UntypedFormControl();

  public createdAt!: string;

  public uploadProgress: number = 0;
  public isUploadInProgress: boolean = false;

  public displayedReplies: IDiscussionItem[] = [];
  public areMoreRepliesToBeShown: boolean = false;

  public isPostReplyInProgress: boolean = false;
  public hasAddedAnswers: boolean = false;
  public isShiftHeld: boolean = false;

  public user!: IUserAPI | undefined;

  public isHidden = false;
  public reportedCount = 0;

  private repliesFilter: IDiscussionFilter = {
    ...DEFAULT_DISCUSSION_FILTER,
    sortDescending: false,
    pageSize: 3,
  };

  constructor(
    public signalRService: DiscussionSignalrService,
    private discussionApiService: DiscussionApiService,
    private usersService: UsersService,
    private dialog: MatDialog,
    private notifyService: NotifyService,
  ) {}

  ngOnInit() {
    if (this.discussion) {
      this.isHidden = this.discussion.isHidden;
      this.totalAnswers = this.discussion.discussionItemChildren
        ? this.discussion.discussionItemChildren.totalCount
        : 0;
      this.upvotes = this.discussion.votes ? this.discussion.votes.length : 0;
      this.name =
        `${this.discussion.user.firstName} ${this.discussion.user.lastName}` ??
        null;
      this.profilePicUrl = this.discussion.user.profilePictureUrl ?? null;
      this.hasUserUpvoted =
        !!this.discussion.votes &&
        !!this.discussion.votes.find(
          (vote) => vote.userId === this.usersService.getUserId(),
        );
      this.isReportedByCurrentUser =
        this.reports.indexOf(this.discussion.id) > -1;

      this.createdAt = DateHelpers.getTimespawnFrom(
        this.discussion.createdAtUtc,
      );
      this.setMaxUpvotedCommentAsFirst();

      this.displayedReplies = this.discussion.discussionItemChildren
        ? this.discussion.discussionItemChildren.items
        : [];
      this.areMoreRepliesToBeShown =
        this.displayedReplies.length <
        (this.discussion.discussionItemChildren
          ? this.discussion.discussionItemChildren.totalCount
          : 0);

      const reportGroupOfItem = this.reportGroups.find(
        (item) => item.id === this.discussion.id,
      );

      if (!reportGroupOfItem) {
        this.reportedCount = 0;
      } else {
        this.reportedCount = reportGroupOfItem.reportsCount;
      }
    }
    this.user = this.usersService.getUserData();
  }

  public vote(upvote: boolean): void {
    const discussion = {
      ...this.discussion,
      votes: [
        {
          isUpVote: upvote,
          userId: this.usersService.getUserId(),
          createdAtUtc: new Date().toISOString(),
        },
        ...this.discussion.votes,
      ],
    };

    this.discussion = discussion;
    this.upvotes = upvote ? this.upvotes + 1 : this.upvotes - 1;
    this.hasUserUpvoted = upvote;

    this.discussionApiService
      .patchDiscussionItem(this.entityId, this.discussion.id, { upvote })
      .subscribe();
  }

  public hideItem(hide: boolean): void {
    this.discussionApiService
      .hideDiscussionItem(this.entityId, this.discussion.id, hide)
      .subscribe((_) => (this.isHidden = !this.isHidden));
  }

  public reportItem(): void {
    const ref = this.dialog.open(ReportDiscussionDialog, {
      width: '400px',
    });

    ref
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        concatMap(({ text, labelIds }) =>
          this.discussionApiService
            .reportDiscussionItem({
              discussionId: this.entityId,
              discussionItemId: this.discussion.id,
              text,
              labelIds,
            })
            .pipe(map((_) => ({ text, labelIds }))),
        ),
      )
      .subscribe(({ text, labelIds }) => {
        this.isReportedByCurrentUser = true;
        this.notifyService.showInfo(
          'Report submitted. An admin will investigate the problem.',
        );
      });
  }

  public submitAnswer(): void {
    if (!this.answerForm?.value?.trim?.()) {
      return;
    }
    if (this.isPostReplyInProgress) {
      return;
    }

    this.isPostReplyInProgress = true;
    this.answerForm.disable({ emitEvent: false });

    this.hasAddedAnswers = true;
    this.answerForm.markAsPristine();

    this.discussionApiService
      .addDiscussionItemAsync(
        this.entityId,
        {
          text: this.answerForm.value,
          parentId: this.discussion.id,
        },
        this.signalRService.connectionId,
      )
      .subscribe((id: IStringResponse) => {
        const newDiscussion = {
          id: id.field,
          userId: this.usersService.getUserId(),
          createdAtUtc: new Date().toISOString(),
          updatedAtUtc: new Date().toISOString(),
          text: this.answerForm.value,
          votes: [],
          user: this.user!,
          isHidden: false,
          discussionItemChildren: { totalCount: 0, items: [] },
          discussionId: this.entityId,
          locallyPushed: true,
        };

        const newDiscussionChildren: IDiscussionItem[] = this.discussion
          .discussionItemChildren
          ? [...this.discussion.discussionItemChildren.items, newDiscussion]
          : [newDiscussion];

        this.discussion = {
          ...this.discussion,
          discussionItemChildren: {
            totalCount: this.discussion.discussionItemChildren.totalCount + 1,
            items: newDiscussionChildren,
          },
        };

        this.displayedReplies = this.discussion.discussionItemChildren
          ? [...this.displayedReplies, newDiscussion]
          : [newDiscussion];
        this.totalAnswers++;
        this.answerForm.setValue(null);
        this.isPostReplyInProgress = false;
        this.answerForm.enable({ emitEvent: false });
        this.isShiftHeld = false;

        this.answerAdded.emit();
      });
  }

  public setMoreVisibleReplies(): void {
    this.repliesFilter = {
      ...this.repliesFilter,
      pageIndex: this.repliesFilter.pageIndex + 1,
    };

    this.discussionApiService
      .getDiscussionItemReplies(
        this.entityId,
        this.discussion.id,
        this.repliesFilter,
      )
      .subscribe((page: IPage<IDiscussionItem>) => {
        if (page.items.length < this.repliesFilter.pageSize) {
          this.repliesFilter = {
            ...this.repliesFilter,
            pageIndex: this.repliesFilter.pageIndex - 1,
          };
        }

        // Make sure incoming reply does not clash with existing
        // Keep the user's last reply at the bottom
        this.displayedReplies = uniqBy(
          [...this.displayedReplies, ...page.items],
          (discussion) => discussion.id,
        ).sort((a, b) => {
          const aSort = a.locallyPushed ? 1 : 0;
          const bSort = b.locallyPushed ? 1 : 0;

          return aSort - bSort;
        });

        this.areMoreRepliesToBeShown =
          this.displayedReplies.length <
          this.discussion.discussionItemChildren.totalCount;
      });
  }

  /**
   * Stops propagation of the enter ( block a new line if shift was not held down )
   */
  public onKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Enter' && this.isShiftHeld) {
      e.preventDefault();
    }
  }

  /**
   * Trigger the submit if the enter and shift/ctrl was pressed
   */
  public onKeyUp(e: KeyboardEvent): void {
    if (e.key === 'Enter' && this.isShiftHeld) {
      this.submitAnswer();
    }
  }

  public onProfilePictureClick() {
    if (!this.usersService.getUserId()) {
      this.askUserToSignIn();
      return;
    }

    this.dialog.open(DiscussionsMemberDialog, {
      data: {
        member: this.discussion.user,
      },
      panelClass: 'member-dialog',
    });
  }

  public askUserToSignIn(): void {
    this.dialog.open(AskToLoginComponent, {
      width: '400px',
    });
  }

  private setMaxUpvotedCommentAsFirst(): void {
    if (!this.discussion.discussionItemChildren) {
      return;
    }
    const maxNoOfUpvotes = Math.max(
      ...this.discussion.discussionItemChildren.items.map((di) =>
        di.votes ? di.votes.length : 0,
      ),
    );

    if (maxNoOfUpvotes === 0) {
      return;
    }

    const item = this.discussion.discussionItemChildren.items.find(
      (dic) => dic.votes && dic.votes.length === maxNoOfUpvotes,
    );
    if (!item) {
      return;
    }

    this.discussion.discussionItemChildren.items =
      this.discussion.discussionItemChildren.items.filter(
        (i) => i.id !== item.id,
      );
    this.discussion.discussionItemChildren.items = [
      item,
      ...this.discussion.discussionItemChildren.items,
    ];
  }
}
