import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IStringResponse } from '../shared/api/string-response';
import { API_BASE_URL } from '../shared/app-constants';
import { DifficultyLevel } from '../shared/enums/difficulty-level';
import {
  IFeedback,
  IFeedbackFilter,
  IFeedbackSummary,
} from '../shared/feedback/feedback';
import { DEFAULT_FILTER } from '../shared/filters/constants';
import { ApiServiceHelper } from '../shared/helpers';
import { FfLanguage } from '../shared/models/language';
import { IPage } from '../shared/pagination';
import { IResourcePost } from '../shared/resources/resource';
import { ICoursePatch, ICoursePost, ICourseReadModel } from './courses';
import { ICourseFilter } from './filters/filters';

@Injectable()
export class CoursesApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getCourseList(
    filter: ICourseFilter,
  ): Observable<IPage<ICourseReadModel>> {
    const url = `${this.apiBaseUrl}/courses`;
    return this.httpClient.get<IPage<ICourseReadModel>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public getCourse(id: string): Observable<ICourseReadModel> {
    const url = `${this.apiBaseUrl}/courses/${id}`;
    return this.httpClient.get<ICourseReadModel>(url);
  }

  public postCourse(post: ICoursePost): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/courses`;
    return this.httpClient.post<IStringResponse>(url, post);
  }

  public getCourseFeedbacks(
    id: string,
    filter: IFeedbackFilter,
  ): Observable<IPage<IFeedback>> {
    const url = `${this.apiBaseUrl}/courses/${id}/feedbacks`;
    return this.httpClient.get<IPage<IFeedback>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public getCourseFeedbacksSummary(
    id: string,
    onlyVerified?: boolean,
  ): Observable<IFeedbackSummary[]> {
    const url = `${this.apiBaseUrl}/courses/${id}/feedbacks-summary`;
    return this.httpClient.get<IFeedbackSummary[]>(url, {
      params: ApiServiceHelper.getQueryParams({ onlyVerified }),
    });
  }

  public patchCourse(
    id: string,
    updates: ICoursePatch,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/courses/${id}`;
    return this.httpClient.patch<IStringResponse>(url, updates);
  }

  public updateCourseVideo(
    courseId: string,
    file: File,
    language: FfLanguage,
  ): Observable<IStringResponse> {
    const headers = new HttpHeaders()
      .set('responseType', 'text/plain')
      .set('accept', 'text/plain');

    const uploadData = new FormData();
    uploadData.append(`video`, file, file.name);

    return this.httpClient.post<IStringResponse>(
      `${this.apiBaseUrl}/courses/${courseId}/recording?language=${language}`,
      uploadData,
      { headers },
    );
  }

  public addLessonResource(
    courseId: string,
    resource: IResourcePost,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/Courses/${courseId}/resources`;
    return this.httpClient.post<IStringResponse>(url, { ...resource });
  }

  public deleteLessonResource(
    courseId: string,
    resourceId: string,
  ): Observable<void> {
    const url = `${this.apiBaseUrl}/Courses/${courseId}/resources/${resourceId}`;
    return this.httpClient.delete<void>(url);
  }

  public reorderLesson(
    courseId: string,
    orderedLessonIds: string[],
    difficultyLevel: DifficultyLevel,
  ): Observable<void> {
    const command = {
      orderedLessonIds,
      difficultyLevel,
    };
    const url = `${this.apiBaseUrl}/Courses/${courseId}/reorder-lessons`;
    return this.httpClient.post<void>(url, command);
  }
}
