export const APP_ICONS = [
    {
        name: 'notifications_outlined',
        url: '../assets/icons/notifications.svg'
    },
    {
        name: 'brief-list-marker',
        url: '../assets/images/course-list-image.svg'
    },
    {
        name: 'zoom_app',
        url: '../assets/icons/zoom.svg'
    },
    {
        name: 'star_filled',
        url: '../assets/icons/star-filled.svg'
    },
    {
        name: 'star_outline',
        url: '../assets/icons/star-outline.svg'
    },
    {
        name: 'star_half',
        url: '../assets/icons/star-half.svg'
    },
    {
        name: 'documents',
        url: '../assets/icons/documents.svg'
    },
    {
        name: 'links',
        url: '../assets/icons/links.svg'
    },
    {
        name: 'tools',
        url: '../assets/icons/tools.svg'
    },
];
