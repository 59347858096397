export class EnumHelpers {
  public static getEnumAsStringArray(
    type: any,
  ): { view: number; viewValue: string }[] {
    return Object.entries(type)
      .map(([key, value]) => ({ view: +key, viewValue: `${value}` }))
      .filter((e: { view: number; viewValue: string }) => !isNaN(e.view));
  }

  public static getStringEnumAsStringArray(
    type: any,
  ): { view: string; viewValue: string }[] {
    return Object.entries(type).map(([key, value]) => ({
      view: `${value}`,
      viewValue: `${value}`,
    }));
  }
}
