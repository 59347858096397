import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { IFeedbackSummary } from '../feedback';

interface IFeedbackSummaryViewModel {
  oneStar: number;
  twoStar: number;
  threeStar: number;
  fourStar: number;
  fiveStar: number;
}

@Component({
  selector: 'feedback-summary',
  templateUrl: 'feedback-summary.component.html',
  styleUrls: ['feedback-summary.component.scss'],
})
export class FeedbackSummaryComponent implements OnInit, OnChanges {
  @Input() public feedbackSummary: IFeedbackSummary[] = [];

  public displayedFeedbacks: IFeedbackSummaryViewModel = {
    oneStar: 0,
    twoStar: 0,
    threeStar: 0,
    fourStar: 0,
    fiveStar: 0,
  };

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['feedbackSummary'] && !changes['feedbackSummary'].firstChange) {
      this.setDisplayedRating();
    }
  }

  public ngOnInit(): void {
    this.setDisplayedRating();
  }

  private setDisplayedRating(): void {
    this.displayedFeedbacks = {
      oneStar: 0,
      twoStar: 0,
      threeStar: 0,
      fourStar: 0,
      fiveStar: 0,
    };
    this.feedbackSummary.forEach((feedback) => {
      if (feedback.key === 1) {
        this.displayedFeedbacks.oneStar = feedback.count;
      }
      if (feedback.key === 2) {
        this.displayedFeedbacks.twoStar = feedback.count;
      }
      if (feedback.key === 3) {
        this.displayedFeedbacks.threeStar = feedback.count;
      }
      if (feedback.key === 4) {
        this.displayedFeedbacks.fourStar = feedback.count;
      }
      if (feedback.key === 5) {
        this.displayedFeedbacks.fiveStar = feedback.count;
      }
    });
  }
}
