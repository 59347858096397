import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { DateTime } from 'luxon';

export interface IDateOutput {
  isoString: string;
  offsetMinutes: number;
}

@Component({
  selector: 'date-time-picker',
  templateUrl: 'date-time.component.html',
  styleUrls: ['date-time.component.scss'],
})
export class DateTimePickerComponent implements OnInit, OnChanges {
  @Input() public label: string = 'Date';
  @Input() public isoString!: string;
  @Output() public outputIsoString = new EventEmitter<IDateOutput>();

  public offsetMinutes: number = 0;
  public openDatePickerLabel: string = 'Choose date';

  public date = new Date();
  public timeFormControl = new UntypedFormControl();

  @ViewChild(MatMenuTrigger) public dateMenu!: MatMenuTrigger;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isoString'] && !changes['isoString'].firstChange) {
      this.initDateInForm(false);
    }
  }

  public ngOnInit(): void {
    this.initDateInForm(false);
  }

  private initDateInForm(emitEvent: boolean): void {
    this.date.setMilliseconds(0);
    this.offsetMinutes = +this.date.getTimezoneOffset();

    if (this.isoString) {
      const d = this.isoString.split('.');
      const isoString = `${d[0]}.000Z`;
      this.date = DateTime.fromISO(isoString).toJSDate();
      this.openDatePickerLabel = this.date.toLocaleString();
    }

    this.timeFormControl.setValue(this.extractHoursAndMinutes(this.date), {
      emitEvent,
    });
  }

  public onMonthChangedInMonthView(change: Date): void {
    this.date.setMonth(change.getMonth());
  }

  public onYearChangedInYearView(change: Date): void {
    this.date.setFullYear(change.getFullYear());
  }

  public onSubmit(): void {
    if (this.timeFormControl.value === '') {
      this.timeFormControl.setValue(this.extractHoursAndMinutes(this.date));
      return;
    }
    const timeSplitByUnits = this.timeFormControl.value.split(':');
    this.date.setMinutes(timeSplitByUnits[1]);
    this.date.setHours(timeSplitByUnits[0]);
    this.date.setSeconds(0);
    this.date.setMilliseconds(0);

    const dateTime = this.date.toISOString();

    const dateTimeSubmitted = dateTime;
    this.openDatePickerLabel = this.date.toLocaleString();

    this.dateMenu.closeMenu();

    this.outputIsoString.emit({
      isoString: dateTimeSubmitted,
      offsetMinutes: this.offsetMinutes,
    });
  }

  private extractHoursAndMinutes(date: Date): string {
    let hours = `${date.getHours()}`;
    if (hours.length === 1) {
      hours = `0${hours}`;
    }
    let minutes = `${date.getMinutes()}`;
    if (minutes.length === 1) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
  }
}
