import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_BASE_URL } from 'src/app/shared/app-constants';
import { ApiServiceHelper } from 'src/app/shared/helpers';
import { IViewModel } from 'src/app/shared/views';

@Injectable()
export class ViewsApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public createView(entityId: string): Observable<string> {
    const url = `${this.apiBaseUrl}/views`;

    const sessionId = sessionStorage.getItem('ff_user_session_id');

    const body = {
      entityId,
      sessionId,
    };

    return this.httpClient.patch<string>(url, body);
  }

  public getViewForEntity(entityId: string): Observable<IViewModel> {
    const url = `${this.apiBaseUrl}/views`;
    return this.httpClient.get<IViewModel>(url, {
      params: ApiServiceHelper.getQueryParams({ entityId }),
    });
  }
}
