import { Component, Input } from "@angular/core";
import { DEFAULT_UPLOAD, IUpload, UploadStatus } from "src/app/core/uploads/upload";

@Component({
    selector: 'video-upload-progress',
    templateUrl: 'video-upload-progress.component.html'
})
export class VideoUploadProgress {
    @Input() public uploadProgress: IUpload = DEFAULT_UPLOAD;
    @Input() public uploadAzureProgress: IUpload = DEFAULT_UPLOAD;

    @Input() public videoUrl: string | undefined;
    @Input() public captionUrl: string | undefined;

    public UploadStatus = UploadStatus;
}