<div
  class="d-flex p-3"
  [class.not-read]="!isRead"
  [class.read]="isRead"
  (click)="navigateToResource()"
  >
  @if (notification.senderPictureUrl) {
    <div class="me-3">
      <img class="profile-picture" [src]="notification.senderPictureUrl" />
    </div>
  }
  <div>
    <div class="mat-body-2">{{ notification.text }}</div>
    @if (createdAt) {
      <div class="mat-caption text-grey">
        <span>{{ createdAt }}</span>
      </div>
    }
  </div>
</div>
