import { Component, Inject, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DateHelpers } from 'src/app/shared/date/date-helpers';
import { CUSTOM_FEATURE_PATHS, Feature } from 'src/app/shared/enums/features';
import { StringHelpers } from 'src/app/shared/helpers';
import { APP_BASE_URL } from '../../../shared/app-constants';
import { INotification, IResourceNavigationItem } from '../notification';
import { NotificationApiService } from '../notification-api.service';

@Component({
  selector: 'notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() public notification!: INotification;

  public isRead: boolean = false;
  public createdAt!: string;

  constructor(
    private notificationApiService: NotificationApiService,
    @Inject(APP_BASE_URL) private appBaseUrl: string,
  ) {}

  public ngOnInit(): void {
    this.isRead = !!this.notification.readAtUtc;
    if (this.notification.createdAtUtc) {
      this.createdAt = DateHelpers.getTimespawnFrom(
        this.notification.createdAtUtc,
      );
    }
  }

  public navigateToResource(): void {
    this.markAsRead().subscribe((_) => {
      if (!this.notification.resourceNavigationItems) {
        return;
      }

      const resourceUrl = this.getResourceUrl(
        this.notification.resourceNavigationItems,
      );
      const url = `${this.appBaseUrl}${resourceUrl}`;
      window.open(url, '_self');
    });
  }

  private getResourceUrl(
    resourceNavigationItems: IResourceNavigationItem[],
  ): string {
    let finalResourceUrl = '';

    const customPath = CUSTOM_FEATURE_PATHS[resourceNavigationItems[0].feature];
    if (!!customPath) {
      finalResourceUrl += `/${customPath.url}`;
      if (customPath.hasId) {
        const resourceId = resourceNavigationItems[0].resourceId;
        finalResourceUrl += `/${resourceId}`;
      }
    } else {
      resourceNavigationItems.forEach((navigationItem) => {
        const resourceName = StringHelpers.getDashSeparatedString(
          navigationItem.resourceTitle || 'unknown',
        );
        const featureString = Feature[navigationItem.feature].toLowerCase();
        const resourceId = navigationItem.resourceId;

        finalResourceUrl += `/${featureString}/${resourceName}/${resourceId}`;
      });
    }

    return finalResourceUrl;
  }

  private markAsRead(): Observable<void> {
    this.notification.readAtUtc = new Date().toISOString();
    this.isRead = true;
    return this.notificationApiService.markAsRead(this.notification.id);
  }
}
