import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { UsersService } from 'src/app/user/users.service';
import { ILabel } from '../label';
import { IFeedback, IFeedbackSummary } from './feedback';

@Component({
  selector: 'feedback-page',
  templateUrl: 'feedback.component.html',
  styleUrls: ['feedback.component.scss'],
})
export class FeedbackPageComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() reviews: IFeedback[] = [];
  @Input() totalReviewsCount: number = 0;
  @Input() feedbackLabels: ILabel[] = [];
  @Input() currentUserReview: IFeedback | undefined;

  @Input() feedbackSummary: IFeedbackSummary[] = [];

  @Input() entityReviewScore: number = 0;
  @Input() entityReviewAverage: number = 0;
  @Input() entityTitle!: string;
  @Input() entityType!: 'course' | 'event';

  @Output() onMoreReviewsClicked = new EventEmitter();
  @Output() onOnlyVerfiedClicked = new EventEmitter<boolean>();
  @Output() onHideReviewClicked = new EventEmitter<{
    userId: string;
    hide: boolean;
  }>();
  @Output() onFeedBackSubmitted = new EventEmitter<{
    text: string;
    labels: ILabel[];
    rating: number;
  }>();

  @ViewChild('addReviewTextarea') public addReviewTextarea!: ElementRef;

  public isAdminViewShown = false;
  public isFeedbackSaving = false;

  public selectedLabelsIndexes: number[] = [];
  public reviewText: string = '';
  public rating = 0;

  public showEditField = false;

  public onlyVerified = false;

  private destroyNotifier = new Subject<void>();

  private initialReview: {
    text: string | undefined;
    rating: number;
    selectedLabelIds: string[];
  } = {
    text: '',
    rating: 0,
    selectedLabelIds: [],
  };

  private isUserLoggedIn = false;

  constructor(private usersService: UsersService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['reviews'] && !changes['reviews'].firstChange) {
      this.isFeedbackSaving = false;
    }

    if (
      changes['currentUserReview'] &&
      !changes['currentUserReview'].firstChange
    ) {
      this.initialReview = {
        text: changes['currentUserReview'].currentValue.text || '',
        rating: changes['currentUserReview'].currentValue.rating || 0,
        selectedLabelIds: changes['currentUserReview'].currentValue.labels
          .map((l: ILabel) => l.id)
          .filter(
            (id: string) =>
              this.feedbackLabels.findIndex((l) => l.id === id) > -1,
          ),
      };
    }
  }

  public ngOnInit(): void {
    this.isUserLoggedIn = !!this.usersService.getUserId();

    combineLatest([
      this.usersService.userAdmin,
      this.usersService.isAdminInterfaceShown,
    ])
      .pipe(takeUntil(this.destroyNotifier))
      .subscribe(([admin, isAdminShown]) => {
        this.isAdminViewShown = admin.isSuperAdmin && isAdminShown;
      });

    if (!!this.currentUserReview) {
      this.rating = this.currentUserReview.rating || 0;
      this.reviewText = this.currentUserReview.text || '';
      this.selectedLabelsIndexes = this.currentUserReview.labels
        .map((l) => l.id)
        .map((id) => this.feedbackLabels.findIndex((l) => l.id === id))
        .filter((id) => id > -1);

      this.initialReview = {
        text: this.currentUserReview.text,
        rating: this.currentUserReview.rating,
        selectedLabelIds: this.currentUserReview.labels
          .map((l) => l.id)
          .filter(
            (id) => this.feedbackLabels.findIndex((l) => l.id === id) > -1,
          ),
      };
    }

    this.showEditField = !this.currentUserReview;

    if (this.showEditField) {
      setTimeout(() => {
        this.setTextareaHeight();
      });
    }
  }

  public ngAfterViewInit(): void {}

  public loadMoreReviews(): void {
    this.onMoreReviewsClicked.emit();
  }

  public isFormModified(): boolean {
    const selectedLabelIds = this.selectedLabelsIndexes.map(
      (i) => this.feedbackLabels[i].id,
    );
    return (
      this.reviewText === this.initialReview.text &&
      this.rating === this.initialReview.rating &&
      this.initialReview.selectedLabelIds.every(
        (id) => selectedLabelIds.indexOf(id) > -1,
      ) &&
      this.initialReview.selectedLabelIds.length === selectedLabelIds.length
    );
  }

  public changeOnlyVerified(change: MatCheckboxChange): void {
    this.onOnlyVerfiedClicked.emit(change.checked);
  }

  private setTextareaHeight(): void {
    this.addReviewTextarea.nativeElement.style.height = 'auto';
    this.addReviewTextarea.nativeElement.style.height =
      this.addReviewTextarea.nativeElement.scrollHeight + 4 + 'px';
  }

  public onTextareChange(): void {
    this.setTextareaHeight();
  }

  public onEditReview(): void {
    this.showEditField = !this.showEditField;
    this.rating = this.currentUserReview?.rating || 0;

    if (this.showEditField) {
      setTimeout(() => {
        this.setTextareaHeight();
      });
    }
  }

  public selectLabel(labelIndex: number): void {
    const existingAtIndex = this.selectedLabelsIndexes.findIndex(
      (i) => labelIndex === i,
    );
    if (existingAtIndex < 0) {
      this.selectedLabelsIndexes = [...this.selectedLabelsIndexes, labelIndex];
    } else {
      this.selectedLabelsIndexes = this.selectedLabelsIndexes.filter(
        (i) => i !== labelIndex,
      );
    }
  }

  public hideFeedback(userId: string, hide: boolean): void {
    this.onHideReviewClicked.emit({ userId, hide });
    const reviewIndex = this.reviews.findIndex((r) => r.userId === userId);
    let review = this.reviews[reviewIndex];
    review = {
      ...review!,
      hiddenAtUtc: hide ? new Date().toISOString() : undefined,
    };
    this.reviews[reviewIndex] = review;
  }

  public changeRating(rating: number): void {
    this.rating = rating;
  }

  public submitFeedback(): void {
    if (this.rating === 0 || this.isFeedbackSaving) {
      return;
    }

    this.isFeedbackSaving = this.isUserLoggedIn;

    const selectedLabels = this.selectedLabelsIndexes.map(
      (labelIndex) => this.feedbackLabels[labelIndex],
    );

    this.onFeedBackSubmitted.emit({
      text: this.reviewText,
      labels: selectedLabels,
      rating: this.rating,
    });
  }

  public cancelFeedbackCompletion(): void {
    this.reviewText = this.initialReview.text || '';
    this.rating = this.initialReview.rating;
    this.selectedLabelsIndexes = this.initialReview.selectedLabelIds.map((id) =>
      this.feedbackLabels.findIndex((l) => l.id === id),
    );

    setTimeout(() => {
      this.setTextareaHeight();
    });
  }

  public isLabelSelected(labelIndex: number): boolean {
    return this.selectedLabelsIndexes.findIndex((i) => labelIndex === i) > -1;
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();
  }
}
