@if (!confirmClicked) {
  <div class="first-confirm">
    <span class="mat-headline-5">Confirm Your Phone</span>
    @if (!endingPhoneNumber) {
      <span class="info">
        To enable, first confirm your phone number so we know it’s you.
      </span>
      <span class="info mt-3">
        We’ll send a code to <b>{{ phone }}</b> for you to enter here.
      </span>
    }
    @if (endingPhoneNumber) {
      <span class="info">
        To continue, please enter the 6-digit verification code sent to <b>your phone ending in {{endingPhoneNumber}}.</b>
      </span>
    }
    <div class="footer mt-5">
      <button mat-stroked-button class="white me-2" (click)="onCancelClicked()">
        Cancel
      </button>
      <button mat-flat-button color="accent" (click)="onFirstConfirmClicked()">
        Confirm
      </button>
    </div>
  </div>
}

@if (confirmClicked) {
  <div class="second-confirm">
    <span class="mat-headline-5">Confirm Your Phone</span>
    <span class="info">
      Please enter the code that you received on <b>{{ phone }}</b> to activate
      the 2-step verification
    </span>
    <span class="info mt-5">Verification code</span>
    <div class="verification-code">
      <code-input
        (codeChanged)="onCodeChanged($event)"
        [isCodeHidden]="false"
        [codeLength]="6"
        >
      </code-input>
      Didn’t get a code? <a>Click to resend.</a>
    </div>
    <div class="footer mt-4">
      <button mat-stroked-button class="white me-2" (click)="onCancelClicked()">
        Cancel
      </button>
      <button
        [disabled]="(smsCode?.length ?? 0) < 6"
        mat-flat-button
        color="accent"
        (click)="onPhoneConfirmed()"
        >
        Confirm
      </button>
    </div>
  </div>
}
