import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DetailedError, Upload } from 'tus-js-client';
import { API_BASE_URL } from '../shared/app-constants';
import { FFInsightsService } from './logging.service';
import { environment } from 'src/environments/environment';

export interface FileStatus {
  filename: string;
  progress: number;
  hash: string;
  uuid: string;
}

@Injectable()
export class ChunkFileUploadService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private ffInsightsService: FFInsightsService,
  ) {}

  private uploadStatus = new Subject<FileStatus[]>();
  uploadProgress = this.uploadStatus.asObservable();

  private _fileUploadError = new Subject<Error | DetailedError>();
  fileUploadError = this._fileUploadError.asObservable();

  private uploadKey: string | undefined;

  fileStatusArr: FileStatus[] = [];

  uploadFile(uploadId: string, file: File, filename: string, body?: any) {
    const fileStatus: FileStatus = {
      filename,
      progress: 0,
      hash: '',
      uuid: '',
    };
    this.fileStatusArr.push(fileStatus);

    this.uploadStatus.next(this.fileStatusArr);

    const endpoint = `${this.apiBaseUrl}/media/videos`;

    const metadata = {
      ...body,
      filename: file.name,
      filetype: file.type,
      userId: localStorage.getItem('usrid'),
      uploadId,
    };

    const upload = new Upload(file, {
      endpoint: endpoint,
      retryDelays: [0, 3000, 6000, 12000, 24000],
      headers: {
        Authorization: `Bearer ${localStorage.getItem('at')!}`,
      },
      chunkSize: environment.chunkSize,
      metadata,
      onError: async (error) => {
        if (this.uploadKey) {
          removeUpload(this.uploadKey);
          this.uploadKey = undefined;
        }
        this.ffInsightsService.logEvent(
          '[ERR] Error when uploading video with metadata',
          { metadata, error },
        );
        this._fileUploadError.next(error);
        return false;
      },
      onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
        this.fileStatusArr.forEach((value) => {
          if (value.filename === filename) {
            value.progress = Math.floor((bytesAccepted / bytesTotal) * 100);
            value.uuid = upload.url!.split('/').slice(-1)[0];
          }
        });
        this.uploadStatus.next(this.fileStatusArr);
      },
      onSuccess: async () => {
        this.fileStatusArr.forEach((value) => {
          if (value.filename === filename) {
            value.progress = 100;
          }
        });
        if (this.uploadKey) {
          removeUpload(this.uploadKey);
          this.uploadKey = undefined;
        }
        this.uploadStatus.next(this.fileStatusArr);
        return true;
      },
    });

    findAllUploads().then((uploads) => {
      const currentUpload = uploads.find(
        (u) =>
          u.metadata.clientId === metadata.clientId &&
          u.metadata.filename === metadata.filename &&
          u.metadata.filetype === metadata.filetype &&
          u.size - file.size === 0,
      );
      if (!!currentUpload) {
        this.uploadKey = currentUpload.urlStorageKey;
        upload.resumeFromPreviousUpload(currentUpload);
      }
      upload.start();
    });
  }
}

function findAllUploads() {
  var results = _findEntries('tus::');
  return Promise.resolve(results);
}

function removeUpload(key: string): void {
  localStorage.removeItem(key);
}

function _findEntries(prefix: string) {
  var results = [];

  for (var i = 0; i < localStorage.length; i++) {
    var _key = localStorage.key(i)!;

    if (_key.indexOf(prefix) !== 0) continue;

    try {
      var upload = JSON.parse(localStorage.getItem(_key)!);
      upload.urlStorageKey = _key;
      results.push(upload);
    } catch (e) {
      // The JSON parse error is intentionally ignored here, so a malformed
      // entry in the storage cannot prevent an upload.
    }
  }

  return results;
}
