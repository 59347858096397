import { Feature } from 'src/app/shared/enums/features';
import { DEFAULT_FILTER } from 'src/app/shared/filters/constants';
import { ISortFilter } from '../../shared/filters/filters';

export interface INotification {
  id: string;
  userId: string;
  text: string;
  resourceTitle?: string;
  resourceNavigationItems?: IResourceNavigationItem[];
  readAtUtc?: string;
  createdAtUtc?: string;
  senderId?: string;
  senderPictureUrl?: string;
}

export interface INotificationFilter extends ISortFilter {
  resourceId?: string;
  feature?: Feature;
  onlyUnreadNotifications: boolean;
}

export const DEFAULT_NOTIFICATION_FILTER: INotificationFilter = {
  ...DEFAULT_FILTER,
  sortBy: 'readAtUtc',
  sortDescending: true,
  onlyUnreadNotifications: false,
};

export interface IResourceNavigationItem {
  feature: Feature;
  resourceId: string;
  resourceTitle?: string;
}

export interface NotificationPostPayload {
  userId: string;
  text: string;
  resourceNavigationItems?: Array<{
    feature: Feature;
    resourceId: string;
    resourceTitle: string;
  }>;
  resourceTitle?: string;
}
