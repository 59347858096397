import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime, Interval } from 'luxon';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  of,
  skip,
  switchMap,
  zip,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventApiService } from '../core/event/events-api.service';
import { NotifyService } from '../core/notify.service';
import { CoursesApiService } from '../courses/course-api.service';
import { Emojis } from '../emojis';
import {
  IDiscussionMemberReadModel,
  IDiscussionSummary,
} from '../shared/discussions/discussion';
import { DiscussionApiService } from '../shared/discussions/discussion-api.service';
import { Feature } from '../shared/enums/features';
import { IPage } from '../shared/pagination';
import { IUserAPI } from './user';
import { UsersService } from './users.service';

export type CompleteProgress = Record<string, boolean>;
export type CompleteProgressSummary = {
  progress: CompleteProgress;
  isCompleted: boolean;
  totalItems: number;
  completedItems: number;
  completedPercentage: number;
  itemName: string;
  data?: Record<string, unknown>;
};
export type ProgressCard = {
  label: string;
  rewardTakenInfo: string;
  rewardInfo: string;
  emoji: string;
  summary?: CompleteProgressSummary;
  default?: CompleteProgressSummary;
  onArrowClicked?: (...args: any[]) => void;
  calculateSummary?: (...args: any[]) => Observable<CompleteProgressSummary>;
};
export type ProgressSummaries = Record<string, CompleteProgressSummary>;

@Injectable()
export class CompleteProgressService {
  public static CACHE_KEY = 'USER_DATA_COMPLETE_PROGRESS';

  public profileCompleteCard = {
    label: 'Complete your profile',
    rewardTakenInfo: '',
    rewardInfo: '',
    emoji: 'female-teacher',

    default: {
      progress: {},
      totalItems: 0,
      completedItems: 0,
      completedPercentage: 0,
      isCompleted: false,
      itemName: 'profileCompleteCard',
    },

    onArrowClicked: () => {
      this.router.navigate(['/profile/profile']);
    },

    calculateSummary: (userData: IUserAPI) => {
      const fields = {
        industry: (userData: IUserAPI) => {
          return !!userData.industry;
        },
        birthDate: (userData: IUserAPI) => {
          return !!(
            userData.dateOfBirthDay &&
            userData.dateOfBirthMonth &&
            userData.dateOfBirthYear
          );
        },
        learningPreferences: (userData: IUserAPI) => {
          return !!userData.userLearningPreferences?.length;
        },
        socialMedias: (userData: IUserAPI) => {
          return userData.socialMediaLinks.some((socialMediaLink) => {
            return socialMediaLink.url;
          });
        },
        profilePic: (userData: IUserAPI) => {
          if (userData.identityProvider) {
            return true;
          }

          return !!userData.profilePictureUrl;
        },
      };

      const progress: Record<string, boolean> = Object.keys(fields).reduce(
        (acc, name: string) => {
          const fieldProgress = (
            fields as Record<string, (user: IUserAPI) => boolean>
          )[name]?.(userData);

          return {
            ...acc,
            [name]: fieldProgress,
          };
        },
        {},
      );

      const totalItems = Object.keys(progress).length;
      const completedItems = Object.keys(progress).filter(
        (key) => progress?.[key],
      ).length;
      const completedPercentage =
        100 - ((completedItems - totalItems) / totalItems) * -100;

      return of({
        progress,
        totalItems,
        completedItems,
        completedPercentage,
        isCompleted: completedPercentage >= 100,
        itemName: 'profileCompleteCard',
      });
    },
  };

  public introduceYourselfCard = {
    label: 'Introduce yourself',
    rewardTakenInfo: '',
    rewardInfo: '',
    emoji: 'star',

    default: {
      progress: {},
      totalItems: 0,
      completedItems: 0,
      completedPercentage: 0,
      isCompleted: false,
      itemName: 'introduceYourselfCard',
    },

    calculateSummary: (userData: IUserAPI) => {
      // Do not fetch everything if already completed, as this wont be updated
      if (
        this.completionSubject.value?.['introduceYourselfCard']?.isCompleted
      ) {
        return of(this.completionSubject.value?.['introduceYourselfCard']);
      }

      return this.discussionApiService
        .getDiscussionsSummary({
          features: [Feature.NewMembersDiscussion],
          resourceTitle: 'Introductions',
          pageSize: 1,
          pageIndex: 0,
        })
        .pipe(
          switchMap((discussionSummaries: IPage<IDiscussionSummary>) => {
            return zip(
              ...discussionSummaries.items.map((discussionSummary) => {
                return this.discussionApiService.getDiscussionsSummaryForMemeber(
                  discussionSummary.id,
                  localStorage.getItem('usrid')!.toString(),
                ).pipe(
                  map((discussionMembers: IDiscussionMemberReadModel) => ({
                    discussionId: discussionSummary.id,
                    ...discussionMembers,
                  }))
                );
              }),
            );
          }),
          map(discussionMembers => {
            const discussionMember = discussionMembers[0];

            let userHasReplied = false;
            if (!!discussionMember) {
              userHasReplied =
                discussionMember.userPosts + discussionMember.userReplies > 0;
            }

            return {
              data: {
                discussionSummaryId: discussionMember.discussionId,
              },
              progress: {},
              totalItems: 1,
              completedItems: userHasReplied ? 1 : 0,
              completedPercentage: userHasReplied ? 100 : 0,
              isCompleted: !!userHasReplied,
              itemName: 'introduceYourselfCard',
            };
          }),
        );
    },

    onArrowClicked: () => {
      const discussionSummaryId =
        this.completionSubject.value?.['introduceYourselfCard']?.data?.[
          'discussionSummaryId'
        ];

      if (!discussionSummaryId) {
        return;
      }

      this.router.navigate(['/discussions', discussionSummaryId]);
    },
  };

  public welcomeCallCard = {
    label: 'Register for a Welcome call',
    rewardTakenInfo: '',
    rewardInfo: '',
    emoji: 'iphone',

    default: {
      progress: {},
      totalItems: 0,
      completedItems: 0,
      completedPercentage: 0,
      isCompleted: false,
      itemName: 'welcomeCallCard',
    },

    onArrowClicked: () => {
      this.router.navigate(['/events']);
    },

    calculateSummary: (userData: IUserAPI) => {
      // Do not fetch everything if already completed, as this wont be updated
      if (this.completionSubject.value?.['welcomeCallCard']?.isCompleted) {
        return of(this.completionSubject.value?.['welcomeCallCard']);
      }

      return zip(
        this.eventApiService.getEvents({
          search: 'Welcome Call',
          pageIndex: 0,
          pageSize: 1,
          endsAfterUtc: userData.createdAtUtc,
          previousEventsForCurrentUser: true,
        }),
        this.eventApiService.getEvents({
          search: 'Welcome Call',
          pageIndex: 0,
          pageSize: 1,
          endsAfterUtc: userData.createdAtUtc,
          upcomingEventsForCurrentUser: true,
        }),
      ).pipe(
        map(([past, upcoming]) => {
          const userParticipated = !!(past.totalCount || upcoming.totalCount);

          return {
            progress: {},
            totalItems: 1,
            completedItems: userParticipated ? 1 : 0,
            completedPercentage: userParticipated ? 100 : 0,
            isCompleted: userParticipated,
            itemName: 'welcomeCallCard',
          };
        }),
      );
    },
  };

  public startCourseCard = {
    label: 'Start a course',
    rewardTakenInfo: '',
    rewardInfo: '',
    emoji: 'bookmark',

    default: {
      progress: {},
      totalItems: 0,
      completedItems: 0,
      completedPercentage: 0,
      isCompleted: false,
      itemName: 'startCourseCard',
    },

    onArrowClicked: () => {
      this.router.navigate(['/courses']);
    },

    calculateSummary: (userData: IUserAPI) => {
      // Do not fetch everything if already completed, as this wont be updated
      if (this.completionSubject.value?.['startCourseCard']?.isCompleted) {
        return of(this.completionSubject.value?.['startCourseCard']);
      }

      return zip(
        // user has any completed
        this.coursesApiService.getCourseList({
          completedCoursesForCurrentUser: true,
          pageIndex: 0,
          pageSize: 1,
          inProgressCoursesForCurrentUser: false,
          favoriteCoursesForCurrentUser: false,
        }),
        // user has any in progress
        this.coursesApiService.getCourseList({
          completedCoursesForCurrentUser: false,
          pageIndex: 0,
          pageSize: 1,
          inProgressCoursesForCurrentUser: true,
          favoriteCoursesForCurrentUser: false,
        }),
      ).pipe(
        map(([completed, future]) => {
          const userParticipated = !!(
            completed.totalCount || future.totalCount
          );

          return {
            progress: {},
            totalItems: 1,
            completedItems: userParticipated ? 1 : 0,
            completedPercentage: userParticipated ? 100 : 0,
            isCompleted: userParticipated,
            itemName: 'startCourseCard',
          };
        }),
      );
    },
  };

  public registerEventCard = {
    label: 'Register for an Event',
    rewardTakenInfo: '',
    rewardInfo: '',
    emoji: 'target',

    default: {
      progress: {},
      totalItems: 0,
      completedItems: 0,
      completedPercentage: 0,
      isCompleted: false,
      itemName: 'registerEventCard',
    },

    onArrowClicked: () => {
      this.router.navigate(['/events']);
    },

    calculateSummary: (userData: IUserAPI) => {
      // Do not fetch everything if already completed, as this wont be updated
      if (this.completionSubject.value?.['registerEventCard']?.isCompleted) {
        return of(this.completionSubject.value?.['registerEventCard']);
      }

      return zip(
        this.eventApiService.getEvents({
          pageIndex: 0,
          pageSize: 5,
          DoesNotContainSearch: true,
          search: 'Welcome Call',
          previousEventsForCurrentUser: true,
        }),
        this.eventApiService.getEvents({
          pageIndex: 0,
          pageSize: 5,
          DoesNotContainSearch: true,
          search: 'Welcome Call',
          upcomingEventsForCurrentUser: true,
        }),
      ).pipe(
        map(([past, upcoming]) => {
          const userRegistered = [...past.items, ...upcoming.items].some(
            (event) => {
              return (
                event.title !== 'Welcome Call' &&
                event.eventForCurrentUser?.isRegistered
              );
            },
          );

          return {
            progress: {},
            totalItems: 1,
            completedItems: userRegistered ? 1 : 0,
            completedPercentage: userRegistered ? 100 : 0,
            isCompleted: userRegistered,
            itemName: 'registerEventCard',
          };
        }),
      );
    },
  };

  public onboardingCompletedAtUtc?: string;

  public cards: ProgressCard[] = [
    this.profileCompleteCard,
    this.introduceYourselfCard,
    this.welcomeCallCard,
    this.startCourseCard,
    this.registerEventCard,
  ];

  public completionObservable: Observable<ProgressSummaries>;

  private completionSubject = new BehaviorSubject<ProgressSummaries>(
    this.getCachedSummary(),
  );

  constructor(
    private usersService: UsersService,
    private discussionApiService: DiscussionApiService,
    private eventApiService: EventApiService,
    private coursesApiService: CoursesApiService,
    private router: Router,
    private notifyService: NotifyService,
  ) {
    this.completionObservable = this.completionSubject.asObservable();

    this.usersService.userApiData.subscribe((userData?: IUserAPI) => {
      if (userData?.id) {
        this.patchUserCompleteLevel(userData);
      }
    });

    this.completionObservable
      .pipe(
        // skip default value
        skip(1),
        switchMap((completeSummary) => {
          return this.usersService.userApiData.pipe(
            filter(Boolean),
            map((userData) => {
              return [completeSummary, userData] as [
                ProgressSummaries,
                IUserAPI,
              ];
            }),
          );
        }),
      )
      .subscribe(([completeSummary, userData]) => {
        this.onboardingCompletedAtUtc = userData?.onboardingCompletedAtUtc;
        const allCompleted = this.cards.every(
          (card) => completeSummary[card.default?.itemName ?? '']?.isCompleted,
        );

        if (Boolean(allCompleted) && !Boolean(this.onboardingCompletedAtUtc)) {
          this.usersService
            .saveUserData({
              completeOnboarding: allCompleted,
            })
            .subscribe(() => {
              this.notifyService.showInfo(
                `Congrats on completing your Onboarding ${Emojis['tada']}`,
              );
            });
        }

        sessionStorage.setItem(
          CompleteProgressService.CACHE_KEY,
          JSON.stringify({
            ...completeSummary,
            onboardingCompletedAtUtc: this.onboardingCompletedAtUtc,
          }),
        );
      });
  }

  public removeCachedSummary() {
    sessionStorage.removeItem(CompleteProgressService.CACHE_KEY);
  }

  public computeCompleteAt(onboardingCompletedAtUtcString: string) {
    const onboardingCompleted24hAgo =
      Math.abs(
        Interval.fromDateTimes(
          DateTime.fromISO(onboardingCompletedAtUtcString),
          DateTime.fromISO(new Date().toISOString()),
        ).length('hours'),
      ) >= 24;

    return {
      onboardingCompleted24hAgo,
      onboardingCompletedAtUtc: new Date(onboardingCompletedAtUtcString),
    };
  }

  private patchUserCompleteLevel(userData: IUserAPI) {
    return zip(
      ...this.cards.map((card) => {
        return card.calculateSummary!(userData);
      }),
    ).subscribe((responses) => {
      this.completionSubject.next(
        responses.reduce((acc, response) => {
          return {
            ...acc,
            [response.itemName]: response,
          };
        }, {}),
      );
    });
  }

  private getCachedSummary(): ProgressSummaries {
    const cache = sessionStorage.getItem(CompleteProgressService.CACHE_KEY);
    if (!cache) {
      return this.cards.reduce((acc, card) => {
        return {
          ...acc,
          [card.default!.itemName]: card.default,
        };
      }, {});
    }

    const parsedCache = JSON.parse(cache);

    this.onboardingCompletedAtUtc = parsedCache.onboardingCompletedAtUtc;

    return parsedCache;
  }
}
