import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-phone-modal',
  templateUrl: './confirm-phone-modal.component.html',
  styleUrls: ['./confirm-phone-modal.component.scss'],
})
export class ConfirmPhoneModalComponent implements OnInit {
  public phone!: number;
  public endingPhoneNumber?: string;
  public smsCode?: string;

  public confirmClicked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      phone: number;
      endingPhoneNumber?: string;
      showConfirmation?: boolean;
    },
    public dialogRef: MatDialogRef<ConfirmPhoneModalComponent>,
  ) {
    this.phone = data.phone;
    this.endingPhoneNumber = data.endingPhoneNumber;
    if (!data.showConfirmation) {
      this.confirmClicked = true;
    }
  }

  ngOnInit(): void {}

  public onCancelClicked() {
    this.dialogRef.close();
  }

  public onFirstConfirmClicked() {
    this.confirmClicked = true;
  }

  public onPhoneConfirmed() {
    this.dialogRef.close(this.phone);
  }

  public onCodeChanged(code: string) {
    this.smsCode = code;
  }
}
