<div class="text-gray pointer cta-text" (click)="onCtaClick()">
  @if (!usersRating) {
    <div [class.text-accent]="isVerifiedAttendee">
      Write a review
    </div>
  }
  @if (usersRating) {
    <div>
      Edit your a review
    </div>
  }
</div>
