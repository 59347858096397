@if (event) {
<div
  class="pointer event-list-card"
  #eventCardContainer
  (mouseenter)="onMouseHover()"
  (mouseleave)="onMouseLeave()"
>
  <div
    (touchstart)="onTouchstart($event)"
    (touchend)="onTouchend($event)"
    (click)="goToEvent()"
  >
    <div class="d-inline-block event-card-content">
      <img
        [src]="event.thumbnailUrl"
        style="aspect-ratio: 4/3; object-fit: cover; width: 100%"
      />
      <div class="favorite-container">
        @if (!isFavorite) {
        <mat-icon
          (touchend)="setFavoriteStatus($event, true)"
          (click)="setFavoriteStatus($event, true)"
          >favorite_outlined</mat-icon
        >
        } @if (isFavorite) {
        <mat-icon
          color="accent"
          (touchend)="setFavoriteStatus($event, false)"
          (click)="setFavoriteStatus($event, false)"
          >favorite</mat-icon
        >
        }
      </div>
      @if (recordingStatusMessage) {
      <div class="recording-status mat-caption">
        {{ recordingStatusMessage }}
      </div>
      }
      <div class="card-text">
        <div #eventTitle class="event-title m-0">{{ event.title }}</div>
        <div class="facilitator-name mt-2" style="min-height: 20px">
          {{ speakers }}
        </div>
        <div class="d-block d-sm-none mt-3 mt-sm-0">
          <div class="mb-1 d-flex align-items-center">
            <mat-icon class="me-2">calendar_today</mat-icon>
            <span>{{ startDate }}</span>
          </div>
          <div class="d-flex align-items-center" (click)="openMaps()">
            @if (event.locationType === LocationType.InPerson) {
            <mat-icon class="me-2">location_on</mat-icon>
            } @if (event.locationType === LocationType.Online) {
            <mat-icon class="me-2" svgIcon="zoom_app"></mat-icon>
            }
            <span>{{ location }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-sm-block">
      <div class="mt-2 w-100 d-flex date-location-container">
        <div class="d-flex align-items-center">
          <mat-icon class="me-2">calendar_today</mat-icon>
          <span>{{ startDate }}</span>
        </div>
        <div class="d-flex align-items-center" (click)="openMaps()">
          @if (event.locationType === LocationType.InPerson) {
          <mat-icon class="ms-3 me-2">location_on</mat-icon>
          } @if (event.locationType === LocationType.Online) {
          <mat-icon class="ms-3 me-2" svgIcon="zoom_app"></mat-icon>
          }
          <span>{{ location }}</span>
        </div>
      </div>
      <div class="mt-2 event-description ellipsis">
        <p [innerHTML]="description"></p>
      </div>
      <div
        class="mt-2 d-flex topic-pill-gap mat-caption"
        style="min-height: 48px"
      >
        @for (topic of topics; track topic) {
        <div class="my-1 topic-pill list">
          {{ topic.title }}
        </div>
        }
      </div>
    </div>
  </div>
  <div class="d-none d-sm-block position-relative mb-5">
    <div
      (touchend)="onTouchend($event)"
      (click)="goToEvent()"
      class="mt-3 ms-1 w-100 d-none d-sm-flex date-location-container"
      [style.visibility]="
        showCta || overlays.goingOverlay ? 'hidden' : 'visible'
      "
    >
      <span class="mat-caption">
        <span class="fw-600">{{ interested }}</span>
        {{ interested === 1 ? 'view' : 'views' }}
        <span class="fw-600" class="ms-2">{{ event.registeredPeople }}</span>
        going
      </span>
    </div>
    <div
      class="d-none d-sm-flex justify-content-between w-100 mt-2 mt-sm-0 px-1 px-sm-0"
      style="min-height: 40px; position: absolute; top: 20px"
      [style.visibility]="
        showCta || overlays.goingOverlay ? 'visible' : 'hidden'
      "
    >
      @if (isInThePast && event.videoUrl) {
      <button
        color="accent"
        mat-flat-button
        class="ff-base-button edged-corner w-100"
        (click)="goToEvent('recording')"
      >
        Watch recording
      </button>
      } @if (isInThePast && !event.videoUrl) {
      <button
        mat-button
        color="primary"
        class="ff-base-button edged-corner w-100"
        (click)="goToEvent()"
      >
        event details
      </button>
      } @if (!isInThePast) {
      <button
        mat-button
        color="primary"
        class="ff-base-button edged-corner"
        [class.w-100]="registrationEnded"
        [class.w-48]="!registrationEnded"
        (click)="goToEvent()"
      >
        event details
      </button>
      @if (!registrationEnded) {
      <button
        mat-button
        class="ff-base-button edged-corner w-48 going-button"
        [class.mat-accent]="isCurrentUserAttending || isCurrentUserOnWaitlist"
        (click)="onOverlayToggle('goingOverlay')"
        (touchend)="onOverlayToggle('goingOverlay', $event)"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
      >
        <span>
          @if (isCurrentUserAttending) { i'm going } @if
          (!isCurrentUserAttending) { going? }
          <!-- Remove ?? 3 after proper debug on WEB -->
          @if (isCurrentUserOnWaitlist) { waitlisted #{{
            event.eventForCurrentUser?.waitlistPosition ?? 3
          }}
          }
          <mat-icon>keyboard_arrow_down</mat-icon>
        </span>
      </button>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="overlays.goingOverlay"
        [cdkConnectedOverlayPositions]="goingOverlayPosition"
        [cdkConnectedOverlayScrollStrategy]="goingScrollStrategy"
        (detach)="overlayDetach('goingOverlay')"
      >
        <div class="going-share-container">
          <mat-radio-group
            aria-label="Select an option"
            labelPosition="before"
            [value]="selectedGoingButton"
            (change)="onGoingStatusUpdate($event)"
          >
            <mat-radio-button value="going">
              <div class="circle-icon-container">
                <mat-icon>check_circle</mat-icon>
              </div>
              I'm Going
            </mat-radio-button>
            <mat-radio-button value="notgoing">
              <div class="circle-icon-container">
                <mat-icon>cancel</mat-icon>
              </div>
              I'm Not Going
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-template>
      } }
    </div>
  </div>
</div>
<!-- mobile expanded view -->
@if (showCta) {
<div
  class="d-block d-sm-none"
  style="
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    background-color: rgb(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
  "
></div>
} @if (showCta) {
<div class="w-100 d-block d-sm-none overlay-mobile-card">
  <div class="position-relative">
    <img
      [src]="event.thumbnailUrl"
      class="pointer"
      style="aspect-ratio: 4/2; object-fit: cover; width: 100%"
      (click)="goToEvent()"
    />
    @if (recordingStatusMessage) {
    <div class="recording-status mat-caption">
      {{ recordingStatusMessage }}
    </div>
    }
    <div
      style="
        position: absolute;
        top: -12px;
        right: -12px;
        border: 4px solid var(--theme-primary-500);
        border-radius: 50%;
      "
    >
      <button
        mat-icon-button
        class="ff-base-button"
        (touchend)="onCloseClickOrTouch($event)"
        (click)="onCloseClickOrTouch($event)"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="mt-3">
      <div #eventTitle class="event-title m-0">{{ event.title }}</div>
      <div class="facilitator-name mt-2" style="min-height: 20px">
        {{ speakers }}
      </div>
    </div>
    <div class="mt-3">
      <div class="mb-1 d-flex align-items-center">
        <mat-icon class="me-2">calendar_today</mat-icon>
        <span>{{ startDate }}</span>
      </div>
      <div class="d-flex align-items-center" (click)="openMaps()">
        @if (event.locationType === LocationType.InPerson) {
        <mat-icon class="me-2">location_on</mat-icon>
        } @if (event.locationType === LocationType.Online) {
        <mat-icon class="me-2" svgIcon="zoom_app"></mat-icon>
        }
        <span>{{ location }}</span>
      </div>
    </div>
    <div>
      <div class="mt-2 event-description">
        <p [innerHTML]="description"></p>
      </div>
      <div
        class="mt-2 d-flex topic-pill-gap mat-caption"
        style="min-height: 36px"
      >
        @for (topic of topics; track topic) {
        <div class="my-1 topic-pill list">
          {{ topic.title }}
        </div>
        }
      </div>
      <div class="mt-3 d-flex align-items-center">
        <span class="mat-caption">
          <span class="fw-600">{{ interested }}</span>
          {{ interested === 1 ? 'view' : 'views' }}
          <span class="fw-600" class="ms-2">{{ event.registeredPeople }}</span>
          going
        </span>
      </div>
    </div>
    <div class="position-relative">
      <div
        (touchstart)="onTouchstart($event)"
        (touchend)="onTouchend($event)"
        class="my-3 w-100"
      >
        <div>
          <div class="d-flex align-items-center justify-content-start">
            <star-rating
              [initialRating]="
                (event.rating && event.feedbacksCount > 10) || isAdminShown
                  ? event.rating
                  : 5
              "
              [readonly]="true"
              [smallDisplay]="true"
            ></star-rating>
            <div class="ms-2">
              {{
                ((event.rating && event.feedbacksCount > 10) || isAdminShown
                  ? event.rating
                  : 5
                ) | number : '1.1'
              }}
              @if (event.feedbacksCount > 10 || isAdminShown) {
              <span class="text-gray">({{ event.feedbacksCount }})</span>
              }
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-between w-100 mt-2 mt-sm-0"
        style="min-height: 40px"
      >
        @if (isInThePast && event.videoUrl) {
        <button
          color="accent"
          mat-flat-button
          class="ff-base-button edged-corner w-100"
          (click)="goToEvent('recording')"
        >
          Watch recording
        </button>
        } @if (isInThePast && !event.videoUrl) {
        <button
          mat-button
          color="primary"
          class="ff-base-button edged-corner w-100"
          (click)="goToEvent()"
        >
          event details
        </button>
        } @if (!isInThePast) {
        <button
          mat-button
          color="primary"
          class="ff-base-button edged-corner"
          [class.w-100]="registrationEnded"
          [class.w-48]="!registrationEnded"
          (click)="goToEvent()"
        >
          event details
        </button>
        @if (!registrationEnded) {
        <button
          mat-button
          class="ff-base-button edged-corner w-48 going-button"
          [class.mat-accent]="isCurrentUserAttending || isCurrentUserOnWaitlist"
          (click)="onOverlayToggle('goingOverlayMobile')"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
        >
          <span>
            @if (isCurrentUserAttending) { i'm going } @if
            (!isCurrentUserAttending) { going? }
            <!-- Remove ?? 3 after proper debug on WEB -->
            @if (isCurrentUserOnWaitlist) { waitlisted #{{
              event.eventForCurrentUser?.waitlistPosition ?? 3
            }}
            }
            <mat-icon>keyboard_arrow_down</mat-icon>
          </span>
        </button>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="overlays.goingOverlayMobile"
          [cdkConnectedOverlayPositions]="goingOverlayPosition"
          [cdkConnectedOverlayScrollStrategy]="goingMobileScrollStrategy"
          (detach)="overlayDetach('goingOverlayMobile')"
        >
          <div class="going-share-container">
            <mat-radio-group
              aria-label="Select an option"
              labelPosition="before"
              [value]="selectedGoingButton"
              (change)="onGoingStatusUpdate($event)"
            >
              <mat-radio-button value="going">
                <div class="circle-icon-container">
                  <mat-icon>check_circle</mat-icon>
                </div>
                I'm Going
              </mat-radio-button>
              <mat-radio-button value="notgoing">
                <div class="circle-icon-container">
                  <mat-icon>cancel</mat-icon>
                </div>
                I'm Not Going
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-template>
        } }
      </div>
    </div>
  </div>
</div>
} } @if (!event) {
<div class="empty-event-card">
  <emojis name="cry" size="32"></emojis>
  <div class="no-events">No events yet.</div>
  <div class="view-all" (click)="navigateToEvents()">View all events</div>
</div>
}
