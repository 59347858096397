import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { environment } from '../../environments/environment.dev';
import { Event, LocationType } from '../events/event';

type ScriptElement = Element & { type: string; text: string };

@Injectable()
export class JsonLDService {
  static scriptType = 'application/ld+json';

  static websiteSchema = () => {
    return {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: environment.appBaseUrl,
      name: 'FutureFemales',
      sameAs: [],
    };
  };

  static EventSchema = (event: Event) => {
    const location =
      event.locationType === LocationType.Online
        ? {
            '@type': 'VirtualLocation',
            url: window.location.href,
          }
        : {
            '@type': 'Place',
            address: {
              '@type': 'PostalAddress',
              addressCountry: event.cityCountry?.country,
              addressRegion: event.cityCountry?.city,
              streetAddress: event.address,
            },
            name: event.address,
          };

    return {
      '@context': 'http://schema.org',
      '@type': 'Event',
      name: event.title,
      startDate: event.startsAtUtc,
      endDate: event.endsAtUtc,
      eventAttendanceMode:
        event.locationType === LocationType.Online
          ? 'https://schema.org/OnlineEventAttendanceMode'
          : 'https://schema.org/OfflineEventAttendanceMode',
      eventStatus: 'https://schema.org/EventScheduled',
      location,
      image: [event.thumbnailUrl],
      description: event.description,
      offers: {
        '@type': 'Offer',
        url: window.location.href,
        price: event.price,
        priceCurrency: event.currency ?? 'USD',
        availability: 'https://schema.org/InStock',
        validFrom: event.registrationStartsAtUtc,
        validThrough: event.registrationEndsAtUtc,
      },
    };
  };

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  public removeStructuredData(): void {
    const els: Element[] = ['structured-data', 'structured-data-org'].flatMap(
      (c) => {
        return Array.from(this._document.head.getElementsByClassName(c));
      },
    );

    els.forEach((el) => this._document.head.removeChild(el));
  }

  public insertSchema(
    schema: Record<string, unknown>,
    className = 'structured-data',
  ): void {
    const existingScripts =
      this._document.head.getElementsByClassName(className);

    const script: ScriptElement =
      (existingScripts?.[0] as ScriptElement) ||
      (this._document.createElement('script') as ScriptElement);

    script.setAttribute('class', className);
    script.type = JsonLDService.scriptType;
    script.text = JSON.stringify(schema, null, 2);

    if (!existingScripts?.[0]) {
      this._document.head.appendChild(script);
    }
  }
}
