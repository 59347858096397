import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ApplicationRef,
} from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  EventMessage,
  EventType,
  RedirectRequest,
  Logger,
  LogLevel,
} from '@azure/msal-browser';
import {
  from,
  fromEvent,
  interval,
  merge,
  skip,
  of,
  Subject,
  EMPTY,
  combineLatest,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  skipUntil,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { JsonLDService } from './core/json-ld.service';
import { protectedResources } from './auth-config';
import { UsersService } from './user/users.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { SidebarService } from './core/sidebar.service';
import { FFInsightsService } from './core/logging.service';
import { NotifyService } from './core/notify.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { APP_ICONS } from './app-icons';
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';
import { DataLayerService } from './core/data-layer.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { CompleteProgressService } from './user/complete-progress.service';
import { AffiliatesCodeService } from './affiliates/affiliates-code.service';
import { SessionApiService } from './session-api.service';
import { SessionService } from './session.service';

declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public title: string = 'future-females-web';

  public isIframe: boolean = false;
  public loginDisplay: boolean = false;
  public isAccessToken: boolean = false;
  public isSigninFlow: boolean = false;
  public secondSegmentTransparent: boolean = false;

  public renderRouterOutlet: boolean = false;
  public isUpdateAvailable = false;

  public isReloginInProgress = false;

  private readonly _destroying$ = new Subject<void>();

  @ViewChild('sidebar', { read: ViewContainerRef })
  viewContainerRef?: ViewContainerRef;

  private cleanOnClosingSession =
    !sessionStorage.getItem('onetimesession') &&
    !!localStorage.getItem('onetimesession');

  private allowedToBypassPaywallRoutes = [
    '/plans',
    '/checkout',
    '/checkout/success',
    '/checkout/failure',
    '/confirm-email',
    '/terms',
    '/privacy',
  ];

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    // DO NOT REMOVE,
    private ffInsightsService: FFInsightsService,
    // DO NOT REMOVE
    // this.ccService.statusChange$.subscribe will return {status: 'allow' | 'deny'}
    private ccService: NgcCookieConsentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private msalBroadcastService: MsalBroadcastService,
    private usersService: UsersService,
    private jsonLDService: JsonLDService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private sidebarService: SidebarService,
    private update: SwUpdate,
    private appRef: ApplicationRef,
    private snackBar: MatSnackBar,
    private notifyService: NotifyService,
    private dataLayerService: DataLayerService,
    private affiliateService: AffiliatesCodeService,
  ) {
    this.jsonLDService.insertSchema(JsonLDService.websiteSchema());

    APP_ICONS.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url),
      );
    });

    if (this.cleanOnClosingSession) {
      this.usersService.clearUserDetails();
    }

    if (environment.production && window) {
      window.console.log = function () {};
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroying$),
      )
      .subscribe((event) => {
        this.checkForLoginProcess((event as NavigationEnd).url);

        this.checkForNewUser(
          !this.activatedRoute.snapshot.queryParamMap.get('newUser'),
        );

        this.checkForAffiliateCode();

        if (this.isUpdateAvailable) {
          window.location.reload();
        }

        let route: ActivatedRoute = this.router.routerState.root;

        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }

        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
          this.titleService.setTitle(routeTitle);
        }

        this.secondSegmentTransparent =
          !!route.snapshot.data['secondSegmentTransparent'];

        if (route.snapshot.data['isSigninFlow']) {
          this.isSigninFlow = true;
        } else {
          this.isSigninFlow = false;
        }

        if (route.snapshot.data['customTagLogging']) {
          return;
        }

        const pageEvent = {
          page_title: routeTitle ?? '',
          page_location: window.location.href,
          page_path: (event as NavigationEnd).urlAfterRedirects,
        };

        this.dataLayerService.logPageView(pageEvent);
      });

    combineLatest([
      this.usersService.userApiData,
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
      ),
    ])
      .pipe(
        takeUntil(this._destroying$),
        map(([userApiData, event]) => {
          this.checkForSubscriptionCode();
          return { userApiData, event };
        }),
        filter(
          ({ userApiData, event }) =>
            !!userApiData &&
            (!userApiData.currentSubscription ||
              (!!userApiData.currentSubscription &&
                !!userApiData.currentSubscription.setOnHoldAtUtc)) &&
            !userApiData.upcomingSubscription,
        ),
        tap(({ userApiData, event }) => {
          if (!userApiData?.eligibleForFreeTrial) {
            this.allowedToBypassPaywallRoutes = [
              ...this.allowedToBypassPaywallRoutes,
              '/profile/membership-billing',
            ];
          }

          const currentRoute = (event as NavigationEnd).url.split('?')[0];
          if (
            this.allowedToBypassPaywallRoutes.indexOf(currentRoute) < 0 &&
            !this.usersService.isAnyTypeOfAdmin() &&
            !userApiData?.hadAnySubscription // TODO: remove this condition
          ) {
            this.router.navigate(['plans']);
          }
        }),
      )
      .subscribe();

    this.usersService.accessToken
      .pipe(takeUntil(this._destroying$))
      .subscribe((token) => {
        if (!!token) {
          this.isAccessToken = true;
        }
      });

    this.usersService.authenticationDone
      .pipe(takeUntil(this._destroying$))
      .subscribe((_) => {
        this.renderRouterOutlet = true;
      });

    this.authService.setLogger(
      new Logger({
        loggerCallback: this.authLoggingCallback,
        piiLoggingEnabled: false,
        logLevel: LogLevel.Verbose,
      }),
    );

    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.updateClient();
    this.checkUpdate();
    this.checkNetworkStatus();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.sidebarService.activeComponentObservable
      .pipe(takeUntil(this._destroying$))
      .subscribe(({ component, data }) => {
        if (!this.viewContainerRef) {
          if (component) {
            console.error('Something went wrong with sidebar rendering!');
          }

          return;
        }

        this.viewContainerRef.clear();

        if (!component) {
          return;
        }

        const componentRef =
          this.viewContainerRef.createComponent<typeof component>(component);

        Object.assign(componentRef.instance, data);
      });
  }

  private checkForLoginProcess(url: string): void {
    const path = url.split('?');

    if (path[0] !== '/login') {
      return;
    }

    this.isReloginInProgress = true;
  }

  private checkForAffiliateCode(): void {
    const affiliateParam =
      this.activatedRoute.snapshot.queryParamMap.get('utm_affiliate');

    if (!affiliateParam) {
      return;
    }

    this.affiliateService.handleNewAffiliateCode(affiliateParam);
  }

  private checkForSubscriptionCode(): void {
    const subscriptionParam =
      this.activatedRoute.snapshot.queryParamMap.get('subscriptionCode');

    if (!subscriptionParam) {
      return;
    }

    localStorage.setItem('subscriptionCode', subscriptionParam);
  }

  private setLoginDisplay(): void {
    this.loginDisplay =
      this.authService.instance.getAllAccounts().length > 0 ||
      (!!localStorage.getItem('at') && !!localStorage.getItem('internalusr'));
  }

  private checkAndSetActiveAccount(): void {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      activeAccount = accounts[0];
    }

    if (!activeAccount && !localStorage.getItem('internalusr')) {
      this.usersService.setUserLoggedIn(false);
      return;
    }

    if (
      !!localStorage.getItem('usrid') &&
      (localStorage.getItem('externalId') ===
        activeAccount?.idTokenClaims?.sub ||
        !!localStorage.getItem('internalusr'))
    ) {
      this.usersService.setUserDataFromStorage();
      this.isSigninFlow = false;
    } else {
      this.isSigninFlow = false;

      from(
        this.authService.instance.acquireTokenSilent({
          scopes: protectedResources.api.scopes,
        }),
      )
        .pipe(
          catchError((err) => {
            const domainHint = localStorage.getItem('hint');

            this.clearUserDetails();
            sessionStorage.clear();

            if (!domainHint) {
              this.ffInsightsService.logEvent(
                '[Err] User hint missing for external signin',
                {
                  err,
                },
              );

              this.authService.logoutRedirect();
              return EMPTY;
            }

            const pathName = window.location.pathname.slice(1);
            this.authService.logoutRedirect({
              postLogoutRedirectUri: `${environment.appBaseUrl}/login?domainHint=${domainHint}&path=${pathName}`,
            });
            return EMPTY;
          }),
        )
        .subscribe((value: AuthenticationResult | void) => {
          if (!value) {
            return;
          }
          localStorage.setItem('azAt', value.accessToken);
          localStorage.setItem(
            'externalId',
            activeAccount?.idTokenClaims?.sub || '',
          );
          this.usersService.setUserLoggedIn(true);
        });
    }
  }

  public clearUserDetails(): void {
    localStorage.removeItem('usrid');
    localStorage.removeItem('noSubscription');
    localStorage.removeItem('azAt');
    localStorage.removeItem('at');
    localStorage.removeItem('externalId');
    localStorage.removeItem('internalusr');
    localStorage.removeItem('onetimesession');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
    sessionStorage.removeItem(CompleteProgressService.CACHE_KEY);
  }

  public logout(): void {
    this.usersService.clearUserDetails();
    this.authService.logoutRedirect();
  }

  private updateClient(): void {
    if (!this.update.isEnabled) {
      return;
    }

    this.update.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      )
      .subscribe((_) => {
        this.isUpdateAvailable = true;
        this.handleUpdate();
      });
  }

  private handleUpdate(): void {
    const snackBarRef = this.snackBar.open(
      'A new version of Future Females is available',
      'Update',
      {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: `notification-service-snackbar-info`,
      },
    );

    this.isUpdateAvailable = true;

    snackBarRef
      .onAction()
      .subscribe((_) =>
        this.update.activateUpdate().then(() => location.reload()),
      );
  }

  private checkUpdate(): void {
    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(1 * 60 * 60 * 1000);

        timeInterval.subscribe(() => {
          this.update.checkForUpdate();
        });
      }
    });
  }

  private checkForNewUser(isExistingUser: boolean): void {
    if (isExistingUser) {
      return;
    }

    this.addEventForNewUserInAnalytics(localStorage.getItem('usrid')!);
  }

  private addEventForNewUserInAnalytics(userId: string): void {
    this.dataLayerService.logObject({
      event: 'acquisition.lead',
      acquisition: null,
    });
    this.dataLayerService.logObject({
      event: 'acquisition.lead',
      acquisition: {
        lead: {
          actionField: {
            userId,
          },
        },
      },
    });
  }

  private checkNetworkStatus(): void {
    merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(
        skip(1),
        map(() => navigator.onLine),
      )
      .subscribe((status) => {
        if (!status) {
          this.notifyService.throwWarning(
            'No internet connection. App may not work as expected.',
            6000,
          );
        } else {
          this.notifyService.showInfo('Back online!', 3000);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private authLoggingCallback = (
    level: LogLevel,
    message: string,
    containsPii: boolean,
  ) => {
    if (containsPii) {
      return;
    }
    switch (level) {
      case LogLevel.Error:
        environment.isLocalhost
          ? console.error(message)
          : this.ffInsightsService.logException(
              new Error(message),
              SeverityLevel.Critical,
            );
        return;
      case LogLevel.Info:
        environment.production ? '' : console.log(message);
        return;
      case LogLevel.Verbose:
        environment.production ? '' : console.debug(message);
        return;
      case LogLevel.Warning:
        environment.isLocalhost
          ? console.error(message)
          : this.ffInsightsService.logEvent('[Warn] MSAL warning', { message });
        return;
    }
  };
}
