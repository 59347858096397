import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_email_signup_login',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://login.futurefemales.co/futurefemalesb2c.onmicrosoft.com/B2C_1_email_signup_login',
    },
  },
  authorityDomain: 'login.futurefemales.co',
};

export const msalConfig: Configuration = {
  auth: {
    clientId: '5fd15f26-806e-456f-b06c-ef647463b5a1',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: environment.redirectUrl,
    postLogoutRedirectUri: environment.appBaseUrl,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel, message, containsPii) => {
        // this is set inside the app component in order to leverage DI for app insisghts service 
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
    iframeHashTimeout: 15000,
    loadFrameTimeout: 15000,
  },
};

export const protectedResources = {
  api: {
    endpoint: environment.apiBaseUrl,
    scopes: [
      'https://futurefemalesb2c.onmicrosoft.com/781785b5-7591-4c83-bbae-c773fbcf03a3/read-membership-content',
    ],
  },
};
export const loginRequest = {
  scopes: [],
};
