import { DateHelpers } from './date-helpers';

export interface IInterval {
  id: string;
  title: string;
  custom?: boolean;
  interval: {
    startsAfterUtc: string;
    startsBeforeUtc: string;
  };
}

export const INTERVALS: IInterval[] = [
  {
    id: 'any-date',
    title: 'Upcoming',
    interval: {
      startsAfterUtc: DateHelpers.getDateWithDifference(0, 0, 0, 0),
      startsBeforeUtc: '',
    },
  },
  {
    id: 'today',
    title: 'Today',
    interval: {
      startsAfterUtc: DateHelpers.getDateWithDifference(0, 0, 0, 0),
      startsBeforeUtc: DateHelpers.getDateWithDifference(1, 0, 0, 0),
    },
  },
  {
    id: 'tomorrow',
    title: 'Tomorrow',
    interval: {
      startsAfterUtc: DateHelpers.getDateWithDifference(1, 0, 0, 0),
      startsBeforeUtc: DateHelpers.getDateWithDifference(2, 0, 0, 0),
    },
  },
  {
    id: 'this-week',
    title: 'This week',
    interval: {
      startsAfterUtc: DateHelpers.getDateWithDifference(0, 0, 0, 0, 'week'),
      startsBeforeUtc: DateHelpers.getDateWithDifference(0, 1, 0, 0, 'week'),
    },
  },
  {
    id: 'next-week',
    title: 'Next week',
    interval: {
      startsAfterUtc: DateHelpers.getDateWithDifference(0, 1, 0, 0, 'week'),
      startsBeforeUtc: DateHelpers.getDateWithDifference(0, 2, 0, 0, 'week'),
    },
  },
  {
    id: 'this-month',
    title: 'This month',
    interval: {
      startsAfterUtc: DateHelpers.getDateWithDifference(0, 0, 0, 0, 'month'),
      startsBeforeUtc: DateHelpers.getDateWithDifference(0, 0, 1, 0, 'month'),
    },
  },
  {
    id: 'custom-date-range',
    title: 'Custom date range',
    custom: true,
    interval: {
      startsAfterUtc: '',
      startsBeforeUtc: '',
    },
  },
];
