import { ThemeVariables, ThemeRef, lyl, StyleRenderer } from '@alyle/ui';
import { STYLES as CROPPER_STYLES } from '@alyle/ui/image-cropper';

export class ImageHelper {
  public static CropOutputSize = {
    sponsor: {
      width: 400,
      height: 400,
    },
    profile: {
      width: 200,
      height: 200,
    },
    portrait: {
      width: 700,
      height: 1225,
    },
    landscape: {
      width: 2880,
      height: 1480,
    },
    speaker: {
      width: 500,
      height: 600,
    },
  };

  public static base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  public static imageRatioToStyles(
    sRenderer: StyleRenderer,
    height: number = 300,
  ) {
    const styles = (_theme: ThemeVariables, ref: ThemeRef) => {
      ref.renderStyleSheet(CROPPER_STYLES);
      const cropper = ref.selectorsOf(CROPPER_STYLES);

      return {
        root: lyl`{
          ${cropper.root} {
            width: 100%
            height: ${height}px
          }
        }`,
        sliderContainer: lyl`{
        text-align: center
        position: absolute
        top: ${height - 40}px
        z-index: 99
        margin: 0
        left: 0
        right: 0
      }`,
      };
    };

    return sRenderer.renderSheet(styles, 'root');
  }
}
