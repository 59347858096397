<input
  type="file"
  #file
  placeholder="Choose file"
  class="d-none"
  (change)="addTeaserRecording($event, file.files)"
/>
<div class="d-flex align-items-center">
  <button
    mat-icon-button
    class="add-video"
    color="accent"
    matTooltip="Add recording"
    (click)="file.click()"
  >
    <mat-icon>add</mat-icon>
  </button>
  <video-upload-progress
    [uploadAzureProgress]="uploadAzureProgress"
    [uploadProgress]="uploadProgress"
    [videoUrl]="videoUrl"
    [captionUrl]="captionUrl"
  ></video-upload-progress>
</div>
