import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { debounceTime, map, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'rich-editor',
  templateUrl: 'rich-editor.component.html',
  styleUrls: ['rich-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RichEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public id: string = 'editor'; // must be set if 2 or more editors used on same page
  @Input() public readMode: boolean = false;
  @Input() public text: string = '';
  @Input() public allowHeader: boolean = false;
  @Input() public allowList: boolean = false;
  @Input() public allowBold: boolean = false;

  @Input() public label: string = 'Text';

  @Output() public textChanges = new EventEmitter<string | null>();

  public textForm = new UntypedFormControl();
  public readModeText!: string | null;
  public showEditor = false;

  public selectedHeading = 'h5';

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: 'p',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [[], []],
  };

  private destroyNotifier = new Subject<void>();

  public executeCommandForEditor(
    heading: string,
    execFn: (action: string, value: string) => {},
  ) {
    this.selectedHeading = heading;
    execFn('formatBlock', heading);
  }

  public ngOnInit(): void {
    const initialFormatSettings = [
      'undo',
      'redo',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'indent',
      'outdent',
      'insertOrderedList',
      'heading',
      'fontName',
    ];

    let formatSettings = this.allowList
      ? initialFormatSettings
      : [...initialFormatSettings, 'insertUnorderedList'];

    formatSettings = this.allowBold
      ? formatSettings
      : [...formatSettings, 'bold'];

    this.editorConfig.toolbarHiddenButtons = [
      formatSettings,
      [
        'fontSize',
        'customClasses',
        'textColor',
        'backgroundColor',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ];

    this.textForm.setValue(this.text, { emitEvent: false });
    this.showEditor = true;

    this.textForm.valueChanges
      .pipe(debounceTime(500), takeUntil(this.destroyNotifier))
      .subscribe((text: string) => {
        this.textChanges.emit(text);
      });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const editorComponent = document.getElementById(this.id);
      if (!editorComponent) {
        return;
      }
      const editableArea = editorComponent.getElementsByClassName(
        'angular-editor-textarea',
      )[0];

      editableArea!.addEventListener('paste', function (e: any) {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, text);
      });
    }, 0);
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();
  }
}
