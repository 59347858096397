import { Component } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class AppFooterComponent {
  public navigateToLink(url: string): void {
    window.open(url, '_blank');
  }
}
