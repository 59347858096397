@if (course) {
<div class="course-list-card__component">
  <div
    class="pointer h-100"
    (mouseenter)="onMouseEnter($event)"
    (mouseleave)="onMouseLeave($event)"
  >
    <div
      class="position-relative card-container-height"
      (touchstart)="onTouchstart($event)"
      (touchend)="onTouchend($event)"
      (click)="onCardClicked()"
    >
      <img
        class="bottom-shadow w-100 card-container-height"
        [src]="
          (isSmallScreen | async)?.matches
            ? course.landscapeThumbnailUrl
            : course.portraitThumbnailUrl
        "
        alt="course thumbnail"
      />
      @if (isComingSoon) {
      <div class="coming-soon-label mat-caption">Coming soon</div>
      }
      <div class="favorite-icon">
        @if (!course.courseForCurrentUser?.isOnFavorite) {
        <mat-icon
          class="pointer"
          (touchend)="onToggleFavoriteStatus($event, true)"
          (click)="onToggleFavoriteStatus($event, true)"
        >
          favorite_outlined
        </mat-icon>
        } @if (course.courseForCurrentUser?.isOnFavorite) {
        <mat-icon
          class="pointer"
          (touchend)="onToggleFavoriteStatus($event, false)"
          (click)="onToggleFavoriteStatus($event, false)"
          color="accent"
          >favorite</mat-icon
        >
        }
      </div>
      <div class="w-100 information pb-3">
        @if (course.speaker) {
        <div class="my-0 course-name text">
          {{ course.speaker.name }}
        </div>
        }
        <div class="mat-body-2 mt-2 course-title text">{{ course.title }}</div>
        <div
          class="mt-3 d-flex topic-pill-gap justify-content-center mat-caption topics"
        >
          @for (topic of twoTopics; track topic) {
          <div class="my-1 topic-pill list">
            {{ topic.title }}
          </div>
          }
        </div>
        <div
          [style.visibility]="!isComingSoon ? 'visible' : 'hidden'"
          class="mt-3 d-flex justify-content-evenly align-items-center activity"
        >
          <div>
            <div class="d-flex align-items-center justify-content-center">
              <star-rating
                [initialRating]="
                  (course.rating && course.feedbacksCount > 10) || isAdminShown
                    ? course.rating
                    : 5
                "
                [readonly]="true"
                [smallDisplay]="true"
              ></star-rating>
              <span class="ms-2">{{
                ((course.rating && course.feedbacksCount > 10) || isAdminShown
                  ? course.rating
                  : 5
                ) | number : '1.1'
              }}</span>
              @if (course.feedbacksCount > 10 || isAdminShown) {
              <span class="text-gray">({{ course.feedbacksCount }})</span>
              }
            </div>
          </div>
          <div class="d-flex">
            <div class="views-text">
              {{ course.viewedByPeople }}
              {{ course.viewedByPeople === 1 ? 'view' : 'views' }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt-3 mx-2 course-progress-bar"
        [style.visibility]="showCTA ? 'hidden' : 'visible'"
      >
        @if (course.courseForCurrentUser?.completedPercentage) {
        <mat-progress-bar
          color="accent"
          [value]="course.courseForCurrentUser?.completedPercentage"
        >
        </mat-progress-bar>
        }
      </div>
    </div>
    <div
      class="cta-container d-none d-sm-flex mt-2 mt-md-0"
      [style.visibility]="showCTA ? 'visible' : 'hidden'"
    >
      <button
        mat-button
        color="accent"
        class="details-button"
        (click)="navigateToCourse()"
      >
        {{
          course.courseForCurrentUser?.completedPercentage
            ? 'Lessons'
            : 'Details'
        }}
      </button>
      @if (!course.courseForCurrentUser?.startedAtUtc && course.teaserUrl) {
      <button
        mat-button
        color="accent"
        class="continue-button"
        (click)="openTeaserVideo($event)"
      >
        Preview
      </button>
      } @if (course.courseForCurrentUser?.completedPercentage) {
      <button
        color="accent"
        class="continue-button"
        mat-button
        (click)="continueCourse()"
      >
        CONTINUE
      </button>
      }
    </div>
  </div>
  <!-- mobile card expand start -->
  @if (showCTA) {
  <div
    class="d-block d-sm-none"
    style="
      height: 100vh;
      width: 100vw;
      z-index: 9999;
      background-color: rgb(0, 0, 0, 0.4);
      position: fixed;
      top: 0;
    "
  ></div>
  } @if (showCTA) {
  <div class="w-100 d-block d-sm-none overlay-mobile-card">
    <div class="position-relative overlay-mobile-card-container">
      <img
        [src]="
          (isSmallScreen | async)?.matches
            ? course.landscapeThumbnailUrl
            : course.portraitThumbnailUrl
        "
        class="pointer"
        style="aspect-ratio: 4/2; object-fit: cover; width: 100%"
        (click)="navigateToCourse()"
      />
      <div
        style="
          position: absolute;
          top: -12px;
          right: -12px;
          border: 4px solid var(--theme-primary-500);
          border-radius: 50%;
        "
      >
        <button
          mat-icon-button
          class="ff-base-button"
          (touchstart)="onCloseClickOrTouch($event)"
          (touchend)="onCloseClickOrTouch($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="w-100 mt-3">
        @if (course.speaker) {
        <div class="mat-headline-3 my-0 course-name">
          {{ course.speaker.name }}
        </div>
        }
        <div class="mat-body-2 mt-2 course-title">{{ course.title }}</div>
        @if (course && course.topics && course.topics.length) {
        <div
          class="mt-3 d-flex topic-pill-gap justify-content-start mat-caption"
        >
          @for ( topic of course.topics | slice : 0 : 2; track trackById($index,
          topic)) {
          <div class="my-1 topic-pill list">
            {{ topic.title }}
          </div>
          }
        </div>
        }
        <div class="mt-3">
          <span
            ><span class="mat-body-1">{{ visibleLessonsCount }}</span>
            @if (visibleLessonsCount === 1) {
            <span> lesson</span>
            } @if (visibleLessonsCount !== 1) {
            <span> lessons</span>
            }
            <span class="mat-body-1 mat-heading-1"> · </span
            ><span class="mat-body-1">{{ averageLessonLength }}</span> min avg /
            lesson</span
          >
        </div>
        <div class="mt-3 d-flex align-items-center">
          <div class="me-5 me-sm-0">
            <div class="d-flex align-items-center justify-content-center">
              <star-rating
                [initialRating]="
                  (course.rating && course.feedbacksCount > 10) || isAdminShown
                    ? course.rating
                    : 5
                "
                [readonly]="true"
                [smallDisplay]="true"
              ></star-rating>
              <span class="ms-2">{{
                ((course.rating && course.feedbacksCount > 10) || isAdminShown
                  ? course.rating
                  : 5
                ) | number : '1.1'
              }}</span>
              @if (course.feedbacksCount > 10 || isAdminShown) {
              <span class="text-gray">({{ course.feedbacksCount }})</span>
              }
            </div>
          </div>
          <div class="flex-grow-1">
            <div style="width: fit-content">
              <div class="views-text">
                {{ course.viewedByPeople }}
                {{ course.viewedByPeople === 1 ? 'view' : 'views' }}
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 cta-container">
          <button
            mat-button
            color="accent"
            class="details-button"
            (click)="navigateToCourse()"
          >
            {{
              course.courseForCurrentUser?.completedPercentage
                ? 'Lessons'
                : 'Details'
            }}
          </button>
          @if ( !course.courseForCurrentUser?.startedAtUtc && course.teaserUrl )
          {
          <button
            mat-button
            color="accent"
            class="continue-button"
            (click)="openTeaserVideo($event)"
          >
            Preview
          </button>
          } @if (course.courseForCurrentUser?.completedPercentage) {
          <button
            color="accent"
            class="continue-button"
            mat-button
            (click)="continueCourse()"
          >
            CONTINUE
          </button>
          }
        </div>
      </div>
    </div>
  </div>
  }
</div>
} @if (!course) {
<div class="empty-course-card">
  <emojis name="cry" size="32"></emojis>
  <div class="no-courses">No courses yet.</div>
  <div class="view-all" (click)="navigateToCourses()">View all courses</div>
</div>
}
