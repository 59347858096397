import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { mergeMap, map, of, combineLatest, Subject, takeUntil } from 'rxjs';

import { IStringResponse } from '../../../shared/api/string-response';
import {
  DEFAULT_DISCUSSION_FILTER,
  DEFAULT_DISCUSSION_FILTER_REPORT,
  IDiscussionFilter,
  IDiscussionItem,
  IDiscussionReport,
  IDiscussionReportGroup,
} from '../discussion';
import { DiscussionApiService } from '../discussion-api.service';
import { UsersService } from '../../../user/users.service';
import { DiscussionSignalrService } from '../discussion-signalr.service';
import { IPage } from 'src/app/shared/pagination';
import { UserAdmin } from 'src/app/user/admin';

const AddedDiscussionItem = 'AddedDiscussionItem';

@Component({
  selector: 'ff-discussion-root',
  templateUrl: 'root.component.html',
  styleUrls: ['root.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DiscussionRootComponent implements OnInit, OnDestroy {
  @Input() public entityId!: string;

  public discussionItems: IDiscussionItem[] = [];

  public reportedIds: string[] = [];

  public questionForm = new UntypedFormControl();
  public areMoreQuestions: boolean = false;

  public totalItemsShown: number = 0;

  public isQuestionPostInProgress: boolean = false;
  public isShiftHeld: boolean = false;

  public profilePicUrl?: string;

  public reportGroups: IDiscussionReportGroup[] = [];
  public isAdminShown = false;

  private discussionFilter: IDiscussionFilter = DEFAULT_DISCUSSION_FILTER;
  private destroyNotifier = new Subject<void>();

  @Output() public questionAdded = new EventEmitter<void>();
  @Output() public answerAdded = new EventEmitter<void>();

  constructor(
    private usersService: UsersService,
    private discussionApiService: DiscussionApiService,
    private signalRService: DiscussionSignalrService,
  ) {}

  public ngOnInit(): void {
    this.signalRService.startConnection();
    this.addAddedDiscussionItemListener();

    combineLatest([
      this.usersService.isAdminInterfaceShown,
      this.usersService.userAdmin,
    ])
      .pipe(takeUntil(this.destroyNotifier))
      .subscribe(([isAdminInterfaceShown, admin]: [boolean, UserAdmin]) => {
        this.isAdminShown = isAdminInterfaceShown && admin.isSuperAdmin;

        this.discussionFilter = {
          ...this.discussionFilter,
          pageIndex: 0,
          includeHidden: this.isAdminShown,
        };

        this.initDiscussionComponent();
      });

    this.usersService.userApiData.subscribe((userData) => {
      this.profilePicUrl = userData?.profilePictureUrl;
    });
  }

  private initDiscussionComponent(): void {
    this.discussionApiService
      .getDiscussionItems(this.entityId, this.discussionFilter)
      .pipe(
        mergeMap((discussionPage: IPage<IDiscussionItem>) => {
          const req = this.isAdminShown
            ? this.discussionApiService.getDiscussionItemReportGroups({
                pageIndex: 0,
                pageSize: 500,
              })
            : of({ items: [], totalCount: 0 } as IPage<IDiscussionReportGroup>);
          return req.pipe(
            map((reportGroups) => ({ discussionPage, reportGroups })),
          );
        }),
        mergeMap(({ discussionPage, reportGroups }) =>
          !!localStorage.getItem('usrid')
            ? this.discussionApiService
                .getDiscussionItemReports({
                  ...DEFAULT_DISCUSSION_FILTER_REPORT,
                  discussionId: this.entityId,
                  reportedByUserId: localStorage.getItem('usrid')!.toString(),
                })
                .pipe(
                  map((reportPage) => ({
                    discussionPage,
                    reportPage,
                    reportGroups,
                  })),
                )
            : of({
                discussionPage,
                reportPage: { items: [], totalCount: 0 },
                reportGroups,
              }),
        ),
      )
      .subscribe(({ discussionPage, reportPage, reportGroups }) => {
        this.discussionItems = discussionPage.items;
        this.totalItemsShown =
          discussionPage.totalCount - discussionPage.items.length;
        this.areMoreQuestions =
          this.discussionItems.length < discussionPage.totalCount;
        this.reportGroups = reportGroups.items;

        this.reportedIds = reportPage.items.map(
          (rep: IDiscussionReport) => rep.discussionItemId,
        );
      });
  }

  public onAnswerAdded(): void {
    this.answerAdded.emit();
  }

  public loadMoreQuestions(): void {
    this.discussionFilter = {
      ...this.discussionFilter,
      pageIndex: this.discussionFilter.pageIndex + 1,
    };

    this.discussionApiService
      .getDiscussionItems(this.entityId, this.discussionFilter)
      .subscribe((page: IPage<IDiscussionItem>) => {
        if (page.items.length < this.discussionFilter.pageSize) {
          this.discussionFilter = {
            ...this.discussionFilter,
            pageIndex: this.discussionFilter.pageIndex - 1,
          };
        }

        this.totalItemsShown = this.totalItemsShown - page.items.length;
        this.discussionItems = [...this.discussionItems, ...page.items];
        this.areMoreQuestions = this.discussionItems.length < page.totalCount;
      });
  }

  public submitQuestion(): void {
    if (!this.questionForm.value || this.questionForm.value.trim() === '') {
      return;
    }
    if (this.isQuestionPostInProgress) {
      return;
    }

    this.isQuestionPostInProgress = true;
    this.questionForm.disable({ emitEvent: false });

    this.questionForm.markAsPristine();

    const text = this.questionForm.value;
    const postDiscussionItemRequest = {
      text,
    };

    this.discussionApiService
      .addDiscussionItemAsync(
        this.entityId,
        postDiscussionItemRequest,
        this.signalRService.connectionId,
      )
      .subscribe((discussionId: IStringResponse) => {
        const newDiscussionItem = {
          id: discussionId.field,
          userId: this.usersService.getUserId(),
          createdAtUtc: new Date().toISOString(),
          updatedAtUtc: new Date().toISOString(),
          text,
          user: this.usersService.getUserData()!,
          votes: [],
          isHidden: false,
          discussionItemChildren: { totalCount: 0, items: [] },
          discussionId: this.entityId,
        };

        this.discussionItems = [newDiscussionItem, ...this.discussionItems];

        this.questionForm.setValue(null);
        this.isQuestionPostInProgress = false;
        this.questionForm.enable({ emitEvent: false });
        this.isShiftHeld = false;

        this.questionAdded.emit();
      });
  }

  /**
   * Stops propagation of the enter ( block a new line if shift was held down )
   */
  public onKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Enter' && this.isShiftHeld) {
      e.preventDefault();
    }
  }

  /**
   * Trigger the submit if the enter and shift/ctrl was pressed
   */
  public onKeyUp(e: KeyboardEvent): void {
    if (e.key === 'Enter' && this.isShiftHeld) {
      this.submitQuestion();
    }
  }

  private addAddedDiscussionItemListener(): void {
    this.signalRService.hubConnection.on(
      AddedDiscussionItem,
      (newDiscussionItem: IDiscussionItem) => {
        if (this.entityId !== newDiscussionItem.discussionId) {
          return;
        }

        if (!newDiscussionItem.parentId) {
          this.discussionItems = [newDiscussionItem, ...this.discussionItems];
          return;
        }

        let question = this.discussionItems.find(
          (di: IDiscussionItem) => di.id === newDiscussionItem.parentId,
        );

        if (!question) {
          throw Error('No question found');
        }

        const newItems = [
          newDiscussionItem,
          ...question.discussionItemChildren.items,
        ];

        question = {
          ...question,
          discussionItemChildren: {
            items: newItems,
            totalCount: question.discussionItemChildren.totalCount + 1,
          },
        };

        const idx = this.discussionItems.findIndex(
          (di) => di.id === newDiscussionItem.parentId,
        );
        this.discussionItems[idx] = question;
      },
    );
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();
  }
}
