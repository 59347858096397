export enum Feature {
  SignUp,
  SignIn,
  Events,
  Courses,
  Groups,
  Lessons,
  Forums,
  Subscription = 7,
  Payment = 8,
  Membership = 9,
  NewMembersDiscussion = 10,
  Plans = 11,
  FoundingMembersDiscussion = 12,
  GeneralDiscussion = 13,
  OnBoarding = 14,
  WeeklyUpdate = 15
}

export const CUSTOM_FEATURE_PATHS: any = {
  [Feature.Subscription]: { url: 'profile/membership-billing', hasId: false },
  [Feature.Payment]: { url: 'profile/membership-billing', hasId: false },
  [Feature.Membership]: { url: 'profile/membership-billing', hasId: false },
  [Feature.OnBoarding]: { url: 'dashboard', hasId: false },
  [Feature.FoundingMembersDiscussion]: { url: 'discussions', hasId: true },
  [Feature.NewMembersDiscussion]: { url: 'discussions', hasId: true },
  [Feature.Plans]: { url: 'discussions', hasId: true },
  [Feature.GeneralDiscussion]: { url: 'discussions', hasId: true },
};
