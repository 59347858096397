import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: 'view-preview.component.html',
  styleUrls: ['view-preview.component.scss'],
})
export class ViewCoursePreviewComponent {
  public playerLoaded = false;

  constructor(
    public dialogRef: MatDialogRef<ViewCoursePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public onPlayerLoad(): void {
    this.playerLoaded = true;
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
