import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { IInterval, INTERVALS } from '../../date/intervals';

@Component({
  selector: 'calendar-filter',
  templateUrl: 'calendar-filter.component.html',
  styleUrls: ['calendar-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarFilterComponent implements OnChanges {
  @Input() selectedInterval!: IInterval;
  @Input() intervals: IInterval[] = INTERVALS;
  
  public startsAfterUtc: string | undefined;
  public startsBeforeUtc: string | undefined;

  public isAnyDateShown = false;
  public selectedRangeValue: DateRange<Date> | undefined;

  @Output() interval = new EventEmitter<{ selectedInterval: IInterval }>();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['selectedInterval'] &&
      !changes['selectedInterval'].firstChange
    ) {
      const selectedInterval = changes['selectedInterval'].currentValue;
      this.isAnyDateShown = selectedInterval.custom;

      if (selectedInterval.id !== 'custom-date-range') {
        this.selectedRangeValue = undefined;
        this.startsAfterUtc = undefined;
        this.startsBeforeUtc = undefined;
      } else {
        this.startsAfterUtc = selectedInterval.interval.startsAfterUtc;
        this.startsBeforeUtc = selectedInterval.interval.startsBeforeUtc;
      }
    }
  }

  public selectedChange(dateRange: DateRange<Date>): void {
    this.selectedRangeValue = dateRange;
  }

  public onApplyCustomDates(triggerReference: any): void {
    if (!this.selectedRangeValue) {
      return;
    }

    let startsAfterDate = this.selectedRangeValue.start;
    let startsBeforeDate = this.selectedRangeValue.end;

    if (!startsAfterDate) {
      return;
    }

    const startsAfterUtc = startsAfterDate.toISOString();
    let startsBeforeUtc = '';

    if (!startsBeforeDate) {
      startsBeforeUtc = DateTime.fromJSDate(startsAfterDate!)
        .plus({ days: 1 })
        .toJSDate()
        .toISOString();
    } else {
      startsBeforeUtc = startsBeforeDate.toISOString();
    }

    const selectedInterval = INTERVALS.find(
      (i) => i.id === 'custom-date-range',
    )!;
    selectedInterval.interval = {
      startsBeforeUtc,
      startsAfterUtc,
    };
    this.interval.emit({ selectedInterval });
    triggerReference.closeMenu();
  }

  public selectInterval(interval: IInterval): void {
    this.selectedInterval = interval;
    this.isAnyDateShown = false;

    this.interval.emit({ selectedInterval: interval });
  }

  public toggleAnyDateCalendar(event: any, interval: IInterval): void {
    event.stopPropagation();
    this.selectedInterval = interval;
    this.isAnyDateShown = !this.isAnyDateShown;
  }
}
