import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { UsersService } from '../../user/users.service';
import { API_BASE_URL } from '../../shared/app-constants';
import { IEvent } from '../../events/event';
import { ICourseReadModel } from '../../courses/courses';
import { DateHelpers } from '../../shared/date/date-helpers';
import { DateTime } from 'luxon';

export enum ShareResourceType {
  'events' = 'user-events',
  'courses' = 'user-courses',
}

@Injectable()
export class ShareService {
  public constructor(
    private usersService: UsersService,
    @Inject(API_BASE_URL) public apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getDescription(
    resourceType: keyof typeof ShareResourceType,
    _resource: Partial<ICourseReadModel | IEvent>,
  ): string {
    if (!resourceType || !_resource) {
      return '';
    }

    if (resourceType === 'courses') {
      const resource = _resource as Partial<ICourseReadModel>;
      const speakerString = resource.speaker?.name
        ? ` presented by ${resource.speaker.name} `
        : '';

      let courseDescription = '';
      if ((resource.registeredPeople ?? 0) >= 10) {
        courseDescription = `Join me and ${resource.registeredPeople} others who are taking this Future Females Course: ${resource.title}${speakerString}`;
      } else {
        courseDescription = `Join me in taking this Future Females Course: ${
          resource.title
        }${speakerString ? `,${speakerString}` : ''}`;
      }
      return courseDescription;
    }

    const resource = _resource as Partial<IEvent>;
    const startsAt = new Date(
      DateHelpers.getParsedIsoFromServer(resource.startsAtUtc!),
    );
    const timezone = DateTime.local().toFormat('ZZZZ');
    const hhmm = `${startsAt.getHours().toString().padStart(2, '0')}:${startsAt
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;

    const timestamp = `${startsAt.toLocaleDateString()} ${hhmm} (${timezone})`;
    const speakerString = resource.facilitators?.[0]?.name
      ? `, presented by ${resource.facilitators[0].name} `
      : '';

    let description = '';
    if ((resource.registeredPeople ?? 0) >= 10) {
      description = `Join me and ${resource.registeredPeople} others on ${timestamp} at the Future Females Event: ${resource.title}${speakerString}`;
    } else {
      description = `Join me on ${timestamp} at the Future Females Event: ${resource.title}${speakerString}`;
    }
    return description;
  }

  public getShareUrl(
    resourceType: keyof typeof ShareResourceType,
    eventId: string,
    socialMediaType: number,
  ): Observable<string> {
    if (!this.usersService.getUserData()?.id) {
      return of(window.location.href);
    }

    return this.httpClient
      .get<{ field: string }>(
        `${this.apiBaseUrl}/${ShareResourceType[resourceType]}/${eventId}/share-url`,
        {
          params: {
            EventId: eventId,
            UserId: this.usersService.getUserData()!.id,
            SocialMediaType: socialMediaType,
          },
        },
      )
      .pipe(map((response) => response.field));
  }
}
