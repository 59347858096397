import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class NotificationService {
  private _notificationCount = new Subject<number>();
  public notificationCount: Observable<number> =
    this._notificationCount.asObservable();

  public updateNotificationCount(count: number): void {
    this._notificationCount.next(count);
  }
}
