<button mat-icon-button [url]="url" shareButton="facebook" #fbBtn="shareButton">
  <!-- Neither boostrap / material has the same icon -->
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 17.938C13.0173 17.6813 14.8611 16.6661 16.1568 15.0988C17.4525 13.5314 18.1027 11.5295 17.9754 9.49997C17.848 7.47041 16.9527 5.56549 15.4713 4.17238C13.9898 2.77927 12.0336 2.00252 10 2C7.96396 1.99848 6.00395 2.77334 4.51934 4.16668C3.03473 5.56002 2.13724 7.46699 2.00974 9.49904C1.88225 11.5311 2.53434 13.5353 3.83314 15.1033C5.13195 16.6712 6.97974 17.685 9 17.938V12H7V10H9V8.346C9 7.009 9.14 6.524 9.4 6.035C9.65611 5.55119 10.052 5.15569 10.536 4.9C10.918 4.695 11.393 4.572 12.223 4.519C12.552 4.498 12.978 4.524 13.501 4.599V6.499H13C12.083 6.499 11.704 6.542 11.478 6.663C11.3431 6.73236 11.2334 6.84215 11.164 6.977C11.044 7.203 11 7.427 11 8.345V10H13.5L13 12H11V17.938ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20Z"
      fill="#09121F"
    />
  </svg>
</button>
