import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { IPage } from '../../shared/pagination';
import {
  IEvent,
  IEventLabelsReadModel,
  IEventPost,
  IEventUpdate,
} from '../../events/event';
import { ApiServiceHelper } from '../../shared/helpers';
import { API_BASE_URL } from '../../shared/app-constants';
import { IEventFilter } from '../../events/filters/filters';
import { IStringResponse } from '../../shared/api/string-response';
import { ICityCountry } from '../../shared/models/location';
import { IResourcePost } from '../../shared/resources/resource';
import { FfLanguage } from '../../shared/models/language';
import { IEventFilterResultCount } from 'src/app/shared/filters/field-filters/location-filter/location-filter';
import { DEFAULT_FILTER } from 'src/app/shared/filters/constants';
import {
  IFeedback,
  IFeedbackFilter,
  IFeedbackSummary,
} from 'src/app/shared/feedback/feedback';

@Injectable()
export class EventApiService {
  constructor(
    private httpClient: HttpClient,
    @Inject(API_BASE_URL) private apiBaseUrl: string,
  ) {}

  public getEvent(id: string): Observable<IEvent> {
    const url = `${this.apiBaseUrl}/Events/${id}`;
    return this.httpClient.get<IEvent>(url);
  }

  public getEvents(filter: Partial<IEventFilter>): Observable<IPage<IEvent>> {
    filter.includeUserEvent = true;
    const url = `${this.apiBaseUrl}/Events`;

    return this.httpClient.get<IPage<IEvent>>(
      this.getQueryUrlForEvents(url, filter),
    );
  }

  public createEvent(event: IEventPost): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/Events`;
    return this.httpClient.post<IStringResponse>(url, { ...event });
  }

  public updateEvent(
    id: string,
    updates: IEventUpdate,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/Events/${id}`;
    return this.httpClient.patch<IStringResponse>(url, { ...updates });
  }

  public updateEventVideo(
    eventId: string,
    file: File,
    language: FfLanguage,
  ): Observable<IStringResponse> {
    const headers = new HttpHeaders()
      .set('responseType', 'text/plain')
      .set('accept', 'text/plain');

    const uploadData = new FormData();
    uploadData.append(`video`, file, file.name);

    return this.httpClient.post<IStringResponse>(
      `${this.apiBaseUrl}/events/${eventId}/recording?language=${language}`,
      uploadData,
      { headers },
    );
  }

  public addEventResource(
    id: string,
    resource: IResourcePost,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/Events/${id}/resources`;
    return this.httpClient.post<IStringResponse>(url, { ...resource });
  }

  public deleteEventResource(eventId: string, id: string): Observable<void> {
    const url = `${this.apiBaseUrl}/Events/${eventId}/resources/${id}`;
    return this.httpClient.delete<void>(url);
  }

  public getEventsCities(filter: any): Observable<IPage<ICityCountry>> {
    const url = `${this.apiBaseUrl}/events/city-countries`;
    return this.httpClient.get<IPage<ICityCountry>>(
      this.getQueryUrlForEvents(url, filter),
    );
  }

  public getEventsFilterResultCount(
    filter: any,
  ): Observable<IEventFilterResultCount> {
    const url = `${this.apiBaseUrl}/events/filter-result-count`;

    return this.httpClient.get<IEventFilterResultCount>(
      this.getQueryUrlForEvents(url, filter),
    );
  }

  public getTopicsAndCategories(
    filter: IEventFilter,
  ): Observable<IEventLabelsReadModel> {
    const url = `${this.apiBaseUrl}/events/labels`;
    return this.httpClient.get<IEventLabelsReadModel>(
      this.getQueryUrlForEvents(url, filter),
    );
  }

  public getFeedbacks(
    id: string,
    filter: IFeedbackFilter,
  ): Observable<IPage<IFeedback>> {
    const url = `${this.apiBaseUrl}/events/${id}/feedbacks`;
    return this.httpClient.get<IPage<IFeedback>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public getEventFeedbacksSummary(
    id: string,
    onlyVerified?: boolean,
  ): Observable<IFeedbackSummary[]> {
    const url = `${this.apiBaseUrl}/events/${id}/feedbacks-summary`;
    return this.httpClient.get<IFeedbackSummary[]>(url, {
      params: ApiServiceHelper.getQueryParams({ onlyVerified }),
    });
  }

  private getQueryUrlForEvents(
    url: string,
    filter: Partial<IEventFilter>,
  ): string {
    let cityCountryParam = '';

    let { selectedInterval, selectedLocation, ...apiFilter } = filter;
    apiFilter.startsAfterUtc =
      selectedInterval?.interval.startsAfterUtc || filter.startsAfterUtc;
    apiFilter.startsBeforeUtc =
      selectedInterval?.interval.startsBeforeUtc || filter.startsBeforeUtc;

    apiFilter = !!selectedLocation?.value.city
      ? {
          ...apiFilter,
          cityCountry: {
            city: selectedLocation.value.city,
            country: selectedLocation.value.country,
          },
        }
      : {
          ...apiFilter,
          ...selectedLocation?.value,
        };

    let params = '';
    if (!!apiFilter.cityCountry) {
      cityCountryParam = `CityCountry.City=${apiFilter.cityCountry.city}&CityCountry.Country=${apiFilter.cityCountry.country}`;
      const { cityCountry, ...filterWithoutCityCountry } = apiFilter;
      const queryFilter = ApiServiceHelper.getQueryParams(
        filterWithoutCityCountry,
      ).toString();

      params = `${queryFilter}&${cityCountryParam}`;
    } else {
      params = ApiServiceHelper.getQueryParams(apiFilter).toString();
    }
    return `${url}?${params}`;
  }
}
