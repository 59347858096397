import { FfLanguage } from '../shared/models/language';
import { ISortByOption } from '../shared/models/sort';
import { PublishStatus } from './courses';

export const COURSE_LANGUAGE = FfLanguage.English_en_US;

export const ALL_COURSE_STATUSES = (): PublishStatus[] => {
  const publishStatusesKeys = Object.values(PublishStatus);
  return publishStatusesKeys
    .slice(publishStatusesKeys.length / 2, publishStatusesKeys.length)
    .map((v) => +v);
};

export const COURSES_SORT_OPTIONS: ISortByOption[] = [
  {
    viewValue: 'Newest',
    value: 'CreatedAtUtc',
    sortingDesc: true,
  },
  {
    viewValue: 'Most Popular',
    value: 'mostPopular',
    sortingDesc: true,
  },
  {
    viewValue: 'Highest Rated',
    value: 'rating',
    sortingDesc: true,
  },
  {
    viewValue: 'Name (A-Z)',
    value: 'title',
    sortingDesc: false,
  },
  {
    viewValue: 'Name (Z-A)',
    value: 'title',
    sortingDesc: true,
  },
];
