<h1 mat-dialog-title>Available speakers</h1>
<div>
  <mat-form-field
    class="w-100"
    appearance="outline"
    floatLabel="always"
    color="accent"
    >
    <mat-label> Search by name </mat-label>
    <input matInput [formControl]="name" />
  </mat-form-field>
</div>
<div mat-dialog-content>
  @if (speakers && speakers.length > 0) {
    <div
      class="d-flex select-existing-facilitators w-100 mt-3"
      >
      <mat-selection-list
        class="w-100 p-0"
        [multiple]="false"
        (selectionChange)="onSelection($event)"
        >
        @for (speaker of speakers | slice: 0:10; track speaker) {
          <mat-list-option
            style="height: 64px"
            class="p-0"
            [value]="speaker.id"
            >
            <div class="d-flex align-items-center my-1">
              <img
                style="width: 58px; height: 64px"
                [src]="speaker.profilePhotoUrl"
                alt="profile"
                />
              <span class="ms-2">{{ speaker.name }}</span>
            </div>
          </mat-list-option>
        }
      </mat-selection-list>
    </div>
  }
</div>
<!-- <div class="mt-3">
<mat-slide-toggle [formControl]="isHost">Host</mat-slide-toggle>
</div> -->
<div class="mt-3">
  @if (error !== '') {
    <div
      class="cross-validation-error-message alert alert-danger"
      >
      {{ error }}
    </div>
  }
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-flat-button color="accent" (click)="onSubmit()" cdkFocusInitial>
    Submit
  </button>
</div>
