import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import { IStringResponse } from '../shared/api/string-response';
import { API_BASE_URL } from '../shared/app-constants';
import { ApiServiceHelper } from '../shared/helpers';
import { FfLanguage } from '../shared/models/language';
import { IPage } from '../shared/pagination';
import { IResourcePost } from '../shared/resources/resource';
import { ICoursePatch, ICoursePost, ICourseReadModel } from './courses';
import { ICourseFilter } from './filters/filters';
import { ICreateLesson, ILessonReadModel, IPatchLesson } from './lessons';

@Injectable()
export class LessonsApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getLesson(
    courseId: string,
    lessonId: string,
  ): Observable<ILessonReadModel | undefined> {
    const url = `${this.apiBaseUrl}/courses/${courseId}/lessons`;
    return this.httpClient
      .get<ILessonReadModel[]>(url)
      .pipe(
        map((list: ILessonReadModel[]) =>
          list.find((lesson) => `${lesson.id}` === lessonId),
        ),
      );
  }

  public postLesson(
    courseId: string,
    postData: ICreateLesson,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/courses/${courseId}/lessons`;
    return this.httpClient.post<IStringResponse>(url, postData);
  }

  public patchLesson(
    courseId: string,
    lessonId: number,
    updates: IPatchLesson,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/courses/${courseId}/lessons/${lessonId}`;
    return this.httpClient.patch<IStringResponse>(url, updates);
  }

  public updateLessonVideo(
    courseId: string,
    lessonId: string,
    file: File,
    language: FfLanguage
  ): Observable<IStringResponse> {
    const headers = new HttpHeaders()
          .set('responseType', 'text/plain')
          .set('accept', 'text/plain');

    const uploadData = new FormData();
    uploadData.append(`video`, file, file.name);

    return this.httpClient.post<IStringResponse>(
      `${this.apiBaseUrl}/courses/${courseId}/lessons/${lessonId}/recording?language=${language}`,
      uploadData,
      { headers },
    );
  }
  public addLessonResource(
    courseId: string,
    lessonId: number,
    resource: IResourcePost,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/Courses/${courseId}/lessons/${lessonId}/resources`;
    return this.httpClient.post<IStringResponse>(url, { ...resource });
  }

  public deleteLessonResource(
    courseId: string,
    lessonId: number,
    resourceId: string,
  ): Observable<void> {
    const url = `${this.apiBaseUrl}/Courses/${courseId}/lessons/${lessonId}/resources/${resourceId}`;
    return this.httpClient.delete<void>(url);
  }
}
