<div>
  <div class="resources-label" [class.large]="largeLabel">Resources</div>
</div>
<div class="resources-card-container">
  <div>
    @if (isAdmin) {
      <div class="mb-3">
        <button
          class="no-shadow"
          mat-icon-button
          color="accent"
          matTooltip="Add new resource"
          (click)="showAddForm()"
          >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    }
    <div>
      @if (isAddFormShown) {
        <div>
          <form [formGroup]="addForm">
            <div class="mb-3">
              <mat-form-field
                color="accent"
                class="me-2"
                appearance="outline"
                floatLabel="always"
                >
                <mat-label>Type</mat-label>
                <mat-select required formControlName="type">
                  @for (type of resourceTypes; track type) {
                    <mat-option
                      [value]="type.view"
                      >{{ type.viewValue }}</mat-option
                      >
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <mat-form-field appearance="outline" floatLabel="always" color="accent">
                <mat-label> Title </mat-label>
                <input matInput formControlName="title" />
              </mat-form-field>
              @if (!isDocumentAddition) {
                <mat-form-field
                  class="ms-2 w-50"
                  appearance="outline"
                  floatLabel="always"
                  color="accent"
                  >
                  <mat-label> Link </mat-label>
                  <input matInput formControlName="link" />
                </mat-form-field>
              }
              @if (isDocumentAddition) {
                <button
                  mat-flat-button
                  class="ms-2"
                  color="accent"
                  (click)="fileInput.click()"
                  >
                  Upload document
                </button>
                @if (file) {
                  <span class="ms-2">{{ file.name }}</span>
                }
                <input
                  type="file"
                  #fileInput
                  class="d-none"
                  (change)="addDocument(fileInput.files)"
                  />
              }
              <div class="mt-3">
                <button mat-flat-button color="accent" (click)="submitResource()">
                  Submit
                </button>
              </div>
            </form>
          </div>
        }

        <div class="row resource-columns-container w-100 mt-3">
          <div class="col-md-4 resource-column">
            <div class="d-flex align-items-center">
              <mat-icon [svgIcon]="'tools'">tools</mat-icon>
              <span class="ms-2 column-label">Tools</span>
            </div>

            <ul class="mt-4">
              @if (tools.length > 0) {
                @for (tool of tools; track tool) {
                  <li class="pointer mat-body-2">
                    <a
                      class="link"
                      href="javascript:void(0);"
                      (click)="navigateToResource(tool.url)"
                      >{{ tool.text }}</a
                      >
                      @if (isAdmin) {
                        <button
                          mat-icon-button
                          (click)="deleteResource(tool.id)"
                          >
                          <mat-icon>delete</mat-icon>
                        </button>
                      }
                      @if (!isAdmin) {
                        <mat-icon class="link-icon"
                          >open_in_new</mat-icon
                          >
                        }
                      </li>
                    }
                  } @else {
                    <li class="pointer mat-body-2">
                      No tools for this {{ featureAsString }}
                    </li>
                  }
                </ul>
              </div>
              <div class="col-md-4 resource-column">
                <div class="d-flex align-items-center">
                  <mat-icon [svgIcon]="'links'">links</mat-icon>
                  <span class="ms-2 column-label">Links</span>
                </div>

                <ul class="mt-4">
                  @if (links.length > 0) {
                    @for (link of links; track link) {
                      <li>
                        <a
                          class="link pointer mat-body-2"
                          href="javascript:void(0);"
                          (click)="navigateToResource(link.url)"
                          >{{ link.text }}</a
                          >
                          @if (isAdmin) {
                            <button
                              mat-icon-button
                              (click)="deleteResource(link.id)"
                              >
                              <mat-icon>delete</mat-icon>
                            </button>
                          }
                        </li>
                      }
                    } @else {
                      <li class="mat-body-2">
                        No links for this {{ featureAsString }}
                      </li>
                    }
                  </ul>
                </div>
                <div class="col-md-4 resource-column last">
                  <div class="d-flex align-items-center">
                    <mat-icon [svgIcon]="'documents'">documents</mat-icon>
                    <span class="ms-2 column-label">Documents</span>
                  </div>

                  <ul class="mt-4">
                    @if (documents.length > 0) {
                      @for (document of documents; track document) {
                        <li>
                          <a
                            class="link pointer mat-body-2"
                            href="javascript:void(0);"
                            (click)="navigateToResource(document.url)"
                            >{{ document.text }}</a
                            >
                            @if (isAdmin) {
                              <button
                                mat-icon-button
                                (click)="deleteResource(document.id)"
                                >
                                <mat-icon>delete</mat-icon>
                              </button>
                            }
                          </li>
                        }
                      } @else {
                        <li class="mat-body-2">
                          No documents for this {{ featureAsString }}
                        </li>
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
