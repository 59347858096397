import { Inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { API_BASE_URL } from '../../shared/app-constants';

@Injectable()
export class DiscussionSignalrService {
  public connectionId!: string;
  public hubConnection!: signalR.HubConnection;

  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string) {}

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.apiBaseUrl}/discussion-hub`)
      .build();
    this.hubConnection
      .start()
      .then(() => this.getConnectionId())
      .catch((err) => console.error('Error while starting connection: ' + err));
  };

  private getConnectionId = () => {
    this.hubConnection.invoke('getconnectionid').then((data) => {
      this.connectionId = data;
    });
  };
}
