import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-errors-page',
  templateUrl: './errors-page.component.html',
  styleUrls: ['./errors-page.component.scss'],
})
export class ErrorsComponent implements OnInit {
  public static headings = {
    '0': 'Server unreachable',
    '404': 'We lost this page',
  } as Record<string, string>;

  public code!: string;
  public description?: string;
  public heading?: string;

  constructor(private router: Router, private urlLocation: Location) {}

  ngOnInit(): void {
    const state = this.urlLocation.getState() as any;

    if (
      this.urlLocation.path().includes('/errors') &&
      ((!state.code && state.code !== '0') || !state.heading)
    ) {
      this.router.navigateByUrl('/');
    }

    this.code = state.code === '0' ? '0' : state.code ?? '404';
    this.heading = state.heading || ErrorsComponent.headings[this.code];
    this.description = state.description;
  }
}
