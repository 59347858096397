<footer class="app-footer w-100 mt-96px">
  <div class="info-section">
    <div class="app-xxl-container">
      <!-- small screen start -->
      <div class="d-block d-md-none small-screen">
        <mat-accordion>
          <mat-expansion-panel class="small-screen-expansion-panels">
            <mat-expansion-panel-header>
              <div class="light-opacity mat-body-1 text-uppercase">
                our community
              </div>
            </mat-expansion-panel-header>
            <div class="my-3 mat-body-2 pointer" [routerLink]="'plans'">
              Become a Member
            </div>
            <div
              class="my-3 mat-body-2 pointer"
              (click)="
                navigateToLink('https://futurefemales.typeform.com/to/g9RPFMG4')
              "
            >
              Become an Ambassador
            </div>
            <div
              class="my-3 mat-body-2 pointer text-nowrap"
              (click)="
                navigateToLink(
                  'https://www.linkedin.com/company/future-females/jobs/ '
                )
              "
            >
              Join the team
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel class="small-screen-expansion-panels">
            <mat-expansion-panel-header>
              <div class="light-opacity mat-body-1 text-uppercase">
                programs
              </div>
            </mat-expansion-panel-header>
            <div class="my-3 mat-body-2 pointer" [routerLink]="'courses'">
              All Courses
            </div>
            <div class="my-3 mat-body-2 pointer" [routerLink]="'events'">
              All Events
            </div>
            <div
              class="my-3 mat-body-2 pointer"
              (click)="
                navigateToLink(
                  'https://member.futurefemales.co/sales-page-541480691649940666880'
                )
              "
            >
              Global Programs
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel class="small-screen-expansion-panels">
            <mat-expansion-panel-header>
              <div class="light-opacity mat-body-1 text-uppercase">Content</div>
            </mat-expansion-panel-header>
            <div
              class="my-3 mat-body-2 pointer text-nowrap"
              [routerLink]="'plans'"
            >
              Future Females Membership
            </div>
            <div
              class="my-3 mat-body-2 pointer"
              (click)="navigateToLink('https://futurefemales.co/blog')"
            >
              Blog
            </div>
            <div
              class="my-3 mat-body-2 pointer text-nowrap"
              (click)="
                navigateToLink('https://join.futurefemales.co/show?r_done=1')
              "
            >
              Future Females Show
            </div>
            <div
              class="my-3 mat-body-2 pointer"
              (click)="
                navigateToLink('https://join.futurefemales.co/ceo_diaries')
              "
            >
              CEO Diaries
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel class="small-screen-expansion-panels">
            <mat-expansion-panel-header>
              <div class="light-opacity mat-body-1 text-uppercase">contact</div>
            </mat-expansion-panel-header>
            <div class="my-3 mat-body-2">
              <div class="mat-body-1 mb-2 text-nowrap">General enquiries</div>
              <a
                class="text-transform-none light-opacity pointer"
                href="mailto:hello@futurefemales.co"
                >hello&#64;futurefemales.co</a
              >
            </div>
            <div class="my-3 mat-body-2">
              <div class="mat-body-1 mb-2 text-nowrap">
                Partnerships & Sponsorships
              </div>
              <a
                class="text-transform-none light-opacity pointer"
                href="mailto:partner@futurefemales.co"
                >partner&#64;futurefemales.co</a
              >
            </div>
            <div
              class="my-3 mat-body-2 pointer text-nowrap"
              [routerLink]="'faq'"
            >
              FAQs
            </div>
            <div
              class="my-3 mat-body-2 pointer text-nowrap"
              (click)="
                navigateToLink(
                  'https://api.whatsapp.com/message/TOZ3PRSUDNB7B1'
                )
              "
            >
              <i class="bi bi-whatsapp"></i>
              <span class="mat-body-2 mx-2">Support</span>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="pt-4 pb-5 d-flex justify-content-center">
          <div>
            <i
              class="mx-2 bi bi-instagram pointer"
              (click)="
                navigateToLink('https://www.instagram.com/futurefemales/')
              "
            ></i>
          </div>
          <div>
            <i
              class="mx-2 bi bi-facebook pointer"
              (click)="
                navigateToLink('https://www.facebook.com/futurefemalesofficial')
              "
            ></i>
          </div>
          <div>
            <i
              class="mx-2 bi bi-linkedin pointer"
              (click)="
                navigateToLink(
                  'https://www.linkedin.com/company/future-females/'
                )
              "
            ></i>
          </div>
          <div>
            <i
              class="mx-2 bi bi-tiktok pointer"
              (click)="navigateToLink('https://www.tiktok.com/@futurefemales/')"
            ></i>
          </div>
          <div>
            <i
              class="mx-2 bi bi-youtube pointer"
              (click)="
                navigateToLink(
                  'https://www.youtube.com/channel/UCUoMtqQMn5v6SMfVvfPQ-KA'
                )
              "
            ></i>
          </div>
        </div>
      </div>
      <!-- small screen end -->

      <!-- large screen start -->
      <div class="d-none d-md-flex justify-content-between">
        <div class="row mt-5 mx-0 w-100">
          <div class="col ps-0">
            <div class="light-opacity mat-body-1 text-uppercase mb-4">
              our community
            </div>
            <div
              class="my-3 mat-body-2 pointer text-nowrap"
              [routerLink]="'plans'"
            >
              Become a Member
            </div>
            <div
              class="my-3 mat-body-2 pointer"
              (click)="
                navigateToLink('https://futurefemales.typeform.com/to/g9RPFMG4')
              "
            >
              Become an Ambassador
            </div>
            <div
              class="my-3 mat-body-2 pointer"
              (click)="
                navigateToLink(
                  'https://www.linkedin.com/company/future-females/jobs/ '
                )
              "
            >
              Join the team
            </div>
          </div>
          <div class="col">
            <div
              class="light-opacity mat-body-1 text-uppercase mb-4 text-nowrap"
            >
              programs
            </div>
            <div class="my-3 mat-body-2 pointer" [routerLink]="'courses'">
              All courses
            </div>
            <div class="my-3 mat-body-2 pointer" [routerLink]="'events'">
              All events
            </div>
            <div
              class="my-3 mat-body-2 pointer"
              (click)="
                navigateToLink(
                  'https://member.futurefemales.co/sales-page-541480691649940666880'
                )
              "
            >
              Global programs
            </div>
          </div>
          <div class="col">
            <div class="light-opacity mat-body-1 text-uppercase mb-4">
              Content
            </div>
            <div class="my-3 mat-body-2 pointer" [routerLink]="'plans'">
              Future Females Membership
            </div>
            <div
              class="my-3 mat-body-2 pointer"
              (click)="navigateToLink('https://futurefemales.co/blog/')"
            >
              Blog
            </div>
            <div
              class="my-3 mat-body-2 pointer"
              (click)="navigateToLink('https://join.futurefemales.co/show')"
            >
              Future Females Show
            </div>
            <div
              class="my-3 mat-body-2 pointer"
              (click)="
                navigateToLink('https://join.futurefemales.co/ceo_diaries')
              "
            >
              CEO Diaries
            </div>
          </div>
          <div class="col">
            <div class="light-opacity mat-body-1 text-uppercase mb-4">
              Contact
            </div>
            <div class="my-3 mat-body-2">
              <div class="mat-body-1 mb-2 text-nowrap">General enquiries</div>
              <a
                class="text-transform-none light-opacity pointer"
                href="mailto:hello@futurefemales.co"
                >hello&#64;futurefemales.co</a
              >
            </div>
            <div class="my-3 mat-body-2">
              <div class="mat-body-1 mb-2 text-nowrap">
                Partnerships & Sponsorships
              </div>
              <a
                class="text-transform-none light-opacity pointer"
                href="mailto:partner@futurefemales.co"
                >partner&#64;futurefemales.co</a
              >
            </div>
            <div class="my-3 mat-body-2 pointer" [routerLink]="'faq'">FAQs</div>
            <div
              class="mb-3 large-social-row pointer"
              (click)="
                navigateToLink(
                  'https://api.whatsapp.com/message/TOZ3PRSUDNB7B1'
                )
              "
            >
              <i class="bi bi-whatsapp"></i>
              <span class="mat-body-2 mx-2">Support</span>
            </div>
          </div>
          <div class="col-auto pe-0">
            <div class="light-opacity mat-body-1 text-uppercase mb-4">
              Connect on Social
            </div>
            <div>
              <div
                class="mb-3 large-social-row pointer"
                (click)="
                  navigateToLink('https://www.instagram.com/futurefemales/')
                "
              >
                <i class="bi bi-instagram"></i>
                <span class="mat-body-2 m-3">Instagram</span>
              </div>
              <div
                class="mb-3 large-social-row pointer"
                (click)="
                  navigateToLink(
                    'https://www.facebook.com/futurefemalesofficial'
                  )
                "
              >
                <i class="bi bi-facebook"></i>
                <span class="mat-body-2 m-3">Facebook</span>
              </div>
              <div
                class="mb-3 large-social-row pointer"
                (click)="
                  navigateToLink(
                    'https://www.linkedin.com/company/future-females/'
                  )
                "
              >
                <i class="bi bi-linkedin"></i>
                <span class="mat-body-2 m-3">LinkedIn</span>
              </div>
              <div
                class="mb-3 large-social-row pointer"
                (click)="
                  navigateToLink('https://www.tiktok.com/@futurefemales/')
                "
              >
                <i class="bi bi-tiktok"></i>
                <span class="mat-body-2 m-3">TikTok</span>
              </div>
              <div
                class="mb-3 large-social-row pointer"
                (click)="
                  navigateToLink(
                    'https://www.youtube.com/channel/UCUoMtqQMn5v6SMfVvfPQ-KA'
                  )
                "
              >
                <i class="bi bi-youtube"></i>
                <span class="mat-body-2 mx-3 mb-1">Youtube</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- large screen end -->
    </div>
  </div>
  <div class="trademark-section mt-md-5">
    <div class="app-xxl-container p-md-0 p-5">
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-center h-48px text-center"
      >
        <div class="d-block d-md-flex align-items-center">
          <div class="me-md-4 my-3 my-md-0">
            <img
              class="h-18px"
              src="/assets/images/ff-logo-dark.png"
              alt="Future females logo"
            />
          </div>
          <div class="mat-caption my-3 my-md-0">
            &copy; Future Females, Inc.
          </div>
        </div>
        <div class="pointer mt-3 mt-md-0">
          <span class="d-block d-sm-inline mat-caption my-0"
            ><u [routerLink]="'/terms'">Terms</u> and
            <u [routerLink]="'/privacy'">privacy</u></span
          >
        </div>
      </div>
    </div>
  </div>
</footer>
