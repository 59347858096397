import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatChip, MatChipOption } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import { ILabel } from 'src/app/shared/label';
import { DiscussionApiService } from '../discussion-api.service';

@Component({
  selector: 'report-discussion',
  templateUrl: 'report-dialog.component.html',
})
export class ReportDiscussionDialog implements OnInit {
  public reportTags: ILabel[] = [];

  public selectedTags: number[] = [];

  public otherForm = new UntypedFormControl(null);

  public isSubmitAttempted: boolean = false;

  @ViewChildren('reportChips') reportChips!: QueryList<any>;

  constructor(
    private discussionApiService: DiscussionApiService,
    public dialogRef: MatDialogRef<ReportDiscussionDialog>,
  ) {}

  public ngOnInit(): void {
    this.discussionApiService
      .getReportTags()
      .subscribe((reportTags) => (this.reportTags = reportTags.items));
  }

  public onSubmit(): void {
    this.isSubmitAttempted = true;

    if (this.selectedTags.length === 0) {
      return;
    }

    const result = {
      text: this.otherForm.value ? this.otherForm.value.trim(' ') : null,
      labelIds: this.selectedTags.map(
        (index: number) => this.reportTags[index].id,
      ),
    };
    this.dialogRef.close(result);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public toggleCategoryChipState(id: number): void {
    const indexOfChipId = this.selectedTags.indexOf(id);
    if (indexOfChipId > -1) {
      this.selectedTags.splice(indexOfChipId, 1);
      (this.reportChips.get(id) as MatChipOption).deselect();
    } else {
      this.selectedTags.unshift(id);
      (this.reportChips.get(id) as MatChipOption).select();
    }
  }
}
