import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'filled-progress-indicator',
    templateUrl: 'filled-progress-indicator.component.html',
    styleUrls: ['filled-progress-indicator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FilledProgressIndicator {
    @Input() public progress: number = 0;

}