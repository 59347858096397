export const Emojis = {
  night_with_stars: '🌃',
  sunrise_over_mountains: '🌄',
  sunrise: '🌅',
  city_sunset: '🌆',
  city_sunrise: '🌇',
  rainbow: '🌈',
  bridge_at_night: '🌉',
  ocean: '🌊',
  volcano: '🌋',
  milky_way: '🌌',
  earth_africa: '🌍',
  earth_americas: '🌎',
  earth_asia: '🌏',
  globe_with_meridians: '🌐',
  new_moon: '🌑',
  waxing_crescent_moon: '🌒',
  first_quarter_moon: '🌓',
  moon: '🌔',
  waxing_gibbous_moon: '🌔',
  full_moon: '🌕',
  waning_gibbous_moon: '🌖',
  last_quarter_moon: '🌗',
  waning_crescent_moon: '🌘',
  crescent_moon: '🌙',
  new_moon_with_face: '🌚',
  first_quarter_moon_with_face: '🌛',
  last_quarter_moon_with_face: '🌜',
  full_moon_with_face: '🌝',
  sun_with_face: '🌞',
  star2: '🌟',
  stars: '🌠',
  cookie: '🍪',
  cake: '🍰',
  bento: '🍱',
  fork_and_knife: '🍴',
  tea: '🍵',
  wine_glass: '🍷',
  cocktail: '🍸',
  tropical_drink: '🍹',
  beer: '🍺',
  beers: '🍻',
  knife_fork_plate: '🍽️',
  champagne: '🍾',
  ribbon: '🎀',
  gift: '🎁',
  birthday: '🎂',
  fireworks: '🎆',
  sparkler: '🎇',
  balloon: '🎈',
  tada: '🎉',
  confetti_ball: '🎊',
  tanabata_tree: '🎋',
  reminder_ribbon: '🎗️',
  microphone: '🎤',
  movie_camera: '🎥',
  cinema: '🎦',
  headphones: '🎧',
  art: '🎨',
  tophat: '🎩',
  circus_tent: '🎪',
  ticket: '🎫',
  clapper: '🎬',
  performing_arts: '🎭',
  video_game: '🎮',
  dart: '🎯',
  slot_machine: '🎰',
  '8ball': '🎱',
  game_die: '🎲',
  bowling: '🎳',
  flower_playing_cards: '🎴',
  musical_note: '🎵',
  notes: '🎶',
  saxophone: '🎷',
  guitar: '🎸',
  musical_keyboard: '🎹',
  trumpet: '🎺',
  violin: '🎻',
  musical_score: '🎼',
  running_shirt_with_sash: '🎽',
  tennis: '🎾',
  ski: '🎿',
  basketball: '🏀',
  checkered_flag: '🏁',
  snowboarder: '🏂',
  'woman-running': '🏃‍♀️',
  'man-running': '🏃‍♂️',
  runner: '🏃‍♂️',
  running: '🏃‍♂️',
  'woman-surfing': '🏄‍♀️',
  'man-surfing': '🏄‍♂️',
  surfer: '🏄‍♂️',
  sports_medal: '🏅',
  trophy: '🏆',
  horse_racing: '🏇',
  football: '🏈',
  rugby_football: '🏉',
  'woman-swimming': '🏊‍♀️',
  'man-swimming': '🏊‍♂️',
  swimmer: '🏊‍♂️',
  'woman-lifting-weights': '🏋️‍♀️',
  'man-lifting-weights': '🏋️‍♂️',
  weight_lifter: '🏋️‍♂️',
  'woman-golfing': '🏌️‍♀️',
  'man-golfing': '🏌️‍♂️',
  golfer: '🏌️‍♂️',
  racing_motorcycle: '🏍️',
  racing_car: '🏎️',
  cricket_bat_and_ball: '🏏',
  volleyball: '🏐',
  field_hockey_stick_and_ball: '🏑',
  ice_hockey_stick_and_puck: '🏒',
  table_tennis_paddle_and_ball: '🏓',
  snow_capped_mountain: '🏔️',
  camping: '🏕️',
  beach_with_umbrella: '🏖️',
  building_construction: '🏗️',
  house_buildings: '🏘️',
  cityscape: '🏙️',
  derelict_house_building: '🏚️',
  classical_building: '🏛️',
  desert: '🏜️',
  desert_island: '🏝️',
  national_park: '🏞️',
  stadium: '🏟️',
  house: '🏠',
  house_with_garden: '🏡',
  office: '🏢',
  post_office: '🏣',
  european_post_office: '🏤',
  hospital: '🏥',
  bank: '🏦',
  atm: '🏧',
  hotel: '🏨',
  love_hotel: '🏩',
  convenience_store: '🏪',
  school: '🏫',
  department_store: '🏬',
  factory: '🏭',
  izakaya_lantern: '🏮',
  lantern: '🏮',
  japanese_castle: '🏯',
  european_castle: '🏰',
  'rainbow-flag': '🏳️‍🌈',
  waving_white_flag: '🏳️',
  'flag-england': '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
  'flag-scotland': '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
  'flag-wales': '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
  waving_black_flag: '🏴',
  rosette: '🏵️',
  label: '🏷️',
  badminton_racquet_and_shuttlecock: '🏸',
  eyes: '👀',
  'eye-in-speech-bubble': '👁️‍🗨️',
  eye: '👁️',
  ear: '👂',
  nose: '👃',
  lips: '👄',
  tongue: '👅',
  point_up_2: '👆',
  point_down: '👇',
  point_left: '👈',
  point_right: '👉',
  facepunch: '👊',
  punch: '👊',
  wave: '👋',
  ok_hand: '👌',
  '+1': '👍',
  thumbsup: '👍',
  '-1': '👎',
  thumbsdown: '👎',
  clap: '👏',
  open_hands: '👐',
  crown: '👑',
  womans_hat: '👒',
  eyeglasses: '👓',
  necktie: '👔',
  shirt: '👕',
  tshirt: '👕',
  jeans: '👖',
  dress: '👗',
  kimono: '👘',
  bikini: '👙',
  womans_clothes: '👚',
  purse: '👛',
  handbag: '👜',
  pouch: '👝',
  mans_shoe: '👞',
  shoe: '👞',
  athletic_shoe: '👟',
  high_heel: '👠',
  sandal: '👡',
  boot: '👢',
  footprints: '👣',
  bust_in_silhouette: '👤',
  busts_in_silhouette: '👥',
  boy: '👦',
  girl: '👧',
  'male-farmer': '👨‍🌾',
  'male-cook': '👨‍🍳',
  'male-student': '👨‍🎓',
  'male-singer': '👨‍🎤',
  'male-artist': '👨‍🎨',
  'male-teacher': '👨‍🏫',
  'male-factory-worker': '👨‍🏭',
  'man-boy-boy': '👨‍👦‍👦',
  'man-boy': '👨‍👦',
  'man-girl-boy': '👨‍👧‍👦',
  'man-girl-girl': '👨‍👧‍👧',
  'man-girl': '👨‍👧',
  'man-man-boy': '👨‍👨‍👦',
  'man-man-boy-boy': '👨‍👨‍👦‍👦',
  'man-man-girl': '👨‍👨‍👧',
  'man-man-girl-boy': '👨‍👨‍👧‍👦',
  'man-man-girl-girl': '👨‍👨‍👧‍👧',
  'man-woman-boy': '👨‍👩‍👦',
  family: '👨‍👩‍👦',
  'man-woman-boy-boy': '👨‍👩‍👦‍👦',
  'man-woman-girl': '👨‍👩‍👧',
  'man-woman-girl-boy': '👨‍👩‍👧‍👦',
  'man-woman-girl-girl': '👨‍👩‍👧‍👧',
  'male-technologist': '👨‍💻',
  'male-office-worker': '👨‍💼',
  'male-mechanic': '👨‍🔧',
  'male-scientist': '👨‍🔬',
  'male-astronaut': '👨‍🚀',
  'male-firefighter': '👨‍🚒',
  'male-doctor': '👨‍⚕️',
  'male-judge': '👨‍⚖️',
  'male-pilot': '👨‍✈️',
  'man-heart-man': '👨‍❤️‍👨',
  'man-kiss-man': '👨‍❤️‍💋‍👨',
  man: '👨',
  'female-farmer': '👩‍🌾',
  'female-cook': '👩‍🍳',
  'female-student': '👩‍🎓',
  'female-singer': '👩‍🎤',
  'female-artist': '👩‍🎨',
  'female-teacher': '👩‍🏫',
  'female-factory-worker': '👩‍🏭',
  'woman-boy-boy': '👩‍👦‍👦',
  'woman-boy': '👩‍👦',
  'woman-girl-boy': '👩‍👧‍👦',
  'woman-girl-girl': '👩‍👧‍👧',
  'woman-girl': '👩‍👧',
  'woman-woman-boy': '👩‍👩‍👦',
  'woman-woman-boy-boy': '👩‍👩‍👦‍👦',
  'woman-woman-girl': '👩‍👩‍👧',
  'woman-woman-girl-boy': '👩‍👩‍👧‍👦',
  'woman-woman-girl-girl': '👩‍👩‍👧‍👧',
  'female-technologist': '👩‍💻',
  'female-office-worker': '👩‍💼',
  'female-mechanic': '👩‍🔧',
  'female-scientist': '👩‍🔬',
  'female-astronaut': '👩‍🚀',
  'female-firefighter': '👩‍🚒',
  'female-doctor': '👩‍⚕️',
  'female-judge': '👩‍⚖️',
  'female-pilot': '👩‍✈️',
  'woman-heart-man': '👩‍❤️‍👨',
  couple_with_heart: '👩‍❤️‍👨',
  'woman-heart-woman': '👩‍❤️‍👩',
  'woman-kiss-man': '👩‍❤️‍💋‍👨',
  couplekiss: '👩‍❤️‍💋‍👨',
  'woman-kiss-woman': '👩‍❤️‍💋‍👩',
  woman: '👩',
  couple: '👫',
  man_and_woman_holding_hands: '👫',
  two_men_holding_hands: '👬',
  two_women_holding_hands: '👭',
  'female-police-officer': '👮‍♀️',
  'male-police-officer': '👮‍♂️',
  cop: '👮‍♂️',
  'woman-with-bunny-ears-partying': '👯‍♀️',
  dancers: '👯‍♀️',
  'man-with-bunny-ears-partying': '👯‍♂️',
  bride_with_veil: '👰',
  'blond-haired-woman': '👱‍♀️',
  'blond-haired-man': '👱‍♂️',
  person_with_blond_hair: '👱‍♂️',
  man_with_gua_pi_mao: '👲',
  'woman-wearing-turban': '👳‍♀️',
  'man-wearing-turban': '👳‍♂️',
  man_with_turban: '👳‍♂️',
  older_man: '👴',
  older_woman: '👵',
  baby: '👶',
  'female-construction-worker': '👷‍♀️',
  'male-construction-worker': '👷‍♂️',
  construction_worker: '👷‍♂️',
  princess: '👸',
  japanese_ogre: '👹',
  japanese_goblin: '👺',
  ghost: '👻',
  angel: '👼',
  alien: '👽',
  space_invader: '👾',
  imp: '👿',
  skull: '💀',
  'woman-tipping-hand': '💁‍♀️',
  information_desk_person: '💁‍♀️',
  'man-tipping-hand': '💁‍♂️',
  'female-guard': '💂‍♀️',
  'male-guard': '💂‍♂️',
  guardsman: '💂‍♂️',
  dancer: '💃',
  lipstick: '💄',
  nail_care: '💅',
  'woman-getting-massage': '💆‍♀️',
  massage: '💆‍♀️',
  'man-getting-massage': '💆‍♂️',
  'woman-getting-haircut': '💇‍♀️',
  haircut: '💇‍♀️',
  'man-getting-haircut': '💇‍♂️',
  barber: '💈',
  syringe: '💉',
  pill: '💊',
  kiss: '💋',
  love_letter: '💌',
  ring: '💍',
  gem: '💎',
  bouquet: '💐',
  wedding: '💒',
  heartbeat: '💓',
  broken_heart: '💔',
  two_hearts: '💕',
  sparkling_heart: '💖',
  heartpulse: '💗',
  cupid: '💘',
  blue_heart: '💙',
  green_heart: '💚',
  yellow_heart: '💛',
  purple_heart: '💜',
  gift_heart: '💝',
  revolving_hearts: '💞',
  heart_decoration: '💟',
  diamond_shape_with_a_dot_inside: '💠',
  bulb: '💡',
  anger: '💢',
  bomb: '💣',
  zzz: '💤',
  boom: '💥',
  collision: '💥',
  sweat_drops: '💦',
  droplet: '💧',
  dash: '💨',
  hankey: '💩',
  poop: '💩',
  shit: '💩',
  muscle: '💪',
  dizzy: '💫',
  speech_balloon: '💬',
  thought_balloon: '💭',
  white_flower: '💮',
  moneybag: '💰',
  currency_exchange: '💱',
  heavy_dollar_sign: '💲',
  credit_card: '💳',
  yen: '💴',
  dollar: '💵',
  euro: '💶',
  pound: '💷',
  money_with_wings: '💸',
  chart: '💹',
  seat: '💺',
  computer: '💻',
  briefcase: '💼',
  minidisc: '💽',
  floppy_disk: '💾',
  cd: '💿',
  dvd: '📀',
  file_folder: '📁',
  open_file_folder: '📂',
  page_with_curl: '📃',
  page_facing_up: '📄',
  date: '📅',
  calendar: '📆',
  card_index: '📇',
  chart_with_upwards_trend: '📈',
  chart_with_downwards_trend: '📉',
  bar_chart: '📊',
  clipboard: '📋',
  pushpin: '📌',
  round_pushpin: '📍',
  paperclip: '📎',
  straight_ruler: '📏',
  triangular_ruler: '📐',
  bookmark_tabs: '📑',
  ledger: '📒',
  notebook: '📓',
  notebook_with_decorative_cover: '📔',
  closed_book: '📕',
  book: '📖',
  open_book: '📖',
  green_book: '📗',
  blue_book: '📘',
  orange_book: '📙',
  books: '📚',
  name_badge: '📛',
  scroll: '📜',
  memo: '📝',
  pencil: '📝',
  telephone_receiver: '📞',
  pager: '📟',
  fax: '📠',
  satellite_antenna: '📡',
  loudspeaker: '📢',
  mega: '📣',
  outbox_tray: '📤',
  inbox_tray: '📥',
  package: '📦',
  'e-mail': '📧',
  email: '📧',
  incoming_envelope: '📨',
  envelope_with_arrow: '📩',
  mailbox_closed: '📪',
  mailbox: '📫',
  mailbox_with_mail: '📬',
  mailbox_with_no_mail: '📭',
  postbox: '📮',
  postal_horn: '📯',
  newspaper: '📰',
  iphone: '📱',
  calling: '📲',
  vibration_mode: '📳',
  mobile_phone_off: '📴',
  no_mobile_phones: '📵',
  signal_strength: '📶',
  camera: '📷',
  camera_with_flash: '📸',
  video_camera: '📹',
  tv: '📺',
  radio: '📻',
  vhs: '📼',
  film_projector: '📽️',
  prayer_beads: '📿',
  twisted_rightwards_arrows: '🔀',
  repeat: '🔁',
  repeat_one: '🔂',
  arrows_clockwise: '🔃',
  arrows_counterclockwise: '🔄',
  low_brightness: '🔅',
  high_brightness: '🔆',
  mute: '🔇',
  speaker: '🔈',
  sound: '🔉',
  loud_sound: '🔊',
  battery: '🔋',
  electric_plug: '🔌',
  mag: '🔍',
  mag_right: '🔎',
  lock_with_ink_pen: '🔏',
  closed_lock_with_key: '🔐',
  key: '🔑',
  lock: '🔒',
  unlock: '🔓',
  bell: '🔔',
  no_bell: '🔕',
  bookmark: '🔖',
  link: '🔗',
  radio_button: '🔘',
  back: '🔙',
  end: '🔚',
  on: '🔛',
  soon: '🔜',
  top: '🔝',
  underage: '🔞',
  keycap_ten: '🔟',
  capital_abcd: '🔠',
  abcd: '🔡',
  symbols: '🔣',
  abc: '🔤',
  fire: '🔥',
  flashlight: '🔦',
  wrench: '🔧',
  hammer: '🔨',
  nut_and_bolt: '🔩',
  hocho: '🔪',
  knife: '🔪',
  gun: '🔫',
  microscope: '🔬',
  telescope: '🔭',
  crystal_ball: '🔮',
  six_pointed_star: '🔯',
  beginner: '🔰',
  trident: '🔱',
  black_square_button: '🔲',
  white_square_button: '🔳',
  red_circle: '🔴',
  large_blue_circle: '🔵',
  large_orange_diamond: '🔶',
  large_blue_diamond: '🔷',
  small_orange_diamond: '🔸',
  small_blue_diamond: '🔹',
  small_red_triangle: '🔺',
  small_red_triangle_down: '🔻',
  arrow_up_small: '🔼',
  arrow_down_small: '🔽',
  om_symbol: '🕉️',
  dove_of_peace: '🕊️',
  kaaba: '🕋',
  mosque: '🕌',
  synagogue: '🕍',
  menorah_with_nine_branches: '🕎',
  clock1: '🕐',
  clock2: '🕑',
  clock3: '🕒',
  clock4: '🕓',
  clock5: '🕔',
  clock6: '🕕',
  clock7: '🕖',
  clock8: '🕗',
  clock9: '🕘',
  clock10: '🕙',
  clock11: '🕚',
  clock12: '🕛',
  clock130: '🕜',
  clock230: '🕝',
  clock330: '🕞',
  clock430: '🕟',
  clock530: '🕠',
  clock630: '🕡',
  clock730: '🕢',
  clock830: '🕣',
  clock930: '🕤',
  clock1030: '🕥',
  clock1130: '🕦',
  clock1230: '🕧',
  candle: '🕯️',
  mantelpiece_clock: '🕰️',
  hole: '🕳️',
  man_in_business_suit_levitating: '🕴️',
  'female-detective': '🕵️‍♀️',
  'male-detective': '🕵️‍♂️',
  sleuth_or_spy: '🕵️‍♂️',
  dark_sunglasses: '🕶️',
  spider: '🕷️',
  spider_web: '🕸️',
  joystick: '🕹️',
  man_dancing: '🕺',
  linked_paperclips: '🖇️',
  lower_left_ballpoint_pen: '🖊️',
  lower_left_fountain_pen: '🖋️',
  lower_left_paintbrush: '🖌️',
  lower_left_crayon: '🖍️',
  raised_hand_with_fingers_splayed: '🖐️',
  middle_finger: '🖕',
  reversed_hand_with_middle_finger_extended: '🖕',
  'spock-hand': '🖖',
  black_heart: '🖤',
  desktop_computer: '🖥️',
  printer: '🖨️',
  three_button_mouse: '🖱️',
  trackball: '🖲️',
  frame_with_picture: '🖼️',
  card_index_dividers: '🗂️',
  card_file_box: '🗃️',
  file_cabinet: '🗄️',
  wastebasket: '🗑️',
  spiral_note_pad: '🗒️',
  spiral_calendar_pad: '🗓️',
  compression: '🗜️',
  old_key: '🗝️',
  rolled_up_newspaper: '🗞️',
  dagger_knife: '🗡️',
  speaking_head_in_silhouette: '🗣️',
  left_speech_bubble: '🗨️',
  right_anger_bubble: '🗯️',
  ballot_box_with_ballot: '🗳️',
  world_map: '🗺️',
  mount_fuji: '🗻',
  tokyo_tower: '🗼',
  statue_of_liberty: '🗽',
  japan: '🗾',
  moyai: '🗿',
  grinning: '😀',
  grin: '😁',
  joy: '😂',
  smiley: '😃',
  smile: '😄',
  sweat_smile: '😅',
  'face-with-tears-of-joy': '😂',
  laughing: '😆',
  satisfied: '😆',
  innocent: '😇',
  smiling_imp: '😈',
  wink: '😉',
  blush: '😊',
  yum: '😋',
  relieved: '😌',
  heart_eyes: '😍',
  sunglasses: '😎',
  smirk: '😏',
  neutral_face: '😐',
  expressionless: '😑',
  unamused: '😒',
  sweat: '😓',
  pensive: '😔',
  confused: '😕',
  confounded: '😖',
  kissing: '😗',
  kissing_heart: '😘',
  kissing_smiling_eyes: '😙',
  kissing_closed_eyes: '😚',
  stuck_out_tongue: '😛',
  stuck_out_tongue_winking_eye: '😜',
  stuck_out_tongue_closed_eyes: '😝',
  disappointed: '😞',
  worried: '😟',
  angry: '😠',
  rage: '😡',
  cry: '😢',
  persevere: '😣',
  triumph: '😤',
  disappointed_relieved: '😥',
  frowning: '😦',
  anguished: '😧',
  fearful: '😨',
  weary: '😩',
  sleepy: '😪',
  tired_face: '😫',
  grimacing: '😬',
  sob: '😭',
  open_mouth: '😮',
  hushed: '😯',
  cold_sweat: '😰',
  scream: '😱',
  astonished: '😲',
  flushed: '😳',
  sleeping: '😴',
  dizzy_face: '😵',
  no_mouth: '😶',
  mask: '😷',
  smile_cat: '😸',
  joy_cat: '😹',
  smiley_cat: '😺',
  heart_eyes_cat: '😻',
  smirk_cat: '😼',
  kissing_cat: '😽',
  pouting_cat: '😾',
  crying_cat_face: '😿',
  scream_cat: '🙀',
  slightly_frowning_face: '🙁',
  slightly_smiling_face: '🙂',
  upside_down_face: '🙃',
  face_with_rolling_eyes: '🙄',
  'woman-gesturing-no': '🙅‍♀️',
  no_good: '🙅‍♀️',
  'man-gesturing-no': '🙅‍♂️',
  'woman-gesturing-ok': '🙆‍♀️',
  ok_woman: '🙆‍♀️',
  'man-gesturing-ok': '🙆‍♂️',
  'woman-bowing': '🙇‍♀️',
  'man-bowing': '🙇‍♂️',
  bow: '🙇‍♂️',
  see_no_evil: '🙈',
  hear_no_evil: '🙉',
  speak_no_evil: '🙊',
  'woman-raising-hand': '🙋‍♀️',
  raising_hand: '🙋‍♀️',
  'man-raising-hand': '🙋‍♂️',
  raised_hands: '🙌',
  'woman-frowning': '🙍‍♀️',
  person_frowning: '🙍‍♀️',
  'man-frowning': '🙍‍♂️',
  'woman-pouting': '🙎‍♀️',
  person_with_pouting_face: '🙎‍♀️',
  'man-pouting': '🙎‍♂️',
  pray: '🙏',
  rocket: '🚀',
  helicopter: '🚁',
  steam_locomotive: '🚂',
  railway_car: '🚃',
  bullettrain_side: '🚄',
  bullettrain_front: '🚅',
  train2: '🚆',
  metro: '🚇',
  light_rail: '🚈',
  station: '🚉',
  tram: '🚊',
  train: '🚋',
  bus: '🚌',
  oncoming_bus: '🚍',
  trolleybus: '🚎',
  busstop: '🚏',
  minibus: '🚐',
  ambulance: '🚑',
  fire_engine: '🚒',
  police_car: '🚓',
  oncoming_police_car: '🚔',
  taxi: '🚕',
  oncoming_taxi: '🚖',
  car: '🚗',
  red_car: '🚗',
  oncoming_automobile: '🚘',
  blue_car: '🚙',
  truck: '🚚',
  articulated_lorry: '🚛',
  tractor: '🚜',
  monorail: '🚝',
  mountain_railway: '🚞',
  suspension_railway: '🚟',
  mountain_cableway: '🚠',
  aerial_tramway: '🚡',
  ship: '🚢',
  'woman-rowing-boat': '🚣‍♀️',
  'man-rowing-boat': '🚣‍♂️',
  rowboat: '🚣‍♂️',
  speedboat: '🚤',
  traffic_light: '🚥',
  vertical_traffic_light: '🚦',
  construction: '🚧',
  rotating_light: '🚨',
  triangular_flag_on_post: '🚩',
  door: '🚪',
  no_entry_sign: '🚫',
  smoking: '🚬',
  no_smoking: '🚭',
  put_litter_in_its_place: '🚮',
  do_not_litter: '🚯',
  potable_water: '🚰',
  'non-potable_water': '🚱',
  bike: '🚲',
  no_bicycles: '🚳',
  'woman-biking': '🚴‍♀️',
  'man-biking': '🚴‍♂️',
  bicyclist: '🚴‍♂️',
  'woman-mountain-biking': '🚵‍♀️',
  'man-mountain-biking': '🚵‍♂️',
  mountain_bicyclist: '🚵‍♂️',
  'woman-walking': '🚶‍♀️',
  'man-walking': '🚶‍♂️',
  walking: '🚶‍♂️',
  no_pedestrians: '🚷',
  children_crossing: '🚸',
  mens: '🚹',
  womens: '🚺',
  restroom: '🚻',
  baby_symbol: '🚼',
  toilet: '🚽',
  wc: '🚾',
  shower: '🚿',
  bath: '🛀',
  bathtub: '🛁',
  passport_control: '🛂',
  customs: '🛃',
  baggage_claim: '🛄',
  left_luggage: '🛅',
  couch_and_lamp: '🛋️',
  sleeping_accommodation: '🛌',
  shopping_bags: '🛍️',
  bellhop_bell: '🛎️',
  bed: '🛏️',
  place_of_worship: '🛐',
  octagonal_sign: '🛑',
  shopping_trolley: '🛒',
  hammer_and_wrench: '🛠️',
  shield: '🛡️',
  oil_drum: '🛢️',
  motorway: '🛣️',
  railway_track: '🛤️',
  motor_boat: '🛥️',
  small_airplane: '🛩️',
  airplane_departure: '🛫',
  airplane_arriving: '🛬',
  satellite: '🛰️',
  passenger_ship: '🛳️',
  scooter: '🛴',
  motor_scooter: '🛵',
  canoe: '🛶',
  sled: '🛷',
  flying_saucer: '🛸',
  zipper_mouth_face: '🤐',
  money_mouth_face: '🤑',
  face_with_thermometer: '🤒',
  nerd_face: '🤓',
  thinking_face: '🤔',
  face_with_head_bandage: '🤕',
  robot_face: '🤖',
  hugging_face: '🤗',
  the_horns: '🤘',
  sign_of_the_horns: '🤘',
  call_me_hand: '🤙',
  raised_back_of_hand: '🤚',
  'left-facing_fist': '🤛',
  'right-facing_fist': '🤜',
  handshake: '🤝',
  crossed_fingers: '🤞',
  hand_with_index_and_middle_fingers_crossed: '🤞',
  i_love_you_hand_sign: '🤟',
  face_with_cowboy_hat: '🤠',
  clown_face: '🤡',
  nauseated_face: '🤢',
  rolling_on_the_floor_laughing: '🤣',
  drooling_face: '🤤',
  lying_face: '🤥',
  'woman-facepalming': '🤦‍♀️',
  'man-facepalming': '🤦‍♂️',
  face_palm: '🤦',
  sneezing_face: '🤧',
  face_with_raised_eyebrow: '🤨',
  face_with_one_eyebrow_raised: '🤨',
  'star-struck': '🤩',
  grinning_face_with_star_eyes: '🤩',
  zany_face: '🤪',
  grinning_face_with_one_large_and_one_small_eye: '🤪',
  shushing_face: '🤫',
  face_with_finger_covering_closed_lips: '🤫',
  face_with_symbols_on_mouth: '🤬',
  serious_face_with_symbols_covering_mouth: '🤬',
  face_with_hand_over_mouth: '🤭',
  smiling_face_with_smiling_eyes_and_hand_covering_mouth: '🤭',
  face_vomiting: '🤮',
  face_with_open_mouth_vomiting: '🤮',
  exploding_head: '🤯',
  shocked_face_with_exploding_head: '🤯',
  pregnant_woman: '🤰',
  'breast-feeding': '🤱',
  palms_up_together: '🤲',
  selfie: '🤳',
  prince: '🤴',
  man_in_tuxedo: '🤵',
  mrs_claus: '🤶',
  mother_christmas: '🤶',
  'woman-shrugging': '🤷‍♀️',
  'man-shrugging': '🤷‍♂️',
  shrug: '🤷',
  'woman-cartwheeling': '🤸‍♀️',
  'man-cartwheeling': '🤸‍♂️',
  person_doing_cartwheel: '🤸',
  'woman-juggling': '🤹‍♀️',
  'man-juggling': '🤹‍♂️',
  juggling: '🤹',
  fencer: '🤺',
  'woman-wrestling': '🤼‍♀️',
  'man-wrestling': '🤼‍♂️',
  wrestlers: '🤼',
  'woman-playing-water-polo': '🤽‍♀️',
  'man-playing-water-polo': '🤽‍♂️',
  water_polo: '🤽',
  'woman-playing-handball': '🤾‍♀️',
  'man-playing-handball': '🤾‍♂️',
  handball: '🤾',
  wilted_flower: '🥀',
  drum_with_drumsticks: '🥁',
  clinking_glasses: '🥂',
  tumbler_glass: '🥃',
  spoon: '🥄',
  goal_net: '🥅',
  first_place_medal: '🥇',
  second_place_medal: '🥈',
  third_place_medal: '🥉',
  boxing_glove: '🥊',
  martial_arts_uniform: '🥋',
  curling_stone: '🥌',
  croissant: '🥐',
  avocado: '🥑',
  cucumber: '🥒',
  bacon: '🥓',
  potato: '🥔',
  carrot: '🥕',
  baguette_bread: '🥖',
  green_salad: '🥗',
  shallow_pan_of_food: '🥘',
  stuffed_flatbread: '🥙',
  egg: '🥚',
  glass_of_milk: '🥛',
  peanuts: '🥜',
  kiwifruit: '🥝',
  pancakes: '🥞',
  dumpling: '🥟',
  fortune_cookie: '🥠',
  takeout_box: '🥡',
  chopsticks: '🥢',
  bowl_with_spoon: '🥣',
  cup_with_straw: '🥤',
  coconut: '🥥',
  broccoli: '🥦',
  pie: '🥧',
  pretzel: '🥨',
  cut_of_meat: '🥩',
  sandwich: '🥪',
  canned_food: '🥫',
  crab: '🦀',
  lion_face: '🦁',
  scorpion: '🦂',
  turkey: '🦃',
  unicorn_face: '🦄',
  eagle: '🦅',
  duck: '🦆',
  bat: '🦇',
  shark: '🦈',
  owl: '🦉',
  fox_face: '🦊',
  butterfly: '🦋',
  deer: '🦌',
  gorilla: '🦍',
  lizard: '🦎',
  rhinoceros: '🦏',
  shrimp: '🦐',
  squid: '🦑',
  giraffe_face: '🦒',
  zebra_face: '🦓',
  hedgehog: '🦔',
  sauropod: '🦕',
  't-rex': '🦖',
  cricket: '🦗',
  cheese_wedge: '🧀',
  face_with_monocle: '🧐',
  adult: '🧑',
  child: '🧒',
  older_adult: '🧓',
  bearded_person: '🧔',
  person_with_headscarf: '🧕',
  woman_in_steamy_room: '🧖‍♀️',
  man_in_steamy_room: '🧖‍♂️',
  person_in_steamy_room: '🧖‍♂️',
  woman_climbing: '🧗‍♀️',
  person_climbing: '🧗‍♀️',
  man_climbing: '🧗‍♂️',
  woman_in_lotus_position: '🧘‍♀️',
  person_in_lotus_position: '🧘‍♀️',
  man_in_lotus_position: '🧘‍♂️',
  female_mage: '🧙‍♀️',
  mage: '🧙‍♀️',
  male_mage: '🧙‍♂️',
  female_fairy: '🧚‍♀️',
  fairy: '🧚‍♀️',
  male_fairy: '🧚‍♂️',
  female_vampire: '🧛‍♀️',
  vampire: '🧛‍♀️',
  male_vampire: '🧛‍♂️',
  genie: '🧞‍♂️',
  brain: '🧠',
  orange_heart: '🧡',
  billed_cap: '🧢',
  scarf: '🧣',
  gloves: '🧤',
  coat: '🧥',
  socks: '🧦',
  watch: '⌚',
  hourglass: '⌛',
  fast_forward: '⏩',
  rewind: '⏪',
  arrow_double_up: '⏫',
  arrow_double_down: '⏬',
  black_right_pointing_double_triangle_with_vertical_bar: '⏭️',
  black_left_pointing_double_triangle_with_vertical_bar: '⏮️',
  black_right_pointing_triangle_with_double_vertical_bar: '⏯️',
  alarm_clock: '⏰',
  stopwatch: '⏱️',
  timer_clock: '⏲️',
  hourglass_flowing_sand: '⏳',
  double_vertical_bar: '⏸️',
  black_square_for_stop: '⏹️',
  black_circle_for_record: '⏺️',
  white_circle: '⚪',
  black_circle: '⚫',
  soccer: '⚽',
  baseball: '⚾',
  snowman_without_snow: '⛄',
  partly_sunny: '⛅',
  thunder_cloud_and_rain: '⛈️',
  ophiuchus: '⛎',
  pick: '⛏️',
  helmet_with_white_cross: '⛑️',
  chains: '⛓️',
  no_entry: '⛔',
  shinto_shrine: '⛩️',
  church: '⛪',
  mountain: '⛰️',
  umbrella_on_ground: '⛱️',
  fountain: '⛲',
  golf: '⛳',
  ferry: '⛴️',
  boat: '⛵',
  sailboat: '⛵',
  skier: '⛷️',
  ice_skate: '⛸️',
  'woman-bouncing-ball': '⛹️‍♀️',
  'man-bouncing-ball': '⛹️‍♂️',
  person_with_ball: '⛹️‍♂️',
  tent: '⛺',
  fuelpump: '⛽',
  scissors: '✂️',
  airplane: '✈️',
  envelope: '✉️',
  fist: '✊',
  hand: '✋',
  raised_hand: '✋',
  v: '✌️',
  writing_hand: '✍️',
  pencil2: '✏️',
  black_nib: '✒️',
  heavy_check_mark: '✔️',
  heavy_multiplication_x: '✖️',
  latin_cross: '✝️',
  star_of_david: '✡️',
  eight_spoked_asterisk: '✳️',
  eight_pointed_black_star: '✴️',
  snowflake: '❄️',
  sparkle: '❇️',
  x: '❌',
  negative_squared_cross_mark: '❎',
  heavy_heart_exclamation_mark_ornament: '❣️',
  heart: '❤️',
  arrow_right: '➡️',
  curly_loop: '➰',
  loop: '➿',
  arrow_heading_up: '⤴️',
  arrow_heading_down: '⤵️',
  arrow_left: '⬅️',
  arrow_up: '⬆️',
  arrow_down: '⬇️',
  black_large_square: '⬛',
  white_large_square: '⬜',
  star: '⭐',
  o: '⭕',
  wavy_dash: '〰️',
  part_alternation_mark: '〽️',
  congratulations: '㊗️',
  secret: '㊙️',
  soap: '🧼',
  virus: '🦠',
  thumbsup_2: '👍🏾',
  thumbsup_3: '👍🏽',
  fist_1: '✊🏾',
  fist_2: '✊🏿',
  target: '🎯',
} as Record<string, string>;
