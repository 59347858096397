import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { IStringResponse } from '../../shared/api/string-response';
import { UsersService } from '../../user/users.service';
import { IFAQ, IFAQUpdate, IFAQPost } from '../../shared/types/faq.type';
import { FrequentlyAskedQuestionsApiService } from '../../faq/services/faq-api.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { FeatureAdminRoles, UserAdmin } from 'src/app/user/admin';
import { Feature } from '../enums/features';

@Component({
  selector: 'ff-entity-faq-section',
  templateUrl: 'faq.component.html',
  styleUrls: ['faq.component.scss'],
})
export class FFFrequentlyAskedQuestionsComponent implements OnInit, OnDestroy {
  @Input() public feature!: Feature;

  public faq: IFAQ[] = [];
  public isAdmin!: boolean;

  public editMode: boolean = false;
  public addMode: boolean = false;

  public isReorder: boolean = false;

  public editedFaqId!: string;

  public faqForm = new UntypedFormGroup({
    question: new UntypedFormControl(null, Validators.required),
    answer: new UntypedFormControl(null, Validators.required),
  });

  private categoryKey!: string;

  private destroyNotifier = new Subject<void>();

  constructor(
    private faqApiService: FrequentlyAskedQuestionsApiService,
    private usersService: UsersService,
  ) {}

  public ngOnInit(): void {
    this.setCategoryKey();

    this.faqApiService
      .getAll(this.categoryKey)
      .subscribe((faq: IFAQ[]) => (this.faq = this.orderFaq(faq)));

    combineLatest([
      this.usersService.isAdminInterfaceShown,
      this.usersService.userAdmin,
    ])
      .pipe(takeUntil(this.destroyNotifier))
      .subscribe(
        ([isAdminInterfaceShown, admin]: [boolean, UserAdmin]) =>
          (this.isAdmin =
            isAdminInterfaceShown &&
            (admin.isSuperAdmin ||
              admin.isAdminForFeature(FeatureAdminRoles.Faqs))),
      );
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();
  }

  public setAddMode(value: boolean): void {
    this.addMode = value;
    this.isReorder = false;
  }

  public reorderFaq(): void {
    this.isReorder = true;
    this.editMode = false;
    this.addMode = false;
  }

  public drop(event: any) {
    moveItemInArray(this.faq, event.previousIndex, event.currentIndex);
  }

  public deleteFaq(id: string): void {
    this.faqApiService
      .delete(id)
      .subscribe((_) => (this.faq = this.faq.filter((f) => f.id !== id)));
  }

  public edit(id: string): void {
    this.isReorder = false;
    this.editedFaqId = id;
    const faqItem = this.faq.find((f) => f.id === id);
    !this.faqForm.get('question')?.setValue(faqItem?.question);
    !this.faqForm.get('answer')?.setValue(faqItem?.answer);
    this.editMode = true;
  }

  public submitFaqOrder(): void {
    this.faqApiService
      .reorder({
        categoryId: this.categoryKey,
        faqIds: this.faq.map((faq) => faq.id),
      })
      .subscribe((_) => {
        this.isReorder = false;
      });
  }

  public submit(edit = false): void {
    if (
      !this.faqForm.get('question')?.value ||
      !this.faqForm.get('answer')?.value ||
      this.faqForm.get('question')?.value.trim() === '' ||
      this.faqForm.get('answer')?.value.trim() === ''
    ) {
      return;
    }

    if (edit) {
      const faqPost: IFAQUpdate = {
        question: this.faqForm.get('question')?.value.trim(),
        answer: this.faqForm.get('answer')?.value.trim(),
      };
      this.faqApiService
        .update(this.editedFaqId!, faqPost)
        .subscribe((idResponse: IStringResponse) => {
          const faqItem = this.faq.find((f) => f.id === this.editedFaqId);
          faqItem!.answer = faqPost.answer;
          faqItem!.question = faqPost.question;
          this.faqForm.reset();
          this.editMode = false;
          this.editedFaqId = '';
        });
      return;
    }

    const faqPost: IFAQPost = {
      question: this.faqForm.get('question')?.value.trim(),
      answer: this.faqForm.get('answer')?.value.trim(),
      categoryId: 'FC-0dcd474037',
    };

    this.faqApiService.postFaq(faqPost).subscribe((id: IStringResponse) => {
      this.faq = [
        {
          id: id.field,
          question: faqPost.question,
          answer: faqPost.answer,
          index: 0,
        },
        ...this.faq,
      ];
      this.faqForm.reset();
      this.addMode = false;
    });
  }

  private orderFaq(faq: IFAQ[]): IFAQ[] {
    return faq.sort((a: IFAQ, b: IFAQ) => a.index - b.index);
  }

  private setCategoryKey(): void {
    if (this.feature === Feature.Courses) {
      this.categoryKey = 'FC-d163c7878f';
      return;
    }

    if (this.feature === Feature.Events) {
      this.categoryKey = 'FC-0dcd474037';
      return;
    }

    if (this.feature === Feature.Plans) {
      this.categoryKey = 'FC-f9398cbde7';
    }
  }
}
