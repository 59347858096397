import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { API_BASE_URL } from '../app-constants';
import { ILabel, ILabelReadModel } from '../label';
import { IBaseFilter } from './filters';
import { ApiServiceHelper } from '../helpers';

@Injectable()
export class FiltersApiService {
  constructor(
    private httpClient: HttpClient,
    @Inject(API_BASE_URL) public apiBaseUrl: string,
  ) {}

  public getCategories(feature: string): Observable<ILabelReadModel[]> {
    const url = `${this.apiBaseUrl}/${feature}/categories`;
    return this.httpClient.get<ILabelReadModel[]>(url);
  }

  public getTopics(feature: string): Observable<ILabelReadModel[]> {
    const url = `${this.apiBaseUrl}/${feature}/topics`;
    return this.httpClient.get<ILabelReadModel[]>(url);
  }

  public patchCategories(): Observable<string[]> {
    const url = `${this.apiBaseUrl}/Events/categories`;
    return this.httpClient.get<string[]>(url);
  }

  public patchTopics(): Observable<string[]> {
    const url = `${this.apiBaseUrl}/Events/topics`;
    return this.httpClient.get<string[]>(url);
  }
}
