import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Emojis } from '../../emojis';

@Component({
  selector: 'emojis',
  template: ` <span [style.font-size]="size + 'px'"> {{ emojiIcon }} </span> `,
  styles: [],
})
export class EmojisComponent implements OnInit, OnChanges {
  @Input()
  public name: string = 'smile';
  @Input()
  public size: string = '30';

  public emojiIcon!: string;

  constructor() {}

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges() {
    this.update();
  }

  private update() {
    this.emojiIcon = Emojis[this.name];
  }
}
