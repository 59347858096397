import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import {
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MsalService,
} from '@azure/msal-angular';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  concatMap,
  EMPTY,
  Observable,
  tap,
  from,
  BehaviorSubject,
  throwError,
  timer,
} from 'rxjs';
import {
  catchError,
  delay,
  filter,
  finalize,
  mergeMap,
  retry,
  switchMap,
  take,
} from 'rxjs/operators';

import { NotifyService } from './notify.service';
import { AskToLoginComponent } from './ask-to-login-dialog/ask-to-login.component';
import { UsersApiService } from '../user/users-api.service';
import { IUserPost, IUserTokenResponse } from '../user/user';

import { API_BASE_URL } from '../shared/app-constants';
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  RedirectRequest,
} from '@azure/msal-browser';
import { protectedResources } from '../auth-config';
import { AccountApiService } from '../user/account-api.service';
import { ITokenResponse } from '../user/account';
import { CompleteProgressService } from '../user/complete-progress.service';
import { FFInsightsService } from '../core/logging.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );

  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private notifyService: NotifyService,
    private usersApiService: UsersApiService,
    private accountApiService: AccountApiService,
    private router: Router,
    public dialog: MatDialog,
    private authService: MsalService,
    private ffInsightsService: FFInsightsService,
  ) {}

  private readonly AllowedPostsWithNoToken = [
    '/accounts',
    '/session',
    '/affiliates/',
    '/views'
  ];

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const azAccessToken = localStorage.getItem('azAt');
    const accessToken = localStorage.getItem('at');
    if (!!accessToken && !this.isCreateUserReq(request)) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${accessToken}` },
      });
    } else if (!!azAccessToken) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${azAccessToken}` },
      });
    } else {
      if (request.method !== 'GET' && !this.isRequestAllowedWithNoToken(request)) {
        const dialogRef = this.dialog.open(AskToLoginComponent, {
          width: '400px',
        });

        return EMPTY;
      }
    }

    if (request.method === 'POST' && request.url.indexOf('/accounts') > -1) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      retry({ count: 2, delay: this.shouldRetry }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 504) {
          this.ffInsightsService.logEvent(
            '[Err]: 504 error not working with retries',
            { request, error },
          );
        }
        return this.handleErrors(error, request, next);
      }),
    );
  }

  private isCreateUserReq(request: HttpRequest<unknown>) {
    return (
      request.method === 'POST' && request.url === `${this.apiBaseUrl}/Users`
    );
  }

  private shouldRetry(error: HttpErrorResponse) {
    if (error.status === 504) {
      return timer(1000);
    }
    throw error;
  }

  private isUserResourceReq(
    request: HttpRequest<unknown>,
    error: HttpErrorResponse,
  ): boolean {
    const urlSplitBySlash = request.url.split('/');
    const resource = `${urlSplitBySlash[3]}/${urlSplitBySlash[4]}`;
    return (
      ((request.method === 'GET' && error.status === 404) ||
        request.method === 'POST') &&
      resource.toLocaleLowerCase() === 'api/users'
    );
  }

  private handleErrors(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      this.notifyService.throwError('Oops! Something went wrong');
      return EMPTY;
    } else {
      if (
        error instanceof HttpErrorResponse &&
        error.status === 401 &&
        !!localStorage.getItem('at')
      ) {
        return this.handle401Error(request, next);
      }

      if (this.isUserResourceReq(request, error)) {
        this.ffInsightsService.logEvent(
          '[Err] User logged out on users endpoint',
          {
            request,
            error,
            isRefresh: this.isRefreshing,
          },
        );

        this.clearUserDetails();
        sessionStorage.clear();

        this.authService.logoutRedirect();
        return EMPTY;
      }

      const msg = error.error?.detail
        ? error.error.detail
        : 'Oops! Something went wrong';

      const title = error.error?.title || 'Oops! Something went wrong';

      if (
        `${error.status}` === '404' &&
        request.url.indexOf('/affiliate/') > -1
      ) {
        return EMPTY;
      }

      if (`${error.status}` === '400') {
        this.notifyService.throwError(msg);
      } else if (
        `${error.status}`.charAt(0) === '4' ||
        `${error.status}`.charAt(0) === '5'
      ) {
        this.router.navigateByUrl('/errors', {
          state: {
            code: `${error.status}`,
            description: `${msg}`,
            heading: title,
          },
        });
      } else if (`${error.status}` === '0') {
        this.router.navigateByUrl('/errors', {
          state: { code: '0', heading: 'Server unreachable' },
        });
      }
    }

    return EMPTY;
  }

  private isRequestAllowedWithNoToken(request: HttpRequest<any>): boolean {
    if (request.method !== 'POST' && request.method !== 'PATCH') {
      return false;
    }

    return this.AllowedPostsWithNoToken.some(
      (resource) => request.url.indexOf(resource) > -1,
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.refreshToken().pipe(
        switchMap((response: IUserTokenResponse) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.token);
          return next.handle(this.addTokenHeader(request, response.token));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          return EMPTY;
        }),
      );
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) =>
        next.handle(this.addTokenHeader(request, token as string)),
      ),
    );
  }

  private addTokenHeader(request: HttpRequest<any>, accessToken: string) {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${accessToken}` },
    });
  }

  private refreshToken(): Observable<any> {
    if (localStorage.getItem('internalusr')) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        this.ffInsightsService.logEvent(
          '[Err] Refresh token not found in local storage',
        );

        this.clearUserDetails();
        sessionStorage.clear();

        this.authService.logoutRedirect();
        return EMPTY;
      }

      return this.accountApiService.refreshToken(refreshToken).pipe(
        tap((tokenResponse: ITokenResponse) => {
          localStorage.setItem('azAt', tokenResponse.accessToken);
          localStorage.setItem('refreshToken', tokenResponse.refreshToken);
          localStorage.setItem('internalusr', 'true');
        }),
        concatMap((_) =>
          this.usersApiService.createUserAsync(this.getUserPostData()).pipe(
            tap((response: IUserTokenResponse) => {
              localStorage.setItem('at', response.token);
            }),
          ),
        ),
        catchError((err) => {
          this.ffInsightsService.logEvent(
            '[INFO] User logged out on internal acc refresh token process',
            { err, isRefresh: this.isRefreshing },
          );
          this.clearUserDetails();
          sessionStorage.clear();

          this.authService.logoutRedirect();
          return EMPTY;
        }),
      );
    }

    return from(
      this.authService.instance.acquireTokenSilent({
        scopes: protectedResources.api.scopes,
      }),
    ).pipe(
      tap((value: AuthenticationResult | void) => {
        if (!!value) {
          localStorage.setItem('azAt', value.accessToken);
        }
      }),
      concatMap((_) =>
        this.usersApiService.createUserAsync(this.getUserPostData()).pipe(
          tap((response: IUserTokenResponse) => {
            localStorage.setItem('at', response.token);
          }),
        ),
      ),
      catchError((err) => this.logoutUserOnErrorForExternal(err)),
    );
  }

  private logoutUserOnErrorForExternal(err: any): Observable<void> {
    const domainHint = localStorage.getItem('hint');

    this.clearUserDetails();
    sessionStorage.clear();

    if (!domainHint) {
      this.ffInsightsService.logEvent(
        '[Err] User hint missing for external signin',
        {
          err,
        },
      );

      this.authService.logoutRedirect();
      return EMPTY;
    }

    const pathName = window.location.pathname.slice(1);
    this.authService.logoutRedirect({
      postLogoutRedirectUri: `${environment.appBaseUrl}/login?domainHint=${domainHint}&path=${pathName}`,
    });
    return EMPTY;
  }

  public clearUserDetails(): void {
    localStorage.removeItem('usrid');
    localStorage.removeItem('azAt');
    localStorage.removeItem('noSubscription');
    localStorage.removeItem('at');
    localStorage.removeItem('externalId');
    localStorage.removeItem('internalusr');
    localStorage.removeItem('onetimesession');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
    sessionStorage.removeItem(CompleteProgressService.CACHE_KEY);
  }

  private getUserPostData(): IUserPost {
    const user = this.authService.instance.getActiveAccount();
    const username = user!.name?.split(' ');
    const firstName = !!username ? username[0] : undefined;
    const lastName =
      !!username && username.length > 1 ? username[1] : undefined;
    return {
      email: user!.username,
      firstName,
      lastName,
    };
  }
}

export const genericRetryStrategy =
  ({
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (
          retryAttempt > maxRetryAttempts ||
          excludedStatusCodes.find((e) => e === error.status)
        ) {
          return throwError(() => error);
        }
        console.log(
          `Attempt ${retryAttempt}: retrying in ${
            retryAttempt * scalingDuration
          }ms`,
        );
        // retry after 1s, 2s, etc...
        return timer(retryAttempt * scalingDuration);
      }),
      finalize(() => {}),
    );
  };
