import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { ILabel, ILabelReadModel } from '../../label';

import { FiltersApiService } from '../filters-api.service';
import { LabelListComponent } from './label-list/label-list.component';

@Component({
  selector: 'topics-and-categories',
  templateUrl: 'topics-and-categories.component.html',
  styleUrls: ['topics-and-categories.component.scss'],
})
export class TopicsAndCategoriesComponent implements OnInit, OnDestroy {
  @Input() public feature!: string;
  @Input() public loadFromApi = true;
  @Input() public selectedTopicsIds: string[] = [];
  @Input() public selectedCategoriesIds: string[] = [];

  @Input() public topics: ILabelReadModel[] = [];
  @Input() public categories: ILabelReadModel[] = [];

  @Output() public partialFilter = new EventEmitter<
    Partial<{ categoryIds: string[]; topicIds: string[] }>
  >();

  @ViewChildren(LabelListComponent)
  public labelQueryList!: QueryList<LabelListComponent>;

  public selectedCategories: number[] = [];
  public selectedTopics: number[] = [];

  private destroyNotifier = new Subject<void>();

  constructor(private filtersApiService: FiltersApiService) {}

  public ngOnInit(): void {
    this.checkIfTopicsAndCategoriesAreLoaded();
  }

  private checkIfTopicsAndCategoriesAreLoaded(): void {
    if (this.loadFromApi) {
      const categoryRequest = this.filtersApiService.getCategories(
        this.feature,
      );
      const topicsRequest = this.filtersApiService.getTopics(this.feature);

      forkJoin([categoryRequest, topicsRequest]).subscribe(
        ([categories, topics]) => {
          this.categories = categories;
          this.topics = topics;
        },
      );
    }
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();
  }

  public resetLabels(): void {
    this.labelQueryList.forEach((list: LabelListComponent) => {
      list.resetLabels();
    });
  }

  public onCategoryChange(ids: string[]): void {
    this.partialFilter.emit({ categoryIds: ids });
  }
  public onTopicChange(ids: string[]): void {
    this.partialFilter.emit({ topicIds: ids });
  }
}
