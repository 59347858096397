<ly-img-cropper
  [config]="config"
  [(scale)]="scale"
  (minScale)="minScale = $event"
  (ready)="setAsReady()"
  (cleaned)="setUnready()"
  (cropped)="onCropped($event)"
  (loaded)="onLoaded($event)"
  (error)="onError($event)"
  [class.cropper-hidden]="!ready"
  [class.complete-hidden]="!imageSelected"
  >
</ly-img-cropper>

@if (ready) {
  <div
    [className]="classes ? classes['sliderContainer'] : undefined"
    >
    <ly-slider
      [thumbVisible]="false"
      [min]="minScale"
      [max]="1"
      [(ngModel)]="scale"
      (input)="onSliderInput($event)"
      step="0.000001"
    ></ly-slider>
  </div>
}

@if (!cropperImage) {
  <input
    #fileInput
    type="file"
    (change)="selectImage($event)"
    accept="image/*"
    hidden
    />
  @if (buttonStyle === 'flat') {
    <button
      mat-flat-button
      color="accent"
      class="mt-2 w-100"
      (click)="fileInput.click()"
      >
      {{ chooseImageLabel }}
    </button>
  }
  @if (buttonStyle === 'raised') {
    <button
      mat-raised-button
      color="accent"
      class="mt-2 w-100"
      (click)="fileInput.click()"
      >
      {{ chooseImageLabel }}
    </button>
  }
}

@if (cropperImage && !hideCTA) {
  @if (buttonStyle === 'flat') {
    <button mat-flat-button color="accent" class="mt-2 w-100" (click)="cancel()">
      Cancel
    </button>
    <button
      mat-flat-button
      color="accent"
      class="mt-2 w-100"
      (click)="cropper?.crop()"
      >
      Crop
    </button>
  }
  @if (buttonStyle === 'raised') {
    <button
      mat-raised-button
      color="accent"
      class="mt-2 w-100"
      (click)="cancel()"
      >
      Cancel
    </button>
    <button
      mat-raised-button
      color="accent"
      class="mt-2 w-100"
      (click)="cropper?.crop()"
      >
      Crop
    </button>
  }
}
