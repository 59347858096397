<div class="py-4">
  <div class="d-flex justify-content-between px-3 mt-2 mb-3 mat-caption">
    <div class="mat-headline-6 my-0">Notifications</div>
    <div class="d-flex align-items-end">
      <u class="ms-2 pointer" (click)="markAllAsRead()">Mark all as read</u>
    </div>
  </div>
  <div class="notification-list">
    @for (notification of notifications; track notification) {
      <div class="notification pointer">
        <notification [notification]="notification"></notification>
      </div>
    }
  </div>
  <div class="d-flex justify-content-end px-3 mt-2 mat-caption">
    @if (pageIndex < 3) {
      <u class="pointer" (click)="loadMore()">See more</u>
    }
  </div>
</div>
