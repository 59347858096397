<h1 mat-dialog-title>Report reason</h1>
<div mat-dialog-content>
  <mat-chip-set selectable multiple>
    @for (chip of reportTags; track chip; let index = $index) {
      <mat-chip
        #reportChips="matChip"
        color="accent"
        (click)="toggleCategoryChipState(index)"
        ><span class="mat-body-1">{{ chip.title }}</span></mat-chip
        >
      }
    </mat-chip-set>
    <div class="mt-3">
      <mat-form-field class="w-50" appearance="outline" floatLabel="always">
        <mat-label> Other </mat-label>
        <input matInput [formControl]="otherForm" />
      </mat-form-field>
    </div>
    @if (isSubmitAttempted && selectedTags.length === 0) {
      <div
        class="mt-3 cross-validation-error-message alert alert-danger"
        >
        No reason selected
      </div>
    }
  </div>
  <div mat-dialog-actions class="d-flex justofy-content-end">
    <button mat-flat-button (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="accent" cdkFocusInitial (click)="onSubmit()">
      Submit
    </button>
  </div>
