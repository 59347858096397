<section class="app-xxl-container">
  <div class="px-3 px-sm-0">
    <div class="title">{{ entityTitle }}</div>
    <div class="my-2 d-flex align-items-center">
      <star-rating
        [readonly]="true"
        [smallDisplay]="true"
        [initialRating]="
          (entityReviewAverage && totalReviewsCount > 10) || isAdminViewShown
            ? entityReviewAverage
            : 5
        "
      ></star-rating>
      <span class="mx-1">{{
        ((entityReviewAverage && totalReviewsCount > 10) || isAdminViewShown
          ? entityReviewScore
          : 5
        ) | number : '1.1'
      }}</span
      >@if (totalReviewsCount > 10 || isAdminViewShown) {
      <span class="text-gray">({{ totalReviewsCount }})</span>
      }
    </div>
  </div>
  <div class="my-5">
    @if (!currentUserReview) {
    <div class="d-flex align-items-center px-3 px-sm-0">
      <span class="important-text me-2">Overall rating</span>
      <star-rating
        [initialRating]="rating"
        (onRatingChanged)="changeRating($event)"
      ></star-rating>
    </div>
    } @if (currentUserReview) {
    <div class="d-flex align-items-center align-items-sm-end px-3 px-sm-0">
      <span class="important-text me-2"
        >Reviewed on
        {{ currentUserReview.createdAtUtc | date : 'MM/dd/yyyy' }}</span
      >
      <star-rating
        [readonly]="!showEditField"
        [initialRating]="rating"
        (onRatingChanged)="changeRating($event)"
      ></star-rating>
      <feedback-cta
        class="ms-2"
        [isVerifiedAttendee]="currentUserReview.isUserVerifiedAttendee"
        [usersRating]="rating"
        [emitEvent]="true"
        (ctaClicked)="onEditReview()"
      ></feedback-cta>
    </div>
    } @if (showEditField) {
    <div class="gradient-box my-4">
      <div class="review-input">
        <div class="mat-body-1">What did you particulary like?</div>
        <div class="mt-3 mb-5">
          @for (label of feedbackLabels; track label; let index = $index) {
          <span
            class="chip me-2 my-1"
            [class.selected]="isLabelSelected(index)"
            (click)="selectLabel(index)"
            >{{ label.title }}</span
          >
          }
        </div>
        <div class="mat-body-1 my-3">Add a written review</div>
        <div>
          <mat-form-field
            class="w-100 textarea-field mb-3"
            appearance="outline"
            color="accent"
          >
            <textarea
              #addReviewTextarea
              class="add-review-textarea"
              (input)="onTextareChange()"
              placeholder="What did you like or dislike?"
              matInput
              [(ngModel)]="reviewText"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="mt-4">
          <button
            mat-flat-button
            class="me-1"
            [disabled]="isFormModified()"
            (click)="cancelFeedbackCompletion()"
          >
            Cancel
          </button>
          <button
            color="accent"
            [disabled]="isFormModified()"
            mat-flat-button
            (click)="submitFeedback()"
          >
            <span class="d-flex align-items-center"
              ><span>Submit </span>@if (isFeedbackSaving) {
              <mat-progress-spinner
                class="ms-2"
                diameter="16"
                mode="indeterminate"
              ></mat-progress-spinner>
              }</span
            >
          </button>
        </div>
      </div>
    </div>
    }
  </div>

  <div class="my-5 px-3 px-sm-0">
    <mat-checkbox
      (change)="changeOnlyVerified($event)"
      [(ngModel)]="onlyVerified"
      >Verified {{ entityType | titlecase }}
      {{ entityType == 'course' ? 'Graduates' : 'Attendees' }}</mat-checkbox
    >
  </div>

  <div class="d-block d-sm-flex flex-gap">
    <div class="my-3 m-sm-0 mx-3 mx-sm-0 feedback-summary-container">
      <div class="d-block d-sm-none title">Rating Statistics</div>
      <mat-divider class="d-block d-sm-none"></mat-divider>
      <div class="reviews">
        <feedback-summary
          [feedbackSummary]="feedbackSummary"
        ></feedback-summary>
      </div>
    </div>

    <section class="review-section justify-content-between">
      <mat-divider></mat-divider>
      @for (review of reviews; track review) {
      <div class="mb-4"></div>
      <div class="d-flex align-items-center px-3 px-sm-0">
        @if (review.userProfilePicture) {
        <img
          class="profile-picture pointer"
          [src]="review.userProfilePicture"
          alt="profile"
        />
        }
        <span class="ms-2 user-name">{{ review.userName }}</span>
        @if (isAdminViewShown) {
        <button
          class="ms-2"
          (click)="hideFeedback(review.userId, !review.hiddenAtUtc)"
          mat-flat-button
          [color]="review.hiddenAtUtc ? 'accent' : 'primary'"
        >
          @if (!review.hiddenAtUtc) {
          <span>Hide</span>
          } @if (review.hiddenAtUtc) {
          <span>Unhide</span>
          }
        </button>
        }
      </div>
      <div class="mt-2 px-3 px-sm-0">
        <div class="d-flex align-items-center mb-3">
          <star-rating
            [readonly]="true"
            [smallDisplay]="true"
            [initialRating]="review.rating"
          ></star-rating>
        </div>
        <div class="text-gray mt-1">
          Reviewed in {{ review.fromCountry }} on
          {{ review.createdAtUtc | date : 'MM/dd/yyyy' }}
        </div>
        @if (review.isUserVerifiedAttendee) {
        <div class="text-accent">Verified Event Attendee</div>
        }
      </div>
      <div class="d-block d-sm-flex align-items-center px-3 px-sm-0 mt-4">
        @if (review.rating >= 3) {
        <span class="important-text me-3">What did you particularly like?</span>
        } @if (review.rating < 3) {
        <span class="important-text me-3">What did you not like?</span>
        }
        <div>
          @for (label of review.labels; track label) {
          <span class="chip m-1">{{ label.title }}</span>
          }
        </div>
      </div>
      @if (review.text && isAdminViewShown) {
      <div class="review-text text-gray px-3 px-sm-0 mt-2">
        {{ review.text }}
      </div>
      }
      <mat-divider class="my-4"></mat-divider>
      }
    </section>
  </div>
  @if (totalReviewsCount - reviews.length > 0) {
  <div class="w-100 d-flex justify-content-center">
    <button
      mat-button
      class="px-4 py-3 pointer show-more-button"
      (click)="loadMoreReviews()"
    >
      See more ({{ totalReviewsCount - reviews.length }})
    </button>
  </div>
  }
</section>
