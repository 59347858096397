<a target="_blank" href="https://wa.me/?text={{ text }}">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
  >
    <g>
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(0%, 0%, 0%);
          fill-opacity: 1;
        "
        d="M 0.046875 20 L 1.453125 14.863281 C 0.585938 13.359375 0.128906 11.65625 0.128906 9.910156 C 0.132812 4.445312 4.578125 0 10.042969 0 C 12.691406 0 15.179688 1.035156 17.050781 2.90625 C 18.921875 4.78125 19.953125 7.269531 19.953125 9.917969 C 19.949219 15.382812 15.503906 19.828125 10.042969 19.828125 C 8.382812 19.828125 6.75 19.410156 5.300781 18.621094 Z M 5.546875 16.828125 C 6.941406 17.65625 8.273438 18.152344 10.039062 18.152344 C 14.578125 18.152344 18.277344 14.460938 18.277344 9.917969 C 18.28125 5.363281 14.601562 1.675781 10.046875 1.671875 C 5.5 1.671875 1.804688 5.367188 1.804688 9.910156 C 1.804688 11.765625 2.347656 13.152344 3.257812 14.605469 L 2.425781 17.644531 Z M 15.035156 12.273438 C 14.972656 12.171875 14.808594 12.109375 14.558594 11.984375 C 14.3125 11.859375 13.09375 11.261719 12.867188 11.179688 C 12.640625 11.097656 12.476562 11.054688 12.308594 11.304688 C 12.144531 11.550781 11.667969 12.109375 11.523438 12.273438 C 11.378906 12.4375 11.234375 12.460938 10.988281 12.335938 C 10.742188 12.210938 9.941406 11.949219 8.996094 11.105469 C 8.261719 10.449219 7.761719 9.640625 7.621094 9.390625 C 7.476562 9.144531 7.605469 9.007812 7.726562 8.886719 C 7.839844 8.773438 7.976562 8.597656 8.097656 8.453125 C 8.226562 8.308594 8.265625 8.203125 8.347656 8.039062 C 8.433594 7.875 8.390625 7.730469 8.328125 7.605469 C 8.265625 7.480469 7.769531 6.261719 7.566406 5.765625 C 7.363281 5.285156 7.160156 5.347656 7.007812 5.339844 L 6.53125 5.332031 C 6.367188 5.332031 6.097656 5.394531 5.871094 5.644531 C 5.644531 5.890625 5.003906 6.488281 5.003906 7.710938 C 5.003906 8.929688 5.894531 10.105469 6.015625 10.269531 C 6.140625 10.4375 7.761719 12.9375 10.246094 14.011719 C 10.835938 14.265625 11.300781 14.417969 11.660156 14.53125 C 12.25 14.71875 12.792969 14.691406 13.21875 14.628906 C 13.695312 14.558594 14.683594 14.03125 14.890625 13.453125 C 15.097656 12.875 15.097656 12.378906 15.035156 12.273438 Z M 15.035156 12.273438 "
      />
    </g></svg
></a>
