<section class="terms-and-privacy app-xxl-container">
  <div class="bold important title">Terms of use</div>

  <div class="bold">1. Welcome to Future Females, Inc.</div>

  <div class="bold">1.1 Introduction:</div>
  <div>
    Future Females, Inc. (“Future Females, Inc.,” “we,” “us,” “our”) provides
    its services (described below) to you through its website located at
    www.futurefemales.co (the “Site”) and through its mobile applications and
    related services (collectively, such services, including any new features
    and applications, and the Site, the “Service(s)”), subject to the following
    Terms and Conditions (the “Terms and Conditions”).PLEASE READ THESE Terms
    and Conditions CAREFULLY, AS THEY GOVERN YOUR USE OF THE SITE AND SERVICES.
  </div>

  <div class="bold">2. Access and Use of the Service</div>
  <div class="bold">2.1 Use description</div>
  <div>
    The Future Females, Inc. service, and any content viewed through our
    service, is solely for your personal and non-commercial use. With your
    Future Females, Inc. purchase we grant you a limited, non-exclusive,
    non-transferable, license to access the Future Females, Inc. content and
    view your course(s) through the service on a streaming-only basis for that
    purpose. Except for the foregoing limited license, no right, title or
    interest shall be transferred to you. You agree not to use the service for
    public performances. Future Females, Inc. may revoke your license at any
    time in its sole discretion. Upon such revocation, you must promptly destroy
    all content downloaded or otherwise obtained through the service, as well as
    copies of such materials, whether made in accordance with these Terms and
    Conditions or otherwise.˝
  </div>

  <div class="bold">2.2 Your Registration Obligations</div>
  <div>
    You may be required to register with Future Females, Inc. in order to access
    and use certain features of the Service. If you choose to register for the
    Service, you agree to provide and maintain true, accurate, current and
    complete information about yourself as prompted by the Service’s
    registration form. Registration data and certain other information about you
    are governed by our <a [routerLink]="'/privacy'">Privacy Policy</a>. We do
    not verify age, so please note that if you are under 13 years of age, you
    are not authorized to use the Service, with or without registering. In
    addition, if you are under 18 years old, you may use the Service, with or
    without registering, only with the approval of your parent or guardian.
  </div>

  <div class="bold">2.3 Member Account, Password and Security</div>
  <div>
    You may never use another's account, and you may not provide another person
    with the username and password to access your account. You should maintain
    control over all of the devices that are used to access the Service. If you
    fail to maintain control of a device, other users may access the Service
    through your account and may be able to access certain of your account
    information. You are fully responsible for any and all activities that occur
    under your password or account, and it is your responsibility to ensure that
    your password remains confidential and secure. You agree to (a) immediately
    notify Future Females, Inc. of any unauthorized use of your password or
    account or any other breach of security, and (b) ensure that you exit from
    your account at the end of each session when accessing the Service. Future
    Females, Inc. will not be liable for any loss or damage arising from your
    failure to comply with this Section.
  </div>

  <div class="bold">2.4 Modifications to Service</div>
  <div>
    Future Females, Inc. reserves the right to modify or discontinue,
    temporarily or permanently, the Service (or any part thereof) with or
    without notice. You agree that Future Females, Inc. will not be liable to
    you or to any third party for any modification, suspension or discontinuance
    of the Service. We have no obligation to retain any of Your Account or
    Submitted Content for any period of time beyond what may be required by
    applicable law.
  </div>

  <div class="bold">2.5 General Practices Regarding Use and Storage</div>
  <div>
    You acknowledge that Future Females, Inc. may establish general practices
    and limits concerning use of the Service, including without limitation the
    maximum period of time that data or other content will be retained by the
    Service and the maximum storage space that will be allotted on Future
    Females, Inc.’s servers on your behalf. You agree that Future Females, Inc.
    has no responsibility or liability for the deletion or failure to store any
    data or other content maintained or uploaded by the Service. You acknowledge
    that Future Females, Inc. reserves the right to terminate accounts that are
    inactive for an extended period of time. You further acknowledge that Future
    Females, Inc. reserves the right to change these general practices and
    limits at any time, in its sole discretion, with or without notice.
  </div>

  <div class="bold">2.6 Mobile Services</div>
  <div>
    The Service may include certain services that are available via a mobile
    device, including (i) the ability to upload content to the Service via a
    mobile device, (ii) the ability to browse the Service and the Site from a
    mobile device and (iii) the ability to access certain features through an
    application downloaded and installed on a mobile device (collectively, the
    “Mobile Services”). To the extent you access the Service through a mobile
    device, your wireless service carrier’s standard charges, data rates and
    other fees may apply. In addition, downloading, installing, or using certain
    Mobile Services may be prohibited or restricted by your carrier, and not all
    Mobile Services may work with all carriers or devices. In using the Mobile
    Services, you may provide your telephone number. By providing your telephone
    number, you consent to receive calls and/or SMS, MMS, or text messages at
    that number. We may share your phone numbers with our affiliates or with our
    service providers (such as customer support, billing or collections
    companies, and text message service providers) who we have contracted with
    to assist us in pursuing our rights or providing our Services under these
    Terms and Conditions, our policies, applicable law, or any other agreement
    we may have with you. You agree these parties may also contact you using
    autodialed or prerecorded calls and text messages, as authorized by us to
    carry out the purposes we have identified above, and not for their own
    purposes. In the event you change or deactivate your mobile telephone
    number, you agree to promptly update your Future Females, Inc. account
    information to ensure that your messages are not sent to the person that
    acquires your old number.
  </div>

  <div class="bold">3. Conditions of Use</div>

  <div class="bold">3.1 User Conduct</div>
  <div>
    You are solely responsible for all code, video, images, information, data,
    text, software, music, sound, photographs, graphics, messages or other
    materials (“content”) that you upload, post, publish or display
    (hereinafter, “upload”) or email or otherwise use via the Service. Future
    Females, Inc. reserves the right to investigate and take appropriate legal
    action against anyone who, in Future Females, Inc.' sole discretion,
    violates this provision, including without limitation, removing the
    offending content from the Service, suspending or terminating the account of
    such violators and reporting you to the law enforcement authorities. You
    agree to not use the Service to:
  </div>

  <ul>
    <li>
      email or otherwise upload any content that (i) infringes any intellectual
      property or other proprietary rights of any party; (ii) you do not have a
      right to upload under any law or under contractual or fiduciary
      relationships; (iii) contains software viruses or any other computer code,
      files or programs designed to interrupt, destroy or limit the
      functionality of any computer software or hardware or telecommunications
      equipment; (iv) poses or creates a privacy or security risk to any person;
      (v) constitutes unsolicited or unauthorized advertising, promotional
      materials, commercial activities and/or sales, “junk mail,” “spam,” “chain
      letters,” “pyramid schemes,” “contests,” “sweepstakes,” or any other form
      of solicitation; (vi) is unlawful, harmful, threatening, abusive,
      harassing, tortious, excessively violent, defamatory, vulgar, obscene,
      pornographic, libelous, invasive of another’s privacy, hateful racially,
      ethnically or otherwise objectionable; or (vii) in the sole judgment of
      Future Females, Inc., is objectionable or which restricts or inhibits any
      other person from using or enjoying the Service, or which may expose
      Future Females, Inc. or its users to any harm or liability of any type;
    </li>
    <li>
      interfere with or disrupt the Service or servers or networks connected to
      the Service, or disobey any requirements, procedures, policies or
      regulations of networks connected to the Service;
    </li>
    <li>
      violate any applicable local, state, national or international law, or any
      regulations having the force of law;
    </li>
    <li>
      impersonate any person or entity, or falsely state or otherwise
      misrepresent your affiliation with a person or entity; solicit personal
      information from anyone under the age of 18;
    </li>
    <li>
      harvest or collect email addresses or other contact information of other
      users from the Service by electronic or other means for the purposes of
      sending unsolicited emails or other unsolicited communications;
    </li>
    <li>
      advertise or offer to sell or buy any goods or services for any business
      purpose that is not specifically authorized;
    </li>
    <li>
      further or promote any criminal activity or enterprise or provide
      instructional information about illegal activities; or
    </li>
    <li>
      obtain or attempt to access or otherwise obtain any materials or
      information through any means not intentionally made available or provided
      for through the Service.
    </li>
  </ul>

  <div class="bold">
    3.2 Special Notice for International Use; Export Controls
  </div>

  <div>
    Software (defined below) available in connection with the Service and the
    transmission of applicable data, if any, is subject to United States export
    controls. No Software may be downloaded from the Service or otherwise
    exported or re-exported in violation of U.S. export laws. Downloading or
    using the Software is at your sole risk. Recognizing the global nature of
    the Internet, you agree to comply with all local rules and laws regarding
    your use of the Service, including as it concerns online conduct and
    acceptable content.
  </div>

  <div class="bold">3.3 Commercial Use</div>

  <div>
    Unless otherwise expressly authorized herein or by Future Females, Inc. in
    writing, you agree not to display, distribute, license, perform, publish,
    reproduce, duplicate, copy, create derivative works from, modify, sell,
    resell, exploit, transfer or upload for any commercial purposes, any portion
    of the Service, use of the Service, or access to the Service. The Service is
    solely for your non-commercial, personal, entertainment use.
  </div>

  <div class="bold">4. Intellectual Property Rights</div>

  <div class="bold">4.1 Service Content, Software and Trademarks</div>

  <div>
    You acknowledge and agree that the Service may contain content or features
    (“Service Content”) that are protected by copyright, patent, trademark,
    trade secret or other proprietary rights and laws. Except as expressly
    authorized by Future Females, Inc., you agree not to modify, copy, frame,
    scrape, rent, lease, loan, sell, distribute or create derivative works based
    on the Service or the Service Content, in whole or in part, except that the
    foregoing does not apply to your own User Content (as defined below) that
    you legally upload to the Service. In connection with your use of the
    Service you will not engage in or use any data mining, robots, scraping or
    similar data gathering or extraction methods. If you are blocked by Future
    Females, Inc. from accessing the Service (including blocking your IP
    address), you agree not to implement any measures to circumvent such
    blocking (e.g., by masking your IP address or using a proxy IP address). Any
    use of the Service or the Service Content other than as specifically
    authorized herein is strictly prohibited. The technology and software
    underlying the Service or distributed in connection therewith are the
    property of Future Females, Inc., our affiliates and our partners (the
    “Software”). You agree not to copy, modify, create a derivative work of,
    reverse engineer, reverse assemble or otherwise attempt to discover any
    source code, sell, assign, sublicense, or otherwise transfer any right in
    the Software. Any rights not expressly granted herein are reserved by Future
    Females, Inc..
  </div>
  <div>
    The Future Females, Inc. name and logos are trademarks and service marks of
    Future Females, Inc. (collectively the “Future Females, Inc. Trademarks”).
    Other Future Females, Inc., product, and service names and logos used and
    displayed via the Service may be trademarks or service marks of their
    respective owners who may or may not endorse or be affiliated with or
    connected to Future Females, Inc.. Nothing in these Terms and Conditions or
    the Service should be construed as granting, by implication, estoppel, or
    otherwise, any license or right to use any of Future Females, Inc.
    Trademarks displayed on the Service, without our prior written permission in
    each instance. All goodwill generated from the use of Future Females, Inc.
    Trademarks will inure to our exclusive benefit.
  </div>

  <div class="bold">4.2 Third Party Material</div>
  <div>
    Under no circumstances will Future Females, Inc. be liable in any way for
    any content or materials of any third parties (including users), including,
    but not limited to, for any errors or omissions in any content, or for any
    loss or damage of any kind incurred as a result of the use of any such
    content. You acknowledge that Future Females, Inc. does not pre-screen
    content, but that Future Females, Inc. and its designees will have the right
    (but not the obligation) in their sole discretion to refuse or remove any
    content that is available via the Service. Without limiting the foregoing,
    Future Females, Inc. and its designees will have the right to remove any
    content that violates these Terms and Conditions or is deemed by Future
    Females, Inc., in its sole discretion, to be otherwise objectionable. You
    agree that you must evaluate, and bear all risks associated with, the use of
    any content, including any reliance on the accuracy, completeness, or
    usefulness of such content.
  </div>

  <div class="bold">4.3 User Content Transmitted Through the Service</div>
  <div>
    With respect to the content or other materials you upload through the
    Service or share with other users or recipients (collectively, “User
    Content”), you represent and warrant that you own all right, title and
    interest in and to such User Content, including, without limitation, all
    copyrights and rights of publicity contained therein, and that you have all
    required rights to post or transmit such content or other materials without
    violation of any third-party rights. By uploading any User Content you
    hereby grant and will grant Future Females, Inc., its affiliated companies
    and partners (including but not limited to Future Females, Inc. instructors,
    practitioners and other third parties providing instructional information
    through the Services, collectively “partners”) a nonexclusive, worldwide,
    royalty free, fully paid up, transferable, sublicensable, perpetual,
    irrevocable license to copy, display, upload, adapt, perform, publish,
    distribute (through multiple tiers of distribution and partnerships), store,
    modify and otherwise use and fully exploit your User Content in any and all
    media, form, medium, technology or distribution methods now known or later
    developed and for any and all purposes (commercial or otherwise).
  </div>

  <div>
    You acknowledge and agree that any questions, comments, suggestions, ideas,
    feedback or other information relevant to the Service (“Submissions”),
    provided by you to Future Females, Inc., its affiliated companies or
    partners are non-confidential and Future Females, Inc., its affiliated
    companies and partners will be entitled to the unrestricted use and
    dissemination of these Submissions for any purpose, commercial or otherwise,
    without acknowledgment or compensation to you.
  </div>

  <div>
    You acknowledge and agree that Future Females, Inc. may preserve content and
    may also disclose content if required to do so by law or in the good faith
    belief that such preservation or disclosure is reasonably necessary to: (a)
    comply with legal process, applicable laws or government requests; (b)
    enforce these Terms and Conditions; (c) respond to claims that any content
    violates the rights of third parties; or (d) protect the rights, property,
    or personal safety of Future Females, Inc., its users and the public. You
    understand that the technical processing and transmission of the Service,
    including your content, may involve (a) transmissions over various networks;
    and (b) changes to conform and adapt to technical requirements of connecting
    networks or devices.
  </div>

  <div class="bold">4.4 Health and Medical Disclaimer</div>

  <div>
    Future Females, Inc. does not provide medical advice. Future Females, Inc.
    only provides general information regarding health, wellness, and physical
    exercise through its Service. You acknowledge and agree that the Service is
    not intended to be, and will not be used as, a substitute for medical
    treatment by a health care professional. You agree to consult your
    healthcare provider before initiating any physical exercises or wellness
    practices, and follow your provider’s advice accordingly. You also
    acknowledge and agree to accept the inherent risks and dangers in
    participating in new or strenuous health, wellness, and physical exercises.
    You agree that Future Females, Inc. will not be liable for any injury, loss,
    or damages arising from your access or practice of the Service’s health,
    wellness, and/or physical exercise content.
  </div>

  <div class="bold">4.5 Copyright Complaints</div>

  <div>
    Future Females, Inc. respects the intellectual property of others. If you
    believe in good faith that any materials on the Sites infringe upon your
    copyrights, please send the following information to Future Females, Inc.’s
    Copyright Designated Agent at
    <a href="mailto:hello@futurefemales.co">hello&#64;futurefemales.co</a>:
  </div>

  <ol>
    <li>
      Identification of the copyrighted work claimed to have been infringed, or,
      if multiple copyrighted works at a single online site are covered by a
      single notification, a representative list of such works at that site;
    </li>
    <li>
      dentification of the material that is claimed to be infringing or to be
      the subject of infringing activity and that is to be removed or access to
      which is to be disabled, and information reasonably sufficient to permit
      the service provider to locate the material;
    </li>
    <li>Your address, telephone number, and email address;</li>
    <li>
      A statement by you that you have a good faith belief that use of the
      material in the manner complained of is not authorized by the copyright
      owner, its agent, or the law;a statement by you, made under penalty of
      perjury, that the information in your notification is accurate, and that
      you are the copyright owner or are authorized to act on the copyright
      owner’s behalf;
    </li>
    <li>
      A physical or electronic signature of the copyright owner or the person
      authorized to act on behalf of the owner of the copyright interest.
    </li>
  </ol>

  <div class="bold">4.6 Counter-Notice</div>
  <div>
    If you believe that your User Content that was removed (or to which access
    was disabled) is not infringing, or that you have the authorization from the
    copyright owner, the copyright owner’s agent, or pursuant to the law, to
    upload and use the content in your User Content, you may send a written
    counter-notice containing the following information to the Copyright Agent:
    your physical or electronic signature; identification of the content that
    has been removed or to which access has been disabled and the location at
    which the content appeared before it was removed or disabled; a statement
    that you have a good faith belief that the content was removed or disabled
    as a result of mistake or a misidentification of the content; and your name,
    address, telephone number, and email address, a statement that you consent
    to the jurisdiction of the federal court located within Northern District of
    California and a statement that you will accept service of process from the
    person who provided notification of the alleged infringement.
  </div>
  <div>
    If a counter-notice is received by the Copyright Agent, Future Females, Inc.
    will send a copy of the counter-notice to the original complaining party
    informing that person that it may replace the removed content or cease
    disabling it in 10 business days. Unless the copyright owner files an action
    seeking a court order against the content provider, member or user, the
    removed content may be replaced, or access to it restored, in 10 to 14
    business days or more after receipt of the counter-notice, at our sole
    discretion.
  </div>

  <div class="bold">4.7 Repeat Infringer Policy</div>

  <div>
    In accordance with the DMCA and other applicable law, Future Females, Inc.
    has adopted a policy of terminating, in appropriate circumstances and at
    Future Females, Inc.'s sole discretion, users who are deemed to be repeat
    infringers. Future Females, Inc. may also at its sole discretion limit
    access to the Service and/or terminate the memberships of any users who
    infringe any intellectual property rights of others, whether or not there is
    any repeat infringement.
  </div>

  <div class="bold">5. Third Party Websites</div>
  <div>
    The Service may provide, or third parties may provide, links or other access
    to other sites and resources on the Internet. Future Females, Inc. has no
    control over such sites and resources and Future Females, Inc. is not
    responsible for and does not endorse such sites and resources. You further
    acknowledge and agree that Future Females, Inc. will not be responsible or
    liable, directly or indirectly, for any damage or loss caused or alleged to
    be caused by or in connection with use of or reliance on any content,
    events, goods or services available on or through any such site or resource.
    Any dealings you have with third parties found while using the Service are
    between you and the third party, and you agree that Future Females, Inc. is
    not liable for any loss or claim that you may have against any such third
    party.
  </div>

  <div class="bold">6. Social Networking Services</div>
  <div>
    You may enable or log in to the Service via various online third party
    services, such as social media and social networking services like Facebook
    or Twitter (“Social Networking Services”). By logging in or directly
    integrating these Social Networking Services into the Service, we make your
    online experiences richer and more personalized. To take advantage of this
    feature and capabilities, we may ask you to authenticate, register for or
    log into Social Networking Services on the websites of their respective
    providers. As part of such integration, the Social Networking Services will
    provide us with access to certain information that you have provided to such
    Social Networking Services, and we will use, store and disclose such
    information in accordance with our
    <a [routerLink]="'/privacy'">Privacy Policy</a>. For more information about
    the implications of activating these Social Networking Services and Future
    Females, Inc.'s use, storage and disclosure of information related to you
    and your use of such services within Future Females, Inc. (including your
    friend lists and the like), please see our
    <a [routerLink]="'/privacy'">Privacy Policy</a>
  </div>

  <div>
    However, please remember that the manner in which Social Networking Services
    use, store and disclose your information is governed solely by the policies
    of such third parties, and Future Females, Inc. shall have no liability or
    responsibility for the privacy practices or other actions of any third party
    site or service that may be enabled within the Service.
  </div>
  <div>
    In addition, Future Females, Inc. is not responsible for the accuracy,
    availability or reliability of any information, content, goods, data,
    opinions, advice or statements made available in connection with Social
    Networking Services. As such, Future Females, Inc. is not liable for any
    damage or loss caused or alleged to be caused by or in connection with use
    of or reliance on any such Social Networking Services. Future Females, Inc.
    enables these features merely as a convenience and the integration or
    inclusion of such features does not imply an endorsement or recommendation.
  </div>

  <div class="title bold important">terms of sale</div>

  <div>
    These terms (these “Terms“) apply to the purchase and sale of products and
    services through our websites (the “Sites“). These Terms are subject to
    change by Future Females, Inc. (referred to as “us“, “we“, or “our” as the
    context may require) without prior written notice at any time, in our sole
    discretion. Any changes to the Terms will be in effect as of the “Last
    Updated Date” referenced on the Sites. You should review these Terms prior
    to purchasing any product or services that are available through the Sites.
    Your continued use of the Sites after the “Last Updated Date” will
    constitute your acceptance of and agreement to such changes.
  </div>

  <div class="bold">1. Order Acceptance, Cancellation and Refunds</div>

  <div>
    You agree that your order is an offer to buy, under these Terms, all
    products and services listed in your order. All orders must be accepted by
    us or we will not be obligated to sell the products or services to you. We
    may choose not to accept orders at our sole discretion, even after we send
    you a confirmation email with your order number and details of the items you
    have ordered.
  </div>
  <div>
    By signing up for a subscription or starting a free trial, you acknowledge
    that you have read and agree to the Future Females Inc Terms & Conditions,
    and you authorise us to charge your designated card or another card on file
    in accordance to the membership plan selected on sign-up, which will
    auto-renew at the designated period (monthly, 3 monthly, 6 monthly or 12
    monthly).
  </div>
  <div>
    If you signed up for a trial, we won’t charge your card until the end of
    your trial period. Your Future Females subscription will be continued until
    cancelled. Your Future Females subscription can be cancelled at any time by
    turning off ‘Auto renew’ on your ‘Membership & Billing’ page inside the
    Future Females platform. Cancelling a subscription stops the monthly
    recurring subscription charge, but doesn’t refund the transaction for the
    current billing period. You can continue to access the subscription until
    the end of the current billing period. Unless otherwise noted during your
    purchase of the Subscription you choose, or as required by applicable law in
    your jurisdiction, there will be no refunds granted after payment has been
    processed, on sign-up or renewal. If you are on a free trial and cancel
    before your trial period is over, you will not be charged.
  </div>
  <div>
    We reserve the right to change Future Females, Inc.'s prices. Your continued
    use of the Service after the price change becomes effective constitutes your
    agreement to pay the changed amount.
  </div>
  <div>
    If you dispute any charges you must let Future Females, Inc. know within
    sixty (60) days after the date that Future Females, Inc. charges you.
  </div>
  <div>
    You will promptly update your account information with any changes (for
    example, a change in your billing address or credit card expiration date)
    that may occur.
  </div>

  <div class="bold">2. Goods Not for Resale or Export</div>
  <div>
    You represent and warrant that you are buying products or services from the
    Sites for your own personal or household use only, and not for downloading,
    recording, repurposing, distribution, resale or export.
  </div>

  <div class="bold">3. Assignment</div>
  <div>
    You will not assign any of your rights or delegate any of your obligations
    under these Terms without our prior written consent. Any purported
    assignment or delegation in violation of this is null and void. No
    assignment or delegation relieves you of any of your obligations under these
    Terms.
  </div>

  <div class="bold">4. No Waivers</div>
  <div>
    The failure by us to enforce any right or provision of these Terms will not
    constitute a waiver of future enforcement of that right or provision. The
    waiver of any right or provision will be effective only if in writing and
    signed by a duly authorized representative of Future Females, Inc.
  </div>

  <div class="bold">5. No Third-Party Beneficiaries</div>
  <div>
    These Terms do not and are not intended to confer any rights or remedies
    upon any person other than you.
  </div>

  <div class="bold">6. Notices</div>
  <div>
    (a). To You. We may provide any notice to you under these Terms by: (i)
    sending a message to the email address you provide or (ii) by posting to the
    Sites. Notices sent by email will be effective when we send the email and
    notices we provide by posting will be effective upon posting. It is your
    responsibility to keep your email address current.
  </div>
  <div>
    (b). To Us. To give us notice under these Terms, you must contact us by
    email to <a href="mailto:hello@futurefemales.co">hello&#64;futurefemales.co</a>.
    We may update the email address for notices to us by posting a notice on the
    Sites. Notices provided by email will be effective one business day after
    they are received.
  </div>

  <div class="bold title important">
    FUTURE FEMALES COMMUNITY FUND COMPETITION
  </div>

  <div>
    The Future Females Community Fund Competition provides an opportunity for
    members to apply and receive a cash prize to support the growth of their
    business. This is an application based competition, with members notified by
    email when the competition period opens and closes, and directed to complete
    their application by Typeform. The winner will be selected by Future Females
    Inc. and notified with 7 days of the competition period closing. Exact
    competition terms, timeline and prize amount will vary over time, and will
    be set at the sole discretion of Future Females Inc. For each competition
    period, the specific terms and conditions will be linked from the
    application form.
  </div>

  <div class="bold title important">AFFILIATE PROGRAM</div>

  <div>
    All members with a current Future Females subscription are automatically
    enrolled in the Future Female Affiliate Program. This program provides
    members an opportunity to earn rewards and/or commission by sharing their
    unique signup link, through which a customer successfully purchases a Future
    Females subscription, as outlined in the Future Females Member Affiliate
    Policy. Future Females retains the right to deactivate your affiliate
    registration at our sole discretion, at which point you will no longer be
    eligible for the rewards and/or commission outlined in the Affiliate Policy.
    If your Future Females subscription is cancelled, by you, or through a
    failure to make a payment due, your Affiliate registration will be
    automatically deactivated.
  </div>

  <div class="title bold important">GENERAL</div>
  <div class="bold">1. Modification to Terms and Conditions</div>
  <div>
    We reserve the right, at our sole discretion, to change or modify portions
    of these Terms and Conditions at any time. If we do this, depending on the
    nature of the change, we will post the changes on this page and indicate at
    the top of this page the date these terms were last revised and/or notify
    you, either through the Services' user interface, in an email notification
    or through other reasonable means and as required by applicable law. Any
    such changes will become effective no earlier than fourteen (14) days after
    they are posted, except that changes addressing new functions of the
    Services or changes made for legal reasons will be effective immediately.
    Your continued use of the Service after the date any such changes become
    effective constitutes your acceptance of the new Terms and Conditions. In
    addition, when using certain Services, you will be subject to any additional
    terms applicable to such Services that may be posted on the Service from
    time to time.
  </div>

  <div class="bold">2. Privacy</div>
  <div>
    At Future Females, Inc., we respect your privacy and are committed to
    protecting it. Our <a [routerLink]="'/privacy'">Privacy Policy</a>. governs
    the processing of all personal data collected from you in connection with
    your purchase of products or services through the Site. By using the
    Service, you consent to our collection and use of personal data as outlined
    therein.
  </div>

  <div class="bold">3. Termination and Severability</div>
  <div>
    You agree that Future Females, Inc., in its sole discretion, may suspend or
    terminate your account (or any part thereof) or use of the Service and
    remove and discard any content within the Service, for any reason,
    including, without limitation, for lack of use or if Future Females, Inc.
    believes that you have violated or acted inconsistently with the letter or
    spirit of these Terms and Conditions. Any suspected fraudulent, abusive or
    illegal activity that may be grounds for termination of your use of Service,
    may be referred to appropriate law enforcement authorities. Future Females,
    Inc. may also in its sole discretion and at any time discontinue providing
    the Service, or any part thereof, with or without notice. You agree that any
    termination of your access to the Service under any provision of these Terms
    and Conditions may be effected without prior notice, and acknowledge and
    agree that Future Females, Inc. may immediately deactivate or delete your
    account and all related information and files in your account and/or bar any
    further access to such files or the Service. Further, you agree that Future
    Females, Inc. will not be liable to you or any third party for any
    termination of your access to the Service.
  </div>
  <div>
    If any provision of these Terms and Conditions is invalid, illegal, void or
    unenforceable, then that provision will be deemed severed from these Terms
    and Conditions and will not affect the validity or enforceability of the
    remaining provisions of these Terms and Conditions.
  </div>

  <div class="bold">4. Disputes Between Users</div>
  <div>
    You agree that you are solely responsible for your interactions with any
    other user in connection with the Service and Future Females, Inc. will have
    no liability or responsibility with respect thereto. Future Females, Inc.
    reserves the right, but has no obligation, to become involved in any way
    with disputes between you and any other user of the Service.
  </div>

  <div class="bold">5. Governing Law and Jurisdiction</div>
  <div>
    All matters arising out of or relating to these Terms are governed by and
    construed in accordance with the internal laws of the U.S.A without giving
    effect to any choice or conflict of law provision or rule (whether of the
    U.S.A or any other jurisdiction) that would cause the application of the
    laws of any jurisdiction other than those of the U.S.A.
  </div>

  <div class="bold">6. Dispute Resolution</div>
  <div>
    If you have any queries or concerns about any purchase under these Terms and
    Conditions please contact us at
    <a href="mailto:hello@futurefemales.co">hello&#64;futurefemales.co</a> and we
    will try to resolve the matter to your satisfaction. Where any complaint or
    dispute is not able to be resolved via informal processes, you agree to the
    following:
  </div>

  <div>
    Any legal suit, action, or proceeding arising out of, or related to, these
    Terms and Conditions shall be instituted exclusively in the courts of the
    U.S.A, although we retain the right to bring any suit, action, or proceeding
    against you for breach of these Terms and conditions in your country of
    residence or any other relevant country. You waive any and all objections to
    the exercise of jurisdiction over you by such courts and to venue in such
    courts.
  </div>
  <div>
    At our sole discretion, we may require You to submit to final and binding
    arbitration in respect of any disputes or claims arising from or in
    connection with these Terms and conditions, including disputes arising from
    or concerning their interpretation, violation, invalidity, non-performance,
    or termination. Such arbitration will be conducted in the U.S.A under the
    ACICA Rules of the U.S.A Centre for International Commercial Arbitration.
  </div>

  <div>
    Where we exercise our right to arbitrate You agree to arbitration on an
    individual basis. In any dispute, NEITHER YOU NOR Future Females, Inc. WILL
    BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS IN
    COURT OR IN ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS
    REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. The
    arbitral tribunal may not consolidate more than one person’s claims, and may
    not otherwise preside over any form of a representative or class proceeding.
    The arbitral tribunal has no power to consider the enforceability of this
    class arbitration waiver and any challenge to the class arbitration waiver
    may only be raised in a court of competent jurisdiction.
  </div>

  <div>
    If any provision of this arbitration agreement is found unenforceable, the
    unenforceable provision will be severed and the remaining arbitration terms
    will be enforced.
  </div>

  <div class="bold">7. Limitation of Liability</div>
  <div>
    YOU EXPRESSLY UNDERSTAND AND AGREE THAT Future Females, Inc. WILL NOT BE
    LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY
    DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO,
    DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
    Future Females, Inc. HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
    WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
    RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE SERVICE; (II) THE
    COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY
    GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES
    RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (III)
    UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV)
    STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (V) ANY OTHER
    MATTER RELATING TO THE SERVICE. IN NO EVENT WILL Future Females, Inc.’S
    TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED
    THE AMOUNT YOU HAVE PAID Future Females, Inc. IN THE LAST SIX (6) MONTHS,
    OR, IF GREATER, ONE HUNDRED DOLLARS ($100).
  </div>
  <div>
    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
    LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
    DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT
    APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR
    WITH THESE Terms and Conditions, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
    DISCONTINUE USE OF THE SERVICE.
  </div>

  <div class="bold">8. Indemnity and Release</div>
  <div>
    To the fullest extent permitted by law, you agree to release, indemnify and
    hold Future Females, Inc. and its affiliates and their officers, employees,
    directors and agent harmless from any from any and all losses, damages,
    expenses, including reasonable attorneys’ fees, rights, claims, actions of
    any kind and injury (including death) arising out of or relating to your use
    of the Service, any User Content, your connection to the Service, your
    violation of these Terms and Conditions or your violation of any rights of
    another.
  </div>

  <div class="bold">9. Disclaimer of Warranties</div>
  <div>
    YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN
    “AS IS” AND “AS AVAILABLE” BASIS. EXCEPT AS OTHERWISE EXPRESSLY PROVIDED
    HEREIN, Future Females, Inc. EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
    WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE
    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
    TITLE AND NON-INFRINGEMENT.Future Females, Inc. MAKES NO WARRANTY THAT (I)
    THE SERVICE WILL MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL BE
    UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE
    OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV)
    THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
    PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR
    EXPECTATIONS.
  </div>

  <div class="bold">10. Force Majeure</div>
  <div>
    We will not be liable or responsible to you, nor be deemed to have defaulted
    or breached these Terms, for any failure or delay in our performance under
    these Terms when and to the extent such failure or delay is caused by or
    results from acts or circumstances beyond our reasonable control, including,
    without limitation, acts of God, flood, fire, earthquake, explosion,
    governmental actions, war, invasion or hostilities (whether war is declared
    or not), terrorist threats or acts, riot or other civil unrest, national
    emergency, revolution, insurrection, pandemic, epidemic, lockouts, strikes
    or other labor disputes (whether or not relating to our workforce), or
    restraints or delays affecting carriers or inability or delay in obtaining
    supplies of adequate or suitable materials, materials or telecommunication
    breakdown or power outage.
  </div>

  <div class="bold">11. Entire Agreement</div>
  <div>
    These Terms and Conditions, and our
    <a [routerLink]="'/privacy'">Privacy Policy</a> will be deemed the final and
    integrated agreement between you and us on the matters contained in these
    Terms and Conditions.
  </div>

  <div class="bold">12. Questions? Concerns? Suggestions?</div>
  <div>
    Please contact us at
    <a href="mailto:hello@futurefemales.co">hello&#64;futurefemales.co</a>
  </div>

  <div>Future Females, Inc.</div>
  <div>9450 SW Gemini Dr</div>
  <div>PMB 64724</div>
  <div>Beaverton, OR</div>
  <div>97008-7105</div>
  <div>USA</div>
</section>
