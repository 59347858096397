import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { concatMap, interval, Subject, takeUntil } from 'rxjs';
import { ChunkFileUploadService } from 'src/app/core/chunk-file-upload.service';
import { FileType } from 'src/app/core/media-api.service';
import {
  DEFAULT_UPLOAD,
  IUpload,
  UploadStatus,
} from 'src/app/core/uploads/upload';
import { UploadApiService } from 'src/app/core/uploads/upload-api.service';
import { IStringResponse } from '../api/string-response';

@Component({
  selector: 'ff-video-upload',
  templateUrl: 'video-upload.component.html',
  styleUrls: [],
  providers: [ChunkFileUploadService],
})
export class VideoUploadComponent implements OnInit, OnDestroy {
  @Input() videoUploadId: string | undefined;
  @Input() entityTitle!: string;

  @Input() public uploadMetadata!: { [key: string]: string };

  public videoUrl: string | undefined;
  public captionUrl: string | undefined;
  public fileChunkUploadFail = false;

  public uploadAzureProgress: IUpload = {
    ...DEFAULT_UPLOAD,
    status: UploadStatus.NotStarted,
  };
  public uploadProgress: IUpload = {
    ...DEFAULT_UPLOAD,
    status: UploadStatus.NotStarted,
  };

  private destroyNotifier = new Subject<void>();
  private fileUploadOnServerDone = new Subject<void>();

  @Output() uploadFinished = new EventEmitter<string>();
  @Output() uploadInProgress = new EventEmitter<string>();
  @Output() captionUploadFinished = new EventEmitter<string>();

  constructor(
    private chunkFileUploadService: ChunkFileUploadService,
    private uploadApiService: UploadApiService,
  ) {}

  public ngOnInit(): void {
    this.checkForCurrentVideoUpload();

    this.chunkFileUploadService.fileUploadError
      .pipe(takeUntil(this.destroyNotifier))
      .subscribe((error) => (this.fileChunkUploadFail = true));
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();

    this.fileUploadOnServerDone.complete();
  }

  public addTeaserRecording(event: any, files: FileList | null): void {
    this.fileChunkUploadFail = false;

    if (!files) {
      return;
    }

    const video = files[0] as File;
    this.onVideoUpdated(video);
    event.target.value = null;
  }

  private checkForCurrentVideoUpload(): void {
    if (!this.videoUploadId) {
      return;
    }

    this.uploadApiService
      .getEventUploadStatus(this.videoUploadId)
      .subscribe((upload: IUpload | undefined) => {
        if (!upload) {
          return;
        }

        if (!!upload.diskFileId) {
          this.uploadProgress.status = UploadStatus.Done;
        }

        this.videoUrl = upload.fileUrl ?? undefined;
        this.captionUrl = upload.captionUrl ?? undefined;

        this.uploadAzureProgress = upload;
        if (
          upload.status === UploadStatus.InProgress ||
          upload.status === UploadStatus.Started
        ) {
          this.uploadInProgress.emit(this.videoUploadId);
          if (!!upload.diskFileId) {
            this.handleAzureUploadInProgress();
          }
        }
      });
  }

  public onVideoUpdated(video: File): void {
    this.uploadAzureProgress = {
      ...DEFAULT_UPLOAD,
      status: UploadStatus.NotStarted,
    };
    this.uploadProgress = {
      ...DEFAULT_UPLOAD,
      status: UploadStatus.NotStarted,
    };

    this.videoUrl = undefined;
    this.captionUrl = undefined;

    this.uploadApiService
      .createUpload({
        uploadMetadata: this.uploadMetadata,
        feature: +this.uploadMetadata['feature'] ?? undefined,
      })
      .subscribe((id: IStringResponse | undefined) => {
        if (!id) {
          return;
        }

        this.videoUploadId = id.field;
        this.uploadInProgress.emit(this.videoUploadId);

        this.chunkFileUploadService.uploadFile(
          id.field,
          video,
          this.entityTitle,
          this.uploadMetadata,
        );

        this.handleUploadProgress();
      });
  }

  private handleUploadProgress(): void {
    this.uploadProgress = {
      id: '',
      userId: '',
      fileType: FileType.Video,
      fileUrl: undefined,
      captionUrl: undefined,
      resourceName: '',
      status: this.fileChunkUploadFail
        ? UploadStatus.Failed
        : UploadStatus.InProgress,
      uploadProgressPercentage: 0,
      captionStatus: UploadStatus.InProgress,
      captionsUploadProgressPercentage: 0,
    };
    this.chunkFileUploadService.uploadProgress
      .pipe(
        takeUntil(this.destroyNotifier),
        takeUntil(this.fileUploadOnServerDone),
      )
      .subscribe((uploadProgress) => {
        this.uploadProgress = {
          ...this.uploadProgress,
          uploadProgressPercentage: uploadProgress[0].progress,
        };

        if (this.uploadProgress.uploadProgressPercentage === 100) {
          this.fileUploadOnServerDone.next();
          this.uploadAzureProgress.diskFileId = 'id';
          this.uploadProgress.status = UploadStatus.Done;
          this.uploadAzureProgress.status = UploadStatus.InProgress;
          this.uploadAzureProgress.uploadProgressPercentage = 0;
          this.handleAzureUploadInProgress();
        }
      });
  }

  private handleAzureUploadInProgress(): void {
    interval(20000)
      .pipe(
        concatMap((_) =>
          this.uploadApiService.getEventUploadStatus(this.videoUploadId!),
        ),
        takeUntil(this.captionUploadFinished),
        takeUntil(this.destroyNotifier),
      )

      .subscribe((upload: IUpload | undefined) => {
        this.handleUploadProgressChanged(upload);
      });
  }

  private handleUploadProgressChanged(upload: IUpload | undefined): void {
    if (!upload) {
      this.uploadFinished.emit();
      return;
    }

    this.uploadAzureProgress = upload;

    if (
      upload.status === UploadStatus.Failed ||
      upload.captionStatus === UploadStatus.Failed
    ) {
      this.uploadFinished.emit();
      this.captionUploadFinished.emit();
    }

    if (upload.status === UploadStatus.Done && !!upload.fileUrl) {
      this.videoUrl = upload.fileUrl!;
      this.uploadFinished.emit(upload.fileUrl);
    }

    if (upload.captionStatus === UploadStatus.Done && !!upload.captionUrl) {
      this.captionUrl = upload.captionUrl;
      this.captionUploadFinished.emit(upload.captionUrl);
    }
  }
}
