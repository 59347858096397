import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatRadioChange } from '@angular/material/radio';
import { DateTime } from 'luxon';
import { IInterval, INTERVALS } from 'src/app/shared/date/intervals';

@Component({
  selector: 'calendar-filter-mobile',
  templateUrl: 'mobile.component.html',
  styleUrls: ['mobile.component.scss'],
})
export class CalendarFilterMobileComponent implements OnInit {
  @Input() public selectedInterval!: IInterval;

  public startsAfterUtc: string | undefined;
  public startsBeforeUtc: string | undefined;

  public intervals = INTERVALS;
  public selectedRangeValue: DateRange<Date> | undefined;

  @Output() interval = new EventEmitter<{ selectedInterval: IInterval }>();

  constructor() {}

  public ngOnInit(): void {
    const selectedInterval = this.selectedInterval;

    if (selectedInterval.id !== 'custom-date-range') {
      this.selectedRangeValue = undefined;
      this.startsAfterUtc = undefined;
      this.startsBeforeUtc = undefined;
    } else {
      this.startsAfterUtc = selectedInterval.interval.startsAfterUtc;
      this.startsBeforeUtc = selectedInterval.interval.startsBeforeUtc;
    }
  }

  public onChange(event: MatRadioChange): void {
    this.selectedInterval = event.value;
    if (this.selectedInterval.id !== 'custom-date-range') {
      this.selectedRangeValue = undefined;
    }
    this.interval.emit({ selectedInterval: event.value });
  }

  public selectedChange(dateRange: DateRange<Date>): void {
    this.selectedRangeValue = dateRange;

    if (!this.selectedRangeValue) {
      return;
    }

    let startsAfterDate = this.selectedRangeValue.start;
    let startsBeforeDate = this.selectedRangeValue.end;

    if (!startsAfterDate) {
      return;
    }

    const startsAfterUtc = startsAfterDate.toISOString();
    let startsBeforeUtc = '';

    if (!startsBeforeDate) {
      startsBeforeUtc = DateTime.fromJSDate(startsAfterDate!)
        .plus({ days: 1 })
        .toJSDate()
        .toISOString();
    } else {
      startsBeforeUtc = startsBeforeDate.toISOString();
    }

    const selectedInterval = INTERVALS.find(
      (i) => i.id === 'custom-date-range',
    )!;
    selectedInterval.interval = {
      startsBeforeUtc,
      startsAfterUtc,
    };
    this.interval.emit({ selectedInterval });
  }
}
