import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import {
  filter,
  fromEvent,
  interval,
  map,
  Observable,
  Subject,
  Subscription,
  takeUntil,
  throttleTime,
} from 'rxjs';

import { UsersService } from '../../user/users.service';
import { INavigationItem } from './navigation/navigation';
import {
  NavigationService,
  NAVIGATION_ITEMS,
} from './navigation/navigation-service';
import { NavigationEnd, Router } from '@angular/router';
import { UserAdmin } from 'src/app/user/admin';
import { NotificationService } from '../notifications/notification.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { APP_DEFAULT_USER_PROFILE } from '../../shared/app-constants';
import { IUserAPI } from 'src/app/user/user';
import { MatTabGroupScrollDirective } from '../../shared/mat-tab-group.directive';
import {
  CompleteProgressService,
  CompleteProgressSummary,
} from '../../user/complete-progress.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() public isUserLoggedIn: boolean = false;

  public primaryColor: string = '#161616';
  public accentColor: string = '#ff3787';

  public navigationItems!: Observable<INavigationItem[]>;
  public midDeviceNavigationItems!: Observable<{
    main: INavigationItem[];
    moreOptions: INavigationItem[];
  }>;

  public loginDisplay: boolean = false;

  public name: string | undefined = undefined;
  public profilePic: string = APP_DEFAULT_USER_PROFILE;
  public timestamp = 0;

  public isUserAdmin: boolean = false;

  public isAdminForm = new UntypedFormControl(true);
  public selectedTab = '';

  public userHasAdminRole: boolean = false;

  public notificationCount = 0;
  public lastestPinnedMenu?: HTMLElement;

  public profileCompleteCard?: CompleteProgressSummary;

  @Output() public notificationClicked = new EventEmitter<void>();
  @Output() public logoutClicked = new EventEmitter<void>();

  private readonly _destroying$ = new Subject<void>();

  private matTabGroupSubscriptions: Subscription[] = [];

  public overlayRef!: OverlayRef;
  public isMobilenavOpen: boolean = false;

  public moreOptionActive = false;

  constructor(
    private navigationService: NavigationService,
    private usersService: UsersService,
    private router: Router,
    private notificationService: NotificationService,
    private elementRef: ElementRef,
    private completeProgressService: CompleteProgressService,
  ) {}

  public ngOnInit(): void {
    this.navigationItems = this.navigationService.getNavigationItems();
    this.midDeviceNavigationItems = this.navigationService
      .getNavigationItems()
      .pipe(
        map((navItems) => ({
          main: navItems.filter((navItem) => !navItem.goesInMoreMenu),
          moreOptions: navItems.filter((navItem) => navItem.goesInMoreMenu),
        })),
      );

    this.notificationService.notificationCount
      .pipe(takeUntil(this._destroying$))
      .subscribe((count: number) => (this.notificationCount = count));

    this.router.events
      .pipe(
        takeUntil(this._destroying$),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event: any) => {
        const pathUrl = event.urlAfterRedirects.split('/');
        const routeName = NAVIGATION_ITEMS.map((ni) =>
          ni.name.toLowerCase(),
        ).findIndex((name) => name === pathUrl[1]);
        this.selectedTab =
          routeName > -1 ? NAVIGATION_ITEMS[routeName].name : '';
        this.moreOptionActive =
          NAVIGATION_ITEMS.findIndex(
            (ni) => ni.goesInMoreMenu && ni.name === this.selectedTab,
          ) > -1;
      });

    this.usersService.userAdmin
      .pipe(takeUntil(this._destroying$))
      .subscribe((admin: UserAdmin) => {
        this.userHasAdminRole = admin.isAnyTypeOfAdmin;
      });

    this.usersService.userApiData
      .pipe(takeUntil(this._destroying$))
      .subscribe((user: IUserAPI | undefined) => {
        this.name = !!user ? `${user.firstName} ${user.lastName}` : '';

        if (
          !user ||
          !user.profilePictureUrl ||
          (user && user.profilePictureUrl === '')
        ) {
          this.profilePic = APP_DEFAULT_USER_PROFILE;
          return;
        }

        this.profilePic = user.profilePictureUrl!;
        this.timestamp = new Date().getTime();
      });

    this.usersService.isAdminInterfaceShown
      .pipe(takeUntil(this._destroying$))
      .subscribe((isAdmin) => {
        this.isUserAdmin = isAdmin;
        this.isAdminForm.setValue(isAdmin, { emitEvent: false });
      });

    this.isAdminForm.valueChanges
      .pipe(takeUntil(this._destroying$))
      .subscribe((isAdmin) => {
        localStorage.setItem(
          'admin_view_preference',
          isAdmin ? 'true' : 'false',
        );
        this.usersService.setAdmin(isAdmin);
      });

    this.completeProgressService.completionObservable
      .pipe(takeUntil(this._destroying$))
      .subscribe((completeProgressSummary) => {
        this.profileCompleteCard =
          completeProgressSummary?.['profileCompleteCard'];
      });
  }

  public ngAfterViewInit(): void {
    this.subscribePinnedMenuChanges();
  }

  public ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  public onNotificationClicked(): void {
    this.notificationClicked.emit();
  }

  public logout(): void {
    this.logoutClicked.emit();
  }

  public navigate(...paths: string[]): void {
    this.router.navigate(paths);
  }

  public openInNewTab(url?: string): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }

  /**
   * Angular does not support dynamic directives.
   * Therefore, when the mat-tab is moved to header, we attach directive methods dinamically
   */
  private subscribePinnedMenuChanges() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        if (
          !this.elementRef.nativeElement.querySelector('mat-tab-header') &&
          this.lastestPinnedMenu
        ) {
          this.detachMatTabHeaderDirective();
        }
      });

    interval(100)
      .pipe(takeUntil(this._destroying$))
      .subscribe(() => {
        const matTabHeader =
          this.elementRef.nativeElement.querySelector('mat-tab-header');

        if (
          !!matTabHeader === !!this.lastestPinnedMenu ||
          matTabHeader === this.lastestPinnedMenu
        ) {
          return;
        }

        if (matTabHeader) {
          this.attachMatTabHeaderDirective(matTabHeader);
        } else {
          this.detachMatTabHeaderDirective();
        }
      });
  }

  private attachMatTabHeaderDirective(matTabHeader: HTMLElement) {
    this.lastestPinnedMenu = matTabHeader;

    const directive = new MatTabGroupScrollDirective({
      nativeElement: matTabHeader,
    });

    directive.scrollToActiveTab();

    this.matTabGroupSubscriptions.push(
      fromEvent(matTabHeader, 'click')
        .pipe(takeUntil(this._destroying$), throttleTime(100))
        .subscribe((event) => {
          const target = event.target as HTMLElement;
          if (
            target.classList.contains('mat-tab-label-content') ||
            target.classList.contains('mat-tab-label')
          ) {
            directive.selectedTabChange();
          } else {
            directive.click(event);
          }
        }),
    );
  }

  private detachMatTabHeaderDirective() {
    this.lastestPinnedMenu = undefined;

    this.matTabGroupSubscriptions.forEach((subscription, index) => {
      subscription.unsubscribe();
      this.matTabGroupSubscriptions.splice(index, 1);
    });
  }
}
