<div class="position-relative">
  <div
    #labelListRef
    class="labels d-flex flex-nowrap position-absolute w-100"
    #elemt
    (panleft)="onPan($event, elemt)"
    (panright)="onPan($event, elemt)"
    (panend)="onPanEnd($event, elemt)"
    (panstart)="onPanStart($event)"
  >
    @if (labelViewList && labelViewList.length) {
    <div
      class="me-2 mt-2 py-2 px-3 chip text-nowrap pointer position-relative"
      [class.selected]="isAllSelected"
      [class.pointer]="haveSomethingUsed"
      [class.disabled]="!haveSomethingUsed || !isLoaded"
      (click)="selectAll()"
    >
      <div>{{ allLabel }}</div>
    </div>
    } @for (label of labelViewList; track label; let index = $index) {
    <div
      class="me-2 mt-2 py-2 px-3 chip text-nowrap pointer position-relative"
      [class.selected]="isSelected(index)"
      [class.pointer]="label.isUsed"
      [class.disabled]="!label.isUsed || !isLoaded"
      (click)="toggleLabelState(index, !label.isUsed)"
    >
      <div>{{ label.title }}</div>
      @if (label.isRecentlyCreated) {
      <div class="recently-added"></div>
      }
    </div>
    }
  </div>
</div>
