<div class="location-dropdown__field-filters-shared">
  <div
    class="location-field d-flex align-items-center"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="locationPick"
  >
    <div class="d-flex align-items-center">
      <mat-icon class="label me-2">room</mat-icon>
      <span>{{ selectedLocation.title }}</span>
    </div>
  </div>
  <mat-menu
    #locationPick="matMenu"
    class="location-dropdown-menu__field-filters-shared p-3"
  >
    <location-filter
      [cityCountries]="cityCountries"
      [selectedLocation]="selectedLocation"
      [genericLocationResultCount]="genericLocationResultCount"
      (locationChange)="onLocationChange($event)"
    ></location-filter>
  </mat-menu>
</div>
