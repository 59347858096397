import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DateHelpers } from '../../date/date-helpers';
import { IDiscussionSummary } from '../discussion';
import { Router } from '@angular/router';

@Component({
  selector: 'discussion-card',
  templateUrl: './discussion-card.component.html',
  styleUrls: ['./discussion-card.component.scss'],
})
export class DiscussionCardComponent implements OnInit, OnChanges {
  @Input()
  public discussion?: IDiscussionSummary;
  @Input()
  public feature: string = '';

  public timeAgo?: string;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['discussion']) {
      const newValue = changes['discussion']
        ?.currentValue as IDiscussionSummary;

      const lastUpdate =
        newValue?.lastDiscussionItemDateUtc || newValue?.createdAtUtc;
      this.timeAgo = lastUpdate
        ? DateHelpers.timeAgo(
            new Date(DateHelpers.getParsedIsoFromServer(lastUpdate)),
          )
        : '-';
    }
  }

  public navigateToDiscussions(): void {
    this.router.navigate([`/discussions`]);
  }
}
