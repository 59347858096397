<button mat-icon-button [url]="url" shareButton="twitter" #twtBtn="shareButton" [description]="description">
  <!-- Neither boostrap / material has the same icon -->
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3 2.55011C13.54 2.54999 12.8103 2.84824 12.2679 3.38068C11.7256 3.91313 11.4139 4.63721 11.4 5.39711L11.372 6.97211C11.3704 7.05668 11.3509 7.13994 11.3148 7.21644C11.2787 7.29294 11.2269 7.36094 11.1627 7.41599C11.0985 7.47105 11.0233 7.5119 10.9422 7.53588C10.8611 7.55985 10.7758 7.56641 10.692 7.55511L9.13102 7.34311C7.07702 7.06311 5.10902 6.11711 3.22102 4.54411C2.62302 7.85411 3.79102 10.1471 6.60402 11.9161L8.35102 13.0141C8.43403 13.0663 8.50299 13.138 8.55187 13.223C8.60075 13.308 8.62806 13.4036 8.63141 13.5016C8.63477 13.5996 8.61407 13.6969 8.57111 13.785C8.52816 13.8731 8.46426 13.9494 8.38502 14.0071L6.79302 15.1701C7.74002 15.2291 8.63902 15.1871 9.38502 15.0391C14.103 14.0971 17.24 10.5471 17.24 4.69111C17.24 4.21311 16.228 2.55011 14.3 2.55011ZM9.40002 5.36011C9.41747 4.39616 9.71891 3.45878 10.2666 2.66534C10.8142 1.87189 11.5838 1.25763 12.4789 0.899481C13.3741 0.541329 14.3549 0.455194 15.2988 0.651862C16.2426 0.848529 17.1074 1.31926 17.785 2.00511C18.496 2.00011 19.101 2.18011 20.454 1.36011C20.119 3.00011 19.954 3.71211 19.24 4.69111C19.24 12.3331 14.543 16.0491 9.77702 17.0001C6.50902 17.6521 1.75702 16.5811 0.39502 15.1591C1.08902 15.1051 3.90902 14.8021 5.53902 13.6091C4.16002 12.7001 -1.32898 9.47011 2.27802 0.786115C3.97102 2.76311 5.68802 4.10911 7.42802 4.82311C8.58602 5.29811 8.87002 5.28811 9.40102 5.36111L9.40002 5.36011Z"
      fill="#09121F"
    />
  </svg>
</button>
