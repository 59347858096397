import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { WithStyles, StyleRenderer, LyClasses } from '@alyle/ui';
import {
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperEvent,
  ImgCropperErrorEvent,
} from '@alyle/ui/image-cropper';
import { LySliderChange } from '@alyle/ui/slider';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { NgxImageCompressService } from 'ngx-image-compress';

import { ImageHelper } from './helper';

export type AppThemeVariables = MinimaLight & MinimaDark;

@Component({
  selector: 'image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  providers: [StyleRenderer],
})
export class ImageCropperComponent implements WithStyles, OnInit {
  public classes!: LyClasses<any>;
  public scale?: number;
  public ready?: boolean;
  public minScale?: number;
  public imageSelected = false;

  @ViewChild(LyImageCropper, { static: true })
  public readonly cropper?: LyImageCropper;

  @Input()
  public cropperImage: string = '';
  @Output()
  public cropperImageChange = new EventEmitter<string>();

  @Output()
  public croppedImage = new EventEmitter<{
    extension: string;
    image: string;
  }>();

  @Output()
  public onUpload = new EventEmitter<string | undefined>();

  @Input()
  public config: ImgCropperConfig = {
    width: 150, // Default `250`
    height: 150, // Default `200`
    type: 'image/jpeg',
    round: true,
  };

  @Input()
  public height!: number;

  @Input()
  public hideCTA = false;

  @Input()
  public chooseImageLabel: string = 'Upload new';

  @Input()
  public buttonStyle: string = 'raised';

  constructor(
    readonly sRenderer: StyleRenderer,
    private imageCompress: NgxImageCompressService,
  ) {}

  public ngOnInit() {
    if (!this.height) {
      this.height = this.config.height;
    }

    this.classes = ImageHelper.imageRatioToStyles(this.sRenderer, this.height);
  }

  public async onCropped(event: ImgCropperEvent) {
    const reducedImg = await this.imageCompress.compressFile(
      event.dataURL!,
      -1,
      100,
      50,
    );

    this.croppedImage.emit({
      extension: 'jpeg',
      image: reducedImg,
    });
  }

  public onLoaded(event: ImgCropperEvent) {
    this.cropperImageChange.emit(event.originalDataURL);
  }

  public onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
  }

  public onSliderInput(event: LySliderChange) {
    this.scale = event.value as number;
  }

  public selectImage(event: any) {
    this.imageSelected = true;

    setTimeout(() => {
      if (!this.cropper) {
        return;
      }

      this.cropper.selectInputEvent(event);
    }, 10);
  }

  public setAsReady() {
    this.ready = true;
  }

  public setUnready() {
    this.ready = false;
  }

  public cancel() {
    this.cropperImageChange.emit('');
    this.setUnready();
    this.imageSelected = false;
  }
}
