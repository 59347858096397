import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IStringResponse } from '../shared/api/string-response';

import { API_BASE_URL } from '../shared/app-constants';
import { FfLanguage } from '../shared/models/language';

export enum FileType {
  Image,
  Document,
  Video
}

export interface IVideoUploadResult {
  fileUrl: string;
  captionUrl: string;
}

export enum UrlType {
  Video = 0,
  Caption = 1,
}

export enum EntityType {
  Event = 0,
  Course = 1,
  Lesson = 2,
}

@Injectable()
export class MediaApiService {
  constructor(
    private httpClient: HttpClient,
    @Inject(API_BASE_URL) private apiBaseUrl: string,
  ) {}

  public uploadMediaAsync(
    file: File,
    type: FileType,
    prefix?: string,
    connectionId?: string,
  ): Observable<IStringResponse> {
    const headers = connectionId
      ? new HttpHeaders()
          .set('responseType', 'text/plain')
          .set('accept', 'text/plain')
          .set('X-connectionid', connectionId)
      : new HttpHeaders()
          .set('responseType', 'text/plain')
          .set('accept', 'text/plain');

    const uploadData = new FormData();
    uploadData.append('file', file, file.name);

    if (!!prefix) {
      uploadData.append(`resourceNamePrefix`, prefix);
    }

    return this.httpClient.post<IStringResponse>(
      this.getMediaUrl(type),
      uploadData,
      { headers },
    );
  }

  public uploadVideoAsync(
    file: File,
    language: FfLanguage,
    entityId: string,
    entityType: EntityType,
    childEntityId?: number,
    connectionId?: string,
  ): Observable<IVideoUploadResult> {
    const headers = connectionId
      ? new HttpHeaders()
          .set('responseType', 'text/plain')
          .set('accept', 'text/plain')
          .set('X-connectionid', connectionId)
      : new HttpHeaders()
          .set('responseType', 'text/plain')
          .set('accept', 'text/plain');

    const uploadData = new FormData();

    uploadData.append(`file`, file, file.name);
    const queryParams = this.appendQueryParams(language, entityId, entityType, childEntityId);

    return this.httpClient.post<IVideoUploadResult>(
      `${this.apiBaseUrl}/Media/videos?${queryParams}`,
      uploadData,
      { headers },
    );
  }

  private appendQueryParams(
    language: FfLanguage,
    entityId: string,
    entityType: EntityType,
    childEntityId?: number,
  ): string {
    let queryParams = `Language=${language}&EntityId=${entityId}&EntityType=${entityType}`;

    if (childEntityId) {
      queryParams = `${queryParams}&ChildEntityId=${childEntityId}`
    }

    return queryParams;
  }

  private getMediaUrl(type: FileType): string {
    switch (type) {
      case FileType.Image:
        return `${this.apiBaseUrl}/Media/images`;
      case FileType.Document:
        return `${this.apiBaseUrl}/Media/documents`;
      case FileType.Document:
        throw Error('Video not suported on this method');
    }
    return '';
  }
}
