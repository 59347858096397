import { IEnvironment } from './environment.model';

export const environment: IEnvironment = {
  production: false,
  appBaseUrl: 'https://web-dev.futurefemales.co',
  apiBaseUrl: 'https://api-dev.futurefemales.co/api',
  redirectUrl: 'https://web-dev.futurefemales.co/login',
  enableServiceWorker: true,
  appInsights: {
    instrumentationKey: '3328cbfb-de4c-4908-ac9a-c40005973e84',
  },
  stripe: {
    clientKey: 'pk_test_u243okiH6LsnmkTlwgZaU256',
    accountId: 'acct_1BaBUSLqu8GAArq4',
  },
  mediaKind: {
    clientSecret: 'd0167b1c-9767-4287-9ddc-e0fa09d31e02',
  },
  chunkSize: 1073741824,
};
