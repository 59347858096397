import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'range-calendar',
  templateUrl: 'range-calendar.component.html',
  styleUrls: ['range-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RangeCalendarComponent implements OnInit {
  public customHeader = CustomHeader;
  public selectedRangeValue: DateRange<Date> | undefined;

  @Input() startsAfterUtc!: string | undefined;
  @Input() startsBeforeUtc!: string | undefined;

  @Output() interval = new EventEmitter<DateRange<Date>>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['startsAfterUtc'] && !changes['startsAfterUtc'].firstChange) {
      const startsAfterUtc = this.startsAfterUtc
        ? new Date(this.startsAfterUtc)
        : null;
      this.selectedChange(startsAfterUtc);
    }
    if (changes['startsBeforeUtc'] && !changes['startsBeforeUtc'].firstChange) {
      const startsBeforeUtc = this.startsBeforeUtc
        ? new Date(this.startsBeforeUtc)
        : null;
      this.selectedChange(startsBeforeUtc);
    }
  }

  public ngOnInit(): void {
    const startsAfterUtc = this.startsAfterUtc
      ? new Date(this.startsAfterUtc)
      : null;
    const startsBeforeUtc = this.startsBeforeUtc
      ? new Date(this.startsBeforeUtc)
      : null;
    this.selectedChange(startsAfterUtc);
    this.selectedChange(startsBeforeUtc);
  }

  public selectedChange(lastDateSelected: Date | null): void {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(lastDateSelected, null);
    } else {
      const start = this.selectedRangeValue.start;
      const end = lastDateSelected;

      if (!end || end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }

    this.interval.emit(this.selectedRangeValue);
  }
}

@Component({
  selector: 'custom-header',
  styles: [
    `
      .header {
        display: flex;
        align-items: center;
        padding: 0.5em;
      }

      .header-label {
        flex: 1;
        height: 1em;
        font-weight: 500;
        text-align: center;
      }
    `,
  ],
  template: `
    <div class="header">
      <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="header-label">{{ periodLabel }}</span>
      <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearLabel,
      )
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
