import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { ICityCountry } from 'src/app/shared/models/location';
import {
  IEventFilterResultCount,
  ILocationOption,
  LOCATION_OPTIONS,
} from './location-filter';

@Component({
  selector: 'location-filter',
  templateUrl: 'location-filter.component.html',
  styleUrls: ['location-filter.component.scss'],
})
export class LocationFilterComponent implements OnInit, OnChanges {
  @Input() public cityCountries!: ICityCountry[];
  @Input() public selectedLocation!: ILocationOption;
  @Input() public genericLocationResultCount!: IEventFilterResultCount;

  public locationOptions: ILocationOption[] = [];

  @Output() public locationChange = new EventEmitter<{
    selectedLocation: ILocationOption;
  }>();

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['cityCountries'] && !changes['cityCountries'].firstChange) {
      this.setLocationOptions();
    }
  }

  public ngOnInit(): void {
    this.setLocationOptions();
  }

  public onChange(event: MatRadioChange): void {
    this.selectedLocation = event.value;
    this.locationChange.emit({ selectedLocation: this.selectedLocation });
  }

  private setLocationOptions(): void {
    const nearMeCount = this.genericLocationResultCount
      ? this.genericLocationResultCount.nearMeEventsCount
      : 0;
    const onlineCount = this.genericLocationResultCount
      ? this.genericLocationResultCount.onlineEventsCount
      : 0;
    const onlineAndNearMe = nearMeCount + onlineCount;

    const locationUpdates = LOCATION_OPTIONS.map((option) => {
      let updatedOption = option;
      if (option.id === 'online-and-near') {
        updatedOption.value.usedCount = onlineAndNearMe;
      }
      if (option.id === 'only-online') {
        updatedOption.value.usedCount = onlineCount;
      }
      if (option.id === 'only-near-me') {
        updatedOption.value.usedCount = nearMeCount;
      }
      return updatedOption;
    });

    this.locationOptions = [
      ...locationUpdates,
      ...(this.cityCountries || []).map((cityCountry: ICityCountry) => ({
        id: `${cityCountry.city}-${cityCountry.country}`,
        title: cityCountry.city,
        value: cityCountry,
      })),
    ];
  }
}
