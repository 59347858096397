import {
  Component,
  AfterViewInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  SimpleChanges,
  OnChanges,
  OnDestroy,
} from '@angular/core';

import * as MKPlayerSDK from '@mediakind/mkplayer';
import { Subject } from 'rxjs';
import {
  DEFAULT_UPLOAD,
  IUpload,
  UploadStatus,
} from 'src/app/core/uploads/upload';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'video-player-mk',
  templateUrl: './video-player-mk.component.html',
  styleUrls: ['./video-player-mk.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerMK implements AfterViewInit, OnChanges, OnDestroy {
  @Input() public videoUrl: string | undefined;
  @Input() public currentTime?: number;
  @Input() public isAdmin!: boolean;
  @Input() public showNextLessonBox = false;
  @Input() public nextVideoTitle: string | null = null;
  @Input() public autoplay: boolean = true;
  @Input() public mediaKindPlayer: any;

  @Output() public videoUploaded = new EventEmitter<File>();
  @Output() public goToNext = new EventEmitter<void>();
  @Output() public videoLoaded = new EventEmitter<boolean>();
  @Output() public currentTimeChange = new EventEmitter<number>();
  @Output() public videoEnded = new EventEmitter<boolean>();

  @Output() public mediaKindPlayerChange = new EventEmitter<any>();

  public videoWasStarted = false;
  public fullscreenOpened = false;

  public uploadAzureProgress: IUpload = {
    ...DEFAULT_UPLOAD,
    status: UploadStatus.NotStarted,
  };
  public uploadProgress: IUpload = {
    ...DEFAULT_UPLOAD,
    status: UploadStatus.NotStarted,
  };

  private nextVideoClickListener: any;
  private readonly destroying$ = new Subject<void>();

  constructor(private renderer: Renderer2) {}

  public ngOnChanges(changes: SimpleChanges): void {
    const _currentTime = changes['currentTime'];
    const playerCurrentTime = this.mediaKindPlayer?.getCurrentTime() ?? 0;
    if (_currentTime) {
      if (Math.abs(_currentTime.currentValue - playerCurrentTime) > 5) {
        this.mediaKindPlayer?.seek(_currentTime.currentValue);
      }
    }
  }

  ngAfterViewInit(): void {
    // 1. Grab the video container
    const videoContainer = document.getElementById('video-container');

    // 2. Prepare the player configuration
    const playerConfig = {
      key: environment.mediaKind!.clientSecret,
      playback: {
        autoplay: this.autoplay,
      },
      ui: true,
      // Subscribe to player events
      events: {
        [MKPlayerSDK.MKPlayerEvent.TimeChanged]: (event: any) => {
          this.currentTimeChange.next(event.time);
        },
        [MKPlayerSDK.MKPlayerEvent.ViewModeChanged]: (event: any) => {
          this.fullscreenOpened = event.to === 'fullscreen';

          if (!this.nextVideoTitle) {
            return;
          }

          if (!this.fullscreenOpened) {
            this.removeDivForNextVideoBoxOnFullscreen();
          } else {
            this.createDivForNextVideoBoxOnFullscreen();
          }
        },
        [MKPlayerSDK.MKPlayerEvent.Muted]: (event: any) => {
          localStorage.setItem('volume_preference', 'muted');
        },
        [MKPlayerSDK.MKPlayerEvent.Unmuted]: (event: any) => {
          localStorage.setItem('volume_preference', 'unmuted');
        },
        [MKPlayerSDK.MKPlayerEvent.PlaybackFinished]: (event: any) => {
          this.videoEnded.emit(true);
        },
      },
    };

    // 3. Initialize the player with video container and player configuration
    this.mediaKindPlayer = new MKPlayerSDK.MKPlayer(
      videoContainer!,
      playerConfig,
    );

    const sourceConfig = {
      hls: this.replaceFormatInUrl(this.videoUrl ?? ''),
      dash: this.videoUrl,
    };

    this.mediaKindPlayer.load(sourceConfig).then(() => {
      if (this.currentTime) {
        this.mediaKindPlayer?.seek(this.currentTime);
      }

      const volumePreference = localStorage.getItem('volume_preference');

      this.mediaKindPlayerChange.emit(this.mediaKindPlayer);
      this.videoLoaded.emit(true);

      if (volumePreference === 'muted') {
        this.mediaKindPlayer.mute();
      } else if (volumePreference === 'unmuted') {
        this.mediaKindPlayer.unmute();
      }
    });
  }

  public addRecording(files: FileList | null): void {
    if (!files) {
      return;
    }

    const video = files[0];

    this.videoUrl = undefined;
    this.videoUploaded.emit(video);
  }

  private createDivForNextVideoBoxOnFullscreen(): void {
    if (!this.nextVideoTitle || !this.showNextLessonBox) {
      return;
    }
    const div1 = this.renderer.createElement('div');
    const div2 = this.renderer.createElement('div');
    const div3 = this.renderer.createElement('div');
    const div4 = this.renderer.createElement('div');
    const div5 = this.renderer.createElement('div');
    const div6 = this.renderer.createElement('div');
    const icon = this.renderer.createElement('mat-icon');

    this.renderer.appendChild(
      icon,
      this.renderer.createText('play_circle_outline'),
    );
    this.renderer.addClass(icon, 'mat-icon');
    this.renderer.addClass(icon, 'material-icons');
    this.renderer.addClass(icon, 'mt-32px');

    this.renderer.addClass(div1, 'next-lesson-fullscreen');
    this.renderer.addClass(div2, 'py-2');
    this.renderer.addClass(div2, 'px-4');
    this.renderer.addClass(div2, 'd-flex');
    this.renderer.addClass(div2, 'align-items-center');
    this.renderer.addClass(div3, 'me-4');
    this.renderer.addClass(div3, 'me-4');
    this.renderer.addClass(div5, 'mat-caption');
    this.renderer.addClass(div5, 'text-gray');
    this.renderer.addClass(div6, 'mat-body-2');
    this.renderer.addClass(div6, 'mt-2');

    this.renderer.appendChild(div5, this.renderer.createText('Up next'));
    this.renderer.appendChild(
      div6,
      this.renderer.createText(this.nextVideoTitle),
    );
    this.renderer.appendChild(div4, div5);
    this.renderer.appendChild(div4, div6);
    this.renderer.appendChild(div3, icon);
    this.renderer.appendChild(div2, div3);
    this.renderer.appendChild(div2, div4);
    this.renderer.appendChild(div1, div2);

    const videoPlayer = document.getElementsByClassName('mk-video-player')[0];

    this.renderer.appendChild(videoPlayer, div1);

    this.nextVideoClickListener = this.renderer.listen(div1, 'click', (evt) => {
      this.goToNext.emit();
    });
  }

  private removeDivForNextVideoBoxOnFullscreen(): void {
    if (!this.nextVideoTitle || !this.showNextLessonBox) {
      return;
    }

    const nextLessonFullscreenDiv = document.getElementsByClassName(
      'next-lesson-fullscreen',
    )[0];

    if (!nextLessonFullscreenDiv) {
      return;
    }

    const parent = nextLessonFullscreenDiv.parentNode;

    if (parent) {
      this.renderer.removeChild(parent, nextLessonFullscreenDiv);
    }
  }

  public ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();

    if (this.nextVideoClickListener) {
      this.nextVideoClickListener();
    }
    this.mediaKindPlayer.destroy();
  }

  private replaceFormatInUrl(url: string): string {
    return url.replace('mpd-time-cmaf', 'm3u8-cmaf');
  }
}
