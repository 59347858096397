import { ICourseReadModel } from 'src/app/courses/courses';

export class CourseCardHelper {
  public static getAverageDuration(course: ICourseReadModel): number {
    if (course?.lessons) {
      const visibleLessons = course.lessons.filter((l) => !l.hiddenAtUtc);
      const visibleLessonsDuration = visibleLessons
        .map((l) => l.durationSeconds)
        .reduce((acc, curr) => acc + curr, 0);

      if (visibleLessons.length === 0) {
        return 0;
      }

      return Math.round(visibleLessonsDuration / 60 / visibleLessons.length);
    }
    return 0;
  }
}
