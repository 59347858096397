import { A } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'gmaps',
  templateUrl: 'gmaps.component.html',
  styleUrls: ['gmaps.component.scss'],
})
export class GmapsComponent {
  @Input() public longitude!: number | null;
  @Input() public latitude!: number | null;
  @Input() public address!: string | null;
  @Input() public city!: string | null;
  @Input() public country!: string | null;

  @Output() public outputCoordinates = new EventEmitter<any>();

  @ViewChild('search')
  public searchElementRef!: ElementRef;
  @ViewChild(GoogleMap)
  public map!: GoogleMap;

  zoom = 15;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDefaultUI: true,
    fullscreenControl: true,
    disableDoubleClickZoom: true,
    mapTypeId: 'hybrid',
    // maxZoom:this.maxZoom,
    // minZoom:this.minZoom,
  };

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    // Binding autocomplete to search input control
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
    );
    // Align search box to center
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      this.searchElementRef.nativeElement,
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
        this.latitude = place.geometry.location?.lat() ?? null;
        this.longitude = place.geometry.location?.lng() ?? null;
        this.center = {
          lat: this.latitude!,
          lng: this.longitude!,
        };

        const cityComponent = place.address_components?.find(
          (ac) => ac.types.indexOf('locality') > -1,
        );
        this.city = cityComponent ? cityComponent.long_name : '';
        const country = place.address_components?.find(
          (ac) => ac.types.indexOf('country') > -1,
        );
        this.country = country ? country.long_name : '';
        this.address = place.formatted_address
          ? `${place.name}, ${place.formatted_address}`
          : '';

      });
    });
  }

  ngOnInit() {
    if (!this.latitude && !this.longitude) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
      return;
    }
    this.center = {
      lat: this.latitude!,
      lng: this.longitude!,
    };
  }

  public onCenterChange(): void {
    const myLatlng = new google.maps.LatLng(this.latitude!, this.longitude!);
    const marker = new google.maps.Marker({
      position: myLatlng,
      title: 'Event location',
    });
    marker.setMap(this.map.googleMap!);

    const coordinates = {
      city: this.city,
      address: this.address,
      country: this.country,
      longitude: this.center.lng,
      latitude: this.center.lat,
    };
    this.outputCoordinates.emit(coordinates);
  }
}
