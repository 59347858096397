import { DEFAULT_FILTER } from './filters/constants';
import { ISortFilter } from './filters/filters';

export interface ILabelPost {
  title: string;
  labelType: LabelType;
}

export interface ILabel extends ILabelPost {
  id: string;
}

export interface ILabelReadModel extends ILabel {
  usedCount: number;
  createdAtUtc: string;
  updatedAtUtd?: string;
}

export enum LabelType {
  Topic = 0,
  Category = 1,
  PositiveFeedback = 2,
  NegativeFeedback = 3,
  CommentReport = 4,
  FeedbackOnlineEvent = 5,
  FeedbackInPersonEvent = 6,
  FeedbackCourse = 7,
  GeneralFeedback = 8,
}

export interface ILabelFilter extends ISortFilter {
  labelType?: LabelType;
  searchText?: string;
}

export const DEFAULT_LABEL_FILTER: ILabelFilter = {
  ...DEFAULT_FILTER,
  pageSize: 20,
  sortBy: 'title',
};
