<section class="terms-and-privacy app-xxl-container">
  <div class="bold important title">Privacy policy</div>
  <div>
    This Privacy Policy describes the data protection practices of Future
    Females, Inc. (“Future Females,” “we,” “us,” or “our”). This Privacy Policy
    applies to information that we collect and use about you when you access or
    use the Future Females, Inc. website, mobile application, or other online or
    mobile service that links to or otherwise presents this Privacy Policy to
    you. We refer to these products and services collectively as the “Services.”
  </div>

  <div class="bold important center my-5">
    PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE HANDLE YOUR
    INFORMATION. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT USE
    THE SERVICES.
  </div>

  <div class="bold">Information We Collect</div>

  <div>
    We collect information about you through the means discussed below. Please
    note that we need certain types of information so that we can provide the
    Services to you. If you do not provide us with such information, or ask us
    to delete it, you may no longer be able to access or use our Services.
  </div>

  <div class="bold important">1. INFORMATION YOU PROVIDE TO US</div>

  <div>
    We collect a variety of information that you provide directly to us. For
    example, we collect information from you through:
  </div>

  <ul>
    <li>The Services you use or processing your orders</li>
    <li>
      Requests or questions you submit to us via online forms, email, or
      otherwise
    </li>
    <li>Your participation in sweepstakes, contests, or surveys</li>
    <li>Any reviews that you submit about the Services</li>
    <li>Account registration and administration of your account</li>
    <li>Uploads or posts to the Services</li>
    <li>Requests for customer support and technical assistance</li>
  </ul>

  <div class="bold">Information about you</div>

  <div>
    While parts of the Services may not require you to provide any information
    that can directly identify you by name (such as if you choose to browse the
    website without logging in), the specific types of information we collect
    will depend upon the Services you use, how you use them, and the information
    you choose to provide. The types of data we collect directly from you
    includes:
  </div>

  <ul>
    <li>Email address</li>
    <li>Name, if you choose to provide it</li>
    <li>Log-in credentials, if you create a Future Females, Inc. account</li>
    <li>
      Billing information, such as shipping address of a gift card recipient,
      credit or debit card number, verification number, zip code, and expiration
      date
    </li>
    <li>Information about purchases or other transactions with us</li>
    <li>Information about your customer service interactions with us</li>
    <li>
      Demographic information such as your gender or other information you
      choose to provide as part of your Future Females, Inc. profile
    </li>
    <li>
      User-generated content you provide to us, such as when you comment on
      content on the Services, respond to a survey request, review a class, or
      participate in the public forums
    </li>
    <li>Classes in which you enroll</li>
    <li>
      Any other information you choose to directly provide to us in connection
      with your use of the Services
    </li>
  </ul>

  <div class="bold">Information about others</div>

  <div>
    If you request that your purchase be provided to someone other than yourself
    (such as a gift recipient), we use the information you provide about the
    other person to fulfill the shipment.
  </div>

  <div class="bold important">
    2. INFORMATION WE COLLECT THROUGH AUTOMATED MEANS
  </div>

  <div>
    When you use our Services, we collect certain information as described in
    this Section. As discussed further below, we and our service providers
    (which are third party companies that work on our behalf) may use a variety
    of technologies, including cookies and similar tools, to assist in
    collecting this information.
  </div>

  <div>Websites</div>

  <div>
    When you use our website, we collect and analyze information such as your IP
    address, browser types, browser language, operating system, software and
    hardware attributes (including device IDs) referring and exit pages and
    URLs, the number of clicks, pages viewed and the order of those pages, date
    and time of use, content watched, total minutes watched, error logs, and
    other similar information about how you use the website.
  </div>

  <div>Mobile Applications</div>

  <div>
    When you use a Future Females, Inc. mobile application or software ("app"),
    we automatically receive certain information about the mobile phone, tablet,
    or computer used to access the app, including device identifiers, IP
    address, operating system, version, Internet service provider, browser type,
    domain name and other similar information, whether and when you update the
    app, date and time of use, content watched, total minutes watched, error
    logs, and other similar information about how you use the app.
  </div>

  <div class="bold">Location Information</div>

  <div>
    When you use the Services, we and our service providers may automatically
    collect general location information (e.g., IP address, city/state and/or
    postal code associated with an IP address, city/state) from your computer or
    mobile device.
  </div>

  <div class="bold important">
    3. INFORMATION WE COLLECT FROM SOCIAL MEDIA AND OTHER CONTENT PLATFORMS
  </div>

  <div>
    If you access the Services through a third-party connection or log-in (e.g.,
    through a social network like Facebook or Twitter), you may allow us to have
    access to and store certain information from your social network profile.
    This can include your name, gender, profile picture, your “likes” and
    check-ins, and your list of friends, depending on your settings on such
    services. If you do not wish to have this information shared, do not use a
    social networking connection to access the Services. For a description of
    how social networking sites handle your information, please refer to their
    privacy policies and terms of use, which may permit you to modify your
    privacy settings. You may also have the option of posting your Services
    activities to Social Networking Services when you access content through the
    Services (for example, you may post to Facebook that you enrolled in a class
    on the Service); you acknowledge that if you choose to use this feature,
    your friends, followers and subscribers on any Social Networking Services
    you have enabled will be able to view such activity.
  </div>

  <div class="bold important">4. INFORMATION WE COLLECT FROM OTHERS</div>

  <div>
    We may receive additional information such as demographic and statistical
    information from third parties, such as business partners, marketers,
    researchers, analysts, and other parties that we may attribute to you based
    on your assignment to certain statistical groups. We use this information to
    supplement the information that we collect directly from you in order to
    derive your possible interests and to provide more relevant experiences for
    you and improve our products, analytics, and advertising.
  </div>

  <div class="bold">How We Use Your Information</div>

  <div>
    We, or our service providers, use your information for various purposes
    depending on the types of information we have collected from and about you,
    in order to:
  </div>

  <ul>
    <li>
      Complete a purchase or provide the Services you have requested, including
      invoicing and accounting
    </li>
    <li>
      Respond to your request for information and provide you with more
      effective and efficient customer service
    </li>
    <li>
      Provide you with updates and information about classes in which you have
      enrolled
    </li>
    <li>
      Contact you by email, postal mail, or phone regarding Future Females, Inc.
      and third-party products, services, surveys, research studies, promotions,
      special events and other subjects that we think may be of interest to you
    </li>
    <li>Customize the advertising and content you see on the Services</li>
    <li>
      Help us better understand your interests and needs, and improve the
      Services, including through research and reports, and test and create new
      products, features, and services
    </li>
    <li>
      Secure our websites and applications, and resolve app crashes and other
      issues being reported
    </li>
    <li>
      Comply with any procedures, laws, and regulations which apply to us where
      it is necessary for our legitimate interests or the legitimate interests
      of others
    </li>
    <li>
      Establish, exercise, or defend our legal rights where it is necessary for
      our legitimate interests or the legitimate interests of others
    </li>
  </ul>

  <div class="bold">Combined Information</div>

  <div>
    For the purposes discussed in this Privacy Policy, we may combine the
    information that we collect through the Services with information that we
    receive from other sources, both online and offline, and use such combined
    information in accordance with this Privacy Policy.
  </div>

  <div class="bold">Aggregate/Anonymous Data</div>

  <div>
    We may aggregate and/or anonymize any information collected through the
    Services so that such information can no longer be linked to you or your
    device. We may use such information for any purpose, including without
    limitation for research and marketing purposes, and may also share such data
    with any third parties, including advertisers, promotional partners, and
    sponsors.
  </div>

  <div class="bold">Cookies and Similar Technologies</div>

  <div>
    To collect the information in the “Information We Collect Through Automated
    Means” section above, we and our service providers use Internet server logs,
    cookies, tracking pixels, and other similar tracking technologies. We use
    these technologies in order to offer you a more tailored experience in the
    future, by understanding and remembering your particular browsing
    preferences. Cookies are small text files that are placed on your computer
    or mobile device when you visit a site that enable us to: (i) recognize your
    computer; (ii) store your preferences and settings; (iii) understand the web
    pages of the Services you have visited; (iv) enhance your user experience by
    delivering and measuring the effectiveness of content and advertising
    tailored to your interests; (v) perform searches and analytics; and (vi)
    assist with security and administrative functions. Some cookies are placed
    in your browser cache while those associated with Flash technologies are
    stored with your Adobe Flash Player files.
  </div>

  <div>
    As we adopt additional technologies, we may also gather information through
    other methods. Please note that you can change your settings to notify you
    when a cookie is being set or updated or to block cookies altogether. Please
    consult the “Help” section of your browser for more information (e.g.,
    Internet Explorer; Google Chrome; Mozilla Firefox; or Apple Safari). You can
    also manage the use of Flash technologies, including flash cookies and local
    storage objects, with the Flash management tools available at Adobe's
    website. Please note that by blocking, disabling, or managing any or all
    cookies, you may not have access to certain features or offerings of the
    Services. For more information about our use of cookies, please see our
    Cookie Policy.
  </div>

  <div class="bold">How We Share and Disclose your Information</div>

  <div>
    Future Females, Inc. will share your information in the following ways:
  </div>

  <div class="bold">Affiliates and Subsidiaries</div>

  <div>
    We may share information we collect within the Yanka Industries family of
    companies to deliver products and services to you, ensure a consistent level
    of service, and enhance our products, services, and your customer
    experience.
  </div>

  <div class="important">Service Providers</div>

  <div>
    We provide access to or share your information with select third parties who
    perform services on our behalf. They have access to perform these services
    but are prohibited from using your information for other purposes. They
    provide a variety of services to us, including billing, sales, marketing,
    product content and features, advertising, analytics, research, customer
    service, data storage, security, fraud prevention, payment processing, and
    legal services
  </div>

  <div class="important">Protection of Future Females, Inc. and Others</div>

  <div>
    By using the Services, you acknowledge and agree that we may access, retain
    and disclose the information we collect and maintain about you if required
    to do so by law or in a good faith belief that such access, retention or
    disclosure is reasonably necessary to: (a) comply with legal process (e.g. a
    subpoena or court order); (b) enforce our Terms of Service, this Privacy
    Policy, or other contracts with you, including investigation of potential
    violations thereof; (c) respond to claims that any content violates the
    rights of third parties; (d) respond to your requests for customer service;
    and/or (e) protect the rights, property or personal safety of Future
    Females, Inc., its agents and affiliates, its users and/or the public. This
    includes exchanging information with other companies and organizations for
    fraud protection, and spam/malware prevention, and similar purposes.
  </div>

  <div class="important">Business Transfers</div>

  <div>
    As we continue to develop our business, we may buy, merge, or partner with
    other companies. In such transactions (including in contemplation of such
    transactions), user information may be among the transferred assets. If a
    portion or all of our assets are sold or transferred to a third-party,
    customer information (including your email address) would likely be one of
    the transferred business assets. If such transfer is subject to additional
    mandatory restrictions under applicable laws, we will comply with such
    restrictions.
  </div>

  <div class="important">Public Forums</div>

  <div>
    Certain features of our Services make it possible for you to share comments
    publicly with other users. Any information that you submit through such
    features is not confidential, and we may use it for any purpose (including
    in testimonials or other marketing materials). Any information you post
    openly in these ways will be available to the public at large and
    potentially accessible through third-party search engines. Accordingly,
    please take care when using these features.
  </div>

  <div class="important">Aggregate/Anonymous Information</div>

  <div>
    From time to time, we may share Aggregate/Anonymous Information about use of
    the Services, such as by publishing a report on usage trends. The sharing of
    such data is unrestricted.
  </div>

  <div class="important">Retention of Your Information</div>

  <div>
    We keep your information for no longer than necessary for the purposes for
    which it is processed. The length of time for which we retain information
    depends on the purposes for which we collected and use it and/or as required
    to comply with applicable laws.
  </div>

  <div class="important">How We Protect Your Information</div>

  <div>
    Future Females, Inc. takes technical and organizational security measures to
    protect the information provided via the Services from loss, misuse, and
    unauthorized access, disclosure, alteration, or destruction. However, no
    Internet or email transmission is ever fully secure or error free. Please
    keep this in mind when disclosing any information to Future Females, Inc.
    via the Internet.
  </div>

  <div class="important">Third Party Links and Features</div>

  <div>
    The Services contain links to third-party websites such as social media
    sites, and also contain third-party plug-ins (such as the Facebook “like”
    button and Twitter “follow” button). If you choose to use these sites or
    features, you may disclose your information not just to those third-parties,
    but also to their users and the public more generally depending on how their
    services function. We are not responsible for the content or practices of
    those websites or services. The collection, use, and disclosure of your
    information will be subject to the privacy policies of the third party
    websites or services, and not this Privacy Policy. We urge you to read the
    privacy and security policies of these third-parties
  </div>

  <div class="important">Children's Privacy</div>

  <div>
    The Services are intended for general audiences and not for children under
    the age of 13. If we become aware that we have inadvertently collected
    “personal information” (as defined by the United States Children’s Online
    Privacy Protection Act) from children under the age of 13 without valid
    parental consent, we will take reasonable steps to delete it as soon as
    possible. We do not knowingly process data of customers under the age of 16
    without parental consent. If we become aware that we have collected data
    from a customer under the age of 16 without parental consent, we will take
    reasonable steps to delete it as soon as possible. We also comply with other
    age restrictions and requirements in accordance with applicable local laws.
  </div>

  <div class="important">Data Subject Rights and Choices</div>

  <div>
    Depending on your jurisdiction of residence, you may have certain rights
    with respect to your information as further described in this section.
  </div>

  <div class="important bold">1. YOUR LEGAL RIGHTS</div>

  <div>
    If you would like further information in relation to your legal rights under
    applicable law or would like to exercise any of them, please contact us
    using the information in the “Contact Information” section below at any
    time. Your local laws (e.g., in the EU,USA,SA) may permit you to request
    that we:
  </div>

  <ul>
    <li>
      provide access to and/or a copy of certain information we hold about you
    </li>
    <li>
      prevent the processing of your information for direct-marketing purposes
      (including any direct marketing processing based on profiling)
    </li>
    <li>update information which is out of date or incorrect</li>
    <li>delete certain information which we are holding about you</li>
    <li>
      restrict the way that we process and disclose certain of your information
    </li>
    <li>transfer your information to a third party provider of services</li>
    <li>revoke your consent for the processing of your information</li>
  </ul>

  <div>
    If at any time you would like to change your contact details, or to request that all personal data collected by this platform is deleted,
     please send an email to <a href="mailto:hello@futurefemales.co">hello&#64;futurefemales.co</a> and this will be actioned within 7 working days.
  </div>

  <div>
    We will consider all requests and provide our response within the time
    period stated by applicable law. Please note, however, that certain
    information may be exempt from such requests in some circumstances, which
    may include if we need to keep processing your information for our
    legitimate interests or to comply with a legal obligation. We may request
    you provide us with information necessary to confirm your identity before
    responding to your request.
  </div>

  <div class="bold important">2. MARKETING COMMUNICATIONS AND SHARING</div>

  <div>
    You may instruct us not to use your contact information to contact you by
    email, postal mail, or phone regarding products, services, promotions and
    special events that might appeal to your interests by contacting us using
    the information below. In commercial email messages, you can also opt out by
    following the instructions located at the bottom of such emails. Please note
    that, regardless of your request, we may still use and share certain
    information as permitted by applicable law. For example, you may not opt out
    of certain operational emails, such as those reflecting our relationship or
    transactions with you, or important notifications regarding classes in which
    you are enrolled.
  </div>

  <div class="bold">Legal Bases for Use of Your Information</div>

  <div>
    The legal bases for using your information as set out in this Privacy Policy
    are as follows:
  </div>

  <ul>
    <li>
      Where use of your information is necessary to perform our obligations
      under a contract with you (for example, to comply with: the terms of
      service of our websites which you accept by browsing the
      websites/registering; and/or our contract to provide our Services to you);
    </li>
    <li>
      Where use of your information is necessary for our legitimate interests or
      the legitimate interests of others (for example, to provide security for
      our website and applications; operate our business and our Services; make
      and receive payments; comply with legal requirements and defend our legal
      rights; prevent fraud and to know the customer to whom we are providing
      Services);
    </li>
    <li>
      Where we are required to process information in accordance with an EU
      Member State legal obligation - General Data Protection Regulation (EU
      GDPR) ; or
    </li>
    <li>
      Where we are required to process information in accordance with Protection
      of Personal Information Act (POPIA); or
    </li>
    <li>Where we have your consent, in accordance with applicable law.</li>
  </ul>

  <div class="bold">Changes to our Privacy Policy</div>

  <div>
    We reserve the right to amend this Privacy Policy at any time to reflect
    changes in the law, our data collection and use practices, the features of
    our Services, or advances in technology. We will make the revised Privacy
    Policy accessible through the Services, so you should review the Privacy
    Policy periodically. You can know if the Privacy Policy has changed since
    the last time you reviewed it by checking the “Date of Last Revision"
    included at the beginning of the document. If we make a material change to
    the Policy, you will be provided with appropriate notice in accordance with
    legal requirements. By continuing to use the Services, you are confirming
    that you have read and understood the latest version of this Privacy Policy.
  </div>

  <div class="bold">Contact Information</div>

  <div>
    Please feel free to contact us if you have any questions about Future
    Females, Inc. Privacy Policy or the information practices of the Services.
  </div>

  <div><a href="mailto:hello@futurefemales.co">hello&#64;futurefemales.co</a></div>

  <div>Future Females, Inc.</div>
  <div>9450 SW Gemini Dr</div>
  <div>PMB 64724</div>
  <div>Beaverton, OR</div>
  <div>97008-7105</div>
  <div>USA</div>
</section>
