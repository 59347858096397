import { Component, Input, OnInit } from '@angular/core';
import { ShareResourceType } from '../../../services/share.service';
import { SocialMediaTypes } from '../../../share';
import { AbstractButtonComponent } from '../abstract-button/abstract-button.component';
import { IEvent } from '../../../../events/event';
import { ICourseReadModel } from '../../../../courses/courses';

/**
 * Uses only OG:*
 * https://www.linkedin.com/help/linkedin/answer/a521928/make-your-website-shareable-on-linkedin?lang=en
 */
@Component({
  selector: 'app-linkedin-button',
  templateUrl: './linkedin-button.component.html',
  styleUrls: ['./linkedin-button.component.scss'],
})
export class LinkedinButtonComponent
  extends AbstractButtonComponent
  implements OnInit
{
  @Input() override url!: string;
  @Input() override resourceId!: string;
  @Input() override resourceType!: keyof typeof ShareResourceType;
  @Input() override description!: string;
  @Input()
  public override resource!: Partial<IEvent | ICourseReadModel>;

  public override ngOnInit(): void {
    super.ngOnInit();
    this.loadUrl(SocialMediaTypes.LinkedIn);
  }
}
