<div class="video-player-mk__video-player">
  @if (isAdmin) {
    <input
      type="file"
      #file
      placeholder="Choose file"
      class="d-none"
      (change)="addRecording(file.files)"
      />
    <button
      mat-icon-button
      class="my-3 add-video"
      color="accent"
      (click)="file.click()"
      >
      <mat-icon>add</mat-icon>
    </button>
  }

  <ng-container>
    <div class="p-relative">
      @if (!videoWasStarted) {
        <div class="video-mobile-play-container"></div>
      }
      <div class="video-player-mk__video-player-container">
        <div id="video-container" class="mk-video-player"></div>
      </div>
    </div>
  </ng-container>

  <video-upload-progress
    [uploadAzureProgress]="uploadAzureProgress"
    [uploadProgress]="uploadProgress"
    [videoUrl]="videoUrl"
  ></video-upload-progress>
</div>
