import { Component, Output, EventEmitter } from '@angular/core';
import { NotificationSignalRService } from './notification-signalR.service';
import { DEFAULT_NOTIFICATION_FILTER, INotification } from './notification';
import { NotificationApiService } from './notification-api.service';
import { IPage } from 'src/app/shared/pagination';
import { concatMap, interval, of, Subject, takeUntil } from 'rxjs';
import { DateHelpers } from 'src/app/shared/date/date-helpers';
import { NotificationService } from './notification.service';

const NotificationAdded = 'PushedNotification';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['notifications.component.scss'],
})
export class NotificationsComponent {
  public notifications: INotification[] = [];

  public signalRConnectionMade = new Subject<void>();

  public pageIndex = 0;

  @Output() public newUnread = new EventEmitter<void>();

  constructor(
    private notificationApiService: NotificationApiService,
    private signalRService: NotificationSignalRService,
    private notificationService: NotificationService,
  ) {}

  public ngOnInit(): void {
    interval(1000)
      .pipe(
        takeUntil(this.signalRConnectionMade),
        concatMap((_) => {
          if (!!this.signalRService.connectionId) {
            return this.notificationApiService.getNotifications(
              this.signalRService.connectionId,
              DEFAULT_NOTIFICATION_FILTER,
            );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe((result) => {
        if (result === null) {
          return;
        }
        this.signalRConnectionMade.next();
      });

    this.notificationApiService
      .getNotifications(null, DEFAULT_NOTIFICATION_FILTER)
      .subscribe((page: IPage<INotification>) => {
        this.notifications = page.items;

        if (page.items.length < 10) {
          this.pageIndex = 4;
        }

        this.setNotificationCount(false);

        this.signalRService.startConnection();
        this.addNewNotificationListener();
      });
  }

  public markAllAsRead(): void {
    this.notificationApiService.markAllAsRead().subscribe((_) => {
      this.notifications = this.notifications.map((n) => ({
        ...n,
        readAtUtc: DateHelpers.toIsoString(new Date()),
      }));
      this.setNotificationCount(true);
    });
  }

  public loadMore(): void {
    this.pageIndex++;
    if (this.pageIndex > 3) {
      return;
    }
    this.notificationApiService
      .getNotifications(this.signalRService.connectionId, {
        ...DEFAULT_NOTIFICATION_FILTER,
        pageIndex: this.pageIndex,
      })
      .subscribe((page: IPage<INotification>) => {
        this.notifications = [...this.notifications, ...page.items];

        this.setNotificationCount(false);
      });
  }

  private addNewNotificationListener(): void {
    this.signalRService.hubConnection.on(
      NotificationAdded,
      (notification: INotification) => {
        this.notifications = [notification, ...this.notifications];
        this.newUnread.emit();

        this.setNotificationCount(false);
      },
    );
  }

  private setNotificationCount(reset: boolean): void {
    const unreadNotificationCount =
      reset === false
        ? this.notifications.filter(n => !n.readAtUtc).length
        : 0;
    this.notificationService.updateNotificationCount(unreadNotificationCount);
  }
}
