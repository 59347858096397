import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IStringResponse } from 'src/app/shared/api/string-response';
import { IPostFeedback } from 'src/app/shared/feedback/feedback';
import { API_BASE_URL } from '../../shared/app-constants';
import { IUserCourseReadModel, PatchUserCourse } from './user';

@Injectable()
export class UserCourseApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getUserCourse(courseId: string): Observable<IUserCourseReadModel> {
    const url = `${this.apiBaseUrl}/user-courses/${courseId}`;
    return this.httpClient.get<IUserCourseReadModel>(url);
  }

  public createOrUpdateAsync(courseId: string): Observable<void> {
    const url = `${this.apiBaseUrl}/user-courses/${courseId}`;
    return this.httpClient.patch<void>(url, {});
  }

  public patchUserCourse(
    courseId: string,
    updates: PatchUserCourse,
  ): Observable<void> {
    const url = `${this.apiBaseUrl}/user-courses/${courseId}`;
    return this.httpClient.patch<void>(url, updates);
  }

  public postUserFeedback(
    courseId: string,
    feedback: IPostFeedback,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/user-courses/${courseId}/feedback`;
    return this.httpClient.post<IStringResponse>(url, feedback);
  }

  public patchUserFeedback(
    courseId: string,
    userId: string,
    feedback: Partial<IPostFeedback>,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/user-courses/${courseId}/feedback/${userId}`;
    return this.httpClient.patch<IStringResponse>(url, feedback);
  }
}
