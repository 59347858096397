@if (!reorderMode) {
  <ul class="image-grid">
    @for (item of allItems; track item) {
      <li
        class="sponsor"
        (click)="toggleImageSelection(item)"
        [class.selected]="isSelected(item)"
        >
        <img [src]="item.image" [alt]="item.alt" />
        <div class="alt-container">
          {{ item.alt }}
        </div>
        <div class="checked">
          <mat-icon>check_circle</mat-icon>
        </div>
      </li>
    }
  </ul>
}

@if (reorderMode) {
  <div
    class="image-grid drag-drop-container"
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="onReorderDrop($event)"
    >
    @for (item of reorderArray; track item) {
      <div class="sponsor drag-drop-item" cdkDrag>
        <img [src]="item.image" [alt]="item.alt" />
        <div class="alt-container">
          {{ item.alt }}
        </div>
      </div>
    }
  </div>
}
@if (reorderMode) {
  <button
    mat-flat-button
    color="accent"
    class="mt-2"
    (click)="onSubmitReorder()"
    >
    Submit
  </button>
}
