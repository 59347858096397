import { DateHelpers } from '../shared/date/date-helpers';
import { ILabelReadModel } from '../shared/label';
import { IResource } from '../shared/resources/resource';
import { ILessonReadModel } from './lessons';
import { IUserCourseReadModel } from '../core/course/user';
import { IDiscussionSummary } from '../shared/discussions/discussion';
import { IFeedback } from '../shared/feedback/feedback';

export enum PublishStatus {
  Hidden,
  ComingSoon,
  Live,
}

export interface ICoursePost {
  title: string;
  description: string;
}

export interface ICoursePatch {
  title?: string;
  description?: string;
  publishStatus?: PublishStatus;
  teaserUrl?: string;
  captionUrl?: string;
  portraitThumbnailUrl?: string;
  landscapeThumbnailUrl?: string;
  banner?: string;

  facilitatorId?: string;
  facilitatorTagline?: string;

  courseBriefItems?: string[];
  topicIds?: string[];
  categoryIds?: string[];
}

export interface ICourseReadModel {
  id: string;
  title: string;
  description: string;

  teaserUrl?: string;
  captionUrl?: string;
  teaserThumbnailUrl?: string;
  teaserVideoDurationSeconds: number;

  videoUploadId?: string;

  portraitThumbnailUrl?: string;
  landscapeThumbnailUrl?: string;

  durationSeconds: number;

  banner?: string;
  rating: number;

  courseBriefItems: any[];

  facilitatorId?: string;
  facilitatorTagline?: string;
  speaker?: ICourseFacilitatorReadModel;
  feedbacksCount: number;

  activePeople: number;
  viewedByPeople: number;
  registeredPeople: number;
  onFavoritePeople: number;
  likedByPeople: number;

  topics: ILabelReadModel[];
  categories: ILabelReadModel[];

  resources: IResource[];
  lessons: ILessonReadModel[];

  courseForCurrentUser?: ICourseForCurrentUser;
  discussion?: IDiscussionSummary;
  publishStatus?: PublishStatus;
}

export interface ICourseFacilitatorReadModel {
  id: string;
  name: string;
  description?: string;
  bio?: string;
  profilePhotoUrl?: string;
  title: string;
  company: string;
}

export interface ICourseForCurrentUser {
  courseId: string;
  userId: string;

  isViewed: boolean;
  isOnFavorite: boolean;
  isLiked: boolean;
  notificationsEnabled: boolean;
  isRecommended: boolean;

  lastActiveAtUtc?: string;
  startedAtUtc?: string;
  completedAtUtc?: string;

  createdAtUtc: string;
  updatedAtUtc?: string;

  feedback?: IFeedback;

  completedPercentage: number;
}

export const EMPTY_USER_COURSE = (
  id: string,
  userId?: string,
): IUserCourseReadModel => ({
  courseId: id,
  userId: userId ? userId : '',

  isViewed: false,
  isOnFavorite: false,
  isLiked: false,
  notificationsEnabled: false,
  isRecommended: false,

  createdAtUtc: DateHelpers.toIsoString(new Date()),

  completedPercentage: 0,

  feedback: {},
  userCourseLessons: [],
  shares: [],
});

export const EMPTY_USER_COURSE_FOR_CURRENT_USER = (
  id: string,
  userId?: string,
): ICourseForCurrentUser => ({
  courseId: id,
  userId: userId ? userId : '',

  isViewed: false,
  isOnFavorite: false,
  isLiked: false,
  notificationsEnabled: false,
  isRecommended: false,

  lastActiveAtUtc: undefined,
  startedAtUtc: undefined,
  completedAtUtc: undefined,

  createdAtUtc: DateHelpers.toIsoString(new Date()),
  updatedAtUtc: undefined,

  completedPercentage: 0,
});
