import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ICityCountry } from 'src/app/shared/models/location';
import { IEventFilterResultCount, ILocationOption } from '../location-filter';

@Component({
  selector: 'location-dropdown',
  templateUrl: 'location-dropdown.component.html',
  styleUrls: ['location-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationDropdownComponent {
  @Input() public cityCountries!: ICityCountry[];
  @Input() public selectedLocation!: ILocationOption;
  @Input() public genericLocationResultCount!: IEventFilterResultCount;

  @Output() public locationChange = new EventEmitter<{
    selectedLocation: ILocationOption;
  }>();

  public onLocationChange(change: { selectedLocation: ILocationOption }): void {
    this.selectedLocation = change.selectedLocation;
    this.locationChange.emit(change);
  }
}
