import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IStringResponse } from '../shared/api/string-response';

import { API_BASE_URL } from '../shared/app-constants';
import {
  ISponsor,
  ISponsorGet,
  ISponsorGetParams,
  ISponsorPost,
} from './sponsor';

@Injectable()
export class SponsorApiService {
  constructor(
    private httpClient: HttpClient,
    @Inject(API_BASE_URL) private apiBaseUrl: string,
  ) {}

  public getAll({ pageSize }: ISponsorGetParams): Observable<ISponsorGet> {
    const url = `${this.apiBaseUrl}/sponsors`;

    return this.httpClient.get<ISponsorGet>(url, {
      params: {
        pageSize,
      },
    });
  }

  public createSponsor(faciliator: ISponsorPost): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/sponsors`;
    return this.httpClient.post<IStringResponse>(url, faciliator);
  }

  public patchSponsor(
    id: string,
    faciliator: Partial<ISponsorPost>,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/sponsors/${id}`;
    return this.httpClient.patch<IStringResponse>(url, faciliator);
  }

  public deleteSponsor(id: string): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/sponsors/${id}`;
    return this.httpClient.delete<IStringResponse>(url);
  }
}
