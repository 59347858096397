export interface IResource {
  id: string;
  text?: string;
  url?: string;
  type: ResourceType;
}

export enum ResourceType {
  Tool = 0,
  Link = 1,
  Document = 2,
}

export interface IResourcePost {
  userId: string;
  text: string;
  url: string;
  type: ResourceType;
}
