<button
  mat-icon-button
  [url]="url"
  shareButton="linkedin"
  #linkedInBtn="shareButton"
>
  <!-- Neither boostrap / material has the same icon -->
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0V0ZM2 2V16H16V2H2ZM3.988 11.065L4.758 7.794H3.564L3.926 6.404H6.794L5.662 11.074C5.59825 11.309 5.56267 11.5506 5.556 11.794C5.556 12.092 5.697 12.18 5.918 12.231C6.053 12.263 7.126 12.241 7.709 10.891L8.453 7.794H7.245L7.608 6.404H10.188L9.857 7.982C10.309 7.102 11.215 6.267 12.105 6.267C13.055 6.267 13.841 6.971 13.841 8.322C13.841 8.667 13.795 9.043 13.675 9.467L13.192 11.272C13.1482 11.4309 13.1227 11.5943 13.116 11.759C13.116 12.073 13.237 12.229 13.463 12.229C13.69 12.229 13.977 12.057 14.309 11.099L14.973 11.366C14.58 12.795 13.871 13.391 12.98 13.391C11.939 13.391 11.441 12.748 11.441 11.868C11.441 11.618 11.471 11.35 11.547 11.083L12.045 9.23C12.105 9.026 12.12 8.838 12.12 8.665C12.12 8.069 11.773 7.707 11.215 7.707C10.505 7.707 10.037 8.237 9.796 9.257L8.83 13.289H7.14L7.443 12.022C6.946 12.872 6.256 13.397 5.405 13.397C4.379 13.397 3.896 12.782 3.896 11.855C3.896 11.62 3.926 11.332 3.986 11.065H3.988ZM5.625 5.625C5.32663 5.625 5.04048 5.50647 4.8295 5.2955C4.61853 5.08452 4.5 4.79837 4.5 4.5C4.5 4.20163 4.61853 3.91548 4.8295 3.7045C5.04048 3.49353 5.32663 3.375 5.625 3.375C5.92337 3.375 6.20952 3.49353 6.4205 3.7045C6.63147 3.91548 6.75 4.20163 6.75 4.5C6.75 4.79837 6.63147 5.08452 6.4205 5.2955C6.20952 5.50647 5.92337 5.625 5.625 5.625Z"
      fill="#09121F"
    />
  </svg>
</button>
