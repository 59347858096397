<div class="ff-filled-progress-indicator__component" style="position: relative">
  <div class="progress-indicator-background"></div>
  <mat-progress-spinner
    diameter="24"
    color="accent"
    mode="determinate"
    [value]="progress"
  >
  </mat-progress-spinner>
</div>
