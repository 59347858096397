import {
  ConnectionPositionPair,
  ScrollStrategy,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import {
  combineLatest,
  concatMap,
  debounceTime,
  filter,
  fromEvent,
  Subject,
  takeUntil,
} from 'rxjs';
import { StringHelpers } from 'src/app/shared/helpers';
import { ILabel } from 'src/app/shared/label';
import { UsersService } from 'src/app/user/users.service';

import { Event, IUserEvent, LocationType } from '../../events/event';
import { EventHelper } from '../../events/helper';
import { UserEventApiService } from '../../core/event/user-events.api.service';
import {
  EventListService,
  IEventInteraction,
  ListInteraction,
} from '../../events/list/list.service';
import { DateTime } from 'luxon';
import { UserAdmin } from 'src/app/user/admin';

@Component({
  selector: 'event-list-card',
  templateUrl: 'event-list-card.component.html',
  styleUrls: ['event-list-card.component.scss'],
})
export class EventListCard implements OnInit, OnDestroy {
  @Input() public event!: Event;
  @Input() public onList!: string;

  @ViewChild('eventCardContainer', { static: false })
  public eventCardContainer?: ElementRef<HTMLElement>;

  @ViewChild('eventTitle', { static: false })
  public eventTitle?: ElementRef<HTMLElement>;

  public topics: ILabel[] = [];
  public speakers!: string;

  public description: string = '';

  public interested: number = 0;
  public attending: number = 0;
  public isFavorite: boolean = false;

  public location!: string;
  public LocationType = LocationType;

  public recordingStatusMessage!: string;

  public startDate!: string;

  public isCurrentUserAttending!: boolean;
  public isCurrentUserOnWaitlist!: boolean;
  public hasAvailableSeats!: boolean;

  public isHovered: boolean = false;

  public isWaitlistState: boolean = false;
  public waitlistCount = 0;

  public showCta: boolean = false;
  public isInThePast: boolean = false;
  public registrationEnded: boolean = false;

  public isAdminShown = false;

  public selectedGoingButton?: string;
  public overlays = {
    goingOverlay: false,
    goingOverlayMobile: false,
  };
  public goingOverlayPosition: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: -25,
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom',
      panelClass: 'going-overlay',
    },
  ];
  public goingScrollStrategy: ScrollStrategy =
    this.scrollStrategyOptions.close();
  public goingMobileScrollStrategy: ScrollStrategy =
    this.scrollStrategyOptions.close();

  private emptyUserEvents!: IUserEvent;
  private destroyNotifier = new Subject<void>();
  private touchStartY?: number;
  private touchStartX?: number;

  constructor(
    private userEventApiService: UserEventApiService,
    private listService: EventListService,
    private usersService: UsersService,
    private router: Router,
    private scrollStrategyOptions: ScrollStrategyOptions,
  ) {}

  public ngOnInit(): void {
    if (!this.event) {
      return;
    }

    combineLatest([
      this.usersService.isAdminInterfaceShown,
      this.usersService.userAdmin,
    ])
      .pipe(takeUntil(this.destroyNotifier))
      .subscribe(
        ([isAdminInterfaceShown, admin]: [boolean, UserAdmin]) =>
          (this.isAdminShown = isAdminInterfaceShown && admin.isSuperAdmin),
      );

    this.interested = this.event.viewedByPeople;
    this.attending = this.event.registeredPeople;
    this.isFavorite = !!this.event.eventForCurrentUser?.isOnFavorite;
    this.topics = this.event.topics.slice(0, 2);
    this.isInThePast = new Date(this.event.endsAtUtc) < new Date();
    if (this.event.registrationEndsAtUtc) {
      this.registrationEnded =
        new Date(this.event.registrationEndsAtUtc) < new Date();
    }
    this.setEventDate();
    this.setLocation();
    this.setSpeakersLabel();
    this.setDescription();
    this.setRecordingStatusMessage();
    this.computeUserEvents();

    this.listService.newInteraction
      .pipe(
        takeUntil(this.destroyNotifier),
        filter(
          (interaction: IEventInteraction) =>
            this.event.id === interaction.event!.id &&
            this.onList !== interaction.listTitle,
        ),
      )
      .subscribe((interaction: IEventInteraction) =>
        this.handleNewInteraction(interaction),
      );

    this.emptyUserEvents = {
      eventId: this.event.id,
      userId: this.usersService.getUserId(),
      isViewed: false,
      isActive: false,
      isOnWaitlist: false,
      isRegistered: false,
      isLiked: false,
      isOnFavorite: false,
      notificationsEnabled: false,
      waitlistPosition: null,
      participationConfirmedAtUtc: null,
    };

    setTimeout(() => {
      this.onResize();
    });

    fromEvent(window, 'resize')
      .pipe(debounceTime(150))
      .subscribe(() => {
        this.onResize();
      });
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
  }

  public handleNewInteraction(interaction: IEventInteraction): void {
    this.event = new Event(interaction.event!);
    this.computeUserEvents();
  }

  public goToEvent(tab?: string): void {
    this.router.navigateByUrl(
      `/events/${this.getUrlNamePath()}/${this.event.id}`,
      {
        state: {
          tab,
        },
      },
    );
  }

  public navigateToEvents(): void {
    this.router.navigateByUrl('/events');
  }

  public onTouchstart(event: any) {
    const evt =
      typeof event.originalEvent === 'undefined' ? event : event.originalEvent;
    const touch = evt.touches[0] || evt.changedTouches[0];
    this.touchStartY = touch.screenY;
    this.touchStartX = touch.screenX;
  }

  public onTouchend(event: any) {
    event.preventDefault();

    try {
      const evt =
        typeof event.originalEvent === 'undefined'
          ? event
          : event.originalEvent;
      const touch = evt.touches[0] || evt.changedTouches[0];

      if (!this.touchStartY || !this.touchStartX) {
        return;
      }

      const delta =
        Math.abs(this.touchStartY - touch.screenY) +
        Math.abs(this.touchStartX - touch.screenX);

      // Show CTA if the user moved the finger while clicking not more than 5pixels
      if (delta < 5) {
        this.showCta = !this.showCta;
      }
    } catch (_) {}

    this.touchStartY = undefined;
    this.touchStartX = undefined;
  }

  public onCloseClickOrTouch(event: MouseEvent | TouchEvent) {
    event.preventDefault();

    this.touchStartY = undefined;
    this.touchStartX = undefined;
    this.showCta = false;
  }

  private setDescription(): void {
    if (!this.event.description) {
      this.description = '';
      return;
    }
    this.description = this.event.description.replace(/<[^>]*>?/gm, '');
    this.description = this.description.replace(/&#160;/gm, ' ');
  }

  public onMouseHover(): void {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    if (vw < 577) {
      return;
    }
    this.showCta = true;
  }

  public onMouseLeave(): void {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    if (vw < 577) {
      return;
    }
    this.showCta = false;
  }

  public setFavoriteStatus(ev: any, isOnFavorite: boolean): void {
    ev.preventDefault();
    ev.stopImmediatePropagation();

    this.userEventApiService
      .patchUserEvents({ eventId: this.event.id, isOnFavorite })
      .subscribe((_) => {
        this.isFavorite = isOnFavorite;
        this.event.eventForCurrentUser = !!this.event.eventForCurrentUser
          ? {
              ...this.event.eventForCurrentUser,
              isOnFavorite,
            }
          : {
              ...this.emptyUserEvents,
              isOnFavorite,
            };

        if (this.isFavorite) {
          this.listService.sendNewInteraction({
            listTitle: 'myFavorite',
            event: { ...this.event },
            interaction: ListInteraction.UserAddedOnFavorite,
          });
        } else {
          this.listService.sendNewInteraction({
            listTitle: 'myFavorite',
            event: { ...this.event },
            interaction: ListInteraction.UserRemovedFromFavorite,
          });
        }
      });
  }

  public joinEvent(): void {
    this.userEventApiService
      .patchUserEvents({
        join: true,
        eventId: this.event.id,
      })
      .subscribe((_) => {
        this.event.registeredPeople++;
        this.event.eventForCurrentUser = this.hasAvailableSeats
          ? {
              ...this.event.eventForCurrentUser!,
              isRegistered: true,
            }
          : {
              ...this.event.eventForCurrentUser!,
              isOnWaitlist: true,
              waitlistPosition: this.event.onWaitlistPeople + 1,
            };
        this.computeUserEvents();

        this.listService.sendNewInteraction({
          listTitle: 'myUpcoming',
          event: { ...this.event },
          interaction: ListInteraction.UserJoined,
        });
      });
  }

  public joinWaitlist(): void {
    this.userEventApiService
      .patchUserEvents({ eventId: this.event.id, join: true })
      .pipe(
        concatMap(() =>
          this.userEventApiService.getUserEvents(
            this.event.id,
            this.usersService.getUserId(),
          ),
        ),
      )
      .subscribe((userEvents: IUserEvent) => {
        this.event.onWaitlistPeople++;
        this.event.eventForCurrentUser!.waitlistPosition =
          userEvents.waitlistPosition;
        this.event.eventForCurrentUser!.isOnWaitlist = true;
        this.computeUserEvents();
      });
  }

  public exitWaitlist(): void {
    this.userEventApiService
      .patchUserEvents({ eventId: this.event.id, join: false })
      .subscribe((_) => {
        this.event.onWaitlistPeople--;
        this.event.eventForCurrentUser = !!this.event.eventForCurrentUser
          ? {
              ...this.event.eventForCurrentUser,
              isRegistered: false,
              isOnWaitlist: false,
              waitlistPosition: null,
            }
          : {
              ...this.emptyUserEvents,
              isRegistered: false,
              isOnWaitlist: false,
              waitlistPosition: null,
            };
        this.computeUserEvents();
      });
  }

  public leaveEvent(): void {
    this.userEventApiService
      .patchUserEvents({
        join: false,
        eventId: this.event.id,
      })
      .subscribe((_) => {
        this.event.registeredPeople--;
        this.event.eventForCurrentUser = {
          ...this.event.eventForCurrentUser!,
          isRegistered: false,
          isOnWaitlist: false,
          waitlistPosition: null,
        };
        this.computeUserEvents();
      });

    this.listService.sendNewInteraction({
      listTitle: 'myUpcoming',
      event: { ...this.event },
      interaction: ListInteraction.UserNotJoining,
    });
  }

  public onOverlayToggle(
    overlayName: keyof typeof this.overlays,
    event?: MouseEvent | TouchEvent,
  ) {
    event?.preventDefault();
    event?.stopImmediatePropagation();

    Object.keys(this.overlays).forEach((key) => {
      if (key === overlayName) {
        this.overlays[key] = !this.overlays[key];
      } else {
        this.overlays[key as keyof typeof this.overlays] = false;
      }
    });
  }

  public overlayDetach(overlayName: keyof typeof this.overlays) {
    this.overlays[overlayName] = false;
  }

  public onGoingStatusUpdate(value: MatRadioChange) {
    this.selectedGoingButton = value.value;

    if (this.selectedGoingButton === 'going') {
      if (!this.isCurrentUserOnWaitlist && !this.hasAvailableSeats) {
        this.joinWaitlist();
      } else {
        this.joinEvent();
      }
    } else {
      if (this.isCurrentUserOnWaitlist) {
        this.exitWaitlist();
      } else {
        this.leaveEvent();
      }
    }

    this.overlayDetach('goingOverlay');
    this.overlayDetach('goingOverlayMobile');
  }

  public openMaps() {
    if (
      !this.event.latitude &&
      !this.event.longitude &&
      this.event.locationType !== LocationType.InPerson
    ) {
      return;
    }

    window.open(
      `https://www.google.com.sa/maps/search/${this.event.latitude},${this.event.longitude}`,
      '_blank',
    );
  }

  private setLocation(): void {
    this.location = EventHelper.getEventLocation(this.event, undefined, true);
  }

  private setEventDate(): void {
    const date = new Date(`${this.event.startsAtUtc}.000Z`);
    const fullDate = date.toString().split(' ');
    const fullTime = fullDate[4].split(':');

    if (new Date().getFullYear() !== date.getFullYear()) {
      this.startDate = `${fullDate[0]}, ${fullDate[1]} ${fullDate[2]} ${
        fullDate[3]
      }, ${fullTime[0]}:${fullTime[1]} (${DateTime.local().toFormat('ZZZZ')})`;
      return;
    }

    this.startDate = `${fullDate[0]}, ${fullDate[1]} ${fullDate[2]}, ${
      fullTime[0]
    }:${fullTime[1]} (${DateTime.local().toFormat('ZZZZ')})`;
  }

  private getUrlNamePath(): string {
    return StringHelpers.getDashSeparatedString(this.event.title!);
  }

  private setRecordingStatusMessage(): void {
    if (this.event.videoUrl) {
      this.recordingStatusMessage = 'Recording available';
      return;
    }

    if (this.event.isRecorded) {
      this.recordingStatusMessage = 'Recording will be available';
      return;
    }

    this.recordingStatusMessage = '';
  }

  private setSpeakersLabel(): void {
    this.speakers = EventHelper.getSpeakersNamesForView(this.event);
  }

  private computeUserEvents(): void {
    this.isCurrentUserAttending = this.event.isCurrentUserAttending();
    this.isCurrentUserOnWaitlist = this.event.isCurrentUserOnWaitlist();
    this.hasAvailableSeats = this.event.hasAvailableSeats();
    this.waitlistCount = this.event.eventForCurrentUser?.waitlistPosition || 0;
    this.isFavorite = !!this.event.eventForCurrentUser?.isOnFavorite;

    this.selectedGoingButton = this.isCurrentUserAttending
      ? 'going'
      : 'notgoing';
  }

  private onResize(): void {
    const { offsetHeight, offsetWidth } =
      this.eventCardContainer?.nativeElement ?? {};

    const title = this.eventTitle?.nativeElement;
    if (!title || !offsetHeight || !offsetWidth) {
      return;
    }

    // 64 = 32 margin bottom + 32 margin right
    title.style.fontSize = `${(offsetWidth + offsetHeight - 64) / 26}px`;
  }
}
