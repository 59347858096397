import { Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { SessionApiService } from './session-api.service';
import { IStringResponse } from './shared/api/string-response';

@Injectable()
export class SessionService {
  constructor(private sessionApiService: SessionApiService) {}

  public addSessionInStorage(): Observable<string> {
    const existingSession = sessionStorage.getItem('ff_user_session_id');
    if (!!existingSession) {
      return of(existingSession);
    }

    return this.sessionApiService.postNewSession().pipe(
      map((id: IStringResponse) => id.field),
      tap((id: string) => sessionStorage.setItem('ff_user_session_id', id)),
    );
  }
}
