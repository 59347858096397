<div class="member-dialog">
  <div class="close" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </div>

  <div class="profile-picture">
    <img [src]="member.profilePictureUrl" alt="profile picture" />
  </div>

  <div class="full-name">{{ member.firstName }} {{ member.lastName }}</div>

  <div class="location">
    <mat-icon>location_on</mat-icon>

    {{ location }}
  </div>

  <div class="socials">
    @for (socialMedia of userSocialMediaTypes; track socialMedia) {
      @if (socialMedia.url) {
        <a [href]="socialMedia.url" target="_blank"
          ><i class="{{ socialMediaIcons[socialMedia.name] }} mx-1"></i
        ></a>
      }
      @if (!socialMedia.url) {
        <span class="disabled"
          ><i class="{{ socialMediaIcons[socialMedia.name] }} mx-1"></i
        ></span>
      }
    }
  </div>

  <mat-divider></mat-divider>

  <div class="infos">
    <div class="info-row">
      <div class="info">Courses watched:</div>
      <div class="value">
        {{
        (user?.totalCompletedCourses ?? 0) +
        (user?.totalInProgressCourses ?? 0)
        }}
      </div>
    </div>
    <div class="info-row">
      <div class="info">Events participated:</div>
      <div class="value">
        {{
        (user?.totalPreviousEvents ?? 0) + (user?.totalUpcomingEvents ?? 0)
        }}
      </div>
    </div>
    <div class="info-row">
      <div class="info">Discussions started:</div>
      <div class="value">{{ user?.totalUserPosts ?? 0 }}</div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="infos">
    <div class="info-row flex-column">
      <div class="info">Learning preferences:</div>
      <div class="value mt-3">{{ learningPreferences || '-' }}</div>
    </div>
  </div>
</div>
