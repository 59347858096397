import { Inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { concatMap, from, tap } from 'rxjs';
import { API_BASE_URL } from '../../shared/app-constants';
import { DEFAULT_NOTIFICATION_FILTER } from './notification';
import { NotificationApiService } from './notification-api.service';

@Injectable()
export class NotificationSignalRService {
  public connectionId!: string;
  public hubConnection!: signalR.HubConnection;

  constructor(
    private notificationApiService: NotificationApiService,
    @Inject(API_BASE_URL) private apiBaseUrl: string,
  ) {}

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.apiBaseUrl}/notification-hub`, {
        accessTokenFactory: () => localStorage.getItem('at')!,
      })
      .build();

    this.setConnectionId();
  };

  private setConnectionId = () => {
    from(this.hubConnection.start())
      .pipe(
        concatMap((_) => from(this.hubConnection.invoke('getconnectionid'))),
        tap((data) => {
          this.connectionId = data;
        }),
        // concatMap(data => this.notificationApiService.getNotifications(this.connectionId, DEFAULT_NOTIFICATION_FILTER))
      )
      .subscribe();
  };
}
