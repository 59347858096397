<svg
  id="dots"
  width="60px"
  height="20px"
  viewBox="0 0 132 58"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
  >
  <!-- Generator: Sketch 3.5.1 (25234) - http://www.bohemiancoding.com/sketch -->
  <title>dots</title>
  <desc>Created with Sketch.</desc>
  <defs></defs>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
    sketch:type="MSPage"
    >
    @if (light) {
      <g id="dots" sketch:type="MSArtboardGroup" fill="#ffffff">
        <circle
          id="dot1"
          sketch:type="MSShapeGroup"
          cx="25"
          cy="30"
          r="13"
        ></circle>
        <circle
          id="dot2"
          sketch:type="MSShapeGroup"
          cx="65"
          cy="30"
          r="13"
        ></circle>
        <circle
          id="dot3"
          sketch:type="MSShapeGroup"
          cx="105"
          cy="30"
          r="13"
        ></circle>
      </g>
    }
    @if (!light) {
      <g id="dots" sketch:type="MSArtboardGroup"  fill="#000000">
        <circle
          id="dot1"
          sketch:type="MSShapeGroup"
          cx="25"
          cy="30"
          r="13"
        ></circle>
        <circle
          id="dot2"
          sketch:type="MSShapeGroup"
          cx="65"
          cy="30"
          r="13"
        ></circle>
        <circle
          id="dot3"
          sketch:type="MSShapeGroup"
          cx="105"
          cy="30"
          r="13"
        ></circle>
      </g>
    }
  </g>
</svg>
