import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DiscussionItemReport } from 'src/app/shared/discussions/discussion';
import { API_BASE_URL } from 'src/app/shared/app-constants';
import { ISortFilter } from 'src/app/shared/filters/filters';
import { ApiServiceHelper } from 'src/app/shared/helpers';
import { IPage } from 'src/app/shared/pagination';

export interface IDiscussionReportFilter extends ISortFilter {
  includeResolved: boolean;
}

@Injectable()
export class DiscussionItemReportsApiService {
  constructor(
    @Inject(API_BASE_URL) public apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getReports(
    filter: IDiscussionReportFilter,
  ): Observable<IPage<DiscussionItemReport>> {
    const url = `${this.apiBaseUrl}/discussion-item-reports`;
    return this.httpClient.get<IPage<DiscussionItemReport>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }
}
