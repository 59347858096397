import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IStringResponse } from '../shared/api/string-response';

import { API_BASE_URL } from '../shared/app-constants';
import { IFacilitator, IFacilitatorPost } from './facilitator';

@Injectable()
export class FacilitatorApiService {
  constructor(
    private httpClient: HttpClient,
    @Inject(API_BASE_URL) private apiBaseUrl: string,
  ) {}

  public getAll(): Observable<IFacilitator[]> {
    const url = `${this.apiBaseUrl}/facilitators`;
    return this.httpClient.get<IFacilitator[]>(url);
  }

  public createFacilitator(
    faciliator: IFacilitatorPost,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/facilitators`;
    return this.httpClient.post<IStringResponse>(url, faciliator);
  }

  public patchFacilitator(
    id: string,
    faciliator: Partial<IFacilitatorPost>,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/facilitators/${id}`;
    return this.httpClient.patch<IStringResponse>(url, faciliator);
  }
}
