export interface ILocationOption {
  id: string;
  title: string;
  value: any;
}

export interface IEventFilterResultCount {
  nearMeEventsCount: number;
  onlineEventsCount: number;
}

export const LOCATION_OPTIONS: ILocationOption[] = [
  {
    id: 'online-and-near',
    title: 'Online and near me',
    value: {
      onlineAndNearMeEvents: true,
    },
  },
  {
    id: 'only-online',
    title: 'Online',
    value: {
      onlyOnlineEvents: true,
    },
  },
  {
    id: 'only-near-me',
    title: 'Near me',
    value: {
      onlyNearMeEvents: true,
    },
  },
];
