<mat-radio-group
  aria-labelledby="time-interval-radio-group"
  class="radio-group"
  (change)="onChange($event)"
  [(ngModel)]="selectedInterval"
  >
  @for (i of intervals; track i) {
    <mat-radio-button
      class="radio-button"
      [value]="i"
      >
      {{ i.title }}
    </mat-radio-button>
  }
</mat-radio-group>
@if (selectedInterval.custom) {
  <div class="calendar-container">
    <range-calendar
      [startsAfterUtc]="startsAfterUtc"
      [startsBeforeUtc]="startsBeforeUtc"
      (interval)="selectedChange($event)"
    ></range-calendar>
  </div>
}
