@if (faq.length > 0 || isAdmin) {
  <div class="d-flex align-items-center mb-3">
    @if (isAdmin) {
      <button
        color="accent"
        (click)="setAddMode(true)"
        mat-icon-button
        >
        <mat-icon>add</mat-icon>
      </button>
    }
    @if (isAdmin) {
      <button
        color="accent"
        (click)="reorderFaq()"
        mat-icon-button
        >
        <mat-icon>reorder</mat-icon>
      </button>
    }
    <span class="section-title">Frequently asked questions</span>
  </div>
  @if ((addMode || editMode) && isAdmin) {
    <form [formGroup]="faqForm">
      <mat-form-field
        class="w-50"
        appearance="outline"
        floatLabel="always"
        color="accent"
        >
        <mat-label> Question </mat-label>
        <input matInput formControlName="question" />
      </mat-form-field>
      <div>
        <mat-form-field
          class="w-50 textarea-field"
          appearance="outline"
          floatLabel="always"
          color="accent"
          >
          <mat-label> Answer </mat-label>
          <textarea matInput formControlName="answer"></textarea>
        </mat-form-field>
      </div>
      @if (addMode) {
        <button
          mat-flat-button
          class="mb-2"
          (click)="submit()"
          color="accent"
          >
          Submit
        </button>
      }
      @if (editMode) {
        <button
          mat-flat-button
          class="mb-2"
          (click)="submit(true)"
          color="accent"
          >
          Submit
        </button>
      }
    </form>
  }
  @if (!isReorder) {
    <ff-entity-faq-accordion
      [multi]="false"
      [faqs]="faq"
      (onEditClicked)="edit($event)"
      (onDeleteClicked)="deleteFaq($event)"
    ></ff-entity-faq-accordion>
  }
  @if (isReorder) {
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      @for (faqItem of faq; track faqItem) {
        <div class="example-box" cdkDrag>
          {{ faqItem.question }}
        </div>
      }
    </div>
    <button
      mat-flat-button
      color="accent"
      class="mt-2"
      (click)="submitFaqOrder()"
      >
      Submit
    </button>
  }
}
