import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ff-carousel',
  templateUrl: 'ff-carousel.component.html',
  styleUrls: ['ff-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FfCarouselComponent {
  mouseDown = false;

  startX: any;

  scrollLeft: any;

  slider = document.querySelector<HTMLElement>('.ff-carousel');

  startDragging(e: any, flag: any, el: any) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.scrollLeft = el.scrollLeft;
  }
  stopDragging(e: any, flag: any) {
    this.mouseDown = false;
  }
  moveEvent(e: any, el: any) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }

    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startX;
    el.scrollLeft = this.scrollLeft - scroll;
  }
}
