import { Injectable } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

@Injectable()
export class SeoService {
  public defaultImage =
    'https://web-dev.futurefemales.co/assets/images/home-screen.jpg';

  constructor(private title: Title, private meta: Meta) {}

  public updateTitle(title: string): void {
    this.title.setTitle(title);
  }

  public updateMetaTags(metaTags: MetaDefinition[]): void {
    metaTags.forEach((tag) => this.meta.updateTag(tag));
  }
}
