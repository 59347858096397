import { Component, Input, OnInit } from '@angular/core';
import { ShareResourceType } from '../../../services/share.service';
import { SocialMediaTypes } from '../../../share';
import { AbstractButtonComponent } from '../abstract-button/abstract-button.component';
import { IEvent } from '../../../../events/event';
import { ICourseReadModel } from '../../../../courses/courses';

/**
 * Uses only OG:*
 * https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started
 */
@Component({
  selector: 'app-twitter-button',
  templateUrl: './twitter-button.component.html',
  styleUrls: ['./twitter-button.component.scss'],
})
export class TwitterButtonComponent
  extends AbstractButtonComponent
  implements OnInit
{
  @Input() override url!: string;
  @Input() override resourceId!: string;
  @Input() override resourceType!: keyof typeof ShareResourceType;
  @Input() override description!: string;
  @Input()
  public override resource!: Partial<IEvent | ICourseReadModel>;

  public override ngOnInit(): void {
    super.ngOnInit();
    this.loadUrl(SocialMediaTypes.Twitter);
  }
}
