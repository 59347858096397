<div class="preview-container">
  <video-player-mk
    class="w-100 video-player"
    [class.d-none]="!playerLoaded"
    [class.d-block]="playerLoaded"
    [videoUrl]="data.videoUrl"
    [autoplay]="true"
    (videoLoaded)="onPlayerLoad()"
  ></video-player-mk>
</div>
