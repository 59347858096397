import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

export interface IImageGalleryItem {
  image: string;
  alt: string;
  id: string;
}

export type ISelectedGalleryItems = Array<string | number>;

@Component({
  selector: 'app-image-gallery-select',
  templateUrl: './image-gallery-select.component.html',
  styleUrls: ['./image-gallery-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ImageGallerySelectComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ImageGallerySelectComponent,
    },
  ],
})
export class ImageGallerySelectComponent implements OnInit {
  onChange = (images: ISelectedGalleryItems) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  @Input('items')
  public allItems: IImageGalleryItem[] = [];
  public selectedItems: ISelectedGalleryItems = [];

  @Input('reorderMode')
  public reorderMode: boolean = false;
  public reorderArray: IImageGalleryItem[] = [];

  @Output()
  public submitReorder = new EventEmitter<IImageGalleryItem[]>();

  constructor() {}

  public ngOnInit(): void {
    this.reorderArray = this.allItems.slice();
  }

  public onReorderDrop(event: any) {
    const dropEvent = event as CdkDragDrop<
      IImageGalleryItem[],
      IImageGalleryItem[],
      IImageGalleryItem
    >;

    moveItemInArray(
      this.reorderArray,
      dropEvent.previousIndex,
      dropEvent.currentIndex,
    );
  }

  public onSubmitReorder() {
    this.submitReorder.next(this.reorderArray);
  }

  public toggleImageSelection(image: IImageGalleryItem) {
    this.markAsTouched();

    if (this.disabled) {
      return;
    }

    const selectedImageIndex = this.selectedItems.findIndex(
      (imageId) => imageId === image.id,
    );

    if (selectedImageIndex !== -1) {
      this.selectedItems.splice(selectedImageIndex, 1);
    } else {
      this.selectedItems.push(image.id);
    }

    this.onChange(this.selectedItems);
  }

  public isSelected(image: IImageGalleryItem) {
    return this.selectedItems.some((imageId) => imageId === image.id);
  }

  public writeValue(images: ISelectedGalleryItems) {
    this.selectedItems = images;
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
