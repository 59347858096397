<div class="ff-carousel w-100">
  <div
    class="scrolling-wrapper"
    #elemt
    (mousedown)="startDragging($event, false, elemt)"
    (mouseup)="stopDragging($event, false)"
    (mouseleave)="stopDragging($event, false)"
    (mousemove)="moveEvent($event, elemt)"
  >
    <ng-content></ng-content>
  </div>
</div>
