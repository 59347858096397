<h1 mat-dialog-title>New facilitator</h1>
<div mat-dialog-content>
  <form [formGroup]="faciliatorForm">
    <div class="my-2">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>
    </div>
    <div class="my-2">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Bio</mat-label>
        <input matInput type="text" formControlName="bio" />
      </mat-form-field>
    </div>
    <div class="my-2">
      <mat-form-field appearance="outline" floatLabel="always" class="w-100 textarea-field">
        <mat-label>Description</mat-label>
        <textarea matInput type="text" formControlName="description"></textarea>
      </mat-form-field>
    </div>
    <div class="my-2">
      <mat-form-field appearance="outline" floatLabel="always" class="w-100">
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title" />
      </mat-form-field>
    </div>
    <div class="my-2">
      <mat-form-field appearance="outline" floatLabel="always" class="w-100">
        <mat-label>Company</mat-label>
        <input matInput type="text" formControlName="company" />
      </mat-form-field>
    </div>
    <!-- <div class="mt-2">
    <mat-slide-toggle formControlName="isHost">Host</mat-slide-toggle>
  </div> -->
</form>
<div>
  @if (file) {
    <div class="my-2">
      <button mat-flat-button color="accent" (click)="clearProfilePicture()">
        Clear profile picture
      </button>
    </div>
  }
  @if (!file) {
    <div>
      <image-cropper
        [(cropperImage)]="cropperImage"
        (croppedImage)="onProfilePictureSelected($event)"
        [config]="speakerCropperConfig"
        chooseImageLabel="Add profile picture"
      ></image-cropper>
    </div>
  }
  @if (file) {
    <div>
      <img
        style="width: 250px;height: 300px;"
        [src]="croppedImage"
        alt="Profile picture"
        />
    </div>
  }
</div>

@if (errors.length > 0) {
  <div class="mt-4 cross-validation-error-message alert alert-danger">
    @for (error of errors; track error) {
      <div>
        <span>{{ error }}</span>
      </div>
    }
  </div>
}
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button cdkFocusInitial (click)="onSubmitAttempted()">Ok</button>
</div>
